import React from 'react'
import { ItemConfigInterface } from '../../../../../interfaces/ItemConfig.interface'
import SkynetImageFixer from '../../../../atoms/SkynetImageFixer/SkynetImageFixer'

interface EquippedItemCardProps {
  item?: ItemConfigInterface
  className?: string
}

const EquippedItemCard: React.FC<EquippedItemCardProps> = ({
  item,
  className = ''
}) => {
  return (
    <article className={`w-1/3 ${className}`}>
      <div className="flex flex-col w-full justify-center items-center bg-dark-purple pt-2 p-5 min-w-full  rounded-12">
        <SkynetImageFixer src={item?.image} className="pb-2 h-16" alt={item?.name} />

        <p className="font-gilroy-semibold text-center text-p5 leading-6">
          {item?.name}
        </p>
      </div>
    </article>
  )
}

export type { EquippedItemCardProps }
export default EquippedItemCard
