import React from 'react'
import { useFreeBetCoins } from '../../../../hooks/useFreeBets'
import FreeBetCoin from '../../../atoms/FreeBetCoin/FreeBetCoin'

const FreeBetCoinsSection: React.FC = () => {
  const { balance, coins } = useFreeBetCoins()

  if (!balance || balance === 0) {
    return null
  }

  return (
    <section className="mt-20">
      <h2 className="text-p-lg font-gilroy-semibold text-gray-200 mb-10">
        Free Bets ({balance})
      </h2>
      {coins?.map((coin) => {
        return (
          <FreeBetCoin
            coin={coin}
            key={`wallet-free-coin-id-${coin.id.toString()}`}
            className="pointer-events-none mr-4"
          />
        )
      })}
    </section>
  )
}

export default FreeBetCoinsSection
