import React, { useMemo } from 'react'
import classNames from 'classnames'
import { BigNumber, BigNumberish, utils } from 'ethers'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Tournament } from '../../../../hooks/useTournament'
import PlayerInterface from '../../../../interfaces/Player.interface'
import Button from '../../../atoms/Button/Button'
import Icon from '../../../atoms/Icon/Icon'
import {
  useGladiatorEquipments,
  useGladiatorRank
} from '../../../../hooks/useGladiator'
import BoughtItemIndicator from '../BoughtItemIndicator/BoughtItemIndicator'
import PercentageMoveIndicator from '../../../composed/PercentageMoveIndicator/PercentageMoveIndicator'
import { TournamentBetAmounts } from '../../../../hooks/useBetting'
import humanBigNumber from '../../../../utils/humanBigNumber'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import ConnectButton from '../../../atoms/ConnectButton/ConnectButton'
import { useCanPlaceItem } from '../../../../hooks/useEquipment'
import { factionToDisplayname } from '../../../../utils/factionToDisplayName'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

export interface Price {
  usd: number
  change24h: number
}

export type PriceTable = Record<string, Price> // faction => price

interface TournamentTableRowProps {
  gladiator: PlayerInterface
  prices: PriceTable
  onPlaceBet: () => void
  tournament: Tournament
  bet: BigNumber
  userBets: TournamentBetAmounts
  userBetsLoading?: boolean
  equipGladiator: (id: BigNumberish) => void
  away: boolean
  onViewGladDetail: (id: BigNumberish, faction: string) => void
}

interface PlaceBetActionsProps {
  champion?: PlayerInterface
  betPlaced: boolean
  started: boolean
  userBetAmount: BigNumber
  onPlaceBet: () => void
  canBet: boolean
  userBetsLoading?: boolean
  connected: boolean
}

const PlacedBetIndicator: React.FC<{ amount: BigNumber }> = ({ amount }) => {
  const isMiniPTG = useMemo(() => {
    return Number(utils.formatEther(amount)) < 1
  }, [amount])

  return (
    <div className="text-gray-300  text-p2">
      Total bet :{' '}
      <span className="font-gilroy-semibold">
        {humanBigNumber(amount, isMiniPTG)} {isMiniPTG ? 'mPTG' : 'PTG'}
      </span>
    </div>
  )
}

const PlacedBetActions: React.FC<PlaceBetActionsProps> = ({
  champion,
  betPlaced,
  started,
  userBetAmount,
  onPlaceBet,
  connected,
  userBetsLoading,
  canBet
}) => {
  return (
    <>
      {!champion && !started && (
        <div className="flex flex-col items-center">
          {canBet && !userBetsLoading && connected && (
            <>
              <Button
                onClick={onPlaceBet}
                background="bg-dark"
                color="text-primary"
                rounded
                className="border hidden lg:flex border-gray-600 mb-4 text-p4 lg:text-base whitespace-no-wrap"
              >
                {betPlaced ? 'Increase bet' : 'Place Bet'}
              </Button>

              <Button
                onClick={onPlaceBet}
                background="bg-dark"
                color="text-primary"
                rounded
                className="border lg:hidden border-gray-600 mb-4 lg:text-base whitespace-no-wrap"
                padding="px-8 py-3"
              >
                {betPlaced ? 'Increase bet' : 'Bet'}
              </Button>
            </>
          )}
          {betPlaced && <PlacedBetIndicator amount={userBetAmount} />}
        </div>
      )}
    </>
  )
}

const BetPerformanceIndicator: React.FC<{
  ifWonBet: boolean
  userBetAmount: BigNumber
}> = ({ userBetAmount, ifWonBet }) => {
  return (
    <div className="flex flex-col items-end">
      <div
        className={classNames(
          'flex mb-3 items-center uppercase font-gilroy-bold  text-p3',
          ifWonBet ? 'text-green-600' : 'text-red-600'
        )}
      >
        <Icon
          icon="thumb_up"
          color={ifWonBet ? 'text-green-600' : 'text-red-600'}
          fontSize={16}
          className="mr-4"
        />
        Bet {ifWonBet ? 'won' : 'lost'}
      </div>
      <PlacedBetIndicator amount={userBetAmount} />
    </div>
  )
}

const TournamentTableRow: React.FC<TournamentTableRowProps> = ({
  gladiator,
  onPlaceBet,
  prices,
  tournament,
  bet,
  userBets,
  userBetsLoading,
  equipGladiator,
  away,
  onViewGladDetail
}) => {
  const { connected } = useWeb3Context()
  const { rank } = useGladiatorRank(gladiator.id.toString())
  const { champion, started, gladiators } = tournament
  const gladiatorEquipments = useGladiatorEquipments(
    tournament.tournamentId,
    gladiator?.id
  )
  const { canPlaceItem } = useCanPlaceItem(
    tournament.notBefore!,
    BigNumber.from(tournament.tournamentId),
    BigNumber.from(gladiator.id)
  )

  const registrationIndex = gladiators.findIndex((glad) => {
    return BigNumber.from(glad.id).eq(gladiator.id)
  })

  function isEven(num: BigNumberish) {
    return BigNumber.from(num).mod(2).eq(0)
  }

  function toHex(num: BigNumberish) {
    return BigNumber.from(num).toHexString()
  }

  const betPlaced = useMemo(() => {
    return (
      userBets[BigNumber.from(gladiator.id).toHexString()] &&
      userBets[BigNumber.from(gladiator.id).toHexString()].gt(0)
    )
  }, [userBets, gladiator.id])

  const userBetAmount = useMemo(() => {
    return userBets[BigNumber.from(gladiator.id).toHexString()]
  }, [userBets, gladiator.id])

  const canBet = useMemo(() => {
    if (betPlaced) return true

    if (isEven(registrationIndex)) {
      // if the number is even then you can't bet on the one just above
      const bet = userBets[toHex(gladiators[registrationIndex + 1].id)]
      if (!bet || bet.eq(0)) {
        return true
      }
    } else {
      // otherwise if it's odd we look down in the index
      const bet = userBets[toHex(gladiators[registrationIndex - 1].id)]
      if (!bet || bet.eq(0)) {
        return true
      }
    }

    return false
  }, [userBets, registrationIndex, gladiators, betPlaced])

  const hideAddItemsButton = useMemo(() => {
    return (
      !canPlaceItem ||
      !connected ||
      champion ||
      started ||
      (gladiatorEquipments && gladiatorEquipments.length >= 3)
    )
  }, [canPlaceItem, connected, champion, started, gladiatorEquipments])

  return (
    <>
      <tr
        key={`gladiatorRow-${gladiator.id}`}
        className={classNames(
          'border-gray-800 border-r border-l rounded-lg',
          away ? 'border-b' : 'border-t'
        )}
      >
        <td className="pl-12 ">
          <div className=" flex flex-col items-center mr-8">
            <p className="text-gray-400 text-p1 font-gilroy-medium">{rank}</p>

            <div className="lg:hidden">
              <PlacedBetActions
                champion={champion}
                betPlaced={betPlaced}
                started={started}
                userBetAmount={userBetAmount}
                onPlaceBet={onPlaceBet}
                connected={connected}
                userBetsLoading={userBetsLoading}
                canBet={canBet}
              />
            </div>
          </div>
        </td>

        <td className="py-10 w-1/3">
          <div className="flex  items-center border-gray-800 bg-dark py-10 pr-16">
            <Link
              to={`/gladiator/${gladiator?.id}?faction=${gladiator?.faction}`}
            >
              <StyleGladiatorImage
                src={gladiator.image}
                alt={gladiator.name}
                className="rounded-full"
              />
            </Link>
            <div className="ml-6">
              <div className="flex items-center">
                <Link
                  to={`/gladiator/${gladiator?.id}?faction=${gladiator?.faction}`}
                >
                  <p className="font-gilroy-medium whitespace-nowrap text-gray-100 text-p-lg">
                    {gladiator.name}
                  </p>
                </Link>
                <button
                  onClick={() => {
                    onViewGladDetail(gladiator.id, gladiator?.faction)
                  }}
                  className="focus:outline-none ml-4"
                >
                  <img src="/images/icons/info.svg" alt="info icon" />
                </button>
              </div>
              <div className="mr-24 lg:mr-0 flex">
                {gladiator.tagline && (
                  <p className="font-gilroy-medium whitespace-no-wrap text-gray-300  text-p3">
                    {gladiator.tagline}
                  </p>
                )}
              </div>
              {!away && (
                <div className=" h-24 -mb-20 flex items-end">
                  <p className="text-p-lg font-bold font-neversaydie text-primary -mb-4">
                    VS
                  </p>
                </div>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="flex items-center bg-dark pr-8 border-gray-800">
            <p className="font-gilroy-bold mr-10 lg:mr-0 leading-3 align-middle text-green-600 text-p-lg">
              {humanBigNumber(bet)}
            </p>
          </div>
        </td>
        <td>
          <div className="flex items-center bg-dark pr-4">
            <img
              className="h-12 w-12 mr-5"
              alt={`${factionToDisplayname(gladiator?.faction)} faction`}
              src={`/factions/${gladiator?.faction}.png`}
            />

            <div>
              <p className="font-gilroy-medium text-p2 text-gray-100">
                {factionToDisplayname(gladiator.faction)}
              </p>
              <div className="flex items-center">
                <p className="text-p3 text-gray-300 font-gilroy-regular mr-2">
                  ${prices[gladiator.faction].usd}
                </p>

                <div className="flex items-center min-h-full pr-24 lg:pr-0">
                  {prices[gladiator.faction].change24h < 0 ? (
                    <PercentageMoveIndicator
                      percent={prices[gladiator.faction].change24h}
                      down
                    />
                  ) : (
                    <PercentageMoveIndicator
                      percent={prices[gladiator.faction].change24h}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="flex items-center justify-between bg-dark pr-16 rounded-tr-24 rounded-br-24">
            {!connected && !champion && !started && (
              <ConnectButton
                customText="Sign in to bet or place items"
                background="bg-transparent"
                className="border-primary border"
              />
            )}
            {gladiatorEquipments?.length ? (
              <div className="flex items-center mt-3">
                {gladiatorEquipments?.map((item, i) => (
                  <BoughtItemIndicator
                    item={item}
                    key={`boughtItem-${item.id.toString()}-${gladiator.id.toString()}-${i}`} // eslint-disable-line react/no-array-index-key
                  />
                ))}

                {!hideAddItemsButton && (
                  <AddItemButton
                    onClick={() => equipGladiator(gladiator?.id as string)}
                    className="bg-primary rounded-full flex items-center focus:outline-none justify-center"
                  >
                    <Icon icon="add" fontSize={25} />
                  </AddItemButton>
                )}
              </div>
            ) : (
              <div>
                {!hideAddItemsButton && (
                  <Button
                    onClick={() => equipGladiator(gladiator?.id as string)}
                    background="bg-dark"
                    color="text-primary"
                    rounded
                    className="border border-gray-600 whitespace-no-wrap mr-8"
                  >
                    Add Items
                  </Button>
                )}
              </div>
            )}

            {!champion && betPlaced && started && (
              <PlacedBetIndicator amount={userBetAmount} />
            )}
            {champion && betPlaced && started && (
              <BetPerformanceIndicator
                ifWonBet={champion?.id.toString() === gladiator.id.toString()}
                userBetAmount={userBetAmount}
              />
            )}

            <PlacedBetActions
              champion={champion}
              betPlaced={betPlaced}
              started={started}
              userBetAmount={userBetAmount}
              onPlaceBet={onPlaceBet}
              connected={connected}
              userBetsLoading={userBetsLoading}
              canBet={canBet}
            />
          </div>
        </td>
      </tr>
    </>
  )
}

const AddItemButton = styled.button`
  background: #ff6240;
  height: 36px;
  width: 36px;
`

const StyleGladiatorImage = styled(SkynetImageFixer)`
  min-width: 6rem;
  max-width: 6rem;
  min-height: 6rem;
  min-height: 6rem;
`

export type { TournamentTableRowProps }
export default TournamentTableRow
