import { useMemo } from 'react'
import { BigNumber } from 'ethers'
import axios from 'axios'
import useSWR from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import gameChain from '../models/GameChain'
import { Bundler } from '../types/contracts'
import ThenArg from '../utils/ThenArg'

export type BundledItem = ThenArg<ReturnType<typeof bundledItemsFetcher>>[0]

const fetchItem = async (bundler: Bundler, id: BigNumber) => {
  const url = await bundler.uri(id)
  const resp = await axios.get(url.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'))
  const addrs = await bundler.bundledAddresses(id)
  return {
      ...resp.data,
      bundled: addrs, // TODO should also have IDs
      id,
  }
}

const bundledItemsFetcher = async () => {
  const { safeAddress, contracts } = gameChain
  const { bundler } = await contracts
  if (!safeAddress) {
    throw new Error('no safe address')
  }

  try {
    const itemIds = await bundler.addressTokens(safeAddress)
    const items = Promise.all(
      itemIds.map((id) => {
        return fetchItem(bundler, id)
      })
    )

    return items
  } catch (err) {
    console.error('error fetching bundled items: ', err)
    throw err
  }
}

const _unbundle = async (safeAddress:string, id:BigNumber) => {
    const { contracts } = gameChain
    const { bundler } = await contracts
    console.log('unbundling  ', safeAddress, id)

    return bundler.unbundleAndBurn(id, safeAddress)
}

export const useBundledItems = () => {
  const { safeAddress } = useWeb3Context()

  const { data, isValidating, revalidate } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('no safe address')
      }
      return ['/bundled-items/', safeAddress]
    },
    {
      fetcher: bundledItemsFetcher
    }
  )

  const unbundle = useMemo(() => {
    return (id:BigNumber) => {
        if (!safeAddress) {
            throw new Error('no safe address')
        }
        return _unbundle(safeAddress, id)
    }
  }, [safeAddress])

  return {
    items: data,
    loading: !!data,
    isValidating,
    revalidate,
    unbundle,
  }
}
