import React, { useCallback } from 'react'
import { useWeb3Context } from '../../../contexts/Web3Context'
import Button from '../Button/Button'
import Modal, { ModalProps } from '../Modal/Modal'

export interface WalletChooserProps extends ModalProps {}

const WalletChooser: React.FC<WalletChooserProps> = ({
  showModal,
  onModalClose
}) => {
  const { connect, connectMetamask } = useWeb3Context()

  const traditionalConnect = useCallback(async () => {
    connect()
    onModalClose()
  }, [connect, onModalClose])

  const metamaskConnect = useCallback(async () => {
    connectMetamask()
    onModalClose()
  }, [connectMetamask, onModalClose])

  return (
    <Modal showModal={showModal} onModalClose={onModalClose}>
      <div className="bg-black flex flex-col items-center pb-20">
        <div className="font-gilroy-semibold mt-8 mb-4 text-h5 text-center py-20">
          <p>Choose your wallet</p>
          <div className="mt-20">
            <Button onClick={metamaskConnect}>
              Web3 Login (Metamask, etc)
            </Button>

            <p className="my-8">or</p>

            <Button onClick={traditionalConnect}>Traditional Login (google, email, etc)</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WalletChooser
