import React from 'react'
import styled from 'styled-components'
import { ResponsiveRadar } from '@nivo/radar'

export interface GladiatorSkillsChartProps {
  stats: {
    hitpoints: number
    defense: number
    attack: number
    pop: number
  }
}

const GladiatorSkillsChart: React.FC<GladiatorSkillsChartProps> = ({
  stats: { hitpoints, defense, attack, pop }
}) => {
  const data = [
    {
      title: 'Hit Points',
      value: hitpoints
    },
    {
      title: 'Defense',
      value: defense
    },
    {
      title: 'Attack',
      value: attack
    },
    {
      title: 'POP',
      value: pop
    }
  ]
  return (
    <Container className=" flex items-center justify-center">
      <ResponsiveRadar
        data={data}
        keys={['value']}
        indexBy="title"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={0}
        gridLevels={2}
        enableDots={false}
        gridShape="linear"
        gridLabelOffset={10}
        colors={{ scheme: 'spectral' }}
        fillOpacity={0.65}
        animate={true}
        isInteractive={false}
      />
    </Container>
  )
}

const Container = styled.figure`
  height: 300px;
`

export default GladiatorSkillsChart
