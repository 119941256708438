import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import Icon from '../../atoms/Icon/Icon'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  onClick?: () => void
}

const BackButton: React.FC<ButtonProps> = ({ className, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'text-p3 font-gilroy-semibold flex focus:outline-none uppercase items-center text-primary',
        className
      )}
    >
      <Icon icon="keyboard_arrow_left" color="text-primary" fontSize={24} />
      <span>go back</span>
    </button>
  )
}

BackButton.defaultProps = {
  onClick: () => history.back()
}

export default BackButton
