/* eslint-disable */
import { Token } from '@uniswap/sdk'
import { BigNumber, BigNumberish, utils } from 'ethers'
import { useCallback } from 'react'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import gameChain, { Contracts } from '../models/GameChain'
import { IUniswapV2Pair__factory } from '../types/contracts'
import { ALLOWED_TO_WITHDRAW_PATH, KILL_SWITCH_PATH } from '../utils/logger'
import { USER_PRESTIGE_KEY } from './usePrestigeBalance'

const WITHDRAWL_FEE_ACCOUNT = '0xB146Bc5244E74A04B5Fa70561267BEFf43873b72'

const getReserves = async () => {
  const { contracts, provider, wrappedMaticAddress } = gameChain
  if (!contracts) {
    throw new Error('no contracts to get reserves')
  }
  const { wrappedPTG, quickswapFactory } = await contracts
  const pairAddr = await quickswapFactory.getPair(
    wrappedMaticAddress,
    wrappedPTG.address
  )
  const pair = IUniswapV2Pair__factory.connect(pairAddr, provider)

  const wMatic = new Token(137, wrappedMaticAddress, 18)
  const wPTG = new Token(137, wrappedPTG.address, 18)
  const tokens = [wMatic, wPTG]
  const [token0] = tokens[0].sortsBefore(tokens[1])
    ? tokens
    : [tokens[1], tokens[0]]

  const [reserve0, reserve1] = await pair.getReserves()
  const [maticReserve, ptgReserve] = token0.equals(wMatic)
    ? [reserve0, reserve1]
    : [reserve1, reserve0]
  console.log('reserves: ', [maticReserve, ptgReserve])
  return [maticReserve, ptgReserve]
}

export const usePtgMaticPair = () => {
  const { contracts, safeAddress, relayer, prestigeID } = useWeb3Context()

  const swap = useCallback(async (inAmount: BigNumber, expectedOut: BigNumber, to: string) => {
      if (BigNumber.from(inAmount).eq(0)) {
        throw new Error('no swapping for 0')
      }
      if (!contracts) {
        throw new Error('no contracts in getOutput')
      }
      if (!safeAddress) {
        throw new Error('no safe address')
      }
      if (!relayer) {
        throw new Error('no relayer')
      }

      const { wrappedPTG, assets, quickswapRouter } = await contracts
      const { wrappedMaticAddress } = gameChain

      // first we wrap the PTG
      const wrapTx = await assets.populateTransaction.safeTransferFrom(safeAddress, wrappedPTG.address, await prestigeID, inAmount, [])
      // then we approve the quickswap router
      const approveTx = await wrappedPTG.populateTransaction.approve(quickswapRouter.address, inAmount.mul(105).div(100))
      // // then we swap
      const swapTx =
        await quickswapRouter.populateTransaction.swapTokensForExactETH(
          expectedOut,
          inAmount.mul(1000).div(1005), // allow .5% slippage,
          [wrappedPTG.address, wrappedMaticAddress],
          to,
          BigNumber.from(Math.floor(new Date().getTime() / 1000 + 100)),
        )
      // then we pay the good guys
      const withdrawlFeeTx = {
        to: WITHDRAWL_FEE_ACCOUNT,
        from: safeAddress!,
        value: expectedOut.mul(2).div(100)
      }

      const txP = relayer.multisend([wrapTx, approveTx, swapTx, withdrawlFeeTx])

      txP.then(async (tx) => {
        try {
          await tx.wait()
          mutate(['/matic-balance', safeAddress], (prevBal: BigNumber) => {
            return prevBal.add(inAmount)
          })
          mutate(['/wrapped-ptg-balance', safeAddress])
          mutate(USER_PRESTIGE_KEY, (prevBal: BigNumber) => {
            return prevBal.sub(expectedOut)
          })
        } catch (err) {
          console.error('error on tx: ', err)
        }
      })

      return txP
  }, [contracts, safeAddress, relayer, prestigeID])

  const getOutput = useCallback(
    async (inAmount: BigNumberish) => {
      try {
        if (BigNumber.from(inAmount).eq(0)) {
          return BigNumber.from('0')
        }
        if (!contracts) {
          throw new Error('no contracts in getOutput')
        }
        const { quickswapRouter } = await contracts

        const [maticReserve, ptgReserve] = await getReserves()
        const out = await quickswapRouter.getAmountOut(
          inAmount,
          ptgReserve,
          maticReserve
        )
        console.log('out: ', out)
        return out.mul(98).div(100) // return with the minus 2%
      } catch (err) {
        console.error('err in get output: ', err)
        throw err
      }
    },
    [contracts]
  )

  return {
    swap,
    getOutput
  }
}

const useMaticPTGPair = () => {
  const { contracts, safeAddress, relayer } = useWeb3Context()

  const swap = useCallback(
    async (inAmount: BigNumberish, expectedOut: BigNumber, to: string) => {
      if (BigNumber.from(inAmount).eq(0)) {
        throw new Error('no swapping for 0')
      }
      if (!contracts) {
        throw new Error('no contracts in getOutput')
      }
      if (!safeAddress) {
        throw new Error('no safe address')
      }
      if (!relayer) {
        throw new Error('no relayer')
      }
      const { quickswapRouter, wrappedPTG, kasumahValueLogger } =
        await contracts
      const { wrappedMaticAddress } = gameChain

      const swapTx =
        await quickswapRouter.populateTransaction.swapExactETHForTokens(
          expectedOut.mul(100).div(101), // allow 1% slippage
          [wrappedMaticAddress, wrappedPTG.address],
          to,
          BigNumber.from(Math.floor(new Date().getTime() / 1000 + 100)),
          {
            value: inAmount
          }
        )
      // Unfortunately we don't know the exact output of PTG because of the 1% slippage allowance
      // so we can only unwrap 99% of it - which will create change in the UI, but at least most of the PTG
      // will be unwrapped.
      const unwrapTx = await wrappedPTG.populateTransaction.unwrap(
        safeAddress,
        safeAddress,
        expectedOut.mul(100).div(101)
      )

      const allowWithdrawTx = await kasumahValueLogger.populateTransaction.set(
        safeAddress,
        ALLOWED_TO_WITHDRAW_PATH,
        utils.defaultAbiCoder.encode(['bool'], [true])
      )

      const txP = relayer.multisend([swapTx, unwrapTx, allowWithdrawTx])

      txP.then(async (tx) => {
        await tx.wait()
        mutate(['/matic-balance', safeAddress], (prevBal: BigNumber) => {
          return prevBal.sub(inAmount)
        })
        mutate(['/wrapped-ptg-balance', safeAddress])
        mutate(USER_PRESTIGE_KEY, (prevBal: BigNumber) => {
          return prevBal.add(expectedOut)
        })
      })

      return txP
    },
    [contracts, safeAddress, relayer]
  )

  const getOutput = useCallback(
    async (inAmount: BigNumberish) => {
      try {
        if (BigNumber.from(inAmount).eq(0)) {
          return BigNumber.from('0')
        }
        if (!contracts) {
          throw new Error('no contracts in getOutput')
        }
        const { quickswapRouter } = await contracts

        const [maticReserve, ptgReserve] = await getReserves()
        const out = await quickswapRouter.getAmountOut(
          inAmount,
          maticReserve,
          ptgReserve
        )
        console.log('out: ', out)
        return out
      } catch (err) {
        console.error('err in get output: ', err)
        throw err
      }
    },
    [contracts]
  )

  return {
    swap,
    getOutput
  }
}

export async function getKillSwitch() {
  const { contracts, deployerAddress } = gameChain
  const { kasumahValueLogger } = await contracts
  try {
    const resp = await kasumahValueLogger.latest(await deployerAddress, KILL_SWITCH_PATH)
    return utils.defaultAbiCoder.decode(['bool'], resp)[0]
  } catch {
    return false
  }
}

async function getUserAllowed() {
  const { contracts, safeAddress } = gameChain
  const { kasumahValueLogger } = await contracts
  try {
    return await kasumahValueLogger.latest(safeAddress!, ALLOWED_TO_WITHDRAW_PATH)
  } catch {
    return false
  }
}

async function getAllowListAllowed() {
  const { contracts, deployerAddress, safeAddress } = gameChain
  const { kasumahValueLogger } = await contracts
  try {
    return await kasumahValueLogger.latest(await deployerAddress, `${ALLOWED_TO_WITHDRAW_PATH}/${safeAddress}`)
  } catch {
    return false
  }
}

export default useMaticPTGPair
