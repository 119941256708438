/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IGameEquipment,
  IGameEquipmentInterface,
} from "../IGameEquipment";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getMetadata",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "int256",
            name: "hitPoints",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "attack",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "defense",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "percentChanceOfUse",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "numberOfUsesPerGame",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "createdAt",
            type: "bytes32",
          },
        ],
        internalType: "struct IGameEquipment.EquipmentMetadata",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IGameEquipment__factory {
  static readonly abi = _abi;
  static createInterface(): IGameEquipmentInterface {
    return new utils.Interface(_abi) as IGameEquipmentInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IGameEquipment {
    return new Contract(address, _abi, signerOrProvider) as IGameEquipment;
  }
}
