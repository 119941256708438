import React from 'react'
import classNames from 'classnames'
import mainbackground from '../assets/images/backgrounds/main.png'
import Navbar from '../components/composed/Navbar/Navbar'
import WelcomeModal from '../components/composed/WelcomeModal/WelcomeModal'
import BattleProgressIndicator from '../components/composed/BattleProgressIndicator/BattleProgressIndicator'
import { useTournamentInProgress } from '../hooks/useTournament'
import DiscordBanner from '../components/composed/DiscordBanner/DiscordBanner'

export interface LayoutProps {
  addPadding?: boolean
  showBackground?: boolean
  showProgressbanner?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  addPadding,
  showBackground = true,
  showProgressbanner = true
}) => {
  const tournamentInProgressId = useTournamentInProgress()

  return (
    <div className="flex pt-5 w-full relative pb-10 items-center flex-col">
      <Navbar />

      {showProgressbanner && tournamentInProgressId && (
        <BattleProgressIndicator tournamentId={tournamentInProgressId} />
      )}
      <div
        className={classNames(
          'flex z-10 mt-32 pt-5 w-full relative pb-10 min-h-full items-center flex-col',
          addPadding && 'lg:px-56 px-6'
        )}
      >
        {children}
      </div>

      {showBackground && (
        <img className="fixed z-0" src={mainbackground} alt="background" />
      )}

      <WelcomeModal />

      <DiscordBanner />
    </div>
  )
}

export default Layout
