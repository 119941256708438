import React, { useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import NavLink from '../../atoms/NavLink/NavLink'
import Logo from '../../atoms/Logo/Logo'
import Icon from '../../atoms/Icon/Icon'
import useGasSubsidy from '../../../hooks/useGasSubsidy'

interface NavLinkInterface {
  title: string
  href: string
}

const navLinks: NavLinkInterface[] = [
  {
    title: 'Tournaments',
    href: '/'
  },
  {
    title: 'Leaderboard',
    href: '/leaderboard'
  },
  {
    title: 'Gladiator Ranks',
    href: '/gladiators'
  },
  {
    title: 'NFT Marketplace',
    href: '/marketplace'
  },
  {
    title: 'Auctions',
    href: '/auctions'
  },
]

export interface MobileMenuProps {}

const MobileMenu: React.FC<MobileMenuProps> = () => {
  const [showHiddenDropdown, setShowHiddenDropdown] = useState(false)
  const { disabled, toggle } = useGasSubsidy()
  let timeOutId: number | any = 0

  const onClickHandler = () => {
    setShowHiddenDropdown(!showHiddenDropdown)
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setShowHiddenDropdown(false)
    }, 10)
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId)
  }

  return (
    <div
      className="mr-4  lg:hidden"
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
    >
      <button
        className="flex items-center focus:outline-none"
        onClick={onClickHandler}
      >
        <img alt="collapse" src="/images/icons/collapse.svg" />
      </button>

      <HiddenDropDown
        className={classNames(
          'bg-dark p-6',
          showHiddenDropdown ? 'block' : 'hidden'
        )}
        style={{
          transform: showHiddenDropdown ? 'scale(1)' : 'scale(0)'
        }}
      >
        <div className=" flex justify-between">
          <Logo />

          <div>
            <button
              onClick={onClickHandler}
              className="focus:outline-none w-auto inline-block"
            >
              <Icon fontSize={30} className="text-gray-300" icon="close" />
            </button>
          </div>
        </div>
        <ul className=" px-8 mt-12">
          {navLinks.map(({ href, title }) => (
            <li key={title} className="mr-16">
              <NavLink href={href} active={location.pathname === href}>
                {title}
              </NavLink>
            </li>
          ))}
          <li className="mr-16">
            <a
              target="_blank"
              rel="noreferrer"
              className="text-white opacity-50 text-p2 uppercase font-gilroy-semibold"
              href="https://docs.cryptocolosseum.com"
            >
              Learn More
            </a>
          </li>
          <li className="text-white opacity-50 text-p3 uppercase font-gilroy-semibold">
            Gasless: <button onClick={toggle} className="underline pb-0 ml-2">{disabled ? 'off' : 'on'}</button>
          </li>
        </ul>
      </HiddenDropDown>
    </div>
  )
}

const HiddenDropDown = styled.nav`
  position: absolute;
  top: 0rem;
  left: 0;
  transition: 0.2s all;
  transform-origin: 100% 0;
  min-width: 80vw;
  max-width: 80vw;
  transition: 0.2s all;
  background: #151520;
  height: 100vh;

  z-index: 1000;
  border: 1px solid var(--color-black);
  border-radius: 8px;
  overflow: hidden;

  ul {
    list-style: none;
    background: var(--color-white);
  }

  li,
  button {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
`

export default MobileMenu
