/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/atoms/Button/Button'
import Icon from '../components/atoms/Icon/Icon'
import Spinner from '../components/atoms/Spinner/Spinner'
import BackButton from '../components/composed/BackButton/BackButton'
import AiNarrative, {
  LiveNarrative
} from '../components/page-specific/battle/AiNarrative/AiNarrative'
import FactionPerformanceCard from '../components/page-specific/battle/Cards/FactionPerformanceCard/FactionPerformanceCard'
import GladiatorBattleCard from '../components/page-specific/battle/Cards/GladiatorBattleCard/GladiatorBattleCard'
import GladiatorHealthBar from '../components/page-specific/battle/GladiatorHealthBar/GladiatorHealthBar'
import ChampionCard from '../components/page-specific/betting-tournament/Cards/ChampionCard/ChampionCard'
import { PriceTable } from '../components/page-specific/betting-tournament/TournamentTableRow/TournamentTableRow'
import { useBlowByBlow, narrativesToEnglish } from '../hooks/useBlowByBlow'
import { useGladiatorEquipments } from '../hooks/useGladiator'
import { usePrice } from '../hooks/usePricing'
import { useTournament } from '../hooks/useTournament'
import { Layout } from '../layouts/Layout'

const Blow: React.FC<{ narrative: string }> = ({ narrative }) => {
  return (
    <pre className="text-p2 whitespace-pre-wrap p-8 bg-black bg-opacity-25">
      {narrative}
    </pre>
  )
}

const LiveBlowText: React.FC<{
  narrative: ReturnType<typeof narrativesToEnglish>[0]
}> = ({ narrative }) => {
  return (
    <div className="text-primary text-h5">
      <p className="p-8">
        rolls: {narrative.gameInfo.rolls.attack.toString()} /{' '}
        {narrative.gameInfo.rolls.defense.toString()}
      </p>
      {!!narrative.gameInfo.attacker.uses.length && (
        <p className="p-8">
          {narrative.gameInfo.attacker.name} uses:{' '}
          {narrative.gameInfo.attacker.uses
            .map((itemMeta) => itemMeta.name)
            .join(', ')}
        </p>
      )}
      {!!narrative.gameInfo.defender.uses.length && (
        <p className="p-8">
          {narrative.gameInfo.defender.name} uses:{' '}
          {narrative.gameInfo.defender.uses.map((itemMeta) => itemMeta.name)}
        </p>
      )}
    </div>
  )
}

interface IGameEndedSection {
  title: 'Battle Scene' | 'Commentary'
  image: 'battle-indicator' | 'commentary-indicator'
}

const BattlePage: React.FC = () => {
  const {
    tournamentId,
    roundNumber: roundNumberString,
    gameNumber: gameNumberString
  } = useParams<{
    tournamentId: string
    roundNumber: string
    gameNumber: string
  }>()
  const tournamentInfo = useTournament(tournamentId)
  const roundNumber = parseInt(roundNumberString, 10)
  const gameNumber = parseInt(gameNumberString, 10)

  const [gameEndedActiveSection, setGameEndedActiveSection] = useState<
    'Battle Scene' | 'Commentary'
  >('Battle Scene')

  const { data: btcPrice } = usePrice('BTC')
  const { data: ethPrice } = usePrice('ETH')
  const { data: maticPrice } = usePrice('MTC')

  const { blowByBlow, loading } = useBlowByBlow(
    tournamentId,
    roundNumber,
    gameNumber
  )

  const [userDefinedIndex, setUserDefinedIndex] = useState(-1)

  const lastNarrativeIndex = (blowByBlow?.narrativesToEnglish?.length || 1) - 1 // default to 0

  const hasBlowByBlow = (blowByBlow?.narrativesToEnglish?.length || 0) > 0

  const currentIndex = blowByBlow?.gameEnded
    ? userDefinedIndex
    : lastNarrativeIndex

  const round = tournamentInfo.tournament.bracket[roundNumber]
  const game = round ? round[gameNumber] : undefined

  const competingGladiators = {
    home: game ? game[0] : undefined,
    away: game ? game[1] : undefined
  }

  const homeEquipment = useGladiatorEquipments(
    tournamentId,
    competingGladiators.home?.id
  )

  const awayEquipment = useGladiatorEquipments(
    tournamentId,
    competingGladiators.away?.id
  )

  if (loading || tournamentInfo.loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    )
  }

  if (!competingGladiators.home || !competingGladiators.away) {
    throw new Error('should not happen, gladiators were undefined')
  }

  const getGladiatorPosition = (name: string, index = currentIndex) => {
    if (!hasBlowByBlow || !blowByBlow) return null // a little hacky to also specify blowByBlow here but makes typescript happy

    let gameInfoIndex = index < 0 ? 0 : index
    gameInfoIndex =
      gameInfoIndex > lastNarrativeIndex ? lastNarrativeIndex : gameInfoIndex

    const currentBlowByBlow =
      blowByBlow.narrativesToEnglish[gameInfoIndex].gameInfo

    if (currentBlowByBlow.attacker.name === name) {
      return {
        attacker: true,
        glad: currentBlowByBlow.attacker
      }
    }

    return {
      attacker: false,
      glad: currentBlowByBlow.defender
    }
  }

  const moveBattle = (previousAction: boolean) => {
    if (!blowByBlow?.narrativesToEnglish) return

    if (previousAction && userDefinedIndex >= 0) {
      setUserDefinedIndex((mi) => mi - 1)
      return
    }

    if (userDefinedIndex <= lastNarrativeIndex + 1) {
      setUserDefinedIndex((mi) => mi + 1)
    }
  }

  const pricing: PriceTable = {
    BTC: {
      usd: btcPrice.usd,
      change24h: btcPrice.usd_24h_change
    },
    ETH: {
      usd: ethPrice.usd,
      change24h: ethPrice.usd_24h_change
    },
    MTC: {
      usd: maticPrice.usd,
      change24h: maticPrice.usd_24h_change
    }
  }

  const gameEndedSections: IGameEndedSection[] = [
    {
      title: 'Battle Scene',
      image: 'battle-indicator'
    },
    {
      title: 'Commentary',
      image: 'commentary-indicator'
    }
  ]

  const showChampionCard =
    blowByBlow?.gameEnded &&
    (userDefinedIndex === -1 || userDefinedIndex === lastNarrativeIndex + 1)

  const getChampion = () => {
    if (!competingGladiators.home || !competingGladiators.away) return null

    const homeHealth = getGladiatorPosition(
      competingGladiators?.home?.name,
      lastNarrativeIndex
    )?.glad.currentHp
    const awayHealth = getGladiatorPosition(
      competingGladiators?.away?.name,
      lastNarrativeIndex
    )?.glad.currentHp
    if (!homeHealth || !awayHealth) {
      return null
    }

    if (homeHealth < 0) return competingGladiators?.away
    if (awayHealth < 0) return competingGladiators?.home

    return null
  }

  return (
    <Layout showBackground={false} showProgressbanner={false}>
      <div className="w-full max-w-full flex flex-wrap lg:ml-24">
        <BackButton />
        {blowByBlow?.gameEnded ? (
          <div className="lg:px-24 mt-12 lg:mt-0 flex items-center justify-center lg:justify-start">
            {gameEndedSections.map((section) => (
              <Button
                key={section.title}
                background="bg-transparent"
                rounded
                className={` focus:outline-none border mx-3 ${
                  section.title === gameEndedActiveSection
                    ? 'border-primary '
                    : ' border-transparent'
                }`}
                color={
                  section.title === gameEndedActiveSection
                    ? 'text-primary'
                    : 'text-white'
                }
                onClick={() => setGameEndedActiveSection(section.title)}
              >
                <img
                  src={`/images/symbols/${section.image}.svg`}
                  alt={section.title}
                  className="mr-3"
                />
                {section.title}
              </Button>
            ))}
          </div>
        ) : (
          <div className="flex-grow hidden lg:flex justify-center">
            <div className="flex w-3/4">
              {Object.keys(pricing).map((faction) => (
                <FactionPerformanceCard
                  className="mx-8"
                  key={`battle-page-faction-${faction}`}
                  faction={faction}
                  price={pricing[faction]}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {gameEndedActiveSection === 'Battle Scene' ? (
        <Container className="w-full mt-8 py-20 px-8 lg:px-20 bg-opacity-0">
          {blowByBlow?.gameEnded && (
            <div className="flex flex-col lg:flex-row items-center justify-center mb-12">
              <Button
                background="bg-dark"
                rounded
                className="mx-8 focus:outline-none border border-transparent focus:border-primary"
                disabled={userDefinedIndex <= 0}
                onClick={() => moveBattle(true)}
              >
                <Icon
                  icon="arrow_back_ios"
                  className=" text-gray-300"
                  fontWeight={700}
                  fontSize={20}
                />
                <p className="font-neversaydie tracking-widest text-p3  text-gray-300 font-bold uppercase">
                  previous action
                </p>
              </Button>
              <Button
                background="bg-primary"
                rounded
                disabled={userDefinedIndex === lastNarrativeIndex + 1}
                className="mx-8 bg-opacity-14 focus:outline-none border border-transparent focus:border-primary"
                onClick={() => moveBattle(false)}
              >
                <p className="font-neversaydie  tracking-widest text-p3  text-primary font-bold uppercase">
                  Next action
                </p>
                <Icon
                  icon="arrow_forward_ios"
                  className="text-primary"
                  fontWeight={700}
                  fontSize={20}
                />
              </Button>
            </div>
          )}
          <div className="flex flex-col lg:flex-row items-center mb-8">
            <GladiatorHealthBar
              name={competingGladiators.home.name}
              image={
                competingGladiators.home.heroImage ||
                competingGladiators.home.image
              }
              home
              currentHP={
                getGladiatorPosition(competingGladiators.home.name)?.glad
                  .currentHp || 0
              }
              initialHP={
                getGladiatorPosition(competingGladiators.home.name)?.glad
                  .initialHp || 0
              }
            />
            <div className="flex-grow flex mx-4 justify-center">
              <div className="flex-grow flex py-12 lg:hidden mx-4 justify-center">
                <div>
                  {blowByBlow?.narrativesToEnglish.map((blow, index) => (
                    <div key={`english-narrative-${index}`}>
                      <AiNarrative
                        tournamentId={tournamentId}
                        round={roundNumber}
                        game={gameNumber}
                        idx={index}
                      />
                      <Blow narrative={blow.english} key={blow.english} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <GladiatorHealthBar
              name={competingGladiators.away.name}
              image={
                competingGladiators.away.heroImage ||
                competingGladiators.away.image
              }
              home={false}
              currentHP={
                getGladiatorPosition(competingGladiators.away.name)?.glad
                  .currentHp || 0
              }
              initialHP={
                getGladiatorPosition(competingGladiators.away.name)?.glad
                  .initialHp || 0
              }
            />
          </div>
          {showChampionCard ? (
            <ChampionCard
              className="mt-12"
              champion={getChampion() || undefined}
              inBracket={false}
              undecided={false}
            />
          ) : (
            <div className="hidden lg:flex justify-between items-center">
              <GladiatorBattleCard
                gladiator={{
                  name: competingGladiators.home.name,
                  image:
                    competingGladiators.home.heroImage ||
                    competingGladiators.home.image,
                  id: competingGladiators.home.id
                }}
                equippedItems={homeEquipment}
                tournamentId={tournamentInfo.tournament.tournamentId}
                home
                action={hasBlowByBlow}
                attack={
                  getGladiatorPosition(competingGladiators.home.name)?.attacker
                }
                damage={String(
                  getGladiatorPosition(competingGladiators.home.name)?.glad
                    .damage
                )}
              />
              <div className="w-1/3 narrative overflow-y-scroll">
                {hasBlowByBlow && blowByBlow && (
                  <>
                    <LiveNarrative
                      tournamentId={tournamentId}
                      round={roundNumber}
                      game={gameNumber}
                      idx={currentIndex}
                    />
                    <LiveBlowText
                      narrative={blowByBlow.narrativesToEnglish[currentIndex]}
                      key={blowByBlow.narrativesToEnglish[currentIndex].english}
                    />
                  </>
                )}
              </div>
              <GladiatorBattleCard
                gladiator={{
                  name: competingGladiators.away.name,
                  image:
                    competingGladiators.away.heroImage ||
                    competingGladiators.away.image,
                  id: competingGladiators.away.id
                }}
                tournamentId={tournamentInfo.tournament.tournamentId}
                equippedItems={awayEquipment}
                home={false}
                attack={
                  getGladiatorPosition(competingGladiators.away.name)?.attacker
                }
                action={hasBlowByBlow}
                damage={String(
                  getGladiatorPosition(competingGladiators.away.name)?.glad
                    .damage
                )}
              />
            </div>
          )}
        </Container>
      ) : (
        <Container className="w-full flex justify-center mt-8 py-20 px-20 bg-opacity-0">
          <div>
            {blowByBlow?.narrativesToEnglish.map((blow, index) => (
              <>
                <AiNarrative
                  tournamentId={tournamentId}
                  round={roundNumber}
                  game={gameNumber}
                  idx={index}
                />
                <Blow narrative={blow.english} key={blow.english} />
              </>
            ))}
          </div>
        </Container>
      )}
    </Layout>
  )
}

const Container = styled.div`
  background: url(/images/backgrounds/battle.png);
  background-size: cover;
  min-height: 80vh;

  .narrative {
    min-height: 40rem;
  }
`

export default BattlePage
