/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BigNumber, BigNumberish } from 'ethers'
import { Layout } from '../layouts/Layout'
import { useTournament } from '../hooks/useTournament'
import Spinner from '../components/atoms/Spinner/Spinner'
import BattleBracket from '../components/page-specific/betting-tournament/BattleBracket/BattleBracket'
import BackButton from '../components/composed/BackButton/BackButton'
import TournamentTable, {
  PriceTable
} from '../components/page-specific/betting-tournament/TournamentTable/TournamentTable'
import { useWeb3Context } from '../contexts/Web3Context'
import PlayerInterface from '../interfaces/Player.interface'
import { useBetsByTournament, useUserBetAmounts } from '../hooks/useBetting'
import { usePrice } from '../hooks/usePricing'
import {
  useGroupedEquipmentList,
  usePlaceItem,
  useUserEquipment
} from '../hooks/useEquipment'
import BattleItemsModal from '../components/page-specific/betting-tournament/BattleItemsModal/BattleItemsModal'
import BetModal from '../components/page-specific/betting-tournament/BetModal/BetModal'
import WonModal from '../components/page-specific/betting-tournament/WonModal/WonModal'
import Button from '../components/atoms/Button/Button'
import GladiatorDetailsModal from '../components/page-specific/betting-tournament/GladiatorDetailsModal/GladiatorDetailsModal'
import humanBigNumber from '../utils/humanBigNumber'
import SlideIndicator from '../components/atoms/SlideIndicator/SlideIndicator'
import TournamentTime from '../components/atoms/TournamentTime/TournamentTime'

type SectionName = 'Tournament Info' | 'Battle Bracket'

interface IGameEndedSection {
  title: SectionName
  mobileTitle: 'Info' | 'Battle'
  image: 'battle-indicator' | 'commentary-indicator'
}

const gameEndedSections: IGameEndedSection[] = [
  {
    title: 'Tournament Info',
    image: 'commentary-indicator',
    mobileTitle: 'Info'
  },
  {
    title: 'Battle Bracket',
    image: 'battle-indicator',
    mobileTitle: 'Battle'
  }
]

const TournamentBracketPage = () => {
  const { tournamentId } = useParams<{ tournamentId: string }>()
  const { tournament, loading: tournLoading } = useTournament(tournamentId)
  const { bracket, champion, gladiators, tournamentName } = tournament
  const [gameEndedActiveSection, setGameEndedActiveSection] =
    useState<SectionName>('Tournament Info')
  const tournmentInfoRef = useRef<any>()
  const tournamentBracketRef = useRef<any>()
  const { userInventory, loading: userEquipmentLoading } = useUserEquipment()
  const groupedEquipmentList = useGroupedEquipmentList(userInventory)
  const placeItem = usePlaceItem()

  const { connecting } = useWeb3Context()
  const [showGladiatorBetModal, setShowGladiatorBetModal] = useState(false)
  const [selectedGladiator, setSelectedGladiator] = useState<
    PlayerInterface | undefined
  >(undefined)
  const { betAmounts } = useBetsByTournament(tournament.tournamentId)
  const { userBets, loading: userBetsLoading } = useUserBetAmounts(
    tournament.tournamentId
  )
  const [placeItemLoading, setPlaceItemLoading] = useState(false)
  const [showItemsModal, setShowItemsModal] = useState(false)
  const [gladiatorToEquip, setGladiatorToEquip] = useState<BigNumberish | null>(
    null
  )
  const { data: btcPrice } = usePrice('BTC')
  const { data: ethPrice } = usePrice('ETH')
  const { data: maticPrice } = usePrice('MTC')
  const { data: dogePrice } = usePrice('DOGE')

  const [gladToViewDetail, setGladToViewDetail] = useState<{
    id: string
    faction: string
  } | null>(null)

  const winnerHexId = champion && BigNumber.from(champion.id).toHexString()

  const userDidWinBet =
    champion &&
    userBets &&
    userBets[winnerHexId!] &&
    userBets[winnerHexId!].gt(0)

  const pricing: PriceTable = {
    BTC: {
      usd: btcPrice.usd,
      change24h: btcPrice.usd_24h_change
    },
    DOGE: {
      usd: dogePrice.usd,
      change24h: dogePrice.usd_24h_change
    },
    ETH: {
      usd: ethPrice.usd,
      change24h: ethPrice.usd_24h_change
    },
    MTC: {
      usd: maticPrice.usd,
      change24h: maticPrice.usd_24h_change
    }
  }

  const selectGladiator = (gladiatorId: BigNumberish) => {
    const gladiator = gladiators.find((glad) => glad.id === gladiatorId)
    setSelectedGladiator(gladiator)
    setShowGladiatorBetModal(true)
  }

  const equipGladiator = (gladiatorId: BigNumberish) => {
    setShowItemsModal(true)
    setGladiatorToEquip(gladiatorId)
  }

  const useItem = async (itemId: BigNumber) => {
    try {
      setPlaceItemLoading(true)
      if (tournLoading) {
        throw new Error('gotta wait for the tournament man')
      }
      const tournId = BigNumber.from(tournamentId)
      const gladiatorId = BigNumber.from(gladiatorToEquip)
      await placeItem(tournId, gladiatorId, itemId!, tournament.notBefore)

      setPlaceItemLoading(false)
      setShowItemsModal(false)
      setGladiatorToEquip(null)
    } catch (error) {
      console.error(error)
      setPlaceItemLoading(false)
    }
    return null
  }

  const getGladiatorToEquipName = useMemo(() => {
    const seleGladiator = gladiators.find(
      (glad) => glad.id === gladiatorToEquip
    )

    return seleGladiator?.name
  }, [gladiatorToEquip])

  const onSectionButtonClick = (section: SectionName) => {
    setGameEndedActiveSection(section)

    if (section === 'Tournament Info') {
      return tournmentInfoRef?.current?.scrollIntoView()
    }

    return tournamentBracketRef?.current?.scrollIntoView()
  }

  const onViewGladDetail = (gladiatorId: BigNumberish, faction: string) => {
    setGladToViewDetail({
      id: gladiatorId.toString(),
      faction
    })
  }

  return (
    <>
      <Layout>
        <div className="lg:px-56 px-6 max-w-screen lg:max-w-screen-2xl">
          <div className="lg:mt-16 mt-0 w-full mb-10 -ml-5">
            <BackButton />
          </div>
          <div className="w-full flex justify-center lg:justify-start items-center top-36 sticky rounded-full bg-dark py-2 px-6 mb-10">
            {gameEndedSections.map((section) => (
              <Button
                key={section.title}
                background="bg-dark"
                rounded
                className={` focus:outline-none border relative mr-5 ${
                  section.title === gameEndedActiveSection
                    ? 'border-primary '
                    : ' border-transparent'
                }`}
                color={
                  section.title === gameEndedActiveSection
                    ? 'text-primary'
                    : 'text-white'
                }
                onClick={() => onSectionButtonClick(section.title)}
              >
                <img
                  src={`/images/symbols/${section.image}.svg`}
                  alt={section.title}
                  className="mr-3"
                />
                <p className="lg:block hidden">{section.title}</p>
                <p className="lg:hidden block">{section.mobileTitle}</p>
              </Button>
            ))}
          </div>

          <div
            ref={tournmentInfoRef}
            className="w-full rounded-12 py-12 min-h-full px-6 lg:px-12 bg-dark border border-gray-800"
          >
            <div className="flex flex-col lg:flex-row  lg:items-center w-full flex-wrap">
              <div className="w-24 h-24 rounded-full mb-10 lg:mb-0 mr-6 bg-gray-800 flex items-center justify-center">
                <img
                  src="/images/symbols/trophy.svg"
                  alt="tournament"
                  className="w-35 h-35"
                />
              </div>
              <div className="mr-24 mb-10 lg:mb-0">
                <p className="text-h4 font-gilroy-semibold">{tournamentName}</p>
                <h3 className=" font-gilroy-bold text-p1 text-gray-400">
                  Tournament
                </h3>
              </div>

              <div className="bg-green-400 py-4 px-6 rounded-bl-none rounded-br-none lg:rounded-bl-12 lg:rounded-br-12 rounded-12 bg-opacity-14">
                <h3 className="font-gilroy-semibold text-p3 tracking-wider text-gray-400">
                  Total purse
                </h3>
                <p className="text-h5 mt-1 text-green-600 font-gilroy-bold leading-10">
                  {humanBigNumber(
                    betAmounts.tournament.add(betAmounts.incentive)
                  )}{' '}
                  PTG
                </p>
                <p>{humanBigNumber(betAmounts.incentive || '0')} incentive</p>
              </div>

              <div className="bg-green-400 py-4 px-6 rounded-tl-none rounded-tr-none lg:rounded-tl-12 lg:rounded-tr-12 rounded-12 bg-opacity-14 lg:ml-24">
                <h3 className="font-gilroy-semibold text-p4 tracking-wider uppercase text-gray-400">
                  {champion ? 'Started On' : 'Starts On'}
                </h3>
                <TournamentTime
                  notBefore={tournament.notBefore}
                  className="text-h5 mt-1 text-green-600 font-gilroy-bold leading-10"
                />
              </div>
              <a
                href="https://discord.gg/Z2S3EtQKCn"
                target="_blank"
                rel="noreferrer noopenner"
                className="font-gilroy-semibold inline-flex items-center justify-center rounded-full px-10 py-4 text-p4 border border-gray-600 text-primary mt-9 lg:mt-0 lg:ml-10 uppercase"
              >
                join us on discord
              </a>
            </div>

            <div className="w-full mt-9">
              <div className="mb-12 w-full  lg:w-4/5 bg-primary py-10 lg:px-10 px-6 rounded-12 bg-opacity-14">
                <p className="uppercase text-p4 text-primary font-gilroy-semibold">
                  betting guidelines
                </p>
                <p className=" text-base leading-10">
                  These gladiators are competing in the {tournamentName}{' '}
                  tournament. You should place a bet on the gladiator that you
                  think will win. You can buy items to help (or hinder) each
                  gladiator&apos;s chances using the orange plus sign. The prize
                  purse is split among everyone who backed the winning
                  gladiator.
                </p>
              </div>

              <div className="overflow-x-scroll lg:overflow-visible max-w-screen">
                <SlideIndicator
                  className="mb-8 px-6"
                  title="gladiator details"
                />
                <TournamentTable
                  equipGladiator={equipGladiator}
                  onPlaceBet={selectGladiator}
                  gladiators={gladiators}
                  prices={pricing}
                  tournament={tournament}
                  bets={betAmounts}
                  userBets={userBets || { tournament: BigNumber.from(0) }}
                  userBetsLoading={userBetsLoading || connecting}
                  onViewGladDetail={onViewGladDetail}
                />
              </div>
            </div>
          </div>
          {bracket.length === 0 ? (
            <div
              style={{
                minHeight: '50vh'
              }}
              className="w-full flex justify-center items-center"
            >
              <Spinner color="white" />
            </div>
          ) : (
            <div className="w-full mt-20 " ref={tournamentBracketRef}>
              <h2 className=" text-h5 px-6 font-gilroy-semibold mb-10">
                Tournament Bracket
              </h2>
              <SlideIndicator className="mb-0 px-6" title="gladiator details" />

              <div className="w-full lg:overflow-x-hidden overflow-x-scroll max-w-screen rounded-12 py-12 bg-dark border border-gray-800">
                <BattleBracket
                  tournament={tournament}
                  equipGladiator={equipGladiator}
                />
              </div>
            </div>
          )}
        </div>
      </Layout>

      {selectedGladiator && (
        <BetModal
          showModal={showGladiatorBetModal}
          onModalClose={() => {
            setShowGladiatorBetModal(false)
            setSelectedGladiator(undefined)
          }}
          selectedGladiator={selectedGladiator}
          tournamentId={tournamentId}
          tournamentName={tournamentName}
          userBets={userBets || { tournament: BigNumber.from(0) }}
        />
      )}

      <WonModal
        champion={champion}
        tournamentId={tournamentId}
        userWon={!!userDidWinBet}
      />

      {groupedEquipmentList && (
        <BattleItemsModal
          gladiatorName={getGladiatorToEquipName}
          placeItemsLoading={placeItemLoading}
          items={groupedEquipmentList}
          equipmentLoading={userEquipmentLoading}
          showModal={showItemsModal}
          onUseHandler={useItem}
          tournamentName={tournamentName}
          onModalClose={() => {
            setShowItemsModal(false)
            setGladiatorToEquip(null)
          }}
        />
      )}

      {gladToViewDetail && (
        <GladiatorDetailsModal
          gladiatorId={gladToViewDetail.id}
          faction={gladToViewDetail.faction}
          onClose={() => setGladToViewDetail(null)}
        />
      )}
    </>
  )
}

export default TournamentBracketPage
