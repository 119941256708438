import useSWR from 'swr'
import { getJSON } from '../models/SkyDb'

export const TOURNAMENT_DATE_KEY = 'tournamentDates'
export const TOURNAMENT_IMAGE_KEY = 'tournamentImages'

async function skyDbFetcher(_label:string, key:string) {
    const { data } = await getJSON<Record<string, string>>(key)
    return data
}

export function useSkyDb(key:string) {
    const swr = useSWR(['skydb', key], {
        fetcher: skyDbFetcher
    })

    return { ...swr, loading: swr.isValidating }
}
