/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {
  supportedTokens,
  TokenListToken
} from '../../../../utils/tokens'
import Icon from '../../../atoms/Icon/Icon'

interface SelecTokenProps {
  selectedToken: TokenListToken
  setSelectedToken:(token:TokenListToken)=>any
}

const SelectToken: React.FC<SelecTokenProps> = ({ selectedToken, setSelectedToken }) => {
  const [showHiddenDropdown, setShowHiddenDropdown] = useState(false)
  let timeOutId: number | any = 0
  const canChangeToken = !(selectedToken.name === 'PTG')

  const onTokenSelect = (token: TokenListToken) => {
    setSelectedToken(token)
    setShowHiddenDropdown(false)
  }

  const onClickHandler = () => {
    if (canChangeToken) {
      setShowHiddenDropdown((s) => !s)
    }
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setShowHiddenDropdown(false)
    }, 10)
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId)
  }

  return (
    <div onBlur={onBlurHandler} onFocus={onFocusHandler} className="relative">
      <div className="flex flex-wrap lg:flex-nowrap items-center justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={onClickHandler}
        >
          <img
            src={selectedToken.logoURI}
            alt={selectedToken.symbol}
            className="w-20 h-20"
          />
          <div className="text-p-lg text-gray-200 ml-5 flex flex-row items-center">
            <p>{selectedToken.name}</p>
            {canChangeToken && <Icon icon="arrow_drop_down" fontSize={22} />}
          </div>
        </div>
      </div>
      <HiddenDropDown
        className={classNames(
          'bg-dark px-6',
          showHiddenDropdown ? 'block' : 'hidden'
        )}
        style={{
          transform: showHiddenDropdown ? 'scale(1)' : 'scale(0)'
        }}
      >
        <ul>
          {supportedTokens.map((token) => {
            return (
              <li
                key={`token-list-${token.address}`}
                onClick={() => onTokenSelect(token)}
                className="cursor-pointer"
              >
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src={token.logoURI}
                      alt={token.symbol}
                      className="w-10 h-10"
                    />
                    <p className=" px-5 text-p-lg text-gray-200">
                      {token.name}
                    </p>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </HiddenDropDown>
    </div>
  )
}

const HiddenDropDown = styled.nav`
  position: absolute;
  top: 5rem;
  left: 0;
  transition: 0.2s all;
  transform-origin: 100% 0;
  min-width: 256px;
  max-width: 256px;
  transition: 0.2s all;
  background: #151520;

  z-index: 1000;
  border: 1px solid var(--color-black);
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    min-width: 200px;
    max-width: 200px;
    transform: translateY(-100px);
    border: none;
  }

  ul {
    list-style: none;
    background: var(--color-white);
    padding: 16px 0px 0px;
  }

  li,
  > button {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 21px;
    display: flex;
    align-items: center;
  }
`

export default SelectToken
