import classNames from 'classnames'
import { utils } from 'ethers'
import React from 'react'
import { FreeBetCoinMetadata } from '../../../hooks/useFreeBets'
import humanBigNumber from '../../../utils/humanBigNumber'

interface FreeBetCoinProps {
  coin: FreeBetCoinMetadata
  onClick?: () => any
  className?: string
}

const one = utils.parseEther('1')
const half = one.div(2)

const FreeBetCoin: React.FC<FreeBetCoinProps> = ({
  coin,
  onClick,
  className
}) => {
  const isFreeCoin = coin.value.lt(half)

  return (
    <button
      onClick={onClick}
      className={classNames(
        'text-base inline-flex items-center px-4 py-2 bg-gray-900 border border-gray-700 rounded-xl font-semibold',
        className
      )}
    >
      <img
        className="w-12 h-12 mr-4"
        alt="free bet"
        src="/images/symbols/champion-coin.png"
      />
      {isFreeCoin
        ? `${0.001 * 1000} mPTG`
        : humanBigNumber(coin.value, Number(utils.formatEther(coin.value)) < 1)}
    </button>
  )
}

export default FreeBetCoin
