/* eslint-disable operator-linebreak */
import { useMemo } from 'react'
import { BigNumber, BigNumberish, utils } from 'ethers'
import useSWR, { keyInterface } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import TrophyInterface from '../interfaces/Trophy.interface'
import convertToReadableTime from '../utils/convertToReadableTime'
import { EquippedItem } from '../interfaces/EquippedItem.interface'
import ThenArg from '../utils/ThenArg'
import { Gladiator } from '../types/contracts'
import { equipperForTournament, useEquipmentList } from './useEquipment'
import gameChain, { Contracts } from '../models/GameChain'
import GladiatorInterface from '../interfaces/Gladiator.interface'
import { tournamentContractForTournament } from '../utils/isTournamentV4'

export type SupportedLeagues = '1' | '2'

interface League {
  title: 'Emperor' | 'Praetorian'
  image: string
  number: SupportedLeagues
}

const asBytes32 = (s: string) => utils.formatBytes32String(s)
type TrophyRange = [BigNumber, BigNumber]

const static32ByteString = {
  A: asBytes32('a'),
  M: asBytes32('m')
}

export const gladiatorLeaguesDetails: League[] = [
  {
    title: 'Emperor',
    number: '1',
    image: '/images/symbols/emperor-league.svg'
  },
  {
    title: 'Praetorian',
    number: '2',
    image: '/images/symbols/items-category/scroll.svg'
  }
]

export interface MinimalGladiator {
  id: BigNumberish
  name: string
  image: string
  heroImage: string
  prestige: BigNumber
  trophyIDs: BigNumber[]
  tagline?: string
}

function trophiesFromInventory(trophyRanges: TrophyRange[], ids: BigNumber[]) {
  return ids
    .filter((inv) => {
      return Boolean(
        trophyRanges.find(([start, end]) => {
          return inv.gte(start) && inv.lte(end)
        })
      )
    })
    .sort((a, b) => {
      if (a.eq(b)) {
        return 0
      }
      if (a.lt(b)) {
        return -1
      }
      return 1
    })
}

function leagueListKey(league: number) {
  return `/leagues/${league.toString()}/gladiators`
}

const gladiatorLeagueFetcher = async () => {
  const { kasumahUintLogger } = await gameChain.contracts
  const deployerAddress = await gameChain.deployerAddress

  const ids = await Promise.all(
    [1, 2].map(async (leagueId) => {
      try {
        const key = leagueListKey(leagueId)
        const vals = await kasumahUintLogger.all(deployerAddress, key)

        return vals.map((bn) => bn.toNumber())
      } catch (err) {
        console.error('error getting id: ', leagueId, err)
        return []
      }
    })
  )

  return ids
}

export const useGroupedGladiatorsLeagues = () => {
  const { gladiators: gladiatorData } = useGladiatorList()
  const { data: leagueIdData } = useSWR(['/gladiator'], {
    fetcher: gladiatorLeagueFetcher
  })

  const leagues = useMemo(() => {
    if (!gladiatorData || !leagueIdData) {
      console.log('Fetching gladiator details')
      return undefined
    }

    const sortedLeagueGlads = leagueIdData.map((_, idx) => {
      const leagueGladiators = gladiatorData.filter((gladiator) => {
        return leagueIdData[idx].find(
          (id) => id.toString() === gladiator.id.toString()
        )
      })
      return leagueGladiators
    })

    return sortedLeagueGlads
  }, [gladiatorData, leagueIdData])

  return {
    leagues,
    loading: !leagues
  }
}

export const useGladiatorList = () => {
  const ids = useMemo(() => {
    const startId = 1
    const endId = 100

    const excluded = [14, 26, 28, 29].map((id) => {
      return BigNumber.from(id).toHexString()
    })
    return new Array(endId + 1 - startId)
      .fill(true)
      .map((_, i) => {
        return BigNumber.from(startId + i)
      })
      .filter((id) => {
        return !excluded.includes(id.toHexString())
      })
      .map((id) => id.toString())
  }, [])

  const { data } = useMinimalGladiator(['/gladiators/minimal', ...ids])

  const sortedData = useMemo(() => {
    if (!data) {
      return undefined
    }
    return Object.values(data)
      .filter((gladiator) => {
        return Boolean(gladiator.name)
      })
      .sort((a, b) => {
        // only use 8 digits of significance
        return b.prestige
          .div(10 ** 10)
          .sub(a.prestige.div(10 ** 10))
          .toNumber()
      })
  }, [data])

  return { gladiators: sortedData, loading: !data }
}

const useGladiatorLeague = (
  id: string
): {
  league?: League
  gladiators?: MinimalGladiator[]
} => {
  const { leagues } = useGroupedGladiatorsLeagues()

  if (!leagues) {
    return {
      league: undefined,
      gladiators: undefined
    }
  }

  for (let i = 0; i < leagues.length; i++) {
    const leagueGladiators = leagues[i]
    const index = leagueGladiators.findIndex((glad) => {
      return glad.id.toString() === id
    })
    if (index > -1) {
      return {
        league: gladiatorLeaguesDetails[i],
        gladiators: leagues[i]
      }
    }
  }

  return {
    league: undefined,
    gladiators: undefined
  }
}

export const useGladiatorRank = (id: string) => {
  const { gladiators, league } = useGladiatorLeague(id)

  if (!gladiators) {
    return {
      rank: undefined,
      numberOfGladiators: 0,
      league: undefined
    }
  }

  const index = gladiators.findIndex((glad) => {
    return glad.id === id
  })

  return {
    rank: index + 1,
    numberOfGladiators: gladiators.length,
    league
  }
}

export const useGladiatorEquipments = (
  tournamentId: BigNumberish | undefined,
  gladiatorId: BigNumberish | undefined
) => {
  const { contracts } = useWeb3Context()
  const { items, loading: equipmentListLoading } = useEquipmentList()

  const fetcher = async (
    _key: string,
    tournamentId: BigNumberish, // hex string
    gladiatorId: BigNumberish // hex string
  ): Promise<EquippedItem[]> => {
    try {
      const equipper = await equipperForTournament(tournamentId, contracts)

      const gladiatorEquipments = await equipper.gladiatorEquipment(
        tournamentId,
        gladiatorId
      )

      return gladiatorEquipments.map((equip) => {
        return {
          ...equip,
          ...items[equip.id.toHexString()]
        }
      })
    } catch (err) {
      console.error('error fetching gladiator equipment: ', err)
      throw err
    }
  }

  const { data } = useSWR(
    () => {
      if (equipmentListLoading) {
        throw new Error('waiting on items')
      }
      if (!gladiatorId) {
        throw new Error('waiting for gladiator')
      }
      // we convert these to strings so we can mutate with string equality (BigNumbers aren't equal to each other in JS land)
      return [
        '/gladiator/equipment',
        BigNumber.from(tournamentId).toHexString(),
        BigNumber.from(gladiatorId).toHexString()
      ]
    },
    {
      fetcher,
      refreshInterval: 10000
    }
  )

  return data
}

const extendedPropPairsToObj = (
  pairs: ThenArg<ReturnType<Gladiator['extendedProperties']>>
): Record<string, string> => {
  return pairs.reduce((memo: Record<string, string>, pair) => {
    return {
      ...memo,
      [utils.parseBytes32String(pair.key)]: pair.value
    }
  }, {})
}

const propPairsToObject = (
  pairs: ThenArg<ReturnType<Gladiator['properties']>>
): Record<string, string | BigNumber> => {
  return pairs.reduce((memo: Record<string, string | BigNumber>, pair) => {
    let val: string | BigNumber
    try {
      val = utils.parseBytes32String(pair.value)
    } catch (err) {
      val = BigNumber.from(pair.value)
    }
    return {
      ...memo,
      [utils.parseBytes32String(pair.key)]: val
    }
  }, {})
}

export const useMinimalGladiator = (key: keyInterface) => {
  const { contracts, trophyRangeV4, trophyRangeV5 } = useWeb3Context()

  // we only need name, image, prestige for these
  // this is an optimization since we don't really need a lot of info about each gladiator and so we don't
  // need to do the "big" request that generates lots of requests... so we first grab all the gladiator information we need in a single requests
  const fetcher = async (
    _key: string,
    ...ids: string[] // hexified ids
  ) => {
    const { gladiator } = await contracts

    // this should fetch all the gladiator metadata (for all the gladiators) in a *single* request because of the multicall wrapping
    const gladiators = ids.map(async (id) => {
      try {
        const bigId = BigNumber.from(id)
        const [
          name,
          image,
          prestige,
          inventory,
          properties,
          extendedProperties
        ] = await Promise.all([
          gladiator.name(bigId),
          gladiator.image(bigId),
          gladiator.prestige(bigId),
          gladiator.inventory(bigId),
          gladiator.properties(bigId),
          gladiator.extendedProperties(bigId)
        ])

        const allProperties = {
          ...propPairsToObject(properties),
          ...extendedPropPairsToObj(extendedProperties)
        }

        const { heroimage, tagline1, tagline2, tagline3 } = allProperties

        const trophies = trophiesFromInventory(
          [await trophyRangeV4, await trophyRangeV5],
          inventory
        )
        // filter out undefined taglines
        const taglines: string[] = [
          tagline1 as string,
          tagline2 as string,
          tagline3 as string
        ].filter((tagline) => tagline)
        const randomIndex = Math.floor(Math.random() * taglines.length)

        return {
          id,
          name,
          image: image.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'),
          heroImage: (heroimage as string)?.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'),
          prestige,
          trophyIDs: trophies,
          tagline: taglines.length ? taglines[randomIndex] : undefined
        }
      } catch (err) {
        console.error('error fetching gladiator: ', err)
        throw err
      }
    })
    const data = await Promise.all(gladiators)
    return ids.reduce((acc: Record<string, MinimalGladiator>, id, i) => {
      acc[id] = data[i]
      return acc
    }, {})
  }

  // see the SWR docs. We pass in a function to the key of useSWR.
  // when the tournament hasn't been fetched yet that function errors
  // which means that SWR doesn't try to fetch the data
  return useSWR(key, {
    fetcher
  })
}

interface MetadataRichProperty {
  name?: string
  value?: string | string[]
  display_value?: string
  class?: string
  css?: {
    [key: string]: string
  }
}

export interface ByteProperty {
  key: string
  value: string
}

export type StringProperty = ByteProperty

interface PropertiesConfig {
  reserved?: string[]
  cast?: { [key: string]: 'BigNumber' }
}

interface Metadata {
  name: string
  decimals?: bigint
  description?: string
  image?: string
  specialMove?: string
  properties: {
    [key: string]: string | MetadataRichProperty | number
  }
  attributes: string[]
}

const PROPERTIES_CONFIG_BY_TYPE: { [key: string]: PropertiesConfig } = {
  gladiator: {
    reserved: ['inventory', 'wins', 'prestige', 'title', 'primordial'],
    cast: {
      generation: 'BigNumber',
      createdAt: 'BigNumber'
    }
  },
  trophy: {
    reserved: ['defeated'],
    cast: {
      createdAt: 'BigNumber'
    }
  }
}

const parseProperties = (
  propertyPairs: ByteProperty[],
  config: PropertiesConfig = {}
): Metadata['properties'] => {
  const properties: Metadata['properties'] = {}
  propertyPairs.forEach((pair) => {
    const key = utils.parseBytes32String(pair.key)
    if ((config.reserved || []).includes(key)) {
      return
    }

    let { value } = pair
    if ((config?.cast || {})[key] === 'BigNumber') {
      value = BigNumber.from(value).toString()
    }

    properties[key] = value
  })
  return properties
}

const titleForPrestige = (prestige: BigNumber) => {
  if (prestige.gte(utils.parseEther('50'))) {
    return 'Champion Gladiator'
  }
  if (prestige.gte(utils.parseEther('20'))) {
    return 'Gladiator'
  }
  return 'Journeyman'
}

const useGladiatorFetcher = async (
  _key: any,
  gladiatorId: string
): Promise<GladiatorInterface> => {
  try {
    const { gladiator } = await gameChain.contracts
    const { trophyRangeV4, trophyRangeV5 } = gameChain

    const [name, image, props, extendedProps, inventory, balances, prestige] =
      await Promise.all([
        gladiator.name(gladiatorId),
        gladiator.image(gladiatorId),
        gladiator.properties(gladiatorId),
        gladiator.extendedProperties(gladiatorId),
        gladiator.inventory(gladiatorId),
        gladiator.balances(gladiatorId),
        gladiator.prestige(gladiatorId)
      ])

    const parsedProperties =
      parseProperties(
        props.concat(extendedProps),
        PROPERTIES_CONFIG_BY_TYPE.gladiator
      ) || {}

    let wins = 0
    let parsedInventory: GladiatorInterface['properties']['inventory']
    if (inventory.length > 0) {
      parsedInventory = {
        name: 'inventory',
        value: inventory.map((i) => utils.hexZeroPad(i.toHexString(), 32))
      }

      parsedProperties.balances = {
        name: 'balances',
        value: balances.map((i) => i.toString())
      }

      wins = trophiesFromInventory(
        [await trophyRangeV4, await trophyRangeV5],
        inventory
      ).length
    }

    return {
      id: gladiatorId,
      image: image?.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'),
      name,
      properties: {
        createdAt: convertToReadableTime(
          parseInt(parsedProperties.createdAt as string, 10)
        ),
        generation: BigNumber.from(parsedProperties.generation).toString(),

        history: parsedProperties.history?.toString().replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'),
        heroimage: parsedProperties.heroimage?.toString().replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'),
        inventory: parsedInventory,
        prestige,
        primordial: parsedProperties.generation === '0' ? 'true' : undefined,
        wins,
        attributes: Object.keys(parsedProperties).filter((gladKey) => {
          return static32ByteString.A === parsedProperties[gladKey]
        }),
        title: titleForPrestige(prestige),
        attack: BigNumber.from(parsedProperties.attack).toNumber(),
        hitpoints: BigNumber.from(parsedProperties.hitpoints).toNumber(),
        defense: BigNumber.from(parsedProperties.defense || '').toNumber(),
        pop: BigNumber.from(parsedProperties.pop || '').toNumber(),
        specialMove: Object.keys(parsedProperties).find((gladKey) => {
          return static32ByteString.M === parsedProperties[gladKey]
        })
      }
    }
  } catch (err) {
    console.error('error fetching gladiator: ', err)
    throw err
  }
}

export const useGladiator = (gladiatorId: string) => {
  const { data } = useSWR(['/gladiator', gladiatorId], {
    fetcher: useGladiatorFetcher
  })

  return {
    gladiator: data,
    loading: !data
  }
}

// fake yellow box with trophy data
const generateTrophyImage = async (tournament: string, gladiator: string) => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" height="200" width="200">
  <rect fill="yellow" x="0" y="0" width="200" height="200"></rect>
  <text x="100" y="15" fill="black" text-anchor="middle" alignment-baseline="central">${tournament}</text>
  <text x="100" y="35" fill="black" text-anchor="middle" alignment-baseline="central">${gladiator}</text>
</svg>`
  return `data:image/svg+xml;base64,${utils.base64.encode(Buffer.from(svg))}`
}

function isTrophyV4(id: BigNumberish, range: TrophyRange) {
  const idNum = BigNumber.from(id)
  return idNum.gte(range[0]) && idNum.lte(range[1])
}

async function trophyContractFor(
  trophyID: BigNumberish,
  contracts: Promise<Contracts>
) {
  const { trophyRangeV4 } = gameChain
  const { trophy, trophyV5 } = await contracts
  if (isTrophyV4(trophyID, await trophyRangeV4)) {
    return trophy
  }

  return trophyV5
}

const trophyFetcher = async (
  trophyId: BigNumber,
  gladiatorId: BigNumberish,
  gladiatorName: string
): Promise<TrophyInterface> => {
  const { gladiator } = await gameChain.contracts
  const trophy = await trophyContractFor(trophyId, gameChain.contracts)
  const [name, properties] = await Promise.all([
    trophy.name(trophyId),
    trophy.properties(trophyId)
  ])
  const parsedProperties = parseProperties(
    properties,
    PROPERTIES_CONFIG_BY_TYPE.trophy
  )
  const tournamentID = BigNumber.from(parsedProperties.tournamentID)
  const tournament = await tournamentContractForTournament(
    tournamentID,
    gameChain.contracts
  )

  const registrations = await tournament.registrations(tournamentID)
  const defeatedIDs: BigNumber[] = registrations
    .filter((reg) => {
      return !reg.gladiator.eq(gladiatorId)
    })
    .map((reg) => reg.gladiator)

  const defeatedNames = await Promise.all(
    defeatedIDs.map((defeatedID) => gladiator.name(defeatedID))
  )

  return {
    image: await generateTrophyImage(name, gladiatorName),
    name,
    properties: {
      createdAt: parsedProperties.createdAt.toString(),
      defeated: {
        value: defeatedNames
      },
      gladiatorID: gladiatorId.toString(),
      tournamentID: tournamentID.toString()
    }
  }
}

const gladiatorTrophiesFetcher = async (
  _: string,
  gladiatorId: BigNumberish
) => {
  try {
    const { gladiator } = await gameChain.contracts
    const { trophyRangeV4, trophyRangeV5 } = gameChain

    const [name, inventory] = await Promise.all([
      gladiator.name(gladiatorId),
      gladiator.inventory(gladiatorId)
    ])

    const winIds = trophiesFromInventory(
      [await trophyRangeV4, await trophyRangeV5],
      inventory
    )

    return Promise.all(
      winIds.map(async (id) => {
        return trophyFetcher(id, gladiatorId, name)
      })
    )
  } catch (err) {
    console.error('error fetching trophies for ', gladiatorId)
    throw err
  }
}

const userGladiatorsFetcher = async (_key: keyInterface, ...ids: string[]) => {
  const { assets } = await gameChain.contracts

  const idswithQuantity = ids.map(async (id) => {
    const quantity = await assets.balanceOf(gameChain.safeAddress!, id)
    if (quantity.toNumber() > 0) return id
    return null
  })

  const filteredIds = await Promise.all(idswithQuantity)
  return filteredIds.filter((id) => id)
}

export const useUserGladiators = () => {
  const { safeAddress } = useWeb3Context()
  const { gladiators } = useGladiatorList()

  const { data: userGladiatorIds } = useSWR(
    () => {
      if (!safeAddress || !gladiators) {
        throw new Error('waiting on safe address or gladiators')
      }
      return ['/user-gladiator', ...gladiators.map(({ id }) => id)]
    },
    {
      fetcher: userGladiatorsFetcher
    }
  )
  const gladiatorIds = userGladiatorIds
    ? userGladiatorIds.filter((glad) => glad)
    : []

  const { data: gladiatorData } = useMinimalGladiator(() => {
    return ['/gladiators/minimal', ...gladiatorIds]
  })

  return { gladiators: gladiatorData }
}

export const useGladiatorTrophies = (gladiatorId: BigNumberish) => {
  const { data } = useSWR(['/gladiator-trophies', gladiatorId], {
    fetcher: gladiatorTrophiesFetcher
  })

  return {
    trophies: data,
    loading: !data
  }
}
