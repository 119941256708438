import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Web3Provider } from './contexts/Web3Context'
import TournamentPage from './pages/Tournament'
import GladiatorDetailPage from './pages/GladiatorDetail'
import WalletPage from './pages/Wallet'
import BattlePage from './pages/Battle'
import LeaderboardPage from './pages/Leaderboard'
import UserItems from './pages/UserItems'
import MarketPlace from './pages/MarketPlace'
import GladiatorsPage from './pages/Gladiators'
import AuctionPage from './pages/Auction'
import AuctionItemPage from './pages/AuctionItemDetail'
import BettingLogPage from './pages/BettingLogPage'
import LandingPage from './pages/Landing'
import AssociatorPage from './pages/Associator'
import TournamentsPage from './pages/Tournaments'
import InviteStore from './components/atoms/InviteStore/InviteStore'
import SeasonTicketLoader from './seasontickets/SeasonTicketLoader'

function App() {
  return (
    <Web3Provider>
      <Router>
        <InviteStore />
        <Switch>
          <Route
            path="/gladiator/:gladiatorId"
            component={GladiatorDetailPage}
          />
          <Route
            exact
            path="/tournament/:tournamentId"
            component={TournamentPage}
          />
          <Route exact path="/wallet" component={WalletPage} />
          <Route exact path="/auctions" component={AuctionPage} />
          <Route exact path="/auction/:auctionId" component={AuctionItemPage} />
          <Route
            exact
            path="/auction/:version/:auctionId"
            component={AuctionItemPage}
          />
          <Route exact path="/leaderboard" component={LeaderboardPage} />
          <Route exact path="/marketplace" component={MarketPlace} />
          <Route exact path="/my-items" component={UserItems} />
          <Route exact path="/gladiators" component={GladiatorsPage} />
          <Route exact path="/betting-log" component={BettingLogPage} />
          <Route exact path="/associate" component={AssociatorPage} />
          <Route
            exact
            path="/battle/:tournamentId/:roundNumber/:gameNumber"
            component={BattlePage}
          />
          <Route path="/tournaments" component={TournamentsPage} />
          <Route path="/seasontickets" component={SeasonTicketLoader} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    </Web3Provider>
  )
}

export default App
