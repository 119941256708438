import { BigNumber, utils } from 'ethers'
import React, { FormEventHandler, useMemo, useState, useEffect } from 'react'
import { Web3ContextData } from '../../../../contexts/Web3Context'
import {
  useIsV2Auction,
  usePlaceAuctionBid,
  useUserAuctionBid
} from '../../../../hooks/useAuction'
import etherToNumber from '../../../../utils/etherToNumber'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'

interface BidOnAuctionModalProps {
  show: boolean
  onModalClose: () => void
  ctx: Web3ContextData
  auctionId: string
  prestigeBalance: BigNumber
  minBidIncrement: BigNumber
  minBidAmount: BigNumber
  highestBid: BigNumber
}

const BidOnAuctionModal: React.FC<BidOnAuctionModalProps> = ({
  show,
  onModalClose,
  prestigeBalance,
  auctionId,
  minBidIncrement,
  minBidAmount,
  highestBid
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const isv2Auction = useIsV2Auction()
  const getMinimumAmount = useMemo(() => {
    const highestBidUsed = highestBid.gt(0) ? highestBid : minBidAmount
    return etherToNumber(minBidIncrement.add(highestBidUsed))
  }, [minBidIncrement, highestBid, minBidAmount])

  const [amountToBid, setAmountToBid] = useState(getMinimumAmount)
  const [placingBidLoading, setPlacingBidLoading] = useState(false)
  const { placeBid } = usePlaceAuctionBid()
  const { amount: previousBidAmount, loading: bidLoading } = useUserAuctionBid(
    auctionId,
    isv2Auction
  )

  useEffect(() => {
    setAmountToBid(getMinimumAmount)
  }, [getMinimumAmount])

  const placeBidHandler: FormEventHandler = async (e) => {
    e.preventDefault()
    setErrorMessage('')

    const previousBid = Number(utils.formatEther(previousBidAmount || 0))
    const ptgBalance = Number(utils.formatEther(prestigeBalance || 0))

    if (amountToBid > ptgBalance) {
      return setErrorMessage('Insufficient balance to place bid')
    }

    if (amountToBid < getMinimumAmount) {
      return setErrorMessage(
        `You must bid above the minimum bid of ${getMinimumAmount}`
      )
    }
    setPlacingBidLoading(true)

    try {
      await placeBid({
        auctionId,
        amount: (amountToBid - previousBid).toString(),
        totalAmount: utils.parseEther(amountToBid.toString()),
        v2Auction: isv2Auction
      })
      setPlacingBidLoading(true)
      setPlacingBidLoading(false)
      onModalClose()
      return setAmountToBid(getMinimumAmount)
    } catch (error) {
      setPlacingBidLoading(false)

      return setErrorMessage(
        'Something went wrong, please ensure you are bidding the minimum amount'
      )
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form
        className="lg:p-16 p-8 bg-dark rounded-24"
        onSubmit={placeBidHandler}
      >
        <legend className="text-h5 mb-16 font-gilroy-medium ">Place bid</legend>

        {placingBidLoading || bidLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <img
              className="rounded-full"
              src="/images/icons/prestige.svg"
              alt="ptg"
            />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">Placing bid, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="lg:p-8 p-6 bg-gray-900 bg-opacity-80 rounded-xl mb-16 flex flex-col justify-center">
              <div className="flex justify-between items-center mb-12">
                <div>
                  <p className="text-p3 text-gray-300 font-gilroy-semibold">
                    Your current Bid:
                  </p>
                  <p className="text-p4 text-gray-400 hover:text-white font-gilroy-semibold">
                    Your current bid in auction
                  </p>
                </div>
                <p className="text-p3 text-gray-300 font-gilroy-semibold">
                  {humanBigNumber(previousBidAmount || 0)}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-p2 text-gray-200 font-gilroy-semibold">
                    Minimum bid required:
                  </p>
                </div>
                <p className="text-p3 text-gray-300 font-gilroy-semibold">
                  {getMinimumAmount}
                </p>
              </div>
            </section>

            <section className="">
              <div className="lg:p-8 p-6 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  New Bid
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src="/images/icons/prestige.svg" alt="prestige" />
                    <p className=" px-5 text-p-lg text-gray-200">PTG</p>
                  </div>
                  <input
                    className=" bg-transparent text-right focus:appearance-none focus:outline-none text-p1 font-gilroy-medium "
                    placeholder="Enter amount here"
                    value={amountToBid}
                    type="number"
                    onChange={(e) => {
                      setAmountToBid(Number(e.target.value))
                    }}
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300">
                BAL: {humanBigNumber(prestigeBalance)} $PTG
              </p>
            </section>

            {errorMessage && (
              <p className="text-p2 text-red-400 text-center my-8">
                {errorMessage}
              </p>
            )}

            <div className="flex justify-center mt-16">
              <Button
                rounded
                padding="px-10 py-6"
                fontSize="text-p2"
                className="uppercase font-gilroy-bold w-1/2"
                type="submit"
              >
                place bid
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { BidOnAuctionModalProps }
export default BidOnAuctionModal
