/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  background?:
    | 'bg-purple-700'
    | 'bg-green-600'
    | 'bg-red-600'
    | 'bg-red-900'
    | 'bg-primary'
    | 'bg-dark'
    | 'bg-black'
    | 'bg-gray-200'
    | 'bg-theme-dark-gray'
    | 'bg-transparent'
  rounded?: boolean
  fontSize?: 'text-p2' | 'text-p3' | 'text-p4' | 'text-p5'
  /**
   Padding choices are from design and the values provided uses tailwind classes
   */
  padding?:
    | ''
    | 'px-10 py-4'
    | 'px-10 py-6'
    | 'px-5 py-3'
    | 'px-10 py-3'
    | 'px-6 py-3'
    | 'px-8 py-3'
  fluidWidth?: boolean
  color?: 'text-primary' | 'text-white'
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  background,
  rounded,
  fluidWidth,
  color,
  padding,
  fontSize,
  ...props
}) => {
  const borderRadius = rounded ? 'rounded-full' : 'rounded-lg'
  const width = fluidWidth ? 'w-full' : 'w-auto'

  const _className = classNames(
    'font-gilroy-semibold duration-200 focus:outline-none whitespace-nowrap inline-flex items-center justify-center transition-colors rounded-lg ',
    props.disabled ? ' opacity-25' : classNames(background, color),
    padding,
    borderRadius,
    fontSize,
    className,
    width
  )

  return (
    <button className={_className} {...props}>
      {children}
    </button>
  )
}

Button.defaultProps = {
  background: 'bg-primary',
  rounded: false,
  fluidWidth: false,
  fontSize: 'text-p2',
  color: 'text-white',
  padding: 'px-10 py-4',
  type: 'button'
}

export default Button
