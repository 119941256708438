import { useState } from 'react'

export const subsidyDisabled = () => {
  return localStorage.getItem('disableRelayer')
}

const disableSubsidy = () => {
  return localStorage.setItem('disableRelayer', 'true')
}

const enableSubsidy = () => {
  return localStorage.removeItem('disableRelayer')
}

const useGasSubsidy = () => {
  const [disabled, setDisabled] = useState(!!subsidyDisabled())

  return {
    disabled,
    toggle: () => {
      try {
        if (subsidyDisabled()) {
          setDisabled(false)
          return enableSubsidy()
        }
        setDisabled(true)
        return disableSubsidy()
      } finally {
        window.location.reload()
      }
    }
  }
}

export default useGasSubsidy
