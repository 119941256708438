/* eslint-disable react/no-array-index-key */
import { BigNumber, BigNumberish, providers } from 'ethers'
import React from 'react'
import styled from 'styled-components'
import { TournamentBetAmounts } from '../../../../hooks/useBetting'
import { Tournament } from '../../../../hooks/useTournament'
import PlayerInterface from '../../../../interfaces/Player.interface'
import splitArrayToChunks from '../../../../utils/splitArrayToChunks'
import TournamentTableRow from '../TournamentTableRow/TournamentTableRow'

interface Price {
  usd: number
  change24h: number
}

export type PriceTable = Record<string, Price> // faction => price

interface TournamentTableProps {
  gladiators: PlayerInterface[]
  onPlaceBet: (id: BigNumberish) => void
  prices: PriceTable
  tournament: Tournament
  bets: TournamentBetAmounts
  userBets: TournamentBetAmounts
  userBetsLoading?: boolean
  equipGladiator: (id: BigNumberish) => void
  onViewGladDetail: (id: BigNumberish, faction: string) => void
  provider?: providers.Provider
}

const HEADING_CLASSES =
  'text-left font-gilroy-medium text-p2 uppercase text-gray-300 font-normal pb-12'

const StyledTable = styled.table`
  td > div {
    height: 100px;
  }
`

const TournamentTable: React.FC<TournamentTableProps> = ({
  gladiators,
  prices,
  onPlaceBet,
  tournament,
  bets,
  userBets,
  userBetsLoading,
  equipGladiator,
  onViewGladDetail
}) => {
  const groupsMatches: PlayerInterface[][] = splitArrayToChunks(gladiators, 2)

  return (
    <div className="w-full max-w-full overflow-x-scroll lg:overflow-visible">
      <StyledTable className="w-full" cellPadding="0">
        <thead>
          <tr>
            <th className={`${HEADING_CLASSES} pl-12 pr-4`}>rank</th>
            <th className={HEADING_CLASSES}>Gladiators</th>
            <th className={HEADING_CLASSES}>Bet($PTG)</th>
            <th className={HEADING_CLASSES}>Faction</th>
            <th className={HEADING_CLASSES}>Action</th>
          </tr>
        </thead>
        <tbody>
          {groupsMatches[0]?.map((gladiator, idx) => (
            <TournamentTableRow
              equipGladiator={equipGladiator}
              key={`gladiator-${gladiator.id}`}
              gladiator={gladiator}
              prices={prices}
              onPlaceBet={() => onPlaceBet(gladiator.id)}
              tournament={tournament}
              bet={
                bets[BigNumber.from(gladiator.id).toHexString()]
                  ? bets[BigNumber.from(gladiator.id).toHexString()]
                  : BigNumber.from(0)
              }
              userBets={userBets}
              userBetsLoading={userBetsLoading}
              away={idx === 1}
              onViewGladDetail={onViewGladDetail}
            />
          ))}
          <tr>
            <td className="py-6" />
            <td />
            <td />
            <td />
            <td />
          </tr>
          {groupsMatches[1]?.map((gladiator, idx) => (
            <TournamentTableRow
              equipGladiator={equipGladiator}
              key={`gladiator-${gladiator.id}`}
              gladiator={gladiator}
              prices={prices}
              onPlaceBet={() => onPlaceBet(gladiator.id)}
              tournament={tournament}
              bet={
                bets[BigNumber.from(gladiator.id).toHexString()]
                  ? bets[BigNumber.from(gladiator.id).toHexString()]
                  : BigNumber.from(0)
              }
              userBets={userBets}
              userBetsLoading={userBetsLoading}
              away={idx === 1}
              onViewGladDetail={onViewGladDetail}
            />
          ))}
          {groupsMatches[2]?.map((gladiator, idx) => (
            <TournamentTableRow
              equipGladiator={equipGladiator}
              key={`gladiator-${gladiator.id}`}
              gladiator={gladiator}
              prices={prices}
              onPlaceBet={() => onPlaceBet(gladiator.id)}
              tournament={tournament}
              bet={
                bets[BigNumber.from(gladiator.id).toHexString()]
                  ? bets[BigNumber.from(gladiator.id).toHexString()]
                  : BigNumber.from(0)
              }
              userBets={userBets}
              userBetsLoading={userBetsLoading}
              away={idx === 1}
              onViewGladDetail={onViewGladDetail}
            />
          ))}
          {groupsMatches[3]?.map((gladiator, idx) => (
            <TournamentTableRow
              equipGladiator={equipGladiator}
              key={`gladiator-${gladiator.id}`}
              gladiator={gladiator}
              prices={prices}
              onPlaceBet={() => onPlaceBet(gladiator.id)}
              tournament={tournament}
              bet={
                bets[BigNumber.from(gladiator.id).toHexString()]
                  ? bets[BigNumber.from(gladiator.id).toHexString()]
                  : BigNumber.from(0)
              }
              userBets={userBets}
              userBetsLoading={userBetsLoading}
              away={idx === 1}
              onViewGladDetail={onViewGladDetail}
            />
          ))}
        </tbody>
      </StyledTable>
    </div>
  )
}

export type { TournamentTableProps }
export default TournamentTable
