/* eslint-disable no-param-reassign */
import React, { useRef } from 'react'
import styled from 'styled-components'
import Icon from '../../../atoms/Icon/Icon'
import SlideIndicator from '../../../atoms/SlideIndicator/SlideIndicator'

export interface HorizontalSliderProps {
  className?: string
  title: string
  slideTitle: string
}

const HorizontalSlider: React.FC<HorizontalSliderProps> = ({
  children,
  className,
  title,
  slideTitle
}) => {
  const sliderContent = useRef<HTMLDivElement>(null)

  Math.easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  const scrollContent = (change: number, duration: number) => {
    const start = sliderContent?.current?.scrollLeft || 0
    let currentTime = 0
    const increment = 20

    const animateScroll = () => {
      currentTime += increment
      const val = Math.easeInOutQuad(currentTime, start, change, duration)

      if (sliderContent.current) {
        sliderContent.current.scrollLeft = val
      }

      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  }

  return (
    <section className={className}>
      <div className="flex items-center flex-wrap lg:flex-no-wrap justify-between mb-10 px-6 lg:px-32">
        <h2 className="mb-8 lg:mb-0 capitalize font-zen-dots text-h5">
          {title}
        </h2>
        <div className="flex items-center">
          <button
            onClick={() => scrollContent(-400, 300)}
            className="flex items-center justify-center h-20 w-20 rounded-full hover:bg-primary duration-200 hover:bg-opacity-25 mr-8 focus:outline-none"
          >
            <Icon icon="keyboard_arrow_left" fontSize={24} />
          </button>
          <button
            onClick={() => scrollContent(400, 300)}
            className=" flex items-center justify-center h-20 w-20 rounded-full hover:bg-primary duration-200 hover:bg-opacity-25 focus:outline-none"
          >
            <Icon
              icon="keyboard_arrow_right"
              color="text-primary"
              fontSize={24}
              className="m-0"
            />
          </button>
        </div>
      </div>
      <SlideIndicator className="mb-4 px-6" title={slideTitle} />
      <SlideContainer
        ref={sliderContent}
        className="flex min-w-full overflow-hidden overflow-x-scroll m-0 px-0 py-0 pr-20 pl-6 lg:pl-32"
      >
        {children}
      </SlideContainer>
    </section>
  )
}

const SlideContainer = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`

export default HorizontalSlider
