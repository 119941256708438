import React from 'react'
import classNames from 'classnames'
import PercentageMoveIndicator from '../../../../composed/PercentageMoveIndicator/PercentageMoveIndicator'
import { Price } from '../../../betting-tournament/TournamentTableRow/TournamentTableRow'
import { factionToDisplayname } from '../../../../../utils/factionToDisplayName'

interface FactionPerformanceCardProps {
  faction: string
  downMove?: boolean
  className?: string
  price: Price
}

const FactionPerformanceCard: React.FC<FactionPerformanceCardProps> = ({
  faction,
  price,
  className
}) => {
  return (
    <div
      className={classNames(
        'flex items-center bg-dark-purple p-5 w-1/3 rounded-12',
        className
      )}
    >
      <img
        className="h-12 w-12 mr-5"
        alt={`${factionToDisplayname(faction)} faction`}
        src={`/factions/${faction}.png`}
      />

      <div>
        <p className="font-gilroy-medium text-p1">{factionToDisplayname(faction)}</p>
        <div className="flex items-center">
          <p className="text-p3 font-gilroy-regular mr-2">${price.usd}</p>

          <div className="flex items-center min-h-full pr-24 lg:pr-0">
            {price.change24h < 0 ? (
              <PercentageMoveIndicator percent={price.change24h} down />
            ) : (
              <PercentageMoveIndicator percent={price.change24h} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export type { FactionPerformanceCardProps }
export default FactionPerformanceCard
