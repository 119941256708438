import React from 'react'
import styled from 'styled-components'
import ProgressBar from '../../atoms/ProgressBar/ProgressBar'

interface CustomProgressGroupProps {
  stats: StatProps[]
  title?: string
}

interface StatProps {
  title: string
  color: string
  stat: {
    value: number
    max: number
  }
}

const CustomProgressGroup: React.FC<CustomProgressGroupProps> = ({
  stats,
  title
}) => {
  return (
    <section className="bg-dark px-10 pt-16 pb-10">
      {title && <p className="text-p3 font-semibold uppercase mb-8">{title}</p>}

      {stats.map(({ title, color, stat: { value, max } }) => (
        <SingleStatsContainer
          color={color}
          className="font-gilroy-semibold mb-6"
          key={title}
        >
          <div className="flex items-center justify-between">
            <p className="stat-title">{title}</p>

            <p>
              <span className="stat-value">{value}</span>
              <span className="opacity-25">/{max}</span>
            </p>
          </div>
          <ProgressBar value={value} max={max} color={color} />
        </SingleStatsContainer>
      ))}
    </section>
  )
}

const SingleStatsContainer = styled.article<{ color: string }>`
  p {
    font-size: 14px;
  }
  .stat-title {
    text-transform: uppercase;
    color: ${({ color }) => color};
    font-size: 12px;
  }
  .state-value {
    color: ${({ color }) => color};
  }
`

export type { CustomProgressGroupProps }

export default CustomProgressGroup
