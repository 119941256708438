/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from 'styled-components'
import DomPortal from '../DomPortal/DomPortal'

export interface ModalProps {
  onModalClose: () => void
  showModal: boolean
  width?: 'w-4/5' | 'w-1/3' | 'w-1/2'
  gradientBorder?: boolean
}

const Modal: React.FC<ModalProps> = ({
  onModalClose,
  children,
  showModal,
  width = 'w-1/3',
  gradientBorder = true
}) => {
  return (
    <DomPortal>
      <ModalContent
        className={[showModal ? 'show' : 'hide', ` w-11/12 lg:${width}`].join(
          ''
        )}
        gradientBorder={gradientBorder}
      >
        <section>{children}</section>
      </ModalContent>

      <Backdrop
        onClick={() => onModalClose()}
        style={{
          display: showModal ? 'flex' : 'none'
        }}
      />
    </DomPortal>
  )
}

const ModalContent = styled.div<{ gradientBorder?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  transition: 0.05s all;
  z-index: 10000;
  padding: 0;
  max-height: 90vh;
  overflow: auto;
  border: 1px solid var(--color-black);
  transform: translate(-50%) scale(1);

  &.show {
    transform: translate(-50%) scale(1);
  }

  &.hide {
    transform: translate(-50%) scale(0) !important;
  }

  & > section {
    border: ${(props) => (props.gradientBorder ? '3px solid' : 'none')};

    border-image-source: linear-gradient(
      180deg,
      #5f2706 0%,
      #ff7320 48.96%,
      #5f2706 97.92%,
      #5f2706 100%
    );

    border-image-slice: 1%;
  }
`

const Backdrop = styled.div`
  background: #0000004d;
  z-index: 9000;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Modal
