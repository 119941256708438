import { BigNumberish } from 'ethers'
import { useCallback } from 'react'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import gameChain from '../models/GameChain'
import { useEquipmentList } from './useEquipment'
import { MinimalGladiator, useGladiatorList } from './useGladiator'

const WRAPPED_ASSETS = '/wrapped-assets'
export type ItemStore = Record<string, ItemConfigInterface | any>

export const useWrappedAssets = () => {
  const { contracts, safeAddress } = gameChain
  const { data } = useUserWrappedAssetBalance()

  const wrap = useCallback(
    async (assetId: BigNumberish, amount: number) => {
      if (!assetId) {
        throw new Error('No id provided')
      }
      const { assets, marketplaceWrapper } = await contracts
      const tx = await assets.safeTransferFrom(
        safeAddress as string,
        marketplaceWrapper.address,
        assetId,
        amount,
        []
      )
      await tx.wait()
    },
    [safeAddress, contracts]
  )

  const unWrap = useCallback(
    async (wrappedId: BigNumberish, isItem: boolean) => {
      const { marketplaceWrapper } = await contracts
      await marketplaceWrapper.burn(wrappedId)

      let updatedData = {
        ...data,
        items: data?.items.filter((wrappedAsset) => {
          return wrappedAsset?.id?.toString() !== wrappedId.toString()
        })
      }

      if (!isItem) {
        updatedData = {
          items: data?.items,
          gladiators: data?.gladiators.filter((wrappedAsset) => {
            return wrappedAsset?.id?.toString() !== wrappedId.toString()
          })
        }
      }

      mutate([WRAPPED_ASSETS, safeAddress], updatedData)
    },
    [contracts, data, safeAddress]
  )

  return {
    wrap,
    unWrap
  }
}

export const useUserWrappedAssetBalance = () => {
  const { contracts, safeAddress } = useWeb3Context()
  const { items } = useEquipmentList()
  const { gladiators } = useGladiatorList()

  const { data: balances } = useSWR(
    () => {
      if (!safeAddress || !contracts || !gladiators || !items) {
        throw new Error('waiting on safe address or contracts')
      }
      return [WRAPPED_ASSETS, safeAddress]
    },
    {
      fetcher: async (_key: any, safeAddress: string) => {
        const { marketplaceWrapper } = await gameChain.contracts
        const tokenLengths = await marketplaceWrapper.balanceOf(safeAddress)
        const generatedArray = Array.from({
          length: tokenLengths.toNumber()
        }).fill(0)

        const tokenIds = await Promise.all(
          generatedArray.map(async (_, index) => {
            const wrappedId = await marketplaceWrapper.tokenOfOwnerByIndex(
              safeAddress,
              index
            )
            const assetId = await marketplaceWrapper.wrapperToAsset(wrappedId)

            if (!assetId) {
              throw new Error('No id found')
            }

            return {
              wrappedId,
              assetId
            }
          })
        )

        let itemsMetadata: ItemConfigInterface[] = []
        let gladiatorsMetadata: MinimalGladiator[] = []

        for (let i = 0; i < tokenIds.length; i++) {
          const tokenId = tokenIds[i]
          const itemDetails: ItemConfigInterface =
            items[tokenId.assetId.toHexString()]
          const gladiatorDetails: MinimalGladiator | undefined =
            gladiators?.find((gladiator) => {
              return gladiator.id.toString() === tokenId.assetId.toString()
            })

          if (itemDetails) {
            itemsMetadata = [
              ...itemsMetadata,
              {
                ...itemDetails,
                attributes: {
                  ...itemDetails.attributes,
                  percentChanceOfUse:
                    itemDetails.attributes.percentChanceOfUse / 10000
                },
                id: tokenId.wrappedId
              }
            ]
          }

          if (gladiatorDetails) {
            gladiatorsMetadata = [
              ...gladiatorsMetadata,
              {
                ...gladiatorDetails,
                id: tokenId.wrappedId
              }
            ]
          }
        }

        return {
          items: itemsMetadata,
          gladiators: gladiatorsMetadata
        }
      },
      dedupingInterval: 1100,
      revalidateOnMount: true
    }
  )

  return {
    data: balances,
    loading: !balances
  }
}
