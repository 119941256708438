import useSWR from 'swr'

const factionToCoingeckoId:Record<string, string> = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    MTC: 'matic-network',
    DOGE: 'dogecoin',
}

async function priceFetcher(url:string, coingeckoID:string) {
    const resp = await fetch(url)
    return (await resp.json())[coingeckoID]
}

export const usePrice = (faction:string) => {
    const coingeckoID = factionToCoingeckoId[faction]
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${coingeckoID}&vs_currencies=usd&include_24hr_change=true`

    return useSWR([url, coingeckoID], {
        fetcher: priceFetcher,
        initialData: {
            usd: 0,
            usd_24h_change: 0
        },
        refreshInterval: 10000,
    })
}
