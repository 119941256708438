import React, { FormEventHandler, useState } from 'react'
import { Web3ContextData } from '../../../../contexts/Web3Context'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import fixer from '../../../../utils/skynetUrlFixer'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import NumberIncrement from '../../../atoms/NumberIncrement/NumberIncrement'
import Spinner from '../../../atoms/Spinner/Spinner'

interface TransferItemModalProps {
  show: boolean
  onModalClose: () => void
  ctx: Web3ContextData
  item: ItemConfigInterface
}

const TransferItemModal: React.FC<TransferItemModalProps> = ({
  show,
  onModalClose,
  ctx: { contracts, safeAddress },
  item
}) => {
  const [quantity, setQuantity] = useState(1)
  const [recieverAddress, setReceiverAddress] = useState('')
  const [transferLoading, setTransferLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    try {
      setError('')
      setTransferLoading(true)
      const { assets } = await contracts

      if (!safeAddress || !item.id) return

      const transfer = await assets.safeTransferFrom(
        safeAddress,
        recieverAddress,
        item.id,
        quantity,
        []
      )

      await transfer.wait()
      setTransferLoading(false)
      onModalClose()
      setReceiverAddress('')
    } catch (error) {
      setTransferLoading(false)
      setError('Error occurred try again')
      console.log(error)
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Transfer Item
        </legend>

        {error && <p>{error}</p>}

        {transferLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <img className="rounded-full" src={fixer(item.image)} alt="ptg" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">
                Transferring item, please wait ...
              </p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  Transfer
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center mr-3">
                    <div className="bg-dark w-16 h-16 rounded-full flex items-center justify-center">
                      <img className="w-12" src={fixer(item.image)} alt="prestige" />
                    </div>
                    <p className=" px-5 text-p1 text-gray-200">{item.name}</p>
                  </div>
                  <NumberIncrement
                    value={quantity}
                    setValue={(value: number) => {
                      if (value <= item.balance.toNumber() && value) {
                        setQuantity(value)
                      }
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <p className="flex uppercase text-gray-400 font-gilroy-semibold text-p4 tracking-wide">
                  Quantity owned
                </p>
                <div className="px-3 bg-primary rounded-full ml-2 flex items-center justify-center">
                  <span className=" text-p3 font-gilroy-semibold ">
                    {item.balance.toNumber()}
                  </span>
                </div>
              </div>
            </section>
            <section className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
              <p className="text-p3 text-gray-300 font-gilroy-medium mb-4">
                Enter Recipient’s Wallet Address here
              </p>
              <input
                required
                className=" bg-transparent focus:outline-none text-p3 font-gilroy-medium "
                placeholder="Click here to enter address"
                value={recieverAddress}
                onChange={(e) => setReceiverAddress(e.target.value)}
              />
            </section>

            <div className=" flex justify-center mt-16">
              <Button
                rounded
                className=" w-2/5 font-bold uppercase"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Transfer item
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { TransferItemModalProps }
export default TransferItemModal
