import React, { useCallback, useState } from 'react'
import { useWeb3Context } from '../../../contexts/Web3Context'
import Button, { ButtonProps } from '../Button/Button'
import Spinner from '../Spinner/Spinner'
import WalletChooser from '../WalletChooser/WalletChooser'

interface ConnectButtonProps {
  customText?: string
  className?: string
  spinnerText?: string
  background?: ButtonProps['background']
}

const ConnectButton: React.FC<ConnectButtonProps> = ({
  customText,
  className,
  background,
  spinnerText
}) => {
  const { connect: torusConnect, connecting, hasMetamask } = useWeb3Context()
  const [loading, setLoading] = useState(false)
  const [showWalletChooser, setShowWalletChooser] = useState(false)

  const buttonText = customText || 'Sign in / Sign up'

  const connect = useCallback(async () => {
    try {
      setLoading(true)
      const metamaskInstalled = await hasMetamask()
      if (!metamaskInstalled) {
        torusConnect()
        setLoading(false)
        return
      }
      console.log('setShowWallet true')
      // otherwise let's give the choice
      setShowWalletChooser(true)
    } catch (error) {
      console.log(
        'Something happened, best line of action is to try again',
        error
      )
      setLoading(false)
    }
  }, [setLoading, hasMetamask, torusConnect])

  const onModalClose = useCallback(() => {
    setLoading(false)
    setShowWalletChooser(false)
  }, [setLoading, setShowWalletChooser])

  if (loading || connecting) {
    return (
      <div className="flex flex-col items-center">
        <Spinner />
        {spinnerText && <p className="text-base">{spinnerText}</p>}
        <WalletChooser
          showModal={showWalletChooser}
          onModalClose={onModalClose}
        />
      </div>
    )
  }

  return (
    <div>
      <Button
        className={className}
        background={background || 'bg-primary'}
        type="button"
        onClick={connect}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default ConnectButton
