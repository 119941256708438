/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

export interface NavLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href: string
  active?: boolean
}

const NavLink: React.FC<NavLinkProps> = ({ children, active, href }) => {
  return (
    <Link
      to={href}
      className={classNames(
        'text-p3 uppercase font-gilroy-semibold',
        active ? 'text-primary opacity-100' : 'text-white opacity-50'
      )}
    >
      {children}
    </Link>
  )
}

NavLink.defaultProps = {
  active: false
}

export default NavLink
