import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { providers, Signer } from 'ethers'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import Button from '../../../atoms/Button/Button'
import gameChain from '../../../../models/GameChain'

const maticAddChain = {
  name: 'Matic(Polygon) Mainnet',
  chain: 'Matic(Polygon)',
  network: 'mainnet',
  rpc: ['https://polygon-rpc.com', 'wss://ws-mainnet.matic.network'],
  faucets: [],
  nativeCurrency: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18
  },
  infoURL: 'https://polygon.technology/',
  shortName: 'matic',
  chainId: 137,
  networkId: 137,
  explorers: [
    {
      name: 'polygonscan',
      url: 'https://polygonscan.com',
      standard: 'EIP3091'
    }
  ]
}

const toHex = (num: number) => {
  return `0x${num.toString(16)}`
}

function useSignerChain(signer?: Signer) {
  const { data, revalidate } = useSWR(
    () => {
      if (!signer) {
        throw new Error('no signer')
      }
      return '/signer/chain'
    },
    {
      fetcher: async () => {
        if (!signer) {
          throw new Error('missing signer')
        }
        console.log('fetch')
        try {
          const id = await signer.getChainId()
          return id
        } catch (err) {
          console.error('error getting chain id: ', err)
          throw err
        }
      }
    }
  )

  useEffect(() => {
    const handleNetworkChange = () => {
      revalidate()
    }
    gameChain.on('chainChanged', handleNetworkChange)
    return () => {
      gameChain.off('chainChanged', handleNetworkChange)
    }
  }, [revalidate])

  return data
}

interface SwitchNetworkButtonProps {
  className?: string
}

const SwitchNetworkButton: React.FC<SwitchNetworkButtonProps> = ({
  className
}) => {
  const { chainId: expectedChainId, signer } = useWeb3Context()
  const signerChainId = useSignerChain(signer)
  const [err, setErr] = useState(false)

  const onClick = async () => {
    try {
      await (signer?.provider as providers.StaticJsonRpcProvider).send(
        'wallet_addEthereumChain',
        [
          {
            chainId: toHex(maticAddChain.chainId),
            chainName: maticAddChain.name,
            nativeCurrency: maticAddChain.nativeCurrency,
            rpcUrls: maticAddChain.rpc,
            blockExplorerUrls: maticAddChain.explorers.map(
              (explorer) => explorer.url
            )
          }
        ]
      )
    } catch (err) {
      setErr(true)
    }
  }

  if (!signerChainId || signerChainId === expectedChainId) {
    return null
  }

  if (err) {
    return (
      <div className={className}>
        <p className="text-p-lg">
          There was an error. Maybe your wallet does not support automatically
          switching networks.{' '}
          <a href="https://discord.gg/2BdX72c3Vd" className="text-primary underline">Hop into our discord</a> for
          help.
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p className="text-p-lg">You are not connected to the polygon network.</p>
      <Button onClick={onClick}>Switch to Polygon</Button>
    </div>
  )
}

export default SwitchNetworkButton
