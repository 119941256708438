import React, { useState } from 'react'
import { useMarketplace } from '../../../../hooks/useEquipment'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import getItemQuantity from '../../../../utils/getItemQuantity'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Button from '../../../atoms/Button/Button'
import Icon from '../../../atoms/Icon/Icon'
import Modal from '../../../atoms/Modal/Modal'
import NumberIncrement from '../../../atoms/NumberIncrement/NumberIncrement'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../../atoms/Spinner/Spinner'

type BuyItemFn = ReturnType<typeof useMarketplace>['buyItem']
type GetPricefn = ReturnType<typeof useMarketplace>['getPrice']

interface BuyItemModalProps {
  item: ItemConfigInterface
  show: boolean
  onModalClose: () => void
  buyItem?: BuyItemFn
  getPrice: GetPricefn
}

const BuyItemModal: React.FC<BuyItemModalProps> = ({
  show,
  onModalClose,
  item,
  buyItem,
  getPrice
}) => {
  const [quantity, setQuantity] = useState(1)
  const [totalCost, setTotalCost] = useState(item.buyPrice)
  const [totalCostLoading, setTotalCostLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const updateQuantity = (newQuantity: number) => {
    setErrorMsg('')
    const qty = newQuantity || 1 // don't let this number go to 0
    setQuantity(qty)
    setTotalCostLoading(true)
    getPrice(item.id!, qty)
      .then((price) => {
        setTotalCost(price.buy)
        setTotalCostLoading(false)
      })
      .catch((err) => {
        throw err
      })
  }

  const onBuyHandler = async () => {
    setErrorMsg('')
    try {
      setLoading(true)
      console.log('buying')
      const result = await (
        await buyItem!(item.id!, quantity, totalCost)
      ).wait()
      console.log(result)
      setLoading(false)
      onModalClose()
    } catch (error) {
      console.error(error)
      updateQuantity(quantity) // update to new price
      setErrorMsg(
        'Error buying. Usually this means someone else bought the item before you could and you can try again at the new price. Or, you do not have enough $PTG.'
      )
      setLoading(false)
    }
  }

  const TotalPriceElement: React.FC = () => {
    if (totalCostLoading) {
      return <Spinner />
    }

    if (totalCost.eq(0)) {
      return <p className="font-gilroy-semibold text-p1 mt-1">Not Available</p>
    }

    return (
      <p className="font-gilroy-semibold text-p1 mt-1">
        {humanBigNumber(totalCost)} PTG
      </p>
    )
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} width="w-1/3">
      <div className=" bg-dark px-16 py-16 relative">
        <div className="flex justify-end absolute left-0 right-0 px-20 py-4">
          <button onClick={onModalClose} className="focus:outline-none">
            <Icon icon="close" fontSize={30} />
          </button>
        </div>
        <h2 className="font-gilroy-bold text-p1 lg:text-h4">Buy Item</h2>
        <p className="font-gilroy-medium text-p3 my-4">
          You are about to purchase {item.name}
        </p>
        {errorMsg && (
          <p className="font-gilroy-medium text-p3 my-4 text-theme-red">
            {errorMsg}
          </p>
        )}
        {loading ? (
          <div className="flex flex-col mt-28 items-center">
            <SkynetImageFixer className="rounded-full" src={item.image} alt="alt" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">Buying item, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <div className="flex">
              <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
                Quantity remaining
              </p>
              <div className="px-4 bg-primary rounded-full ml-2 flex items-center justify-center">
                <span className=" text-p3 font-gilroy-semibold ">
                  {getItemQuantity(item.reserves.item.toNumber())}
                </span>
              </div>
            </div>
            <div className=" mt-10">
              <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
                Price
              </p>
              <div className="mt-1 flex items-center">
                <p className="font-gilroy-semibold text-p1">
                  {humanBigNumber(item.buyPrice)} PTG
                </p>
              </div>
              <p className="text-p3 text-gray-300 mt-3 p-4 bg-black mb-6">
                Item prices are determined by the amount of Items remaining and
                the number you are buying (a bonding curve).
              </p>
            </div>
            <div className="mt-10">
              <NumberIncrement
                value={quantity}
                setValue={(value: number) => updateQuantity(value)}
              />
            </div>
            <div className="my-10">
              <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
                total Price
              </p>
              <TotalPriceElement />
            </div>
            <div className="w-3/5">
              {totalCost.gt(0) && (
                <Button
                  rounded
                  className="uppercase w-40 tracking-wide font-gilroy-semibold"
                  fluidWidth
                  padding="px-10 py-6"
                  onClick={onBuyHandler}
                >
                  buy now
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export type { BuyItemModalProps }
export default BuyItemModal
