/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { ITournamentV5, ITournamentV5Interface } from "../ITournamentV5";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "creator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "notBefore",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "NewTournament",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "gladiatorId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "faction",
        type: "uint16",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "registrationId",
        type: "uint256",
      },
    ],
    name: "RegistrationEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "winner",
        type: "uint256",
      },
    ],
    name: "TournamentComplete",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "factions",
    outputs: [
      {
        internalType: "string[]",
        name: "",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "firstRoll",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "lastRoll",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "maxFactionBonus",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "notBefore",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "registrations",
    outputs: [
      {
        components: [
          {
            internalType: "uint16",
            name: "faction",
            type: "uint16",
          },
          {
            internalType: "uint256",
            name: "gladiator",
            type: "uint256",
          },
        ],
        internalType: "struct ITournamentV4.Registration[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "roller",
    outputs: [
      {
        internalType: "contract IDiceRolls",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "rollerV5",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "started",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ITournamentV5__factory {
  static readonly abi = _abi;
  static createInterface(): ITournamentV5Interface {
    return new utils.Interface(_abi) as ITournamentV5Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ITournamentV5 {
    return new Contract(address, _abi, signerOrProvider) as ITournamentV5;
  }
}
