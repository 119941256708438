import React from 'react'
import Icon from '../Icon/Icon'

interface NumberIncrementProps {
  value: number
  setValue: (value: number) => void
}

interface IncrementButtonProps {
  increase?: boolean
  onClick: () => void
}

const IncrementButton: React.FC<IncrementButtonProps> = ({
  increase,
  onClick
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="py-3 px-2 border border-primary flex focus:outline-none items-center"
    >
      <Icon icon={increase ? 'add' : 'remove'} fontSize={22} />
    </button>
  )
}

const NumberIncrement: React.FC<NumberIncrementProps> = ({
  value,
  setValue
}) => {
  const decreaseValue = () => {
    let val = value - 1
    if (!value) {
      val = value
    }
    setValue(val)
  }

  return (
    <div className="flex items-center">
      <IncrementButton onClick={decreaseValue} />
      <input
        value={value}
        onChange={() => setValue(value)}
        className="bg-transparent w-24 font-bold text-white focus:outline-none text-h5 text-center"
      />
      <IncrementButton increase onClick={() => setValue(value + 1)} />
    </div>
  )
}

export type { NumberIncrementProps }
export default NumberIncrement
