import React from 'react'
import fixer from '../../../utils/skynetUrlFixer'
import Button from '../../atoms/Button/Button'

interface ItemCategoryButtonProps {
  active: boolean
  category: string
  onClick: () => void,
  image: string
}

const getTitle = (category: string) => {
  if (category === 'potions') return 'potions & elixirs'
  return category
}

const TabWithIconButton: React.FC<ItemCategoryButtonProps> = ({
  category,
  active,
  onClick,
  image
}) => {
  const buttonClasses = [
    'capitalize focus:outline-none border border-transparent ',
    active && 'border-primary'
  ].join('')

  return (
    <Button
      background="bg-transparent"
      color={active ? 'text-primary' : 'text-white'}
      rounded
      onClick={onClick}
      className={buttonClasses}
    >
      <img src={fixer(image)} className=" w-10 mr-4" alt={category} />
      <span className=" font-gilroy-medium">{getTitle(category)}</span>
    </Button>
  )
}

export type { ItemCategoryButtonProps }
export default TabWithIconButton
