import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useInviteStore from '../../../hooks/useInviteStore'

const InviteStore: React.FC = () => {
  const { search } = useLocation()
  const { setReferrer, setInviteCode } = useInviteStore()

  useEffect(() => {
    const params = new URLSearchParams(search)
    const referrer = params.get('ref')
    const inviteCode = params.get('i')
    if (referrer) {
      setReferrer(referrer)
    }
    if (inviteCode) {
      setInviteCode(inviteCode)
    }
  }, [search, setReferrer, setInviteCode])

  return null
}

export default InviteStore
