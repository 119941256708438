import React from 'react'
import { DateTime } from 'luxon'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import InventoryInterFace from '../../../../interfaces/Trophy.interface'

interface GladiatorHistoryCardProps {
  className?: string
  trophy: InventoryInterFace
  gladiatorImage: string
}

const GladiatorHistoryCard: React.FC<GladiatorHistoryCardProps> = ({
  className,
  gladiatorImage,
  trophy
}) => {
  const getTime = (): DateTime => {
    return DateTime.fromSeconds(parseInt(trophy.properties.createdAt, 10), {
      zone: 'UTC'
    })
  }

  return (
    <Container className={classNames('p-10', className)}>
      <p className="text-base opacity-80">{getTime().year}</p>
      <div className="font-vermin text-p1">
        <p className="text-p2 font-bold">
          {`${getTime().monthShort}. ${getTime().day}`}
        </p>
      </div>
      <div className="flex mt-6">
        <FactionImage src={gladiatorImage.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud')} alt="gladiator image" />

        <div className="ml-8">
          <h4 className="text-primary text-p-lg mb-5">Champion</h4>
          <h5 className="text-p-lg opacity-80 font-gilroy-semibold">
            {trophy.name} Tournament
          </h5>
          <p className="opacity-60 text-base leading-9 mb-4 mt-2">
            Defeated{': '}
            {trophy.properties.defeated.value.map((gladiator) => (
              <li key={`card-defeated-${gladiator}-${trophy.name}`}>
                <span>{` ${gladiator}`} </span>
              </li>
            ))}
          </p>
          <Link
            to={`/tournament/${trophy.properties.tournamentID}`}
            className="text-p4 uppercase text-primary font-gilroy-semibold"
          >
            Relive the glory {'>>'}
          </Link>
        </div>
      </div>
    </Container>
  )
}

const FactionImage = styled.img`
  width: 8rem;
  height: 8rem;
`

const Container = styled.article`
  background: #151520;
  border-radius: 12px;
  max-width: 400px;
  min-width: 400px;
`

export type { GladiatorHistoryCardProps }

export default GladiatorHistoryCard
