import { constants, BigNumber, utils } from 'ethers'
import React, { useState, useEffect, useMemo } from 'react'
import { TournamentBetAmounts, useMakeBet } from '../../../../hooks/useBetting'
import {
  FreeBetCoinMetadata,
  useFreeBetCoins
} from '../../../../hooks/useFreeBets'
import { usePrestigeBalance } from '../../../../hooks/usePrestigeBalance'
import PlayerInterface from '../../../../interfaces/Player.interface'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Button from '../../../atoms/Button/Button'
import FreeBetCoin from '../../../atoms/FreeBetCoin/FreeBetCoin'
import Modal, { ModalProps } from '../../../atoms/Modal/Modal'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../../atoms/Spinner/Spinner'

export interface BetModalProps extends ModalProps {
  selectedGladiator: PlayerInterface
  tournamentId: string
  tournamentName: string
  userBets: TournamentBetAmounts
}

const oneHundred = utils.parseEther('100')
const one = utils.parseEther('1')

const BetModal: React.FC<BetModalProps> = ({
  showModal,
  onModalClose,
  selectedGladiator,
  tournamentId,
  tournamentName,
  userBets
}) => {
  const [makeBetLoading, setMakeBetLoading] = useState(false)
  const { makeBet } = useMakeBet()
  const { balance, coins, makeBet: makeFreeBet } = useFreeBetCoins()
  const prestigeBalance = usePrestigeBalance()
  const [amountToBet, setAmountToBet] = useState(
    prestigeBalance.gt(oneHundred) ? '100' : humanBigNumber(prestigeBalance)
  )
  const [errors, setErrors] = useState('')

  useEffect(() => {
    setErrors('')
  }, [])

  const betPlaced = useMemo(() => {
    return (
      userBets[BigNumber.from(selectedGladiator.id).toHexString()] &&
      userBets[BigNumber.from(selectedGladiator.id).toHexString()].gt(0)
    )
  }, [userBets, selectedGladiator])

  const userBetAmount = useMemo(() => {
    if (!userBets[BigNumber.from(selectedGladiator.id).toHexString()]) {
      return constants.Zero
    }

    return userBets[BigNumber.from(selectedGladiator.id).toHexString()]
  }, [userBets, selectedGladiator])

  const isMiniPTG = useMemo(() => {
    return Number(utils.formatEther(userBetAmount)) < 1
  }, [userBetAmount])

  const ifPlaceBetWithMPTG = useMemo(() => {
    return prestigeBalance.lt(one)
  }, [prestigeBalance])

  const hasFreeBetCoins = !!balance && balance > 0

  const placeBet = async (freeBetCoin?: FreeBetCoinMetadata) => {
    if (!selectedGladiator?.id) {
      throw new Error('trying to bet without a selected gladiator')
    }
    setErrors('')
    setMakeBetLoading(true)

    const amount = ifPlaceBetWithMPTG
      ? (Number(amountToBet) / 1000).toString()
      : amountToBet

    try {
      if (freeBetCoin) {
        await makeFreeBet(
          freeBetCoin,
          tournamentId,
          selectedGladiator?.id,
          freeBetCoin.value.add(userBetAmount)
        )
      } else {
        await makeBet({
          tournamentId,
          gladiatorId: selectedGladiator?.id,
          amount,
          expectedTotalBet: utils.parseEther(amount).add(userBetAmount)
        })
      }

      onModalClose()
    } catch (error) {
      console.error(error)
      setErrors(
        `Something went wrong. Your best course of action is generally to bet again. Error: ${
          (error as any).message
        }`
      )
    } finally {
      setMakeBetLoading(false)
    }
  }

  return (
    <Modal showModal={showModal} onModalClose={onModalClose}>
      <div className="bg-black flex flex-col items-center px-16 pt-20 pb-16">
        <SkynetImageFixer
          className="w-64 h-64 rounded-full"
          src={selectedGladiator?.image}
          alt="alt"
        />
        {errors && (
          <p className="text-p2 text-red-400 text-center my-8">{errors}</p>
        )}
        {makeBetLoading && (
          <div className="flex flex-col items-center mb-16 mt-8">
            <p className=" text-p1 mb-4">Placing bet, please wait ...</p>
            <Spinner />
          </div>
        )}
        {!makeBetLoading && (
          <div>
            <p className="font-gilroy-semibold mt-8 mb-4 text-h5 text-center">
              {betPlaced && 'Increase'} Bet on {selectedGladiator?.name} to win{' '}
              <br /> {tournamentName}
            </p>
            {userBetAmount.gt(0) && (
              <p className="text-center text-base">
                Existing bet: {humanBigNumber(userBetAmount, isMiniPTG)}{' '}
                {isMiniPTG ? 'mPTG' : 'PTG'}
              </p>
            )}
            {/* handle the case where the user has bet with a free bet but has no PTG */}
            {betPlaced && prestigeBalance.lte(2) && (
              <div className="mt-10 mb-10 text-center">
                <p className="text-p-lg mb-4">
                  You cannot increase your bet with a free bet coin.
                </p>
                <Button onClick={() => onModalClose()}>Close</Button>
              </div>
            )}
            {hasFreeBetCoins && !betPlaced && (
              <div className="mt-10 mb-10 text-center">
                <h2 className="text-p-lg mb-4">Choose a free bet coin</h2>
                {coins?.map((coin) => {
                  return (
                    <FreeBetCoin
                      coin={coin}
                      onClick={() => placeBet(coin)}
                      className="mr-4 mb-4"
                    />
                  )
                })}
              </div>
            )}
            {prestigeBalance.gt(2) && hasFreeBetCoins && (
              <p className="text-h4 uppercase my-10 text-center">Or</p>
            )}
            {prestigeBalance.gt(2) && (
              <div>
                <label className="bg-dark w-full px-8 py-4 flex flex-col rounded-xl my-5">
                  <span className="text-p5 uppercase font-gilroy-semibold w-full">
                    bet amount
                  </span>
                  <input
                    onChange={(e) => setAmountToBet(e.target.value)}
                    value={amountToBet}
                    className="text-h5 font-gilroy-semibold bg-transparent outline-none"
                  />
                </label>
                <Button
                  onClick={() => placeBet()}
                  className="w-full items-center flex"
                  rounded
                >
                  <span className=" uppercase text-p3 mt-1 mr-4">bet</span>
                  <span className=" text-p-lg font-gilroy-semibold uppercase">
                    {amountToBet} {ifPlaceBetWithMPTG ? 'mPTG' : 'PTG'}
                  </span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default BetModal
