import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import getItemRarityColor from '../../../../utils/getItemRarityColor'
import Button from '../../../atoms/Button/Button'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

type TypeName = 'common' | 'uncommon' | 'rare'

interface ItemCardProps {
  item: ItemConfigInterface
  viewItem: (x: boolean) => void
}

const UserItemCard: React.FC<ItemCardProps> = ({ item, viewItem }) => {
  return (
    <StyledContainer className="w-full lg:w-1/4 h-full lg:px-5 mb-10">
      <div className="info w-full flex flex-col justify-around items-center py-6 px-10 bg-dark bg-opacity-50">
        <div className="flex flex-col items-center">
          <span
            className={classNames(
              'inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1 ',
              getItemRarityColor(item.attributes.rarity as TypeName)
            )}
          >
            {item.attributes.rarity}
          </span>
          <p className="font-gilroy-semibold text-base pt-4 text-center">
            {item.name}
          </p>
        </div>
        <SkynetImageFixer src={item.image} className="pt-12 pb-8" alt={item.name} />

        <div className="flex flex-col items-center">
          <p className="text-p3 font-gilroy-semibold text-center mb-4">
            {item.attributes.description}
          </p>
          <div className="flex mb-5">
            <p className="flex text-gray-300 font-gilroy-semibold text-p3">
              Quantity
            </p>
            <div className="px-3 bg-primary rounded-full ml-2 flex items-center justify-center">
              <span className=" text-p3 font-gilroy-semibold ">
                {item.balance.toNumber()}
              </span>
            </div>
          </div>
          {!item.attributes.disableMarketplace && (
            <div className="flex">
              <Button
                rounded
                background="bg-red-900"
                color="text-white"
                className="uppercase w-full mr-3 focus:outline-none mt-6"
                fontSize="text-p4"
                onClick={() => viewItem(true)}
                style={{
                  background: 'rgba(61, 18, 9, 1)'
                }}
              >
                Buy more
              </Button>
              <Button
                rounded
                background="bg-black"
                color="text-white"
                className="uppercase border border-gray-700 w-full focus:outline-none mt-6"
                fontSize="text-p4"
                onClick={() => viewItem(false)}
              >
                sell
              </Button>
            </div>
          )}
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.article`
  .info {
    min-height: 380px;
  }
`

export type { ItemCardProps }
export default UserItemCard
