import React, { FormEventHandler, useState } from 'react'
import { MinimalGladiator } from '../../../../hooks/useGladiator'
import { useWrappedAssets } from '../../../../hooks/useWrappedAssets'
import fixer from '../../../../utils/skynetUrlFixer'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../../atoms/Spinner/Spinner'

interface WrapGladiatorModalProps {
  show: boolean
  onModalClose: () => void
  safeAddress?: string
  gladiator: MinimalGladiator
}

const WrapGladiatorModal: React.FC<WrapGladiatorModalProps> = ({
  show,
  onModalClose,
  safeAddress,
  gladiator
}) => {
  const { wrap } = useWrappedAssets()
  const [wrappingLoading, setWrappingLoading] = useState(false)

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    try {
      setWrappingLoading(true)
      if (!safeAddress || !gladiator.id) return
      await wrap(gladiator.id, 1)
      setWrappingLoading(false)
      onModalClose()
    } catch (error) {
      setWrappingLoading(false)
      throw error
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Wrap gladiator
        </legend>

        {wrappingLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <SkynetImageFixer className="rounded-full" src={gladiator.image} alt="ptg" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">
                Wrapping gladiator, please wait ...
              </p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16 flex items-center flex-col">
              <img
                className="w-60"
                src={fixer(gladiator.image)}
                alt={gladiator.name}
              />

              <div className="p-8 w-full bg-gray-900 bg-opacity-80 rounded-xl flex flex-col justify-center">
                <p className="text-p1 text-gray-200 mb-4 font-gilroy-bold">
                  Wrap {gladiator.name}
                </p>
                <p className=" text-p2 text-gray-200">
                  Wrapping gladiator allow your gladiator to be Treadable on
                  OpenSea, Rarible or any other ERC721 marketplace
                </p>
              </div>
            </section>

            <div className=" flex justify-center mt-16">
              <Button
                rounded
                className=" w-2/5 font-bold uppercase"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Wrap gladiator
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { WrapGladiatorModalProps }
export default WrapGladiatorModal
