import React, { useState } from 'react'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import {
  useApproval,
  useBalance,
  useIsApproved,
  useSwapToPTG,
  useTokenQuote
} from '../../../../hooks/use1Inch'
import humanBigNumber from '../../../../utils/humanBigNumber'
import { PTG, tokenListByName } from '../../../../utils/tokens'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'
import SwitchNetworkButton from '../SwitchNetworkButton/SwitchNetworkButton'
import SelectToken from './SelectToken'

interface SwapModalProps {
  show: boolean
  onModalClose: () => void
}

const SwapModal: React.FC<SwapModalProps> = ({
  show,
  onModalClose: parentOnModalClose
}) => {
  const { signer, safeAddress } = useWeb3Context()
  const [amountToSwap, setAmountToSwap] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedInput, setSelectedInput] = useState(
    tokenListByName['Matic']
  )
  const { balance } = useBalance(selectedInput.address)
  const [errors, setErrors] = useState('')

  const { loading: quoteLoading, amountOut } = useTokenQuote(
    selectedInput.address,
    PTG.wrappedAssetAddress!,
    amountToSwap
  )
  const { isApproved, revalidate: revalidateIsApproved } = useIsApproved(
    selectedInput.address,
    amountToSwap,
    signer?.address
  )
  const { approve } = useApproval()
  const { swap } = useSwapToPTG(selectedInput.address, amountToSwap)

  const onModalClose = () => {
    setAmountToSwap('')
    setErrors('')
    parentOnModalClose()
  }

  const swapOrApprove = async () => {
    console.log('swapOrApprove')
    setLoading(true)
    setErrors('')
    try {
      if (!isApproved) {
        const tx = await approve(selectedInput.address)
        console.log('approve hash: ', tx?.hash)
        await tx?.wait()
        revalidateIsApproved()
        return true
      }
      // otherwise we can swap
      await swap()
      onModalClose()
    } catch (err: any) {
      setErrors(err.message)
    } finally {
      setLoading(false)
    }
    return true
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <div className="px-6 py-4 lg:p-16 bg-dark rounded-24 text-base">
        <legend className="text-h5 mb-16 font-gilroy-medium ">Get PTG</legend>
        <SwitchNetworkButton className="mb-10" />
        {!loading && (
          <p className="text-base mb-16 font-gilroy-medium ">
            Swap tokens on the Polygon network. Don&apos;t have tokens on the
            Polygon network? Try{' '}
            <a
              href={`https://hippodrome.cryptocolosseum.com/ptg/${safeAddress}`}
              className="underline text-primary"
            >
              Hippodrome
            </a>
            .
          </p>
        )}

        {errors && (
          <p className="text-base text-theme-red mb-16 font-gilroy-medium ">
            {errors}
          </p>
        )}

        {loading ? (
          <div className="flex flex-col mt-28 items-center">
            <img className="h-24 w-24" src="/factions/MTC.png" alt="$MATIC" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">
                please wait ...
              </p>
              <p className=" text-p1 mb-4">
                There will be two popups. One to do the swap and one to unwrap the PTG.
              </p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <p>Send</p>
            <section className="flex-wrap bg-gray-900 bg-opacity-80 rounded-xl flex flex-col">
              <div className="p-8 flex flex-row justify-between items-center">
                <SelectToken
                  selectedToken={selectedInput}
                  setSelectedToken={setSelectedInput}
                />
                <input
                  className="bg-transparent focus:outline-none text-p3 lg:text-p-lg font-gilroy-medium text-right"
                  placeholder="Amount to swap"
                  value={amountToSwap}
                  min={0}
                  step="any"
                  type="string"
                  onChange={(e) => {
                    setAmountToSwap(e.target.value)
                  }}
                />
              </div>
              <div className="mr-10 text-p2 pb-4 text-right">
                <p>Balance {humanBigNumber(balance || 0)}</p>
              </div>
            </section>
            <p className="mt-16">Receive</p>
            <section className="mb-16 p-8 bg-gray-900 bg-opacity-80 rounded-xl flex flex-row justify-between items-center">
              <div className="flex flex-wrap lg:flex-nowrap items-center justify-between">
                <div className="flex items-center cursor-pointer">
                  <img
                    src={PTG.logoURI}
                    alt={PTG.symbol}
                    className="w-20 h-20"
                  />
                  <div className="text-p-lg text-gray-200 ml-5 flex flex-row items-center">
                    <p>{PTG.name}</p>
                  </div>
                </div>
              </div>
              <div className="bg-transparent focus:outline-none text-p-lg font-gilroy-medium text-right">
                {quoteLoading && <Spinner />}
                {!quoteLoading && <p>{humanBigNumber(amountOut || 0)}</p>}
              </div>
            </section>
            <div className="flex justify-center mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p3"
                onClick={swapOrApprove}
              >
                {isApproved ? 'Swap' : 'Approve'}
              </Button>
            </div>
            <div className="flex flex-col items-center mt-10">
              <p>Powered by</p>
              <img
                src="/images/icons/1inch_color_white.svg"
                alt="1inch logo"
                className="w-48"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export type { SwapModalProps }
export default SwapModal
