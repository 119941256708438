import React, { Suspense } from 'react'
import Spinner from '../components/atoms/Spinner/Spinner'

const SeasonTicketApp = React.lazy(() => import('./App'))

const SeasonTicketLoader: React.FC = () => {
  return (
    <Suspense
      fallback={(
        <div>
          <Spinner />
        </div>
      )}
    >
      <SeasonTicketApp />
    </Suspense>
  )
}

export default SeasonTicketLoader
