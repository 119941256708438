// This file is necessary because "usegladiatorOwner" hook couldn't be added on useGladiator file as it is dependent on useLeaderboard.ts, add that will lead to dependency cycling

import { useMemo } from 'react'
import { useWeb3Context } from '../contexts/Web3Context'
import { useLeaderboardList } from './useLeaderboard'

export const useGladiatorOwner = (gladiatorId: string) => {
  const { data: leaderboard } = useLeaderboardList()
  const { safeAddress } = useWeb3Context()

  const owner = useMemo(() => {
    const owner = leaderboard?.usersWithGladiators.find((user) => {
      const ifOwnsGladiator = user.gladiators.find(
        (usersGladiator) => gladiatorId === usersGladiator?.id
      )
      return ifOwnsGladiator
    })
    return owner
  }, [leaderboard, gladiatorId])

  const isCurrentUserOwner = useMemo(() => {
    return owner?.address.toLowerCase() === safeAddress?.toLowerCase()
  }, [owner, safeAddress])

  return {
    owner,
    isCurrentUserOwner,
    loading: !leaderboard
  }
}
