import React from 'react'
import classNames from 'classnames'

interface YouPillProps {
  className?: string
}

const YouPill: React.FC<YouPillProps> = ({ className }) => {
  return (
    <div
      className={classNames(
        'inline-block rounded-full text-white text-p4 font-gilroy-bold px-4 py-1 bg-primary',
        className
      )}
    >
      You
    </div>
  )
}

export default YouPill
