import { constants } from 'ethers'
import React, { useState } from 'react'
import { useClaimBet, useExpectedWinnings } from '../../../../hooks/useBetting'
import PlayerInterface from '../../../../interfaces/Player.interface'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'
import ChampionCard from '../Cards/ChampionCard/ChampionCard'

export interface WonModalprops {
  champion?: PlayerInterface
  tournamentId: string
  userWon: boolean
}

const WonModal: React.FC<WonModalprops> = ({
  champion,
  tournamentId,
  userWon
}) => {
  const [claimBetLoading, setClaimBetLoading] = useState(false)
  const { claimBet } = useClaimBet()

  const {
    expectedWinning,
    loading: loadingExpectedWinnings
  } = useExpectedWinnings(tournamentId, champion)

  const claimWinnings = async () => {
    try {
      setClaimBetLoading(true)
      await claimBet(tournamentId)
      // we purposely don't turn off the loading here. It'll stay loading until the modal is cleared.
    } catch (error) {
      console.log(error)
      setClaimBetLoading(false)
      throw new Error(error)
    }
  }

  const winnings = expectedWinning || constants.Zero

  const amountWon = loadingExpectedWinnings ? (
    <Spinner />
  ) : (
    humanBigNumber(winnings)
  )

  return (
    <Modal showModal={userWon} onModalClose={() => {}}>
      <div className="bg-black px-16 py-20">
        <ChampionCard
          className="mb-4"
          champion={champion}
          inBracket={false}
          undecided={false}
        />
        <p className="text-h5 font-gilroy-semibold text-center">You Won!</p>
        <div className="text-p2 text-center pt-0 pb-8">
          Congratulations! You won {amountWon} PTG because you bet on{' '}
          {champion?.name}.
        </div>
        {claimBetLoading ? (
          <div className="flex flex-col items-center mb-16">
            <p className=" text-p1 mb-4">Claiming, please wait ...</p>
            <Spinner />
          </div>
        ) : (
          <Button onClick={claimWinnings} className="w-full uppercase" rounded>
            claim your winnings
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default WonModal
