import classNames from 'classnames'
import { BigNumber, utils } from 'ethers'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useWeb3Context } from '../../../contexts/Web3Context'
import { ItemConfigInterface } from '../../../interfaces/ItemConfig.interface'
import getItemQuantity from '../../../utils/getItemQuantity'
import getItemRarityColor, { TypeName } from '../../../utils/getItemRarityColor'
import humanBigNumber from '../../../utils/humanBigNumber'
import fixer from '../../../utils/skynetUrlFixer'
import Button from '../../atoms/Button/Button'
import ConnectButton from '../../atoms/ConnectButton/ConnectButton'

interface ItemCardProps {
  item: ItemConfigInterface
  onBuyHandler?: (id: BigNumber) => void
  onUseHandler?: (id: BigNumber) => void
  rounded?: boolean
}

const ItemCard: React.FC<ItemCardProps> = ({
  item,
  onBuyHandler,
  onUseHandler,
  rounded
}) => {
  const { connected } = useWeb3Context()

  const isMiniPTG = useMemo(() => {
    if (item.buyPrice) {
      return item.buyPrice.lt(utils.parseEther('1'))
    }
    return false
  }, [item.buyPrice])

  if (!onBuyHandler && !onUseHandler) {
    throw new Error('you must specify either a use handler or a buy handler')
  }

  if (onUseHandler && onBuyHandler) {
    throw new Error(
      'you may only specify one of onUseHandler or onBuyHandler, you specified both'
    )
  }

  const CTASection: React.FC = () => {
    if (onUseHandler) {
      return (
        <div className="mt-4 flex flex-col items-center w-full">
          <div className="flex mb-10 items-center">
            <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
              Quantity
            </p>
            <div className="px-4 bg-primary rounded-full ml-2 flex items-center justify-center">
              <span className="text-p3 font-gilroy-semibold ">
                {item.balance.toString()}
              </span>
            </div>
          </div>
          <Button
            rounded
            background="bg-red-900"
            color="text-white"
            className="uppercase w-full focus:outline-none"
            fontSize="text-p4"
            onClick={() => onUseHandler(BigNumber.from(item.id))}
            style={{
              background: 'rgba(61, 18, 9, 1)'
            }}
          >
            Use item
          </Button>
        </div>
      )
    }
    if (!onBuyHandler) {
      throw new Error('should not happen: buy handler not specified')
    }
    if (item.buyPrice.eq(0)) {
      return (
        <div className="mt-4 flex flex-col items-center w-full">
          <p className=" font-gilroy-bold text-green-700 mb-8 text-p1">
            Sold Out
          </p>
        </div>
      )
    }

    return (
      <div className="mt-4 flex flex-col items-center w-full">
        <p className=" font-gilroy-bold text-green-700 mb-8 text-p1">
          {humanBigNumber(item.buyPrice, isMiniPTG)}{' '}
          {isMiniPTG ? 'isMiniPTG' : 'PTG'}
        </p>

        <div className="flex mb-10 items-center">
          <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
            Quantity remaining
          </p>
          <div className="px-4 bg-primary rounded-full ml-2 flex items-center justify-center">
            <span className="text-p3 font-gilroy-semibold ">
              {getItemQuantity(item.reserves.item.toNumber())}
            </span>
          </div>
        </div>

        {connected && (
          <Button
            rounded
            background="bg-red-900"
            color="text-white"
            className="uppercase w-full focus:outline-none"
            fontSize="text-p4"
            onClick={() => onBuyHandler(BigNumber.from(item.id))}
            style={{
              background: 'rgba(61, 18, 9, 1)'
            }}
          >
            Buy item
          </Button>
        )}
        {!connected && <ConnectButton customText="Sign in to Buy" />}
      </div>
    )
  }

  return (
    <StyledContainer className="w-full lg:w-1/4 h-full lg:px-5 mb-10">
      <div
        className={classNames(
          'info w-full flex flex-col justify-around items-center py-6 px-10 bg-dark bg-opacity-50',
          rounded && 'rounded-16'
        )}
      >
        <div className="flex flex-col items-center">
          <span
            className={classNames(
              'inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1',
              getItemRarityColor(item.attributes.rarity as TypeName)
            )}
          >
            {item.attributes.rarity}
          </span>
          <p className="font-gilroy-semibold text-base pt-4 text-center">
            {item.name}
          </p>
        </div>
        <img src={fixer(item.image)} className="pt-12 pb-8" alt={item.name} />

        <div className="flex flex-col items-center">
          <p className="text-p3 font-gilroy-semibold text-center mb-4">
            {item.attributes.description}
          </p>
        </div>
      </div>
      <CTASection />
    </StyledContainer>
  )
}

const StyledContainer = styled.article`
  .info {
    min-height: 300px;
  }
`

export type { ItemCardProps }
export default ItemCard
