import classNames from 'classnames'
import React, { useState, Suspense } from 'react'
import styled from 'styled-components'
import Button from '../../../atoms/Button/Button'
import getItemRarityColor, {
  TypeName
} from '../../../../utils/getItemRarityColor'
import { Web3ContextData } from '../../../../contexts/Web3Context'
import Spinner from '../../../atoms/Spinner/Spinner'
import {
  ItemAttribute,
  ItemConfigInterface
} from '../../../../interfaces/ItemConfig.interface'
import fixer from '../../../../utils/skynetUrlFixer'

const TransferWrappedItemModal = React.lazy(
  () => import('../TransferWrappedItemModal/TransferWrappedItemModal')
)
const UnwrapItemModal = React.lazy(
  () => import('../UnwrapItemModal/UnwrapItemModal')
)

interface ItemCardProps {
  item: ItemConfigInterface
  ctx: Web3ContextData
}

const WrappedItemCard: React.FC<ItemCardProps> = ({ item, ctx }) => {
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showUnwrapModal, setShowUnwrapModal] = useState(false)

  return (
    <>
      <StyledContainer className="w-full lg:w-1/4 bg-dark h-full lg:px-5 mb-10">
        <div className="'info w-full flex flex-col justify-around items-center py-6 px-10 bg-dark bg-opacity-50'">
          <div className="flex flex-col items-center">
            <p className="font-gilroy-semibold text-base pt-4 text-center">
              {item.name}
            </p>
          </div>
          <img src={fixer(item.image)} className="pt-12 pb-8" alt={item.name} />

          <div className="flex flex-col items-center">
            <p className="text-p3 font-gilroy-semibold truncate w-1/2 text-center mb-4">
              {item.attributes.description}
            </p>
          </div>
        </div>
        <div className="mt-4 flex flex-col items-center w-full">
          <div className="flex mt-4 mb-10">
            <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
              Attributes
            </p>
          </div>

          <div className="w-full mb-4 px-8">
            {[
              'rarity',
              'attack',
              'defense',
              'hp',
              'percentChanceOfUse',
              'numberOfUses'
            ].map((attribute) => {
              const textColor = getItemRarityColor(
                item.attributes.rarity as TypeName,
                true
              )
              let attributeTitle = attribute

              if (attribute === 'percentChanceOfUse') {
                attributeTitle = 'Chance of use'
              }
              if (attribute === 'numberOfUses') {
                attributeTitle = 'No. of Use'
              }

              return (
                <div
                  key={attribute}
                  className="flex text-p3 mb-3 w-full normal-case items-center justify-between"
                >
                  <p className="font-semibold">{attributeTitle}</p>

                  {attribute === 'rarity' ? (
                    <span
                      className={classNames(
                        'inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1',
                        getItemRarityColor(item.attributes.rarity as TypeName)
                      )}
                    >
                      {item.attributes[attribute]}
                    </span>
                  ) : (
                    <p className={classNames('font-gilroy-bold', textColor)}>
                      {item.attributes[attribute as keyof ItemAttribute]}
                    </p>
                  )}
                </div>
              )
            })}
          </div>

          <div className="flex">
            <Button
              rounded
              background="bg-theme-dark-gray"
              color="text-white"
              className=" border border-gray-900 mr-4 focus:outline-none"
              fontSize="text-p3"
              onClick={() => setShowUnwrapModal(true)}
            >
              Unwrap
            </Button>
            <Button
              rounded
              background="bg-red-900"
              color="text-white"
              className="focus:outline-none"
              fontSize="text-p3"
              onClick={() => setShowTransferModal(true)}
              style={{
                background: 'rgba(61, 18, 9, 1)'
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </StyledContainer>

      <Suspense fallback={<Spinner />}>
        <UnwrapItemModal
          item={item}
          safeAddress={ctx.safeAddress}
          show={showUnwrapModal}
          onModalClose={() => setShowUnwrapModal(false)}
          isItem
        />
      </Suspense>

      <Suspense fallback={<Spinner />}>
        <TransferWrappedItemModal
          item={item}
          ctx={ctx}
          show={showTransferModal}
          onModalClose={() => {
            setShowTransferModal(false)
          }}
        />
      </Suspense>
    </>
  )
}

const StyledContainer = styled.article`
  .info {
    min-height: 300px;
  }
`

export type { ItemCardProps }
export default WrappedItemCard
