/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PlayerInterface from '../../../../../interfaces/Player.interface'
import { factionToDisplayname } from '../../../../../utils/factionToDisplayName'
import SkynetImageFixer from '../../../../atoms/SkynetImageFixer/SkynetImageFixer'

export interface ChampionCardProps {
  champion?: PlayerInterface
  undecided?: boolean
  /** If champion card is being used in tournament bracket or not */
  inBracket?: boolean
  className?: string
}

interface StyledContainerProps {
  inBracket?: boolean
}

const ChampionCard: React.FC<ChampionCardProps> = ({
  undecided,
  champion,
  inBracket,
  className,
}) => {
  return (
    <Container inBracket={inBracket} className={className}>
      <InfoContainer
        className={`p-0 bg-dark border  rounded-12 ${
          inBracket ? 'border-primary' : 'border-gray-700'
        }`}
      >
        {undecided ? (
          <div className="flex items-center justify-center flex-col h-full w-full">
            <SkynetImageFixer
              className="rounded-full bg-gray-700 w-20 h-20 object-cover"
              alt="undecided"
              src="/images/icons/rounded-user.svg"
            />
            <p className="font-gilroy-medium text-p1 mt-3">No Champion yet</p>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div className="flex items-center w-full justify-between py-6 px-8">
              {undecided ? (
                <img
                  className="rounded-full bg-gray-700 w-20 h-20 object-cover"
                  alt="undecided"
                  src="/images/icons/rounded-user.svg"
                />
              ) : (
                <Link
                  to={`/gladiator/${champion?.id}?faction=${champion?.faction}`}
                >
                  <SkynetImageFixer
                    className="rounded-full bg-black w-20 h-20 object-cover object-top"
                    alt={champion?.name}
                    src={champion?.image}
                  />
                </Link>
              )}

              <div className="flex-grow ml-4">
                <span className=" inline-block px-4 py-1 rounded-full mb-2 uppercase bg-green-600 bg-opacity-25 font-gilroy-semibold text-p5 text-green-600">
                  Champion
                </span>
                <p className="text-gray-300 capitalize text-p2 font-gilroy-medium">
                  {champion?.name}
                </p>
              </div>

              <img
                className=" w-16 h-full object-cover"
                alt={`${factionToDisplayname(champion?.faction)} faction`}
                src={`/factions/${champion?.faction}.png`}
              />
            </div>
            <ChampionCoinContainer className="flex items-center justify-center mt-6">
              <Link
                to={`/gladiator/${champion?.id}?faction=${champion?.faction}`}
              >
                <SkynetImageFixer
                  className="w-40 h-40 object-cover"
                  alt={champion?.name}
                  src={champion?.image}
                />
              </Link>
            </ChampionCoinContainer>
          </div>
        )}
      </InfoContainer>
    </Container>
  )
}

export const Container = styled.div<StyledContainerProps>`
  --border-color: ${({ inBracket }) => (inBracket ? ' #ff6240' : '#2A2A3A')};

  display: flex;
  align-items: center;
  flex-direction: column;

  &::before {
    content: '';
    border-top: ${({ inBracket }) => {
      return inBracket ? '1px solid var(--border-color)' : 'none'
    }};
    position: absolute;
    top: 50%;
    width: 2rem;
    left: -2rem;
  }
`

const InfoContainer = styled.div`
  width: 301px;
  height: 276px;
`

const ChampionCoinContainer = styled.div`
  background: url('/images/symbols/champion-coin.png');
  background-size: cover;
  border-radius: 1000px;
  height: 156px;
  width: 156px;
`

ChampionCard.defaultProps = {
  undecided: true,
  inBracket: true
}

export default ChampionCard
