import classNames from 'classnames'
import React from 'react'
import SlideIndicator from '../../atoms/SlideIndicator/SlideIndicator'
import BackButton from '../BackButton/BackButton'
import SpinnerWithTitle from '../SpinnerWithTitle/SpinnerWithTitle'

interface PageContainerWithTitleProps {
  title: string
  subTitle?: string
  loadingTitle: string
  loading: boolean
  additionalItem?: any
  showSlideIndicator?: boolean
}

const PageContainerWithTitle: React.FC<PageContainerWithTitleProps> = ({
  title,
  subTitle,
  loadingTitle,
  loading,
  children,
  additionalItem,
  showSlideIndicator
}) => {
  return (
    <div className="lg:px-56 px-6 rounded-12 max-w-screen-2xl w-full">
      <div className="lg:mt-16 mt-0 w-full mb-10 -ml-5">
        <BackButton />
      </div>

      <div className="w-full py-16 px-6 lg:px-16 min-h-80 rounded-12 bg-dark border border-gray-800 text-base">
        <div className=" flex items-center justify-between">
          <h2 className="font-gilroy-bold text-p1 lg:text-h4">{title}</h2>
          {additionalItem}
        </div>

        {subTitle && (
          <p className="font-gilroy-medium text-p3 mt-4">{subTitle}</p>
        )}

        {loading && <SpinnerWithTitle title={loadingTitle} />}

        {showSlideIndicator && !loading && (
          <SlideIndicator className="mt-10" />
        )}

        {!loading && (
          <div className={classNames(showSlideIndicator ? 'mt-0' : 'mt-10')}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

export default PageContainerWithTitle
