import { ERC20__factory, ERC20 } from '../types/contracts'
import chain from '../models/GameChain'

export interface TokenListToken {
  name: string
  address: string
  symbol: string
  decimals: number
  chainId: number
  logoURI: string

  wrappedAssetAddress?: string
  native?: boolean
  depositable?: boolean // can this token be used for season tickets?
}

export function tokenFromAddress(address: string) {
  const { provider } = chain
  return chain.wrapInMulticall<ERC20>(ERC20__factory.connect(address, provider))
}

export const PTG: TokenListToken = {
  name: 'PTG',
  address: 'wrapped-ptg',
  wrappedAssetAddress: '0xc0f14c88250e680ecd70224b7fba82b7c6560d12',
  symbol: 'PTG',
  decimals: 18,
  chainId: 137,
  logoURI: 'https://arena.cryptocolosseum.com/images/icons/prestige.svg'
}

export const supportedTokens: TokenListToken[] = [
  {
    name: 'Ether',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    symbol: 'ETH',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
  },
  {
    name: 'Ren BTC',
    address: '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501',
    symbol: 'renBTC',
    decimals: 8,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png' // wrapped BTC logo as a placeholder
  },
  {
    name: 'Ren Dogecoin',
    address: '0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
    symbol: 'renDOGE',
    decimals: 8,
    chainId: 137,
    logoURI: '/factions/DOGE.png'
  },
  {
    name: 'Wrapped BTC',
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    symbol: 'WBTC',
    decimals: 8,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
  {
    name: 'Wrapped Matic',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    symbol: 'WMATIC',
    decimals: 18,
    chainId: 137,
    depositable: true,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png'
  },
  {
    name: 'Matic',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'MATIC',
    decimals: 18,
    chainId: 137,
    native: true,
    depositable: true,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png'
  },
  {
    name: 'USD Coin',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    symbol: 'USDC',
    decimals: 6,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  {
    name: 'MAI',
    address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    symbol: 'MAI',
    decimals: 18,
    chainId: 137,
    logoURI:
      'https://raw.githubusercontent.com/0xlaozi/qidao/main/images/mimatic-red.png'
  }
]

export const tokenListTokensByAddress = supportedTokens.reduce(
  (mem: Record<string, TokenListToken>, token) => {
    return { ...mem, [token.address.toLowerCase()]: token }
  },
  {}
)

export const tokenListByName = supportedTokens.reduce(
  (mem: Record<string, TokenListToken>, token) => {
    return { ...mem, [token.name]: token }
  },
  {}
)

export function isNativeToken(address: string) {
  return (tokenListTokensByAddress[address.toString()] || {}).native
}
