import { utils } from 'ethers'
import useSWR from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'

const useInviteCodeMetadata = (inviteCodeString?: string) => {
  const { contracts } = useWeb3Context()

  const { data, revalidate } = useSWR(['/invite-codes', inviteCodeString], {
    fetcher: async (_, inviteCodeString) => {
      if (!inviteCodeString) {
        console.log('called metadata with no invite code string')
        return null
      }
      try {
        console.log('fetchin: ', inviteCodeString)
        const hash = utils.keccak256(Buffer.from(inviteCodeString))
        const { inviteCode: inviteCodeContract } = await contracts
        const data = await inviteCodeContract.inviteCodes(hash)
        console.log('metadata: ', data)
        return data
      } catch (err) {
        console.error('error fetching ', inviteCodeString, err)
        throw err
      }
    }
  })

  return {
    metadata: data,
    loading: data === undefined, // null is ok here so specifically calling for undefined
    revalidate
  }
}

export default useInviteCodeMetadata
