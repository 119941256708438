import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import Button from '../../../atoms/Button/Button'
import Modal, { ModalProps } from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'
import BattleItemCard from '../../../composed/ItemCard/ItemCard'
import ItemCategoryButton from '../../../composed/TabWithIconButton/TabWithIconButton'

export interface BattleItemsModalProps extends ModalProps {
  items?: Record<string, ItemConfigInterface[]>
  equipmentLoading?: boolean
  onUseHandler: (id: BigNumber) => void
  placeItemsLoading?: boolean
  gladiatorName?: string
  tournamentName?: string
}
const BattleItemsModal: React.FC<BattleItemsModalProps> = ({
  showModal,
  onModalClose,
  equipmentLoading = false,
  items = {},
  onUseHandler,
  placeItemsLoading,
  gladiatorName,
  tournamentName
}) => {
  const [selectedActiveCategory, setActiveCategory] = useState<string|undefined>(undefined)
  const availableCategories = Object.keys(items)

  const activeCategory = selectedActiveCategory || availableCategories[0]

  const ItemListOrEmpty: React.FC = () => {
    if (equipmentLoading) {
      return (
        <div className=" flex flex-col mt-32 mb-16 items-center w-full">
          <Spinner />
          <p className="text-base mb-8">
            Loading your inventory.
          </p>
        </div>
      )
    }

    if (Object.keys(items).length === 0) {
      return (
        <div className=" flex flex-col mt-32 mb-16 items-center w-full">
          <p className="text-base mb-8">
            You have no items. Visit the NFT Marketplace to buy.
          </p>
          <Link to="/marketplace">
            <Button
              rounded
              background="bg-dark"
              fontSize="text-p2"
              color="text-primary"
              className="border border-gray-600"
              padding="px-10 py-4"
            >
              Buy More Items
            </Button>
          </Link>
        </div>
      )
    }
    return (
      <>
        {items[activeCategory]?.map((item) => (
          <BattleItemCard
            onUseHandler={onUseHandler}
            item={item}
            key={`battle-item-${BigNumber.from(item.id).toHexString()}`}
          />
        ))}
      </>
    )
  }

  return (
    <Modal showModal={showModal} width="w-4/5" onModalClose={onModalClose}>
      <div className="lg:p-32 p-6 bg-black">
        <header className="flex flex-wrap lg:flex-nowrap justify-between w-full items-center">
          <div>
            <h2 className="font-gilroy-bold text-p1 lg:text-h4 mb-4">
              Equip {gladiatorName} for {tournamentName} Tournament
            </h2>
            <p className="font-gilroy-medium text-p3 mb-4 lg:mb-0">
              Use your items to help your gladiator win. (Note only 3 can be
              applied for any battle). Click Buy More Items to go to the
              marketplace.
            </p>
          </div>
          <Link to="/marketplace">
            <Button
              rounded
              background="bg-dark"
              fontSize="text-p4"
              color="text-primary"
              className="border border-gray-600 uppercase"
            >
              Buy More Items
            </Button>
          </Link>
        </header>
        <ul className="flex items-center lg:bg-dark overflow-x-scroll max-w-full bg-opacity-50 lg:px-10 py-4 my-10">
          {Object.keys(items!).map((category) => (
            <ItemCategoryButton
              active={activeCategory === category}
              category={category}
              key={category}
              image={
                items[category][0]?.image ||
                `/images/symbols/items-category/${category}.svg`
              }
              onClick={() => setActiveCategory(category)}
            />
          ))}
        </ul>
        {placeItemsLoading && (
          <div className="flex flex-col items-center mb-16 mt-32">
            <p className=" text-p1 mb-4">
              Equipping gladiator, please wait ...
            </p>
            <Spinner />
          </div>
        )}
        {!placeItemsLoading && (
          <section className="w-full mt-10 flex flex-wrap lg:-mx-5">
            <ItemListOrEmpty />
          </section>
        )}
      </div>
    </Modal>
  )
}

export default BattleItemsModal
