import React from 'react'
import { BigNumberish } from 'ethers'
import { Link } from 'react-router-dom'
import Button from '../../../atoms/Button/Button'
import { MinimalGladiator } from '../../../../hooks/useGladiator'
import humanBigNumber from '../../../../utils/humanBigNumber'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

interface WalletItemsCardProps {
  gladiator: MinimalGladiator
  onTransferGladiator: (x: BigNumberish) => void
  onWithdrawGladiator: (x: BigNumberish) => void
  wrapGladiator: () => void
}

const WalletGladiatorCard: React.FC<WalletItemsCardProps> = ({
  gladiator,
  onTransferGladiator,
  onWithdrawGladiator,
  wrapGladiator
}) => {
  return (
    <div className="flex items-center flex-wrap lg:flex-nowrap justify-between bg-gray-800 bg-opacity-30 px-4 lg:px-12 py-10 mb-4 rounded-12">
      <SkynetImageFixer
        src={gladiator.image}
        alt="gladiator"
        className="w-40 h-40 object-contain lg:mr-10"
      />
      <div className="flex-grow lg:mr-10">
        <div className="flex items-center">
          <h3 className="mr-4 text-p2 italic">{gladiator.name}</h3>
        </div>
        {gladiator.tagline && (
          <p className="flex my-3 text-gray-400 font-gilroy-semibold text-p2 tracking-wide">
            {gladiator.tagline}
          </p>
        )}
        <p className=" font-gilroy-semibold text-p-lg text-green-600">
          {humanBigNumber(gladiator.prestige)} $PTG
        </p>
      </div>
      <div className="flex items-center lg:w-1/2 flex-wrap lg:flex-nowrap  justify-between lg:justify-end">
        <Button
          rounded
          className=" font-bold ml-8 border border-gray-700"
          background="bg-black"
          color="text-primary"
          onClick={wrapGladiator}
        >
          Wrap
        </Button>

        <Button
          rounded
          className=" font-bold ml-8 border border-gray-700"
          background="bg-black"
          color="text-primary"
          onClick={() => onTransferGladiator(gladiator.id)}
        >
          Transfer
        </Button>

        <Button
          rounded
          className=" font-bold ml-8 bg-opacity-90"
          background="bg-theme-dark-gray"
          onClick={() => onWithdrawGladiator(gladiator.id)}
        >
          Withdraw
        </Button>

        <Link to={`/gladiator/${gladiator.id.toString()}`}>
          <Button
            rounded
            className=" font-bold ml-8  border border-primary"
            background="bg-theme-dark-gray"
          >
            View details
          </Button>
        </Link>
      </div>
    </div>
  )
}

export type { WalletItemsCardProps }
export default WalletGladiatorCard
