import { BigNumber, utils } from 'ethers'
import React, { FormEventHandler, useState } from 'react'
import useSWR from 'swr'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import useMaticBalance from '../../../../hooks/useMaticBalance'
import useMaticPTGPair from '../../../../hooks/useMaticPTGPair'
import humanBigNumber from '../../../../utils/humanBigNumber'
import waitForTx from '../../../../utils/waitForTx'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'

interface SwapMaticModalProps {
  show: boolean
  onModalClose: () => void
}

const usePoolOutput = (amountToSwap: number) => {
  const { getOutput } = useMaticPTGPair()

  const { data } = useSWR(['/pool-output', amountToSwap], {
    fetcher: (_: string, amountToSwap: number) => {
      if (amountToSwap === 0) {
        return BigNumber.from('0')
      }
      return getOutput(utils.parseEther(amountToSwap.toString()))
    }
  })

  return {
    ptgOut: data
  }
}

const SwapMaticModal: React.FC<SwapMaticModalProps> = ({
  show,
  onModalClose
}) => {
  const { safeAddress } = useWeb3Context()
  const { balance: maticBalance } = useMaticBalance()

  const [amountToSwap, setAmountToSwap] = useState(
    parseFloat(utils.formatEther(maticBalance || 0))
  )
  const [errors, setErrors] = useState('')

  const [swapping, setSwapping] = useState(false)
  const { ptgOut } = usePoolOutput(amountToSwap)
  const { swap } = useMaticPTGPair()

  const swapMatic: FormEventHandler = async (e) => {
    e.preventDefault()
    setErrors('')
    const value = utils.parseEther(amountToSwap.toString())
    if (value.eq(0)) {
      return
    }
    try {
      if (!ptgOut) {
        throw new Error('need to know how much to swap for')
      }
      if (!safeAddress) {
        throw new Error('must be logged in')
      }
      setSwapping(true)

      const receipt = await waitForTx(swap(value, ptgOut, safeAddress))
      console.log('receipt for swap: ', receipt.transactionHash)

      setSwapping(false)
      onModalClose()
      setAmountToSwap(0)
    } catch (error) {
      setSwapping(false)
      console.error('error swapping: ', error)
      setErrors(`Error swapping: ${error.message}`)
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={swapMatic}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Swap MATIC for PTG
        </legend>
        {errors && (
          <legend className="text-base text-theme-red mb-16 font-gilroy-medium ">
            {errors}
          </legend>
        )}

        {swapping ? (
          <div className="flex flex-col mt-28 items-center">
            <img className="h-24 w-24" src="/factions/MTC.png" alt="$MATIC" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">swapping, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl lg:h-40 flex flex-col justify-center">
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src="/factions/MTC.png"
                      alt="$MATIC"
                      className="w-20 h-20"
                    />
                    <p className=" px-5 text-p-lg text-gray-200">MATIC</p>
                    <button
                      type="button"
                      onClick={() => {
                        setAmountToSwap(
                          parseFloat(utils.formatEther(maticBalance || 0))
                        )
                      }}
                      className="px-6 py-2 focus:outline-none text-primary bg-primary bg-opacity-20 text-p4 tracking-wide font-gilroy-semibold rounded-full"
                    >
                      MAX
                    </button>
                  </div>
                  <input
                    className=" bg-transparent mt-6 lg:mt-0 focus:outline-none text-h5 font-gilroy-medium lg:text-right"
                    placeholder="Amount to swap"
                    value={amountToSwap}
                    min={0}
                    step="any"
                    max={parseFloat(utils.formatEther(maticBalance || 0))}
                    type="number"
                    onChange={(e) => {
                      setAmountToSwap(parseFloat(e.target.value || '0'))
                    }}
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300">
                BAL: {humanBigNumber(maticBalance || 0)} $MATIC
              </p>
            </section>
            <section className="text-h4">
              <span>
                Receive PTG: {ptgOut ? humanBigNumber(ptgOut) : <Spinner />}
              </span>
              <p className="text-base">1% slippage allowed.</p>
            </section>

            <div className="flex justify-center mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Swap
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { SwapMaticModalProps }
export default SwapMaticModal
