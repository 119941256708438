import {
  BigNumber,
  BigNumberish,
  ContractTransaction,
  providers,
  utils
} from 'ethers'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { KasumahUintLogger } from 'kasumah-logger/dist/types/ethers-contracts'
import { useWeb3Context } from '../contexts/Web3Context'
import { useSubscriber } from './useSubscription'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import gameChain, { Contracts } from '../models/GameChain'
import { EquippedItem } from '../interfaces/EquippedItem.interface'
import { isV4 } from '../utils/isTournamentV4'

export type ItemStore = Record<string, ItemConfigInterface>

export type BuyItemFn = ReturnType<typeof useMarketplace>['buyItem']
export type SellItemFn = ReturnType<typeof useMarketplace>['sellItem']
export type GetPricefn = ReturnType<typeof useMarketplace>['getPrice']

// For now we're just loading the JSON up into the bundle, in the future we should switch it to axios
// but just moving the JSON around from place to place requires some unnecessary
// build work that's hard to update during in-flux dev times.
// To sum: using bundled JSON now - will in the future switch over to an axios request.

let marketplaceItems: ItemStore = {}
let communityItems: ItemStore = {}
const networkName = process.env.REACT_APP_NETWORK_NAME || 'localhost'

let deprecatedItems: ItemStore = {}

try {
  marketplaceItems = require(`../deployments/${networkName}/items/items-marketplace.json`) // eslint-disable-line import/no-dynamic-require
} catch (e) {
  console.log('no marketplace items available on ', networkName)
}

try {
  communityItems = require(`../deployments/${networkName}/items/items-community.json`) // eslint-disable-line import/no-dynamic-require
} catch (e) {
  console.log('no community items available on ', networkName)
}

try {
  deprecatedItems = require(`../deployments/${networkName}/items/items.json`) // eslint-disable-line import/no-dynamic-require
} catch (e) {
  console.log('no deprecated items available on ', networkName)
}

export async function equipperForTournament(tournamentId:BigNumberish, contracts:Promise<Contracts>) {
  const { equipper, equipperV5 } = await contracts
  if (isV4(tournamentId)) {
    return equipper
  }
  return equipperV5
}

export const useEquipmentList = () => {
  const {
    items,
    revalidate: revalidateMarketplace,
    updating
  } = useMarketplace()
  const {
    items: communityItems,
    revalidate: revalidateCommunity,
    updating: communityUpdating
  } = useCommunityItems()

  const revalidate = () => {
    revalidateMarketplace()
    revalidateCommunity()
  }

  return {
    items: {
      ...(items || {}),
      ...(communityItems || {}),
      ...(deprecatedItems || {})
    },
    revalidate,
    loading: !items || !communityItems,
    updating: updating || communityUpdating
  }
}

export const useUserEquipment = () => {
  const {
    userInventory: marketplaceInventory,
    loading: marketplaceLoading,
    updating: marketplaceUpdating
  } = useMarketplace()
  const {
    userInventory: communityInventory,
    loading: communityLoading,
    updating: communityUpdating
  } = useCommunityItems()
  return {
    userInventory: {
      ...marketplaceInventory,
      ...communityInventory
    },
    loading: marketplaceLoading || communityLoading,
    updating: marketplaceUpdating || communityUpdating
  }
}

gameChain.on('connected', () => {
  mutate('/community-items')
})

const useCommunityItemsFetcher = async () => {
  console.log('fetching community items')
  const { safeAddress, contracts } = gameChain
  const { assets } = await contracts

  try {
    const itemsWithBalance = await Promise.all(
      Object.keys(communityItems).map(async (id) => {
        const item = communityItems[id]
        const [balance] = await Promise.all([
          safeAddress
            ? assets.balanceOf(safeAddress, id)
            : Promise.resolve(BigNumber.from('0'))
        ])
        return {
          ...item,
          id: BigNumber.from(id),
          balance
        }
      })
    )
    return itemsWithBalance.reduce((store: ItemStore, item) => {
      return {
        ...store,
        [item.id.toHexString()]: item
      }
    }, {})
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const useCommunityItems = () => {
  const { safeAddress } = useWeb3Context()

  const { data, revalidate, isValidating } = useSWR('/community-items', {
    fetcher: useCommunityItemsFetcher
  })

  const userInventory = useMemo(() => {
    if (!data || !safeAddress) {
      return {}
    }
    return Object.values(data).reduce((memo: ItemStore, item) => {
      if (!item.balance || item.balance.eq(0)) {
        return memo
      }
      return {
        [item.id!.toHexString()]: item,
        ...memo
      }
    }, {})
  }, [data, safeAddress])

  return {
    userInventory,
    items: data,
    revalidate,
    loading: !data,
    updating: isValidating
  }
}

gameChain.on('connected', (addr: string) => {
  mutate(['/marketplace/items', addr])
  mutate(['/marketplace/items', undefined])
})

const useMarketplaceFetcher = async (_: string, safeAddress: string) => {
  console.log('fetching marketplace: ', safeAddress)
  const { assets, marketplace } = await gameChain.contracts

  try {
    const itemsWithPrices = await Promise.all(
      Object.keys(marketplaceItems).map(async (id) => {
        const item = marketplaceItems[id]
        const [{ buy: buyPrice, sell: sellPrice }, balance, reserves] =
          await Promise.all([
            marketplace.prices(id, 1),
            safeAddress
              ? assets.balanceOf(safeAddress, id)
              : Promise.resolve(BigNumber.from('0')),
            marketplace.reserves(id)
          ])
        return {
          ...item,
          id: BigNumber.from(id),
          balance,
          buyPrice,
          sellPrice,
          reserves: {
            ptg: reserves.ptg,
            item: reserves.item
          }
        }
      })
    )
    return itemsWithPrices.reduce((store: ItemStore, item) => {
      return {
        ...store,
        [item.id.toHexString()]: item
      }
    }, {})
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const useMarketplace = () => {
  const { contracts, safeAddress, prestigeID } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const { data, revalidate, isValidating } = useSWR(
    () => {
      return ['/marketplace/items', safeAddress]
    },
    {
      fetcher: useMarketplaceFetcher,
      onSuccess: (_, key) => {
        const cb = () => {
          mutate(key)
        }
        subscribe(key, 'useMarketplaceBuy', async () => {
          const { marketplace } = await contracts
          const filter = marketplace.filters.Buy(null, null, null, null)
          return [marketplace, filter, cb]
        })
        subscribe(key, 'useMarketplaceSell', async () => {
          const { marketplace } = await contracts
          const filter = marketplace.filters.Sell(null, null, null, null)
          return [marketplace, filter, cb]
        })
      }
    }
  )

  const revalidateAfterTx = async (txP: Promise<ContractTransaction>) => {
    try {
      const tx = await txP
      await tx.wait()
      console.log('revalidate')
      revalidate()
    } catch (err) {
      console.error('tx failed')
    }
  }

  const buyItem = async (
    itemId: BigNumberish,
    quantity: number,
    totalPrice: BigNumberish
  ) => {
    if (!safeAddress) {
      throw new Error('cannot buy when not loggded in')
    }
    const { assets, marketplace } = await contracts
    const ptgId = await prestigeID
    const dataField = utils.defaultAbiCoder.encode(
      ['uint256', 'uint256', 'uint256', 'uint256'],
      [
        itemId,
        quantity,
        totalPrice,
        Math.floor((DateTime.utc().toMillis() + 10 * 60000) / 1000)
      ]
    )

    const tx = assets.safeTransferFrom(
      safeAddress,
      marketplace.address,
      ptgId,
      totalPrice,
      dataField
    )

    revalidateAfterTx(tx)

    return tx
  }

  const userInventory = useMemo((): ItemStore => {
    if (!data || !safeAddress) {
      return {}
    }
    return Object.values(data).reduce((memo: ItemStore, item) => {
      if (!item.balance || item.balance.eq(0)) {
        return memo
      }
      return {
        [item.id!.toHexString()]: item,
        ...memo
      }
    }, {})
  }, [safeAddress, data])

  const sellItem = async (
    itemId: BigNumberish,
    quantity: number,
    minNumber: BigNumberish
  ) => {
    if (!safeAddress) {
      throw new Error('cannot buy when not loggded in')
    }
    const { assets, marketplace } = await contracts
    const dataField = utils.defaultAbiCoder.encode(
      ['uint256', 'uint256'],
      [minNumber, Math.floor((DateTime.utc().toMillis() + 10 * 60000) / 1000)]
    )

    const tx = assets.safeTransferFrom(
      safeAddress,
      marketplace.address,
      itemId,
      quantity,
      dataField
    )

    revalidateAfterTx(tx)

    return tx
  }

  const getPrice = async (itemId: BigNumberish, quantity: number) => {
    const { marketplace } = await contracts
    return marketplace.prices(itemId, quantity)
  }

  return {
    buyItem,
    sellItem,
    getPrice,

    items: data,
    userInventory,
    revalidate,
    loading: !data,
    updating: isValidating
  }
}

const CAN_PLACE_ITEM_PATH = '/canPlaceItem'

async function canPlaceItem(
  kasumahUintLogger: KasumahUintLogger,
  safeAddress: string,
  tournamentId: BigNumber,
  gladiatorId: BigNumber
) {
  const logs = await kasumahUintLogger.all(
    safeAddress,
    equipmentLogKey(tournamentId)
  )
  return !logs
    .map((log) => log.toHexString())
    .includes(gladiatorId.toHexString())
}

function isBeforeInterval(futureTime:number, nowTime:number, interval:number) {
  return futureTime - nowTime > interval
}

const useLockTime = () => {
  const { contracts } = useWeb3Context()
  const { data } = useSWR('/equipper/lock-time', {
    fetcher: async () => {
      const { equipperV5 } = await contracts
      return equipperV5.lockTime()
    },
    dedupingInterval: 120000 // 2 minutes
  })

  return data
}

export const useCanPlaceItem = (
  tournamentStartTime: number,
  tournamentId: BigNumber,
  gladiatorId: BigNumber
) => {
  const { contracts, safeAddress } = useWeb3Context()
  const { blockTime, loading } = useCurrentBlockTime()
  const equipmentLockTime = useLockTime()

  const { data, revalidate, isValidating } = useSWR(
    () => {
      if (!equipmentLockTime) {
        throw new Error('waiting on locktime')
      }
      if (loading) {
        throw new Error('waiting on blocktime')
      }
      if (!safeAddress) {
        throw new Error('no safe address')
      }
      return [
        CAN_PLACE_ITEM_PATH,
        tournamentId.toHexString(),
        gladiatorId.toHexString()
      ]
    },
    {
      fetcher: async (
        _: string,
        tournamentIdStr: string,
        gladiatorIdStr: string
      ) => {
        try {
          if (!equipmentLockTime) {
            throw new Error('waiting on locktime')
          }
          if (!safeAddress) {
            throw new Error('no safe address')
          }
          if (!blockTime) {
            throw new Error("don't know blocktime yet")
          }
          const isAllowed = isBeforeInterval(
            tournamentStartTime,
            Math.floor((new Date().getTime() + blockTime?.drift) / 1000),
            equipmentLockTime.toNumber()
          )
          if (!isAllowed) {
            return false
          }
          const { kasumahUintLogger } = await contracts

          const tournamentId = BigNumber.from(tournamentIdStr)
          const gladiatorId = BigNumber.from(gladiatorIdStr)
          console.log('checking: ', gladiatorId.toString())
          return canPlaceItem(
            kasumahUintLogger,
            safeAddress,
            tournamentId,
            gladiatorId
          )
        } catch (err) {
          console.error('error fetching canPlaceItem: ', err)
          throw err
        }
      }
    }
  )

  return {
    canPlaceItem: data,
    loading: !data,
    revalidate,
    isValidating
  }
}

function equipmentLogKey(tournamentId: BigNumber) {
  return `/equips/${tournamentId.toString()}`
}

function itemToEquippedItem(
  id: BigNumber,
  item: ItemConfigInterface
): EquippedItem {
  return {
    id,
    hitPoints: BigNumber.from(item.attributes.hp),
    attack: BigNumber.from(item.attributes.attack),
    defense: BigNumber.from(item.attributes.defense),
    percentChanceOfUse: BigNumber.from(item.attributes.percentChanceOfUse),
    numberOfUsesPerGame: BigNumber.from(item.attributes.numberOfUses),
    createdAt: new Date().toString(), // not used
    ...item
  }
}

// this is the function for using items already in your account
export const usePlaceItem = () => {
  const { contracts, relayer, safeAddress, provider } = useWeb3Context()
  const { items } = useEquipmentList()
  const equipmentLockTime = useLockTime()

  return async (
    tournamentId: BigNumber,
    gladiatorId: BigNumber,
    itemId: BigNumber,
    tournamentStartTime?: number
  ) => {
    if (!safeAddress || !relayer || !provider || !equipmentLockTime) {
      throw new Error('missing required info')
    }
    if (!tournamentStartTime) {
      throw new Error("Can't fetch tournament or block time")
    }
    const { assets, kasumahUintLogger } = await contracts

    const equipper = await equipperForTournament(tournamentId, contracts)

    const currentBlockNumber = await provider.getBlockNumber()
    const currentBlock = await provider.getBlock(currentBlockNumber!)
    const blockchainTime = currentBlock?.timestamp

    if (!isBeforeInterval(tournamentStartTime, blockchainTime, equipmentLockTime.toNumber())) {
      throw new Error(
        "Can't add item: Tournament is starting in less than 15 minutes"
      )
    }

    const isAllowed = await canPlaceItem(
      kasumahUintLogger,
      safeAddress,
      tournamentId,
      gladiatorId
    )
    if (!isAllowed) {
      throw new Error('May only place one item per gladiator')
    }

    const useEquipmentData = utils.defaultAbiCoder.encode(
      ['uint256', 'uint256'],
      [tournamentId, gladiatorId]
    )

    const txs = await Promise.all([
      kasumahUintLogger.populateTransaction.add(
        safeAddress,
        equipmentLogKey(tournamentId),
        gladiatorId
      ),
      assets.populateTransaction.safeTransferFrom(
        safeAddress,
        equipper.address,
        itemId,
        1,
        useEquipmentData
      )
    ])

    const tx = relayer.multisend(txs)
    tx.then(async (tx) => {
      await tx.wait()
      mutate(
        [
          CAN_PLACE_ITEM_PATH,
          tournamentId.toHexString(),
          gladiatorId.toHexString()
        ],
        false,
        false
      )
      mutate(
        [
          '/gladiator/equipment',
          tournamentId.toHexString(),
          gladiatorId.toHexString()
        ],
        (data: EquippedItem[] | undefined) => {
          const item = items[itemId.toHexString()]
          return (data || []).concat([itemToEquippedItem(itemId, item)])
        }
      )
      mutate(['/marketplace/items', safeAddress])
      mutate('/community-items')
    })
    return tx
  }
}

export const useGroupedEquipmentList = (items: ItemStore) => {
  return Object.values(items).reduce((hash: any, obj: ItemConfigInterface) => {
    return {
      ...hash,
      [obj.type]: [...(hash[obj.type] || []), obj]
    }
  }, {})
}

export const useBuyItem = () => {
  return async (
    _tournamentId: BigNumber, // eslint-disable-line @typescript-eslint/no-unused-vars
    _gladiatorId: BigNumber, // eslint-disable-line @typescript-eslint/no-unused-vars
    _itemId: BigNumber // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    throw new Error('deprecated')
  }
}

const _currentBlockTimeFetcher = async (
  _key: any,
  provider: providers.Provider
) => {
  const currentBlockNumber = await provider?.getBlockNumber()
  const currentBlock = await provider?.getBlock(currentBlockNumber!)
  const now = new Date().getTime()
  const blockchainTime = currentBlock.timestamp
  const drift = now - (blockchainTime * 1000)

  return {
    blockchainTime,
    sampledAt: now,
    drift
  }
}

const useCurrentBlockTime = () => {
  const { provider } = useWeb3Context()

  const { data, isValidating } = useSWR(
    () => {
      if (!provider) {
        throw new Error('waiting for provider')
      }
      return ['/block-time', provider]
    },
    {
      fetcher: _currentBlockTimeFetcher,
      dedupingInterval: 60000
    }
  )

  return { blockTime: data, loading: !data, isValidating }
}
