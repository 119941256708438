/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
// import styles from './Logo.module.scss';

export interface LogoProps {
  type?: 'logo.png' | 'logo.svg'
  className?: string
}

const Logo: React.FC<LogoProps> = ({ type, className }) => {
  return (
    <Link to="/">
      <img
        src={`/images/logo/${type}`}
        alt="Crypto collosuem"
        className={className}
      />
    </Link>
  )
}

Logo.defaultProps = {
  type: 'logo.svg'
}

export default Logo
