import React from 'react'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { MinimalGladiator } from '../../../hooks/useGladiator'
import SkynetImageFixer from '../../atoms/SkynetImageFixer/SkynetImageFixer'

interface MinimalGladiatorImageProps {
  gladiator: MinimalGladiator
  className?: string
}

const MinimalGladiatorImage: React.FC<MinimalGladiatorImageProps> = ({
  gladiator,
  className
}) => {
  const { name, image, id } = gladiator
  return (
    <Link to={`/gladiator/${id}`} className="w-20 h-20">
      <div>
        <p data-tip={gladiator.name}>
          <SkynetImageFixer
            className={classNames(
              'rounded-full bg-black -ml-8 object-cover object-top',
              className
            )}
            alt={name}
            src={image}
          />
        </p>
        <ReactTooltip place="top" type="dark" effect="float" />
      </div>
    </Link>
  )
}

export default MinimalGladiatorImage
