import { BigNumber } from 'ethers'
import useSWR from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import { MinimalGladiator, useGladiatorList } from './useGladiator'

interface ILeaderboardUser {
  address: string
  balance: BigNumber
  gladiators: MinimalGladiator[]
}

export const useLeaderboardList = () => {
  const web3Context = useWeb3Context()
  const { gladiators } = useGladiatorList()

  const { data } = useSWR(
    () => {
      if (!gladiators) {
        throw new Error('waiting gladiators')
      }
      return '/leaderboard/all'
    },
    {
      fetcher: async () => {
        try {
          const { contracts, prestigeID, provider, gladiatorRange } = web3Context
          if (!provider || !gladiators) {
            console.error('no provider or no gladiator')
            throw new Error('need provider and gladiators')
          }
          const { bettingLogger, assets } = await contracts
          const ptgID = await prestigeID
          const [gladiatorStartID, gladiatorEndID] = await gladiatorRange

          const usersWithGladiators: ILeaderboardUser[] = []

          const unsorted = await Promise.all(
            (
              await bettingLogger.all()
            ).map(async (address) => {
              const [userTokens, balance] = await Promise.all([
                assets.accountTokens(address),
                assets.balanceOf(address, ptgID)
              ])
              const gladiatorIds = userTokens.filter((id) => {
                return id.gte(gladiatorStartID) && id.lte(gladiatorEndID)
              })
              const userGladiators:MinimalGladiator[] = gladiatorIds.map((id) => {
                const glad = gladiators.find((g) => BigNumber.from(g.id).eq(id))
                if (!glad) {
                  throw new Error('impossibly had a user with a gladiator id, but we do not know about the gladiator')
                }
                return glad
              })

              const user = {
                address,
                balance,
                gladiators: userGladiators
              }

              if (userGladiators.length > 0) {
                usersWithGladiators.push(user)
              }
              return user
            })
          )

          return {
            leaderboard: unsorted.sort((a, b) => {
              return b.balance.sub(a.balance).div(1e12).toNumber()
            }),
            usersWithGladiators
          }
        } catch (err) {
          console.error('error loading leaderboard: ', err)
          throw err
        }
      },
      dedupingInterval: 60000 // no need to update the leaderboard all the time
    }
  )

  return {
    data
  }
}
