import { BigNumber, BigNumberish } from 'ethers'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import { DiceRoller, DiceRollerV5, GameLogicV4, GameLogicV5, TournamentV4, TournamentV5 } from '../types/contracts'
import ThenArg from '../utils/ThenArg'
import { useEquipmentList } from './useEquipment'
import { useSubscriber } from './useSubscription'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import { ContractBracket, useTournament } from './useTournament'
import { getJSON } from '../models/SkyDb'
import { isV4 } from '../utils/isTournamentV4'
import { Contracts } from '../models/GameChain'

export type Narrative = ThenArg<ReturnType<GameLogicV4['blowByBlow']>>[0]
export type Narratives = Narrative[]

export type AiTournamentDescriptions = Record<string, string>

const zero = BigNumber.from('0')

interface BlowByBlowContracts {
  tournament: TournamentV4|TournamentV5
  gameLogic: GameLogicV4|GameLogicV5
  diceRoller: DiceRoller|DiceRollerV5
}

function tournamentDescriptionsKey(tournamentId: BigNumberish) {
  return `/touranments/hitDescriptions/${BigNumber.from(
    tournamentId
  ).toHexString()}`
}

export function aiBlowByBlowKey(
  round: number,
  game: number,
  internalRound: number
) {
  return `r${round}-g${game}-${internalRound + 1}`
}

const aiFetcher = async (_label:string, key: string) => {
  try {
    const { data } = await getJSON<AiTournamentDescriptions>(key)
    return data
  } catch (err) {
    console.error('error fetching ', key, err)
    throw err
  }
}

export function useAiDescriptions(tournamentId: string) {
  const { contracts } = useWeb3Context()
  const { subscribe } = useSubscriber()
  const { tournament } = useTournament(tournamentId)

  const swr = useSWR(['skydb', tournamentDescriptionsKey(tournamentId)], {
    fetcher: aiFetcher,
    refreshInterval: 3000,
    onSuccess: (_, key) => {
      if (tournament && tournament.champion) {
        return // no need to subscribe to completed tournaments
      }
      subscribe(key, 'useAiDescriptions', async () => {
        try {
          const { diceRoller } = await contracts
          const filter = diceRoller.filters.DiceRoll(null, null)
          const cb = () => {
            // delay here a bit to allow skydb sync
            setTimeout(() => mutate(key), 2000)
          }
          return [diceRoller, filter, cb]
        } catch (err) {
          console.error('error subscribing: ', err)
          throw err
        }
      })
    }
  })

  return { ...swr, loading: !swr.data, updating: swr.isValidating }
}

async function contractsForTournament(tournamentId: BigNumberish, contracts: Promise<Contracts>) {
  const { gameLogic, tournament, gameLogicV5, tournamentV5, diceRoller, diceRollerV5 } = await contracts
  if (isV4(tournamentId)) {
    return {
      gameLogic,
      tournament,
      diceRoller
    }
  }
  return {
    gameLogic: gameLogicV5,
    tournament: tournamentV5,
    diceRoller: diceRollerV5,
  }
}

export function useBlowByBlow(
  tournamentId: BigNumberish,
  roundNumber: number,
  gameNumber: number
) {
  const { contracts } = useWeb3Context()
  const { items, loading: itemsLoading } = useEquipmentList()

  const { subscribe } = useSubscriber()

  const fetcher = async (
    _key: string,
    tournamentId: BigNumberish,
    roundNumber: number,
    gameNumber: number
  ) => {
    try {
      const { gameLogic, tournament, diceRoller } = await contractsForTournament(tournamentId, contracts)

      const [gladiators, bracket] = await Promise.all([
        gameLogic.gameGladiators(tournamentId),
        getBracket({ gameLogic }, tournamentId)
      ])

      const gameGladiators = gladiators.reduce(
        (mem: Record<string, GameGladiator>, glad) => {
          return { ...mem, [glad.id.toHexString()]: glad }
        },
        {}
      )
      const narratives = await blowByBlow(
        {
          tournament,
          gameLogic,
          diceRoller
        },
        bracket,
        tournamentId,
        roundNumber,
        gameNumber
      )

      return {
        gameEnded: isGameOver(bracket, roundNumber, gameNumber),
        narrativesToEnglish: narrativesToEnglish(
          gameGladiators,
          items,
          narratives
        )
      }
    } catch (err) {
      console.error('error fetching blow by blow: ', err)
      throw err
    }
  }

  const { data, isValidating } = useSWR(
    () => {
      if (itemsLoading) {
        throw new Error('waiting on items load')
      }
      if (!tournamentId) {
        throw new Error('awaiting tournamentId')
      }
      return ['/blow-by-blow', tournamentId, roundNumber, gameNumber]
    },
    {
      fetcher,
      onSuccess: (_, key) => {
        subscribe(key, 'blowByBlow', async () => {
          try {
            const { diceRoller } = await contractsForTournament(tournamentId, contracts)

            const filter = diceRoller.filters.DiceRoll(null, null)
            const cb = () => {
              mutate(key)
            }
            return [diceRoller, filter, cb]
          } catch (err) {
            console.error('error subscribing: ', err)
            throw err
          }
        })
      }
    }
  )

  return { blowByBlow: data, loading: !data, updating: isValidating }
}

async function getBracket(
  contracts: {
    gameLogic: GameLogicV4|GameLogicV5
  },
  tournamentId: BigNumberish,
  lastRoll?: BigNumberish
) {
  const { gameLogic } = contracts
  return gameLogic.bracket(tournamentId, lastRoll || -1)
}

function isGameOver(
  bracket: ContractBracket,
  roundNumber: number,
  gameNumber: number
) {
  const round = bracket[roundNumber]
  const { lastRoll } = round.games[gameNumber]
  return lastRoll.gt(0)
}

export async function blowByBlow(
  contracts: BlowByBlowContracts,
  bracket: ContractBracket,
  tournamentId: BigNumberish,
  roundNumber: number,
  gameNumber: number
) {
  const { gameLogic, diceRoller } = contracts

  const latest = await diceRoller.latest()

  // const bracket = await getBracket(contracts, tournamentId)

  const round = bracket[roundNumber]

  let { lastRoll } = round.games[gameNumber]

  if (lastRoll.eq(0)) {
    lastRoll = round.lastRoll
  }
  // if lastRoll is *still* 0 then the round isn't finished and we'll just go
  // up to the number of rolls available
  if (lastRoll.eq(0)) {
    lastRoll = latest
  }

  let { firstRoll } = round
  // if the game isn't the first game then let's see if the game before it is finished
  if (gameNumber > 0) {
    const previousGame = round.games[gameNumber - 1]
    if (previousGame.lastRoll.eq(0)) {
      console.log("game hasn't started")
      // the game before this one isn't finished yet so let's just return nothing
      return []
    }
    // otherwise we can set the first roll to when the game started
    firstRoll = round.games[gameNumber].firstRoll
    if (firstRoll.eq(0)) {
      console.log("game hasn't started")
      // if that's zero then the game is just about to start and we can just also return nothing
      return []
    }
  }
  if (latest.lt(firstRoll)) {
    console.log("game hasn't started")
    return []
  }

  // first get the bracket at right before the first roll
  const brack = await getBracket(contracts, tournamentId, firstRoll.sub(1))
  const roundBeforeRoll = brack[roundNumber]

  const game = roundBeforeRoll.games[gameNumber]

  // now we'll get the rolls from first roll to last Roll
  const rolls = await diceRoller.getRange(firstRoll, lastRoll)

  if (isV4(tournamentId)) {
    return (gameLogic as GameLogicV4).blowByBlow(game, rolls)
  }

  return (gameLogic as GameLogicV5).blowByBlow(tournamentId, game, rolls)
}

type GameGladiator = ThenArg<ReturnType<GameLogicV4['gameGladiators']>>[0]

export function narrativesToEnglish(
  gameGladiators: Record<string, GameGladiator>,
  itemMetadata: Record<string, ItemConfigInterface>,
  narratives: Narratives
) {
  if (narratives.length === 0) {
    return []
  }

  const english = narratives.map((narrative, i) => {
    const previous = narratives[i - 1] // ok if this is undefined
    const uses = equipmentUsage(gameGladiators, narrative, previous)

    const attacker = gameGladiators[narrative.attacker.toHexString()]
    const defender = gameGladiators[narrative.defender.toHexString()]
    const { attackRoll, defenseRoll } = narrative

    let damage: BigNumberish | string = attackRoll.sub(defenseRoll)
    if (attackRoll.lt(defenseRoll)) {
      damage = 'no'
    }

    const useObject = {
      attack: Object.values(uses.attack)
      .map((item) => {
        return itemMetadata[item!.id.toHexString()]
      }),
      defense: Object.values(uses.defense).map((item) => {
        return itemMetadata[item!.id.toHexString()]
      })
    }

    const useEnglish = Object.values(uses.attack)
      .map((item) => {
        const meta = itemMetadata[item!.id.toHexString()]
        return `${attacker.name} uses ${meta.name}`
      })
      .concat(
        Object.values(uses.defense).map((item) => {
          const meta = itemMetadata[item!.id.toHexString()]
          return `${defender.name} uses ${meta.name}`
        })
      )
      .join('\n')

    const english = `
###
(rolls: ${narrative.attackRoll} / ${narrative.defenseRoll})
${attacker.name} attacks ${defender.name}
${defender.name} takes ${damage} damage
${useEnglish}
${attacker.name} Health: ${narrative.attackerHP}
${defender.name} Health: ${narrative.defenderHP}
    `

    return {
      english,
      gameInfo: {
        rolls: {
          attack: narrative.attackRoll,
          defense: narrative.defenseRoll,
        },
        attacker: {
          name: attacker.name,
          initialHp: attacker.hitPoints.toNumber(),
          currentHp: narrative.attackerHP.toNumber(),
          damage,
          uses: useObject.attack
        },
        defender: {
          name: defender.name,
          initialHp: defender.hitPoints.toNumber(),
          currentHp: narrative.defenderHP.toNumber(),
          damage,
          uses: useObject.defense
        }
      }
    }
  })

  return english
}

function equipmentUsage(
  gameGladiators: Record<string, GameGladiator>,
  current: Narrative,
  previous?: Narrative
) {
  // default to 0 uses
  const previousUses = Object.values(gameGladiators).reduce(
    (mem: Record<string, BigNumber[]>, glad) => {
      return {
        ...mem,
        [glad.id.toHexString()]: Array(glad.equipment.length).map(() => zero)
      }
    },
    {}
  )
  if (previous) {
    // then lets use the previous usage counts
    previousUses[previous.attacker.toHexString()] = previous.attackEquipment
    previousUses[previous.defender.toHexString()] = previous.defenseEquipment
  }

  const defense = current.defenseEquipment
    .map((cnt, i) => {
      const defenderId = current.defender.toHexString()
      const prev = previousUses[defenderId][i]
      if (cnt.gt(prev || zero)) {
        return gameGladiators[defenderId].equipment[i]
      }
      return undefined
    })
    .filter(Boolean) // reject not used

  const attack = current.attackEquipment
    .map((cnt, i) => {
      const attackerId = current.attacker.toHexString()
      const prev = previousUses[attackerId][i]
      if (cnt.gt(prev || zero)) {
        return gameGladiators[attackerId].equipment[i]
      }
      return undefined
    })
    .filter(Boolean) // reject not used

  return {
    attack,
    defense
  }
}
