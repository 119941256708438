import { useMemo } from 'react'
import { BigNumber, BigNumberish } from 'ethers'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import { FixedLengthArray } from '../interfaces/FixedLengthArray.interface'
import PlayerInterface from '../interfaces/Player.interface'
import { GameLogicV4, TournamentV4 } from '../types/contracts'
import { useMinimalGladiator, MinimalGladiator } from './useGladiator'
import ThenArg from '../utils/ThenArg'
import { useSubscriber } from './useSubscription'
import humanBigNumber from '../utils/humanBigNumber'
import gameChain from '../models/GameChain'
import { isV4 } from '../utils/isTournamentV4'

type Round = FixedLengthArray<PlayerInterface, 2>[]

type Bracket = Round[]

export interface Tournament {
  tournamentName: string
  tournamentId: string
  bracket: Bracket
  champion?: PlayerInterface
  totalRounds: number
  currentRound: number
  currentGame: number
  started: boolean,
  notBefore?: number
  gladiators: PlayerInterface[]
}

export type ContractBracket = ThenArg<ReturnType<GameLogicV4['tournament']>>
type Registrations = ThenArg<ReturnType<TournamentV4['registrations']>>

const bracketToUi = (
  bracket: ContractBracket,
  gladiators: Record<string, MinimalGladiator>,
  factions: string[]
): Bracket => {
  const uiBracket = bracket.map((round, i) => {
    if (i > 0 && !bracket[i - 1].lastRoll.gt(0)) {
      return undefined
    }
    return round.games.map((game) => {
      const getPrestige = (
        value: BigNumberish | undefined
      ): string | undefined => {
        if (value) {
          return humanBigNumber(value)
        }
        return undefined
      }

      const player0Id = game.players[0].id.toHexString()
      const player1Id = game.players[1].id.toHexString()

      return [
        {
          name: gladiators[player0Id].name,
          image: gladiators[player0Id].image,
          id: game.players[0].id,
          heroImage: gladiators[player0Id].heroImage,
          faction: factions[game.players[0].faction.toNumber()],
          winner: game.decided && game.winner === 0,
          properties: {
            prestige: getPrestige(gladiators[player0Id].prestige)
          },
          wins: gladiators[player0Id].trophyIDs,
          tagline: gladiators[player0Id].tagline
        },
        {
          name: gladiators[player1Id].name,
          image: gladiators[player1Id].image,
          id: game.players[1].id,
          heroImage: gladiators[player1Id].heroImage,
          faction: factions[game.players[1].faction.toNumber()],
          winner: game.decided && game.winner === 1,
          properties: {
            prestige: getPrestige(gladiators[player1Id].prestige)
          },
          wins: gladiators[player1Id].trophyIDs,
          tagline: gladiators[player1Id].tagline
        }
      ]
    })
  })
  return uiBracket.filter((r) => r) as Bracket
}

// get all the gladiators involved in this tournament
const idsFromBracket = (bracket: ContractBracket) => {
  const ids: string[] = bracket
    .map((round) => {
      return round.games
        .map((game) => {
          return game.players
            .map((player) => {
              return player.id.toHexString()
            })
            .flat()
        })
        .flat()
    })
    .flat()
  return new Set(ids)
}

const idsFromRegistration = (tournaments: Registrations[]) => {
  const ids: string[] = tournaments
    .map((registrations) => {
      return registrations
        .map((registration) => {
          return registration.gladiator.toHexString()
        })
        .flat()
    })
    .flat()
  return new Set(ids)
}

const latestRollFetcher = async () => {
  const { diceRoller } = await gameChain.contracts
  return diceRoller.latest()
}

export const useLatestRoll = () => {
  const { data } = useSWR('/tournament/latest-roll', {
    fetcher: latestRollFetcher,
    dedupingInterval: 10000 // this should be as long as one roll
  })

  return { loading: !data, firstRoll: data || 0 }
}

export interface MinimalTournamnet {
  id: BigNumberish
  name: string
  gladiators: MinimalGladiator[]
  firstRoll: BigNumberish
  lastRoll: BigNumberish
  totalRounds: BigNumberish
  notBefore: BigNumber
}

export function currentRound(bracket: ContractBracket) {
  // a little odd here but we want the *last* round and we can't use lastIndexOf and we can't use reverse() due to
  // the way the bracket is defined from the blockchain... so instead we just find all of the finished rounds and count them.
  const finishedRoundCount = bracket.filter((round) => {
    return round.lastRoll.gt(0)
  })
  return finishedRoundCount ? finishedRoundCount.length : 0
}

export function currentGame(bracket: ContractBracket, round: number) {
  // find the first game in the round that does *not* have a lastRoll
  if (round >= bracket.length) {
    return 0
  }
  const idx = bracket[round].games.findIndex((game) => {
    return !game.lastRoll || game.lastRoll.eq(0)
  })
  return idx
}

const tournamentBlocklist = [
  '1099528405003',
  '1099528405002',
  '1099528405208',
  '1099528405256',
  '1099528405266',
  '1099528405253',
  '4'
].map((n) => {
  return BigNumber.from(n)
})

function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const chunks: T[][] = []

  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize))
  }

  return chunks
}

const tournamentListFetcherV5 = async () => {
  const { deployerAddress } = gameChain
  const { tournamentV5, kasumahUintLogger } = await gameChain.contracts
  try {
    if (!gameChain.provider) {
      throw new Error('no provider')
    }
    console.log('searching for v5 tournaments')
    const allIds = await kasumahUintLogger.all(await deployerAddress, '/tournaments/v5')
    console.log('v5 ids: ', allIds)
    const idChunks = chunk(allIds.slice(-500), 50) // split ids into chunks of 50
    const tournaments = []

    for (let i = 0; i < idChunks.length; i++) {
      const ids = idChunks[i]
      const tournamentPromises = ids.map(async (id) => {
        try {
          const { name, firstRoll, lastRoll, totalRounds, notBefore } = await tournamentV5._tournaments(id)
          const registrations = await tournamentV5.registrations(id)
          // const [
          //   { name, firstRoll, lastRoll, totalRounds, notBefore },
          //   registrations
          // ] = await Promise.all([
          //   tournamentV5._tournaments(id),
          //   tournamentV5.registrations(id),
          // ])

          return {
            id,
            name,
            registrations,
            firstRoll,
            lastRoll,
            totalRounds: BigNumber.from(totalRounds),
            notBefore
          }
        } catch (err) {
          console.error('error fetching: ', err)
          throw err
        }
      })

      const tournamentChunk = await Promise.all(tournamentPromises)
      tournaments.push(...tournamentChunk)
    }

    return tournaments
  } catch (err) {
    console.error('error fetch list: ', err)
    throw err
  }
}

// const tournamentListFetcherV4 = async () => {
//   console.log('fetching tourn list V4, awaiting contracts')
//   const { tournament, tournamentLogger } = await gameChain.contracts
//   try {
//     if (!gameChain.provider) {
//       throw new Error('no provider')
//     }

//     const ids = await tournamentLogger.all()
//     return Promise.all(
//       ids.map(async (id) => {
//         try {
//           const [
//             name,
//             registrations,
//             firstRoll,
//             lastRoll,
//             totalRounds,
//             notBefore
//           ] = await Promise.all([
//             tournament.name(id),
//             tournament.registrations(id),
//             tournament.firstRoll(id),
//             tournament.lastRoll(id),
//             tournament.totalRounds(id),
//             tournament.notBefore(id),
//           ])

//           return {
//             id,
//             name,
//             registrations,
//             firstRoll,
//             lastRoll,
//             totalRounds,
//             notBefore
//           }
//         } catch (err) {
//           console.error('error fetching: ', err)
//           throw err
//         }
//       })
//     )
//   } catch (err) {
//     console.error('error fetch list: ', err)
//     throw err
//   }
// }

const tournamentListFetcher = async () => {
  try {
    return tournamentListFetcherV5()
  } catch (err) {
    console.error('error getting tournament list: ', err)
    throw err
  }
}

export const useTournamentList = () => {
  const { provider } = useWeb3Context()

  const { data: tournamentChainData } = useSWR(
    () => {
      if (!provider) {
        console.log('no provider')
        throw new Error('no provider')
      }
      return '/tournaments'
    },
    {
      fetcher: tournamentListFetcher,
      refreshWhenHidden: false,
    }
  )

  const { data: gladiatorData } = useMinimalGladiator(() => {
    return [
      '/gladiators/minimal',
      ...idsFromRegistration(tournamentChainData!.map((t) => t.registrations))
    ]
  })

  const tournaments: MinimalTournamnet[] = useMemo(() => {
    if (!tournamentChainData || !gladiatorData) {
      return []
    }
    return tournamentChainData
      .map((tourn) => {
        return {
          ...tourn,
          gladiators: tourn.registrations.map((reg) => {
            return gladiatorData[reg.gladiator.toHexString()]
          })
        }
      })
      .filter((tourn) => {
        return (
          tourn.gladiators.length > 0 &&
          !tournamentBlocklist.find((blockedId) => tourn.id.eq(blockedId))
        )
      }) // filter was added as a hot fix because there were a couple of tournaments created but failed so had no gladiators
  }, [tournamentChainData, gladiatorData])

  return { tournaments, loading: !(tournamentChainData && gladiatorData) }
}

export const useTournamentInProgress = () => {
  const { tournaments, loading: listLoading } = useTournamentList()

  if (listLoading) return undefined

  const upcomingAndRunning = tournaments.filter((tourn) => {
    return BigNumber.from(tourn.lastRoll).eq(0)
  })

  if (!upcomingAndRunning.length) return undefined

  return upcomingAndRunning![0]?.id.toString()
}

// const tournamentFetcherV4 = async (_key: any, tournamentId: BigNumberish) => {
//   try {
//     const { tournament: tournaments, gameLogic } = await gameChain.contracts

//     const [
//       tournamentName,
//       totalRounds,
//       firstRoll,
//       tournLastRoll,
//       started,
//       factions,
//       notBefore,
//       bracket
//     ] = await Promise.all([
//       tournaments.name(tournamentId),
//       tournaments.totalRounds(tournamentId),
//       tournaments.firstRoll(tournamentId),
//       tournaments.lastRoll(tournamentId),
//       tournaments.started(tournamentId),
//       tournaments.factions(tournamentId),
//       tournaments.notBefore(tournamentId),
//       gameLogic.bracket(tournamentId, -1),
//     ])

//     const currRound = currentRound(bracket)
//     const currGame = currentGame(bracket, currRound)

//     return {
//       bracket,
//       currentRound: currRound,
//       currentGame: currGame,
//       tournamentName,
//       totalRounds,
//       firstRoll,
//       lastRoll: tournLastRoll,
//       started,
//       notBefore,
//       factions
//     }
//   } catch (err) {
//     console.error('error fetching tournament: ', err)
//     throw err
//   }
// }

const tournamentFetcherV5 = async (_key: any, tournamentId: BigNumberish) => {
  try {
    const { tournamentV5, gameLogicV5 } = await gameChain.contracts

    const [
      metadata,
      bracket,
      started,
      factions,
    ] = await Promise.all([
      tournamentV5._tournaments(tournamentId),
      gameLogicV5.bracket(tournamentId, -1),
      tournamentV5.started(tournamentId),
      tournamentV5.factions(tournamentId),
    ])

    const currRound = currentRound(bracket)
    const currGame = currentGame(bracket, currRound)

    return {
      bracket,
      currentRound: currRound,
      currentGame: currGame,
      tournamentName: metadata.name,
      totalRounds: BigNumber.from(metadata.totalRounds),
      firstRoll: metadata.firstRoll,
      lastRoll: metadata.lastRoll,
      started,
      notBefore: metadata.notBefore,
      factions
    }
  } catch (err) {
    console.error('error fetching tournament: ', err)
    throw err
  }
}

const tournamentFetcher = (_key: any, tournamentId: BigNumberish) => {
  if (isV4(tournamentId)) {
    throw new Error('v4 tournaments not supported')
    // return tournamentFetcherV4(_key, tournamentId)
  }
  return tournamentFetcherV5(_key, tournamentId)
}

export const useTournament = (tournamentId: string) => {
  const { contracts } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const initialData: Tournament = {
    tournamentName: '',
    tournamentId,
    bracket: [],
    champion: undefined,
    currentRound: 0,
    currentGame: 0,
    totalRounds: 0,
    started: false,
    gladiators: []
  }

  const {
    data: tournamentBlockchain,
    isValidating: tournamentRevalidating
  } = useSWR(['/tournaments', tournamentId], {
    fetcher: tournamentFetcher,
    dedupingInterval: 1100,
    revalidateOnMount: true,
    onSuccess: (data, key) => {
      if (data.lastRoll && data.lastRoll.gt(0)) {
        return // do nothing
      }
      subscribe(key, 'useTournament', async () => {
        const { diceRoller } = await contracts
        const filter = diceRoller.filters.DiceRoll(null, null)
        const handler = () => {
          mutate(key)
        }
        return [diceRoller, filter, handler]
      })
    }
  })

  const { data: gladiatorData } = useMinimalGladiator(() => {
    return [
      '/gladiators/minimal',
      ...idsFromBracket(tournamentBlockchain!.bracket)
    ]
  })

  let tournamentData: Tournament = initialData
  if (tournamentBlockchain && gladiatorData) {
    const {
      bracket,
      factions,
      totalRounds,
      tournamentName,
      started,
      currentRound,
      currentGame,
      notBefore,
    } = tournamentBlockchain
    const tournamentsWithAssets = bracketToUi(bracket, gladiatorData, factions)

    let tournamentChampion
    if (tournamentBlockchain.lastRoll.gt(0)) {
      const lastBracket =
        tournamentsWithAssets[tournamentsWithAssets.length - 1][0]
      const winner = lastBracket.find((b) => b.winner)
      if (winner) {
        tournamentChampion = {
          ...winner,
          champion: true
        }
      }
    }

    tournamentData = {
      tournamentName,
      tournamentId,
      bracket: tournamentsWithAssets,
      currentRound,
      currentGame,
      totalRounds: BigNumber.from(totalRounds).toNumber(),
      champion: tournamentChampion,
      started,
      notBefore: notBefore.toNumber(),
      gladiators: tournamentsWithAssets[0].flat()
    }
  }

  return {
    tournament: tournamentData,
    loading: !(tournamentBlockchain && gladiatorData),
    updating: tournamentRevalidating
  }
}
