import React from 'react'
import { ImgProps } from '@chakra-ui/image'

// TODO: right now this is defaulting to fileportal.org since we know it's broken,
// but it should probably default to siaskynet on a normal basis
const SkynetImageFixer:React.FC<ImgProps> = ({ src: userSrc, alt, className }) => {
  const src = userSrc?.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud')

  return (
    <img src={src} alt={alt} className={className} />
  )
}

export default SkynetImageFixer
