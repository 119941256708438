import { BigNumber, constants, utils } from 'ethers'
import { Address } from 'kasumah-wallet'
import { useParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import AuctionInterface, { Bid } from '../interfaces/Auction.interface'
import gameChain, { Contracts } from '../models/GameChain'
import { useEquipmentList } from './useEquipment'
import { MinimalGladiator, useGladiatorList } from './useGladiator'
import { useSubscriber } from './useSubscription'
import waitForTx from '../utils/waitForTx'
import { Auction, AuctionV2 } from '../types/contracts'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'

export const USER_BID_KEY = '/bid/auction/user'
export const BIDS_IN_AUCTION = '/bid/auction'
export const HIGHEST_BIDS_IN_AUCTION = '/bid/auction/highest'
export const AUCTION_LIST = '/auction'

export const V2_IDENTIFIER = 'v2'

const V1_HIDE_LIST = [24]
const V2_HIDE_LIST = [95]

interface PlaceBidProps {
  auctionId: string
  amount: string
  totalAmount: BigNumber
  v2Auction: boolean
}

interface Auctionversions {
  v1Auctions: AuctionInterface[]
  v2Auctions: AuctionInterface[]
}

const _placeBid = async (
  contracts: Promise<Contracts>,
  safeAddress: Address,
  prestigePromise: Promise<BigNumber>,
  { auctionId, amount, totalAmount, v2Auction }: PlaceBidProps
) => {
  try {
    const { relayer } = gameChain
    if (!relayer) {
      throw new Error('no relayer')
    }
    const { assets, auctionV2, auction, bidChecker } = await contracts

    const auctionContract = v2Auction ? auctionV2 : auction

    const prestigeID = await prestigePromise
    const data = utils.defaultAbiCoder.encode(['uint256'], [auctionId])
    const biddingAmount = utils.parseEther(amount)

    const bidTx = await assets.populateTransaction.safeTransferFrom(
      safeAddress,
      auctionContract.address,
      prestigeID,
      biddingAmount,
      data
    )

    const checkTx = await bidChecker.populateTransaction.checkBid(
      auctionContract.address,
      safeAddress,
      auctionId,
      totalAmount
    )

    const tx = await waitForTx(relayer.multisend([bidTx, checkTx]))
    console.log('bid receipt: ', tx)

    const { endTime: latestEndTime } = await auctionContract.auctions(auctionId)

    mutate([USER_BID_KEY, auctionId, safeAddress, v2Auction], biddingAmount)
    mutate(AUCTION_LIST, (auctions: Auctionversions): Auctionversions => {
      console.log('auctions from mutate: ', auctions)
      const versionToUpdate = v2Auction ? 'v2Auctions' : 'v1Auctions'

      const updatedAuctions = auctions[versionToUpdate].map((storedAuction) => {
        if (storedAuction.id === auctionId) {
          console.log('Latest auction endtime: ', latestEndTime.toNumber())
          return {
            ...storedAuction,
            endTime: latestEndTime.toNumber()
          }
        }
        return storedAuction
      })

      return {
        ...auctions,
        [versionToUpdate]: updatedAuctions
      }
    })
    mutate([BIDS_IN_AUCTION, auctionId, v2Auction], (bids: Bid[]) => {
      console.log('bids from mutate: ', bids)
      return [
        ...(bids || []),
        {
          time: BigNumber.from(Math.floor(new Date().getTime() / 1000)),
          amount: biddingAmount,
          bidder: safeAddress
        }
      ]
    })
    mutate([HIGHEST_BIDS_IN_AUCTION, auctionId, v2Auction], biddingAmount)

    return true
  } catch (error) {
    console.error('error bidding ', error)
    throw error
  }
}

const auctionFetcher = async (
  auctionContract: AuctionV2 | Auction,
  items: { [x: string]: ItemConfigInterface },
  gladiators?: MinimalGladiator[],
  hiddenIds: number[] = []
): Promise<AuctionInterface[]> => {
  const latestAucitonId = BigNumber.from(
    await auctionContract.tokenIds()
  ).toNumber()

  const getGladiatorRank = (gladiatorId: string) => {
    const index = gladiators?.findIndex((glad) => {
      return glad.id === gladiatorId
    })
    return index !== undefined ? (index + 1).toString() : '--'
  }

  const auctions: AuctionInterface[] = (
    await Promise.all(
      Array.from({
        length: latestAucitonId
      }).map(async (_, idx) => {
        if (hiddenIds.includes(idx + 1)) {
          return undefined
        }

        const [fetchedAuction, userBidAmount] = await Promise.all([
          auctionContract.auctions(idx + 1),
          gameChain.safeAddress
            ? auctionContract.getBidAmount(idx + 1, gameChain.safeAddress)
            : Promise.resolve(constants.Zero)
        ])
        const equipment =
          items[BigNumber.from(fetchedAuction.itemId).toHexString()]
        const gladiator = gladiators?.find(
          (glad) => glad.id === fetchedAuction.itemId.toString()
        )

        const v2Auction =
          typeof (auctionContract.functions as any)[
            'endTimeExtensionOffset' as any
          ] === 'function'

        return {
          id: fetchedAuction.id.toString(),
          owner: fetchedAuction.owner,
          itemId: fetchedAuction.itemId,
          itemDetails: {
            gladiator: gladiator
              ? {
                  ...gladiator,
                  rank: getGladiatorRank(gladiator.id.toString())
                }
              : undefined,
            equipment
          },
          isEquipment: typeof equipment !== 'undefined',
          startTime: fetchedAuction.startTime.toNumber(),
          endTime: fetchedAuction.endTime.toNumber(),
          minBidAmount: fetchedAuction.minBidAmount,
          minBidIncrement: fetchedAuction.minIncrement,
          userBidAmount,
          // state
          canceled: fetchedAuction.canceled,
          highestBidder: fetchedAuction.highestBidder,
          ownerHasWithdrawn: fetchedAuction.ownerHasWithdrawn,
          highestBidderHasWithdrawn: fetchedAuction.highestBidderHasWithdrawn,
          v2Auction
        }
      })
    )
  )
    .filter(Boolean)
    .sort((a, b) => {
      return BigNumber.from(a!.id).sub(BigNumber.from(b!.id)).toNumber()
    }) as AuctionInterface[]

  return auctions
}

export const useAuctionList = () => {
  const { items, loading: equipmentListLoading } = useEquipmentList()
  const { gladiators, loading: gladiatorsLoading } = useGladiatorList()
  const { subscribe } = useSubscriber()

  const auctionListFetcher = async (): Promise<Auctionversions> => {
    console.log('fetching auction list')

    const { auctionV2, auction } = await gameChain.contracts

    const v1Auctions = await auctionFetcher(auction, items, gladiators, V1_HIDE_LIST)
    const v2Auctions = await auctionFetcher(auctionV2, items, gladiators, V2_HIDE_LIST)

    return {
      v1Auctions,
      v2Auctions
    }
  }

  const { data } = useSWR(
    () => {
      if (equipmentListLoading || gladiatorsLoading) {
        console.log('waiting on items or gladiators')
        throw new Error('waiting on items or glatiators')
      }
      // console.log('gladiators: ', JSON.stringify(gladiators), JSON.stringify(items))
      return AUCTION_LIST
    },
    {
      fetcher: auctionListFetcher,
      dedupingInterval: 2000,
      revalidateOnMount: true,
      onSuccess: (_, key) => {
        const cb = () => {
          console.log('mutating auction list')
          mutate(key)
        }

        subscribe(key, 'useAuctionList', async () => {
          const { auction } = await gameChain.contracts
          const filter = auction.filters.NewAuction(
            null,
            null,
            null,
            null,
            null
          )
          return [auction, filter, cb]
        })

        subscribe(key, 'useAuctionList', async () => {
          const { auctionV2 } = await gameChain.contracts
          const filter = auctionV2.filters.NewAuction(
            null,
            null,
            null,
            null,
            null
          )
          return [auctionV2, filter, cb]
        })
      }
    }
  )

  return { auctions: data, loading: !data }
}

export const useGroupedAuctions = () => {
  const { auctions } = useAuctionList()

  const groupedAuctions = useMemo(() => {
    if (!auctions) return null

    const allAuctions = auctions.v2Auctions.concat(auctions.v1Auctions)

    return {
      open: allAuctions.filter(
        (auction) => auction.endTime * 1000 > Date.now() && !auction.canceled
      ),
      closed: allAuctions.filter(
        (auction) => Date.now() > auction.endTime * 1000 && !auction.canceled
      )
    }
  }, [auctions])

  return {
    auctions: groupedAuctions,
    loading: !groupedAuctions
  }
}

const auctionHighestBidfetcher = async (
  _key: any,
  auctionId: string,
  v2Auction: boolean
) => {
  const { auctionV2, auction } = await gameChain.contracts

  if (v2Auction) return auctionV2.highestBidAmount(auctionId)

  return auction.highestBidAmount(auctionId)
}

export const useAuctionHighestBid = (auctionId: string, v2Auction: boolean) => {
  const { contracts } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const { data } = useSWR([HIGHEST_BIDS_IN_AUCTION, auctionId, v2Auction], {
    fetcher: auctionHighestBidfetcher,
    dedupingInterval: 700,
    revalidateOnMount: true,
    onSuccess: (_, key) => {
      const cb = () => {
        mutate(key)
        mutate(AUCTION_LIST)
      }

      subscribe(key, 'useAuctionHighestBid', async () => {
        const { auction } = await contracts
        const filter = auction.filters.NewBid(
          null,
          BigNumber.from(auctionId),
          null,
          null,
          null
        )
        return [auction, filter, cb]
      })
    }
  })

  return { highestBid: data, loading: !data }
}

const auctionBiddersFetcher = async (
  _key: any,
  auctionId: string,
  v2Auction: boolean
) => {
  const { auctionV2, auction } = await gameChain.contracts

  if (v2Auction) {
    const v2Bidders = await auctionV2.getAuctionBidders(auctionId)
    return v2Bidders
  }
  const bidders: Bid[] = await auction.getAuctionBidders(auctionId)

  return bidders
}

export const useAuctionBidders = (auctionId: string, v2Auction: boolean) => {
  const { contracts } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const { data } = useSWR([BIDS_IN_AUCTION, auctionId, v2Auction], {
    fetcher: auctionBiddersFetcher,
    dedupingInterval: 1000,
    revalidateOnMount: true,
    onSuccess: (_, key) => {
      const cb = () => {
        mutate(key)
      }

      subscribe(key, 'useAuctionBidders', async () => {
        const { auctionV2, auction } = await contracts

        if (v2Auction) {
          const v2Filter = auctionV2.filters.NewBid(
            null,
            BigNumber.from(auctionId),
            null,
            null,
            null
          )

          return [auctionV2, v2Filter, cb]
        }

        const filter = auction.filters.NewBid(
          null,
          BigNumber.from(auctionId),
          null,
          null,
          null
        )
        return [auction, filter, cb]
      })
    }
  })

  return { bidders: data, loading: !data }
}

export const useAuction = (auctionId: string, v2Auction: boolean) => {
  const { auctions } = useAuctionList()

  return useMemo(() => {
    console.log('recalculating auctions memo')
    if (!auctions) return null

    if (v2Auction) {
      console.log(auctions, 'checking uacitons')
      return auctions.v2Auctions.find(
        (auction) => auction.id.toString() === auctionId
      )
    }
    return auctions.v1Auctions.find(
      (auction) => auction.id.toString() === auctionId
    )
  }, [auctions, auctionId, v2Auction])
}

const userBidfetcher = async (
  _key: any,
  auctionId: string,
  safeAddress: string,
  v2Auction: boolean
) => {
  const { auctionV2, auction } = await gameChain.contracts
  const auctionContract = v2Auction ? auctionV2 : auction

  const userBid = await auctionContract.getBidAmount(
    BigNumber.from(auctionId),
    safeAddress
  )
  return userBid
}

export const useUserAuctionBid = (auctionId: string, v2Auction: boolean) => {
  const { safeAddress } = useWeb3Context()

  const { data } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('waiting on safe address')
      }

      return [USER_BID_KEY, auctionId, safeAddress, v2Auction]
    },
    {
      fetcher: userBidfetcher,
      revalidateOnMount: true
    }
  )

  return { amount: data, loading: !data }
}

export const usePlaceAuctionBid = () => {
  const placeBid = useCallback(async (bid: PlaceBidProps) => {
    const { contracts, prestigeID, safeAddress } = gameChain

    if (!safeAddress) {
      throw new Error('bidding when no safe address')
    }
    return _placeBid(contracts, safeAddress, prestigeID, bid)
  }, [])

  return {
    placeBid
  }
}

const withdraw = async (auctionId: string, v2Auction: boolean) => {
  const { contracts, safeAddress } = gameChain
  const { auctionV2, auction } = await contracts

  const auctionContract = v2Auction ? auctionV2 : auction

  if (!safeAddress) {
    throw new Error('Waiting on safe address')
  }

  const auctionMeta = await auctionContract.auctions(auctionId)
  const isHighestBidder =
    auctionMeta.highestBidder.toLowerCase() === safeAddress.toLowerCase()
  const isOwner = auctionMeta.owner.toLowerCase() === safeAddress.toLowerCase()

  if (isHighestBidder) {
    return auctionContract.claimItem(auctionId)
  }
  if (isOwner) {
    return auctionContract.claimPayment(auctionId)
  }

  return auctionContract.withdraw(safeAddress, auctionId)
}

export const useAuctionWithdraw = () => {
  return {
    withdraw
  }
}

export const useIsV2Auction = () => {
  const { version } = useParams<{ version: string }>()
  return version === V2_IDENTIFIER
}
