import { Contract, EventFilter } from 'ethers'
import gameChain, { Subscription } from '../models/GameChain'

type CallbackFunction = () => void

// holds all app subscriptions here
const subscriptions: Record<string, Promise<Subscription>> = {}

function subscriptionId(key: string, nonce: string) {
  return [key, nonce].join('--')
}

// This is a work in progress. The nonce here is so that different parts of the app
// can still subscribe to the same update. seems like the most obvious way of de-duping
// without getting fancy and introspecting the functions
const subscribe = (
  key: string,
  nonce: string,
  fn: () => Promise<[Contract, EventFilter, CallbackFunction]>
) => {
  const subId = subscriptionId(key, nonce)
  if (!subscriptions[subId]) {
    subscriptions[subId] = (async () => {
      const [contract, filter, cb] = await fn()
      return gameChain.subscribe(contract, filter, cb)
    })()
  }
}

const unsubscribe = async (key: string, nonce: string) => {
  const subscription = await subscriptions[subscriptionId(key, nonce)]
  if (subscription) {
    subscription.unsubscribe()
  }
}

export const useSubscriber = () => {
  return {
    subscribe,
    unsubscribe
  }
}
