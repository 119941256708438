import React from 'react'
import { BigNumber } from 'ethers'
import classNames from 'classnames'
import styled from 'styled-components'
import { Layout } from '../layouts/Layout'
import { useWeb3Context } from '../contexts/Web3Context'
import humanBigNumber from '../utils/humanBigNumber'
import medalRank1 from '../assets/images/medals/1.svg'
import medalRank2 from '../assets/images/medals/2.svg'
import medalRank3 from '../assets/images/medals/3.svg'
import medalRank4 from '../assets/images/medals/4.svg'
import medalRank5 from '../assets/images/medals/5.svg'
import { useLeaderboardList } from '../hooks/useLeaderboard'
import Avatar from '../components/atoms/Avatar/Avatar'
import { MinimalGladiator } from '../hooks/useGladiator'
import MinimalGladiatorImage from '../components/composed/MinimalGladiatorImage/MinimalGladiatorImage'
import YouPill from '../components/atoms/YouPill/YouPill'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'

const medals = [medalRank1, medalRank2, medalRank3, medalRank4, medalRank5]

const LeaderboardTableRow: React.FC<{
  currentUser?: string
  address: string
  gladiators: (MinimalGladiator | null)[]
  balance: BigNumber
  index: number
}> = ({ currentUser, address, balance, index, gladiators }) => {
  const isCurrentUser = currentUser?.toLowerCase() === address.toLowerCase()
  const currentUserClasses = isCurrentUser ? 'bg-gray-700' : ''

  const rank =
    index <= 4 ? (
      <img src={medals[index]} alt={`medal of rank ${index + 1}`} />
    ) : (
      index + 1
    )

  return (
    <tr className={classNames(currentUserClasses)}>
      <td className="text-center">{rank}</td>
      <td>
        <div className="py-8 flex items-center max-w-full">
          <Avatar size={40} text={address} />
          <p className="text-p1 mx-8">{address}</p>
          {isCurrentUser && <YouPill />}
        </div>
      </td>
      <td>
        <div className="flex my-7 ml-8">
          {gladiators.map((gladiator) => {
            if (!gladiator) return <></>

            return (
              <MinimalGladiatorImage
                gladiator={gladiator}
                key={`glad-image-${gladiator.id.toString()}-${address}`}
              />
            )
          })}
        </div>
      </td>
      <td className="text-right text-theme-green">{humanBigNumber(balance)}</td>
    </tr>
  )
}

const LeaderboardPage = () => {
  const { data } = useLeaderboardList()
  const { safeAddress } = useWeb3Context()

  return (
    <Layout>
      <PageContainerWithTitle
        title="Leaderboard - Top 100"
        loading={!data?.leaderboard}
        loadingTitle="Leaderbaord loading"
        showSlideIndicator
      >
        {data?.leaderboard && (
          <div className="max-w-full overflow-x-scroll text-base">
            <LeaderboardTable className="w-full text-left">
              <thead>
                <tr>
                  <th className="w-32 text-center">Rank</th>
                  <th>Address</th>
                  <th>Gladiators</th>
                  <th className="text-right">Balance (PTG)</th>
                </tr>
              </thead>
              <tbody>
                {data.leaderboard.slice(0, 100).map((rowData, idx) => {
                  return (
                    <LeaderboardTableRow
                      {...rowData}
                      index={idx}
                      currentUser={safeAddress}
                      key={`leaderboard-${rowData.address}`}
                    />
                  )
                })}
              </tbody>
            </LeaderboardTable>
          </div>
        )}
      </PageContainerWithTitle>
    </Layout>
  )
}

const LeaderboardTable = styled('table')`
  td,
  th {
    padding: 1.5rem 1.5rem;
  }
  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`

export default LeaderboardPage
