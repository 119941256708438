import { useCallback, useState } from 'react'
import { BigNumber } from 'ethers'
import useSWR, { mutate } from 'swr'

import { useWeb3Context } from '../contexts/Web3Context'
import { useSubscriber } from './useSubscription'
import gameChain from '../models/GameChain'

export const USER_PRESTIGE_KEY = '/user/prestige'

const usePrestigeFetcher = async () => {
  const { assets } = await gameChain.contracts
  return assets.balanceOf(gameChain.safeAddress!, await gameChain.prestigeID)
}

export const useWalletPrestige = () => {
  const { contracts, signer, safeAddress } = useWeb3Context()
  const [isSending, setIsSending] = useState(false)

  const { data, revalidate } = useSWR(() => {
    if (!signer || !contracts) {
      throw new Error('waiting on signer')
    }
    return '/user/prestige-in-non-game-wallet'
  }, async () => {
    if (!signer) {
      throw new Error('missing signer')
    }
    const { assets } = await contracts
    return assets.balanceOf(await signer.getAddress(), await gameChain.prestigeID)
  })

  const depositPrestige = useCallback(async (amountInWei:BigNumber) => {
    if (!signer || !contracts || !safeAddress) {
      throw new Error('missing signer or contracts or safe address')
    }
    setIsSending(true)
    const { assets } = await contracts
    try {
      const tx = await assets.connect(signer).safeTransferFrom(await signer.getAddress(), safeAddress, await gameChain.prestigeID, amountInWei, [])
      console.log('sending prestige')
      await tx.wait()
    } catch (err) {
      console.error('error doing tx: ', err)
      throw err
    } finally {
      revalidate()
      setIsSending(false)
    }
  }, [signer, setIsSending, contracts, safeAddress, revalidate])

  return {
    balance: data,
    depositPrestige,
    isSending
  }
}

export const usePrestigeBalance = () => {
  const { contracts, safeAddress } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const { data } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('waiting for safe Address')
      }
      return USER_PRESTIGE_KEY
    },
    {
      fetcher: usePrestigeFetcher,
      onSuccess: (_, key) => {
        subscribe(key, 'prestigeUserSend', async () => {
          if (!safeAddress) {
            console.error('unknown error: no safe')
            throw new Error('Should not happen: no safe address')
          }
          const { assets } = await contracts
          const filter = assets.filters.TransferSingle(
            null,
            null,
            safeAddress,
            null,
            null
          )
          return [assets, filter, () => mutate(key)]
        })

        subscribe(key, 'prestigeUserReceive', async () => {
          if (!safeAddress) {
            console.error('unknown error: no safe')
            throw new Error('Should not happen: no safe address')
          }
          const { assets } = await contracts
          const filter = assets.filters.TransferSingle(
            null,
            safeAddress,
            null,
            null,
            null
          )
          return [assets, filter, () => mutate(key)]
        })
      }
    }
  )

  return data || BigNumber.from(0)
}
