import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Layout } from '../layouts/Layout'
import humanBigNumber from '../utils/humanBigNumber'
import {
  gladiatorLeaguesDetails,
  MinimalGladiator,
  SupportedLeagues,
  useGroupedGladiatorsLeagues
} from '../hooks/useGladiator'
import { useGladiatorOwner } from '../hooks/useGladiatorOwner'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'
import Avatar from '../components/atoms/Avatar/Avatar'
import YouPill from '../components/atoms/YouPill/YouPill'
import Spinner from '../components/atoms/Spinner/Spinner'
import TabWithIconButton from '../components/composed/TabWithIconButton/TabWithIconButton'

const GladiatorTableRow: React.FC<{
  gladiator: MinimalGladiator
  index: number
  relegation: boolean
  playOff: boolean
}> = ({ gladiator, index, relegation, playOff }) => {
  const rank = index + 1

  const { owner, isCurrentUserOwner, loading } = useGladiatorOwner(
    gladiator.id.toString()
  )

  return (
    <tr>
      <td className="text-center">
        <div
          className={classNames(
            'flex items-center justify-center  h-24',
            relegation && 'border-l-4 border-red-600',
            playOff && 'border-l-4 border-yellow-400'
          )}
        >
          {rank}
        </div>
      </td>
      <td>
        <div className="flex  items-center border-gray-800 bg-dark py-10 pr-16">
          <Link to={`/gladiator/${gladiator?.id}`}>
            <StyleGladiatorImage
              src={gladiator.image.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud')}
              alt={gladiator.name}
              className="rounded-full"
            />
          </Link>
          <div className="ml-6">
            <div className="flex items-center">
              <p className="font-gilroy-medium text-gray-100 text-p-lg">
                <Link to={`/gladiator/${gladiator?.id}`}>{gladiator.name}</Link>
              </p>
            </div>
            <div className="mr-24 lg:mr-0">
              {gladiator.tagline && (
                <p className="font-gilroy-medium whitespace-no-wrap text-gray-300  text-p3">
                  {gladiator.tagline}
                </p>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="text-right text-theme-green">
        {humanBigNumber(gladiator.prestige)}
      </td>
      <td className="text-right w-1/3">
        {loading ? (
          <Spinner />
        ) : (
          // check if owner exist
          <>
            {owner && (
              <div className="flex items-center justify-end">
                <p className=" text-p3 mx-8 truncate w-2/3 font-semibold">
                  {owner.address}
                </p>
                <Avatar size={40} text={owner.address} />
                {isCurrentUserOwner && <YouPill />}
              </div>
            )}
          </>
        )}
      </td>
    </tr>
  )
}

const GladiatorsPage = () => {
  const location = useLocation()
  const history = useHistory()
  const [activeleague, setActiveLeague] = useState<SupportedLeagues>('1')

  const { leagues: gladiatorLeaguesData } = useGroupedGladiatorsLeagues()

  useEffect(() => {
    const autoSetLeague = () => {
      const params = new URLSearchParams(location.search)
      const league: SupportedLeagues | undefined = params.get(
        'league'
      ) as SupportedLeagues
      if (!league) {
        setActiveLeague('1')
      } else {
        setActiveLeague(league)
      }
    }
    autoSetLeague()
  }, [location.search])

  const gladiators = useMemo(() => {
    if (!gladiatorLeaguesData) {
      return []
    }
    if (activeleague === '1') {
      return gladiatorLeaguesData[0]
    }
    return gladiatorLeaguesData[1]
  }, [activeleague, gladiatorLeaguesData])

  return (
    <Layout>
      <PageContainerWithTitle
        loadingTitle="Gladiators loading"
        loading={!gladiatorLeaguesData}
        title="Gladiator Rankings"
        showSlideIndicator
      >
        <section className="flex items-center mt-12">
          {gladiatorLeaguesDetails.map((league) => (
            <TabWithIconButton
              active={league.number === activeleague}
              category={`${league.title} league`}
              key={league.title}
              image={league.image}
              onClick={() => {
                history.push(`${location.pathname}?league=${league.number}`)
              }}
            />
          ))}
        </section>
        <div className="overflow-x-scroll mt-10 text-base">
          <GladiatorTable className="w-full text-left">
            <thead>
              <tr>
                <th className="w-32 text-center">Rank</th>
                <th>Gladiator</th>
                <th className="text-right">Total $PTG</th>
                <th className="text-right">Owner</th>
              </tr>
            </thead>
            <tbody>
              {gladiators?.map((gladiator, idx) => {
                const leagueOne = activeleague === '1'
                const relegation = leagueOne
                  ? idx > gladiators.length - 3
                  : false
                const playOff =
                  leagueOne && !relegation && idx + 1 > gladiators.length - 3

                return (
                  <GladiatorTableRow
                    index={idx}
                    playOff={playOff}
                    gladiator={gladiator}
                    key={`gladiator-leaderboard-${gladiator.id}`}
                    relegation={relegation}
                  />
                )
              })}
            </tbody>
          </GladiatorTable>

          <div className=" mt-12 p-10 bg-dark-purple rounded-12 inline-flex flex-col">
            <p className="text-gray-400 text-p4 ">QUALIFICATION/RELEGATION</p>

            <div className="mt-4 flex items-center">
              <div className="flex items-center mr-16">
                <span className="h-4 w-4 bg-red-600 inline-block mr-4" />
                <p>Relegation</p>
              </div>
              <div className="flex items-center mr-8">
                <span className="h-4 w-4 rounded-full bg-yellow-400 inline-block mr-4" />
                <p>Relegation Play-offs</p>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWithTitle>
    </Layout>
  )
}

const GladiatorTable = styled('table')`
  td,
  th {
    padding: 1.5rem 1.5rem;
  }
  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`

const StyleGladiatorImage = styled.img`
  max-width: 6rem;
  min-width: 6rem;
  height: 6rem;
`

export default GladiatorsPage
