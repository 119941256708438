export type TypeName = 'common' | 'uncommon' | 'rare' | 'ultra-rare'

export default (type: TypeName, text?: boolean) => {
  if (text) {
    switch (type) {
      case 'common':
        return 'text-green-600'
      case 'uncommon':
        return 'text-indigo-600'
      case 'rare':
        return 'text-purple-600'
      case 'ultra-rare':
        return 'text-red-600'
      default:
        return ''
    }
  }

  switch (type) {
    case 'common':
      return 'bg-green-600'
    case 'uncommon':
      return 'bg-indigo-600'
    case 'rare':
      return 'bg-purple-600'
    case 'ultra-rare':
      return 'bg-red-600'
    default:
      return ''
  }
}
