import axios from 'axios'
import { constants, utils, BigNumberish, BigNumber, ContractTransaction } from 'ethers'
import { OPERATION } from 'kasumah-wallet'
import { signer } from 'kasumah-relay-wrapper/dist/src/relayers/signer'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import gameChain from '../models/GameChain'
import { bettingPoolForTournament, localMutateBets } from './useBetting'
import { isV4 } from '../utils/isTournamentV4'

const MAX_BETS = 6
const BET_AMOUNT = utils.parseEther('.001')

export interface FreeBetCoinMetadata {
  value: BigNumber
  blockCreatedAt: BigNumber
  expiresAtBlock: BigNumber
  creator: string
  id: BigNumber
}

const oldFreeBetsFetcher = async () => {
  const { safeAddress, contracts } = gameChain
  if (!safeAddress) {
    throw new Error('no free bets for unknown user')
  }
  const { freeBet } = await contracts

  const used = await freeBet.usedFreeBets(safeAddress)

  return MAX_BETS - used
}

const mutateAfterTransaction = (txP:Promise<ContractTransaction>, token:FreeBetCoinMetadata, tournamentID:BigNumberish, gladiatorID:BigNumberish) => {
  const { safeAddress } = gameChain
  if (!safeAddress) {
    throw new Error('must have a safe address')
  }
  txP.then(async (tx) => {
    await tx.wait()
    localMutateBets(
      BigNumber.from(tournamentID).toString(),
      gladiatorID,
      token.value,
    )
    mutate(['/freeBetCoins', safeAddress], async (tokens:FreeBetCoinMetadata[]) => {
      return tokens.filter((meta) => !meta.id.eq(token.id))
    })
  })
  .catch((tx) => {
    console.error('failed new user bet: ', tx)
  })
}

const betWithToken = async (token:FreeBetCoinMetadata, tournamentID:BigNumberish, gladiatorID:BigNumberish, expectedTotal:BigNumberish) => {
  const { safeAddress, contracts } = gameChain
  const { freeBetCoin } = await contracts
  if (!safeAddress) {
    throw new Error('must have a safe address')
  }
  const txP = token.id.lt(0) ? makeOldFreeBet(tournamentID, gladiatorID) : freeBetCoin.use(token.id, safeAddress, tournamentID, gladiatorID, expectedTotal)
  mutateAfterTransaction(txP, token, tournamentID, gladiatorID)
  return txP
}

const makeOldFreeBet = async (
  tournamentID: BigNumberish,
  gladiatorID: BigNumberish,
) => {
  const {
    userSafe,
    prestigeID,
    signer: userSigner,
    provider,
  } = gameChain
  const { assets } = await gameChain.contracts

  const bettingPool = await bettingPoolForTournament(
    tournamentID,
    gameChain.contracts
  )

  if (!userSafe || !userSigner) {
    throw new Error('no user safe or signer')
  }
  const nonce = await userSafe.nonce()

  const betData = isV4(tournamentID)
    ? utils.defaultAbiCoder.encode(
        ['uint256', 'uint256'],
        [tournamentID, gladiatorID]
      )
    : utils.defaultAbiCoder.encode(
        ['address', 'uint256', 'uint256', 'uint256'],
        [userSafe.address, tournamentID, gladiatorID, BET_AMOUNT]
      )

  const userBetTx = await assets.populateTransaction.safeTransferFrom(
    userSafe.address,
    bettingPool.address,
    await prestigeID,
    BET_AMOUNT,
    betData
  )

  const aliceSig = await signer(
    userSigner,
    userSafe.address,
    assets.address,
    0,
    userBetTx.data!,
    OPERATION.CALL,
    0,
    0,
    0,
    constants.AddressZero,
    constants.AddressZero,
    nonce
  )

  const resp = await axios.post('/api/new-user-bet', {
    safeAddress: userSafe.address,
    tournamentID: tournamentID.toString(),
    gladiatorID: gladiatorID.toString(),
    signature: aliceSig.slice(2)
  })

  const txP = provider.getTransaction(resp.data.transactionHash)
  return txP
}

export const useFreeBetCoins = () => {
  const { safeAddress, contracts } = useWeb3Context()

  const { data, revalidate } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('waiting on safe addr')
      }
      return ['/freeBetCoins', safeAddress]
    },
    {
      fetcher: async (_, safeAddress): Promise<FreeBetCoinMetadata[]> => {
        const { freeBetCoin } = await contracts
        const numberOfTokens = await freeBetCoin.balanceOf(safeAddress)
        const oldFreeBetsRemaining = await oldFreeBetsFetcher()
        const tokens = await Promise.all(
          Array(numberOfTokens.toNumber())
            .fill(true)
            .map(async (_, i) => {
              const id = await freeBetCoin.tokenOfOwnerByIndex(safeAddress, i)
              const metadata = await freeBetCoin.metadata(id)
              return {
                ...metadata,
                id
              } as FreeBetCoinMetadata
            })
        )

        // fill in the remaining free bets from the old style of 6 free 0.001 PTG bets
        const fakeTokens = Array(oldFreeBetsRemaining)
          .fill(true)
          .map((_, i): FreeBetCoinMetadata => {
            return {
              id: BigNumber.from((i + 1) * -1),
              value: BET_AMOUNT,
              blockCreatedAt: constants.Zero,
              expiresAtBlock: constants.Zero,
              creator: constants.AddressZero
            }
          })

        return tokens.concat(fakeTokens)
      }
    }
  )

  return {
    balance: data?.length,
    coins: data,
    loading: !data,
    makeBet: betWithToken,
    revalidate
  }
}
