import React from 'react'
import { Jazzicon } from '@ukstv/jazzicon-react'
import styled from 'styled-components'

interface AvatarProps {
  text: string
  size?: number
}

const Avatar: React.FC<AvatarProps> = ({ text, size = 40 }) => {
  return (
    <Container size={size} className="flex items-center justify-center">
      <div style={{ width: size, height: size }}>
        <Jazzicon address={text} />
      </div>
    </Container>
  )
}

const Container = styled.div<{ size: number }>`
  border-radius: 100px;
  overflow: hidden;

  height: ${(props) => props.size};
  width: ${(props) => props.size};

  max-height: ${(props) => props.size};
  min-height: ${(props) => props.size};
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
`

export type { AvatarProps }
export default Avatar
