import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  MinimalTournamnet,
  useTournament
} from '../../../../hooks/useTournament'
import Button from '../../../atoms/Button/Button'
import Spinner from '../../../atoms/Spinner/Spinner'
import TournamentTime from '../../../atoms/TournamentTime/TournamentTime'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
//
interface PastTournamentTableProps {
  tournaments: MinimalTournamnet[]
}

interface ITournamentRow {
  tournament: MinimalTournamnet
}

const TournamentRow: React.FC<ITournamentRow> = ({ tournament }) => {
  const { tournament: fetchedTournament } = useTournament(
    tournament.id.toString()
  )

  return (
    <tr>
      <td className="">
        <div className="py-8 w-64 lg:w-auto flex flex-col mr-8 lg:mr-0">
          <h3 className="text-p1 font-gilroy-bold">{tournament.name}</h3>
          <TournamentTime
            notBefore={tournament.notBefore}
            className="text-p4"
          />
        </div>
      </td>
      <td>
        {fetchedTournament.champion ? (
          <div className="flex items-center ml-4">
            <SkynetImageFixer
              className="rounded-full bg-black w-16 h-16 object-cover object-top'"
              alt={fetchedTournament.champion?.name}
              src={fetchedTournament.champion?.image}
            />
            <p className=" font-gilroy-medium whitespace-nowrap mr-20 capitalize ml-3 text-gray-200">
              {fetchedTournament.champion?.name}
            </p>
          </div>
        ) : (
          <div className="justify-center">
            <Spinner />
          </div>
        )}
      </td>
      <td>
        <div className="flex items-center px-10">
          {tournament.gladiators.map((gladiator) => (
            <SkynetImageFixer
              key={gladiator.id.toString()}
              className="rounded-full bg-black -ml-8 w-16 h-16 object-cover object-top"
              alt={gladiator.name}
              src={gladiator.image}
            />
          ))}
        </div>
      </td>
      <td>
        <Link to={`/tournament/${tournament.id.toString()}`}>
          <Button
            type="button"
            rounded
            color="text-primary"
            background="bg-transparent"
            className="border border-gray-600 hover:border-gray-400"
          >
            Relive the glory
          </Button>
        </Link>
      </td>
    </tr>
  )
}

const PastTournamentTable: React.FC<PastTournamentTableProps> = ({
  tournaments
}) => {
  return (
    <Container className="w-full overflow-y-scroll">
      <table className="w-full">
        <thead>
          <tr>
            <th className="uppercase">name</th>
            <th>champion</th>
            <th>gladiators</th>
            <th>Visit</th>
          </tr>
        </thead>
        <tbody>
          {tournaments.map((tournament) => (
            <TournamentRow
              tournament={tournament}
              key={tournament.id.toString()}
            />
          ))}
        </tbody>
      </table>
    </Container>
  )
}

const Container = styled.div`
  min-height: 432px;
  max-height: 432px;

  tbody {
    overflow-y: scroll;
  }

  th {
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
  }
`

export default PastTournamentTable
