/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { BetChecker, BetCheckerInterface } from "../BetChecker";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "bettingPoolAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "gladiatorId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedAmount",
        type: "uint256",
      },
    ],
    name: "checkBet",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50610228806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063c70edfbf14610030575b600080fd5b61004361003e366004610134565b610059565b6040516100509190610199565b60405180910390f35b60405163055dae6160e21b8152600090869082906001600160a01b03831690631576b98490610090908a908a908a906004016101d3565b60206040518083038186803b1580156100a857600080fd5b505afa1580156100bc573d6000803e3d6000fd5b505050506040513d601f19601f820116820180604052508101906100e09190610181565b905080841461010a5760405162461bcd60e51b8152600401610101906101a4565b60405180910390fd5b506001979650505050505050565b80356001600160a01b038116811461012f57600080fd5b919050565b600080600080600060a0868803121561014b578081fd5b61015486610118565b94506020860135935061016960408701610118565b94979396509394606081013594506080013592915050565b600060208284031215610192578081fd5b5051919050565b901515815260200190565b602080825260159082015274155b995e1c1958dd19590810995d08105b5bdd5b9d605a1b604082015260600190565b9283526001600160a01b0391909116602083015260408201526060019056fea2646970667358221220774c6a1833fdb75388b4709b3e24ea0d441e84211816510472b38a0afaf3d46564736f6c63430007040033";

export class BetChecker__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<BetChecker> {
    return super.deploy(overrides || {}) as Promise<BetChecker>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): BetChecker {
    return super.attach(address) as BetChecker;
  }
  connect(signer: Signer): BetChecker__factory {
    return super.connect(signer) as BetChecker__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): BetCheckerInterface {
    return new utils.Interface(_abi) as BetCheckerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): BetChecker {
    return new Contract(address, _abi, signerOrProvider) as BetChecker;
  }
}
