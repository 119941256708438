import { useCallback } from 'react'
import useSWR from 'swr'
import { BigNumberish } from 'ethers'
import { useWeb3Context } from '../contexts/Web3Context'
import gameChain from '../models/GameChain'

const wrappedPTGBalanceFetcher = async (_: string, safeAddress: string) => {
  const { wrappedPTG } = await gameChain.contracts
  return wrappedPTG.balanceOf(safeAddress)
}

export const useWrappedPTG = () => {
  const { safeAddress, contracts } = useWeb3Context()

  const { data, revalidate, isValidating } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('no safe address')
      }
      return ['/wrapped-ptg-balance', safeAddress]
    },
    {
      fetcher: wrappedPTGBalanceFetcher
    }
  )

  const unwrap = useCallback(
    async (amount: BigNumberish) => {
      if (!safeAddress) {
        throw new Error('must be logged in to unwrap')
      }
      const { wrappedPTG } = await contracts
      const tx = wrappedPTG.unwrap(safeAddress, safeAddress, amount)
      tx.finally(revalidate)
      return tx
    },
    [safeAddress, contracts, revalidate]
  )

  return {
    balance: data,
    loading: !data,
    updating: isValidating,
    revalidate,
    unwrap
  }
}
