/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { BidChecker, BidCheckerInterface } from "../BidChecker";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "auctionContractAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "bidder",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "auctionId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedAmount",
        type: "uint256",
      },
    ],
    name: "checkBid",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50610211806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c80637410384e14610030575b600080fd5b61004361003e366004610131565b610059565b604051610050919061018a565b60405180910390f35b604051632d1c226d60e21b8152600090859082906001600160a01b0383169063b47089b49061008e9088908a906004016101c4565b60206040518083038186803b1580156100a657600080fd5b505afa1580156100ba573d6000803e3d6000fd5b505050506040513d601f19601f820116820180604052508101906100de9190610172565b90508084146101085760405162461bcd60e51b81526004016100ff90610195565b60405180910390fd5b5060019695505050505050565b80356001600160a01b038116811461012c57600080fd5b919050565b60008060008060808587031215610146578384fd5b61014f85610115565b935061015d60208601610115565b93969395505050506040820135916060013590565b600060208284031215610183578081fd5b5051919050565b901515815260200190565b602080825260159082015274155b995e1c1958dd195908109a5908105b5bdd5b9d605a1b604082015260600190565b9182526001600160a01b031660208201526040019056fea264697066735822122023203da14c9bb1ee0bd276c3313c62f5497d4b88ada4d518fd67b6af07b7c27264736f6c63430007040033";

export class BidChecker__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<BidChecker> {
    return super.deploy(overrides || {}) as Promise<BidChecker>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): BidChecker {
    return super.attach(address) as BidChecker;
  }
  connect(signer: Signer): BidChecker__factory {
    return super.connect(signer) as BidChecker__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): BidCheckerInterface {
    return new utils.Interface(_abi) as BidCheckerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): BidChecker {
    return new Contract(address, _abi, signerOrProvider) as BidChecker;
  }
}
