import React from 'react'
import classNames from 'classnames'
import { BigNumberish } from 'ethers'
import styled from 'styled-components'
import EquippedItemCard from '../EquippedItemCard/EquippedItemCard'
import SuccessfulHit from '../../ActionIndicators/SuccessfulHit/SuccessfulHit'
import BlockedHit from '../../ActionIndicators/BlockedHit/BlockedHit'
import { EquippedItem } from '../../../../../interfaces/EquippedItem.interface'
import SkynetImageFixer from '../../../../atoms/SkynetImageFixer/SkynetImageFixer'

interface GladiatorBattleCardProps {
  equippedItems?: EquippedItem[]
  home: boolean
  action?: boolean
  attack?: boolean
  damage?: string
  gladiator: {
    name: string
    id: BigNumberish
    image: string
  }
  tournamentId?: BigNumberish
}

const GladiatorBattleCard: React.FC<GladiatorBattleCardProps> = ({
  home,
  action,
  gladiator,
  attack,
  damage,
  equippedItems
}) => {
  return (
    <Wrapper className="relative overflow-visible">
      <SkynetImageFixer
        src={gladiator.image}
        alt={gladiator.name}
        className={classNames('h-full object-cover relative')}
        style={{
          transform: home ? '' : 'rotateY(180deg)'
        }}
      />
      {equippedItems?.length ? (
        <div className="absolute bottom-0 left-0 pb-5 px-2 w-full">
          <section className="flex items-center px-2 bg-dark rounded-xl py-4 w-full">
            {(equippedItems || []).map((item, idx) => (
              <EquippedItemCard
                key={item.name + idx.toString()}
                item={item}
                className="px-2"
              />
            ))}
          </section>
        </div>
      ) : (
        <></>
      )}

      {action && (
        <>
          {damage === 'no' && !attack ? (
            <BlockedHit name={gladiator.name} home={home} />
          ) : (
            <SuccessfulHit
              attack={attack || false}
              damage={damage || '0'}
              statAffected="hit points"
              home={home}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.article`
  width: 310px;
  height: 424px;
`

export type { GladiatorBattleCardProps }
export default GladiatorBattleCard
