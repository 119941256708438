import { BigNumberish } from 'ethers'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGladiatorEquipments } from '../../../../../hooks/useGladiator'
import PlayerInterface from '../../../../../interfaces/Player.interface'
import { factionToDisplayname } from '../../../../../utils/factionToDisplayName'
import SkynetImageFixer from '../../../../atoms/SkynetImageFixer/SkynetImageFixer'
import BoughtItemIndicator from '../../BoughtItemIndicator/BoughtItemIndicator'

export interface ContestantCardProps {
  detail?: PlayerInterface
  undecided?: boolean
  equipGladiator: (id: BigNumberish) => void
  tournamentId: string
}

const ContestantCard: React.FC<ContestantCardProps> = ({
  detail,
  undecided,
  tournamentId
}) => {
  const data = useGladiatorEquipments(tournamentId, detail?.id)

  return (
    <div className="flex items-center w-full justify-between py-6 px-8">
      {undecided ? (
        <img
          className="rounded-full bg-gray-700 w-20 h-20 object-cover object-top"
          alt="undecided"
          src="/images/icons/rounded-user.svg"
        />
      ) : (
        <Link to={`/gladiator/${detail?.id}?faction=${detail?.faction}`}>
          <SkynetImageFixer
            className="rounded-full bg-black w-20 h-20 object-cover object-top"
            alt={`${detail?.name} collectible`}
            src={detail?.image}
          />
        </Link>
      )}

      <div className="flex-grow ml-4">
        {undecided ? (
          <p className="text-gray-300 capitalize text-p2 font-gilroy-medium">
            Undecided
          </p>
        ) : (
          <div>
            <Link to={`/gladiator/${detail?.id}?faction=${detail?.faction}`}>
              <p className="text-gray-300 capitalize text-p2 font-gilroy-medium">
                {detail?.name}
              </p>
            </Link>

            <div className="flex items-center mt-3">
              {data &&
                data.map((item, i) => (
                  <BoughtItemIndicator
                    item={item}
                    key={`contestantCard-bought-${item.id.toString()}-${i}`} // eslint-disable-line react/no-array-index-key
                  />
                ))}
            </div>
          </div>
        )}
      </div>

      {!undecided && (
        <img
          className=" w-16 h-full object-cover"
          alt={`${factionToDisplayname(detail?.faction)} faction`}
          src={`/factions/${detail?.faction}.png`}
        />
      )}
    </div>
  )
}

export default ContestantCard
