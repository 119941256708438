import React, { useMemo, useState } from 'react'
import AuctionCard from '../components/page-specific/auction/AuctionCard/AuctionCard'
import { Layout } from '../layouts/Layout'
import Tabs from '../components/composed/Tabs/Tabs'
import { useGroupedAuctions } from '../hooks/useAuction'
import AuctionInterface from '../interfaces/Auction.interface'
import { useWeb3Context } from '../contexts/Web3Context'
import NoDataIndicator from '../components/atoms/NoDataIndicator/NoDataIndicator'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'

interface AuctionsList {
  auctions: {
    open: AuctionInterface[]
    closed: AuctionInterface[]
  }
  safeAddress?: string
}

interface IAuctionsNeedAction {
  auctions: AuctionInterface[]
}

const AuctionsNeedAction: React.FC<IAuctionsNeedAction> = ({ auctions }) => {
  if (!auctions.length) {
    return <NoDataIndicator subtitle="You have no pending auctions" />
  }

  return (
    <>
      {auctions.map((auction) => (
        <AuctionCard key={auction.id.toString()} auction={auction} />
      ))}
    </>
  )
}

const AuctionsList: React.FC<AuctionsList> = ({ auctions, safeAddress }) => {
  const auctionTabs = safeAddress
    ? ['Open', 'Closed', 'Action required ⚔️']
    : ['Open', 'Closed']

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const auctionsNeedingAction = useMemo(() => {
    if (!safeAddress) {
      return []
    }
    const getAuctionsWithBid = auctions.closed.map((auction) => {
      const isHighestBidder =
        auction?.highestBidder.toString().toLowerCase() ===
        safeAddress?.toLowerCase()

      if (isHighestBidder && auction?.highestBidderHasWithdrawn) {
        return null
      }
      if (auction?.userBidAmount && auction?.userBidAmount.gt(0)) {
        return auction
      }

      return null
    })

    return getAuctionsWithBid.filter(
      (auction) => auction !== null
    ) as AuctionInterface[]
  }, [auctions.closed, safeAddress])

  return (
    <>
      <Tabs
        tabs={auctionTabs}
        onTabSelect={setActiveTabIndex}
        activeTabIndex={activeTabIndex}
        className="my-10 text-p-lg"
        capitalize={false}
      />
      <section className="w-full mt-10 flex flex-wrap lg:-mx-5">
        {activeTabIndex === 0 &&
          auctions.open
            .map((auction) => (
              <AuctionCard key={auction.id.toString()} auction={auction} />
            ))
            .reverse()}

        {activeTabIndex === 1 &&
          auctions.closed
            .map((auction) => (
              <AuctionCard key={auction.id.toString()} auction={auction} />
            ))
            .reverse()}

        {activeTabIndex === 2 && (
          <AuctionsNeedAction auctions={auctionsNeedingAction} />
        )}
      </section>
    </>
  )
}

const AuctionPage = () => {
  const { safeAddress } = useWeb3Context()
  const { auctions, loading: auctionLoading } = useGroupedAuctions()

  return (
    <Layout>
      <PageContainerWithTitle
        title="Auction"
        subTitle="Are you a pleb or a patrician?"
        loadingTitle="Auctions loading, ready your PTG"
        loading={auctionLoading}
      >
        {auctions && (
          <AuctionsList safeAddress={safeAddress} auctions={auctions} />
        )}
      </PageContainerWithTitle>
    </Layout>
  )
}

export default AuctionPage
