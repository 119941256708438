import React from 'react'
import styled from 'styled-components'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

export interface GladiatorImageProps {
  image: string
  gladiator: string
}

const GladiatorImage: React.FC<GladiatorImageProps> = ({
  image,
  gladiator
}) => {
  return (
    <Container className=" flex items-center bg-transparent lg:bg-black justify-center lg:w-1/3 w-full">
      <SkynetImageFixer src={image} alt={gladiator} />
    </Container>
  )
}

const Container = styled.figure`
  border: 3px solid;
  border-image-source: linear-gradient(
    180deg,
    #5f2706 0%,
    #ff7320 48.96%,
    #5f2706 97.92%,
    #5f2706 100%
  );
  border-image-slice: 1%;
  height: 530px;

  img {
    max-height: 80%;
  }

  @media only screen and (max-width: 400px) {
    height: 200px;
    border: none;
  }
`

export default GladiatorImage
