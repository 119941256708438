/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { BigNumberish } from 'ethers'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PlayerInterface from '../../../../../interfaces/Player.interface'
import { FixedLengthArray } from '../../../../../interfaces/FixedLengthArray.interface'
import ContestantCard from '../ContestantCard/ContestantCard'

export interface ContestantCardGroupProps {
  contestants?: FixedLengthArray<PlayerInterface, 2>
  inProgress?: boolean
  matchEnded?: boolean
  undecided?: boolean
  equipGladiator: (id: BigNumberish) => void
  tournamentId: string
  roundIndex?: number
  gameNumber?: number
}

const ContestantCardGroup: React.FC<ContestantCardGroupProps> = ({
  contestants,
  inProgress, // used by commented out code below
  undecided,
  equipGladiator,
  tournamentId,
  gameNumber,
  matchEnded,
  roundIndex
}) => {
  return (
    <Container>
      <InfoContainer className="p-0 bg-dark rounded-12 border border-gray-800">
        <div className="flex flex-col rounded-md">
          <ContestantCard
            tournamentId={tournamentId}
            equipGladiator={equipGladiator}
            undecided={undecided}
            detail={contestants && contestants[0]}
          />
          <hr className="border border-gray-800 w-full inline-block" />
          <ContestantCard
            tournamentId={tournamentId}
            equipGladiator={equipGladiator}
            undecided={undecided}
            detail={contestants && contestants[1]}
          />
        </div>
      </InfoContainer>
      <div className="flex items-center text-primary w-full mt-6 uppercase text-p5 font-gilroy-medium">
        {inProgress && (
          <>
            <p className="mr-4">match is live now</p>
            <Link
              to={`/battle/${tournamentId}/${roundIndex}/${gameNumber}`}
              className="underline cursor-pointer"
            >
              check it out {'>>'}
            </Link>
          </>
        )}
        {matchEnded && (
          <>
            <p className="mr-4">Match ended</p>
            <Link
              to={`/battle/${tournamentId}/${roundIndex}/${gameNumber}`}
              className="underline cursor-pointer"
            >
              Relive the glory {'>>'}
            </Link>
          </>
        )}
      </div>
    </Container>
  )
}

export const Container = styled.div`
  --border-color: #ff6240;

  padding: 1em 2rem 1em 6rem;
  width: 100%;
  position: relative;
  flex: 0 1 auto;

  &::after {
    content: '';
    position: absolute;
    height: 50%;
    width: 2rem;
    right: 0px;
  }

  &:nth-child(even)::before {
    content: '';
    border-top: 1px solid var(--border-color);
    position: absolute;
    top: -0.5px;
    width: 6rem;
    right: -6rem;
  }

  &:nth-child(even)::after {
    border-bottom: 1px solid var(--border-color);
    top: -0.5px;
    border-right: 1px solid var(--border-color);
  }
  &:nth-child(odd):not(:last-child)::after {
    border-top: 1px solid var(--border-color);
    top: calc(50% - 0.5px);
    border-right: 1px solid var(--border-color);
  }
`

const InfoContainer = styled.div`
  width: 328px;
`

export default ContestantCardGroup
