/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber, BigNumberish, utils } from 'ethers'
import Spinner from '../components/atoms/Spinner/Spinner'
import { useWeb3Context, Web3ContextData } from '../contexts/Web3Context'
import { Layout } from '../layouts/Layout'
import { usePrestigeBalance, useWalletPrestige } from '../hooks/usePrestigeBalance'
import humanBigNumber from '../utils/humanBigNumber'
import WalletItemsCard from '../components/page-specific/wallet/WalletItemsCard/WalletItemsCard'
import Icon from '../components/atoms/Icon/Icon'
import { useMarketplace, useUserEquipment } from '../hooks/useEquipment'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import BuyItemModal from '../components/page-specific/marketplace/BuyItemModal/BuyItemModal'
import SellItemModal from '../components/page-specific/marketplace/SellItemModal/SellItemModal'
import TransferPTGModal from '../components/page-specific/wallet/TransferPTGModal/TransferPTGModal'
import TransferItemModal from '../components/page-specific/wallet/TransferItemModal/TransferItemModal'
import truncateText from '../utils/truncateText'
import { useWrappedPTG } from '../hooks/useWrappedPrestige'
import BalanceCard from '../components/page-specific/wallet/BalanceCard/BalanceCard'
import useMaticBalance from '../hooks/useMaticBalance'
import SwapMaticModal from '../components/page-specific/wallet/SwapMaticModal/SwapMaticModal'
import BundledItems from '../components/page-specific/wallet/BundledItems/BundledItems'
import TransferMaticModal from '../components/page-specific/wallet/TransferMaticModal/TransferMaticModal'
import NoDataIndicator from '../components/atoms/NoDataIndicator/NoDataIndicator'
import SwapPTGModal from '../components/page-specific/wallet/SwapPTGModal/SwapPTGModal'
import { MinimalGladiator, useUserGladiators } from '../hooks/useGladiator'
import TabWithIconButton from '../components/composed/TabWithIconButton/TabWithIconButton'
import WalletGladiatorCard from '../components/page-specific/wallet/WalletGladiatorCard/WalletGladiatorCard'
import TransferGladiatorModal from '../components/page-specific/wallet/TransferGladiatorModal/TransferGladiatorModal'
import WithdrawFromGladiatorModal from '../components/page-specific/wallet/WithdrawFromGladiatorModal/WithdrawFromGladiatorModal'
import SwapModal from '../components/page-specific/wallet/SwapModal/SwapModal'
import FreeBetCoinsSection from '../components/page-specific/wallet/FreeBetCoinsSection/FreeBetCoinsSection'
import { MPTG_PER_PTG } from '../constants'
import WrapItemModal from '../components/page-specific/wallet/WrapItemModal/WrapItemModal'
import { useUserWrappedAssetBalance } from '../hooks/useWrappedAssets'
import SpinnerWithTitle from '../components/composed/SpinnerWithTitle/SpinnerWithTitle'
import WrappedItemCard from '../components/page-specific/wallet/WrappedItemCard/WrappedItemCard'
import WrapGladiatorModal from '../components/page-specific/wallet/WrapGladiatorModal/WrapGladiatorModal'
import WrappedGladiatorCard from '../components/page-specific/wallet/WrappedGladiatorCard/WrappedGladiatorCard'

const DUST_AMOUNT = utils.parseEther('0.001')

interface WallerContentPageProps {
  ctx: Web3ContextData
}

interface UserGladiatorsProps {
  gladiators: Record<string, MinimalGladiator> | undefined
  onTransferGladiator: (x: BigNumberish) => void
  onWithdrawGladiator: (x: BigNumberish) => void
  wrapGladiator: (id: BigNumberish, isItem: boolean) => void
}

interface ItemsSectionProps {
  items: ItemConfigInterface[]
  selectItem: (id: BigNumber, buying: boolean, transfer?: boolean) => void
  wrapItem: (id: BigNumber, isItem: boolean) => void
}

interface WrappedItemsSectionProps {
  items?: ItemConfigInterface[]
  gladiators?: MinimalGladiator[]
  ctx: Web3ContextData
}

const UserGladiators: React.FC<UserGladiatorsProps> = ({
  gladiators,
  onTransferGladiator,
  onWithdrawGladiator,
  wrapGladiator
}) => {
  if (!gladiators) return <Spinner />

  return (
    <>
      {Object.values(gladiators!).length ? (
        Object.values(gladiators!).map((gladiator: MinimalGladiator) => (
          <WalletGladiatorCard
            onTransferGladiator={onTransferGladiator}
            onWithdrawGladiator={onWithdrawGladiator}
            key={gladiator.id.toString()}
            gladiator={gladiator}
            wrapGladiator={() => wrapGladiator(gladiator.id, false)}
          />
        ))
      ) : (
        <NoDataIndicator
          image="/images/symbols/gladiators.svg"
          subtitle="You have no gladiator"
          addPadding={false}
        />
      )}
    </>
  )
}

const ItemsSection: React.FC<ItemsSectionProps> = ({
  items,
  selectItem,
  wrapItem
}) => {
  if (!items) {
    return <SpinnerWithTitle title="items loading" />
  }

  return (
    <>
      {items.length ? (
        items.map((item: ItemConfigInterface) => (
          <WalletItemsCard
            viewItem={(buy: boolean, transfer?: boolean) => {
              selectItem(item.id as BigNumber, buy, transfer)
            }}
            wrapItem={() => wrapItem(item.id as BigNumber, true)}
            key={item.id?.toString()}
            item={item}
          />
        ))
      ) : (
        <NoDataIndicator
          image="/images/symbols/items.svg"
          subtitle="You have no items"
          addPadding={false}
        />
      )}
    </>
  )
}

const WrappedItemsSection: React.FC<WrappedItemsSectionProps> = ({
  items,
  gladiators,
  ctx
}) => {
  if (!items || !gladiators) {
    return <SpinnerWithTitle title="Wrapped assets loading" />
  }

  return (
    <section className="pt-20">
      <article className="mb-20">
        <h4 className="text-h6 text-gray-300 mb-4">Wrapped Gladiators</h4>

        {gladiators.length ? (
          <section className="flex flex-wrap">
            {gladiators.map((gladiator) => (
              <WrappedGladiatorCard
                ctx={ctx}
                key={gladiator.id?.toString()}
                gladiator={gladiator}
              />
            ))}
          </section>
        ) : (
          <NoDataIndicator
            image="/images/symbols/items.svg"
            subtitle="You have no wrapped gladiators"
            addPadding={false}
          />
        )}
      </article>

      <article>
        <h4 className="text-h6 text-primary mb-4">Wrapped Items</h4>
        {items.length ? (
          <section className="flex flex-wrap mb-10 mt-3">
            {items.map((item) => (
              <WrappedItemCard
                ctx={ctx}
                key={item.id?.toString()}
                item={item}
              />
            ))}
          </section>
        ) : (
          <NoDataIndicator
            image="/images/symbols/items.svg"
            subtitle="You have no wrapped items"
            addPadding={false}
          />
        )}
      </article>
    </section>
  )
}

const WalletContentPage: React.FC<WallerContentPageProps> = ({ ctx }) => {
  const { safeAddress } = ctx
  const { connected, connect, loading } = useWeb3Context()
  const { buyItem, getPrice, sellItem } = useMarketplace()
  const { data: wrappedAssetBalances } = useUserWrappedAssetBalance()
  const { gladiators: userGladiators } = useUserGladiators()
  const { userInventory } = useUserEquipment()
  const items = Object.values(userInventory)
  const prestigeBalance = usePrestigeBalance()
  const { balance: walletPrestigeBalance, depositPrestige, isSending } = useWalletPrestige()
  const { balance: wrappedPTGBalance, unwrap } = useWrappedPTG()
  const { balance: maticBalance } = useMaticBalance()
  const [unwrapping, setUnwrapping] = useState(false)

  const [showGetMorePTG, setShowGetMorePTG] = useState(false)
  const [addressCopied, setAddressCopied] = useState(false)
  const [activeNFTTab, setActiveNFTtab] = useState(0)
  const [selectedItem, setSelectedItem] = useState<
    ItemConfigInterface | undefined
  >(undefined)
  const [selectedGladiator, setSelectedGladiator] = useState<
    MinimalGladiator | undefined
  >(undefined)

  // Modal constrols
  const [showTransferPTGMdoal, setShowTransferPTGModal] = useState(false)
  const [showWrapItemModal, setShowWrapItemModal] = useState(false)
  const [showWrapGladiatorModal, setShowWrapGladiatorModal] = useState(false)
  const [showTransferItemModal, setShowTransferItemModal] = useState(false)
  const [showTransferGladiatorModal, setShowTransferGladiatorModal] =
    useState(false)
  const [showWithdrawGladiatorModal, setShowWithdrawGladiatorModal] =
    useState(false)
  const [showBuyModal, setShowBuyModal] = useState(false)
  const [showSellModal, setShowSellModal] = useState(false)
  const [showBuyPTGModal, setShowBuyPTGModal] = useState(false)
  const [showTransferMaticModal, setShowTransferMaticModal] = useState(false)
  const [showSwapPTGModal, setShowSwapPTGModal] = useState(false)

  const selectItem = (itemId: BigNumber, buy: boolean, transfer?: boolean) => {
    if (!loading && !connected) {
      connect()
      return null
    }
    const item = items.find((item: ItemConfigInterface) => item.id === itemId)
    setSelectedItem(item)

    if (transfer) {
      return setShowTransferItemModal(true)
    }
    if (buy) {
      return setShowBuyModal(true)
    }

    return setShowSellModal(true)
  }

  const onWrapAsset = (assetId: BigNumberish, isItem: boolean) => {
    if (isItem) {
      const item = items.find(
        (item: ItemConfigInterface) => item.id === assetId
      )
      setSelectedItem(item)
      return setShowWrapItemModal(true)
    }

    const gladiator = Object.values(userGladiators!).find(
      (gladiator: MinimalGladiator) => {
        return gladiator.id.toString() === assetId.toString()
      }
    )
    setSelectedGladiator(gladiator)
    return setShowWrapGladiatorModal(true)
  }

  const selectGladiator = (gladiatorId: BigNumberish, withdrawal?: boolean) => {
    if (!loading && !connected) {
      connect()
      return null
    }
    const gladiator = Object.values(userGladiators!).find(
      (gladiator: MinimalGladiator) => gladiator.id === gladiatorId
    )
    setSelectedGladiator(gladiator)

    if (withdrawal) {
      return setShowWithdrawGladiatorModal(true)
    }

    return setShowTransferGladiatorModal(true)
  }

  const ClipURLcopyHandler = () => {
    navigator.clipboard.writeText(safeAddress || '')
    setAddressCopied(true)
  }

  const hasGameWalletMatic = maticBalance?.gt(DUST_AMOUNT)
  const hasGameWalletWPTG = wrappedPTGBalance?.gt(DUST_AMOUNT)
  const hasPTGBalance = prestigeBalance?.gt(DUST_AMOUNT)

  useEffect(() => {
    setTimeout(() => {
      setAddressCopied(false)
    }, 2000)
  }, [addressCopied])

  const unwrapPTG = useCallback(() => {
    if (!wrappedPTGBalance || wrappedPTGBalance.eq(0) || unwrapping) {
      return
    }
    setUnwrapping(true)
    unwrap(wrappedPTGBalance).finally(() => setUnwrapping(false))
  }, [unwrap, setUnwrapping, wrappedPTGBalance, unwrapping])

  const isMiniPTG = useMemo(() => {
    return Number(utils.formatEther(prestigeBalance)) < 1
  }, [prestigeBalance])

  return (
    <>
      <section className="flex flex-wrap items-center justify-between mb-10 text-base">
        <div className="">
          <h2 className=" text-p2 font-gilroy-semibold text-gray-200">
            {isMiniPTG
              ? `mPTG balance  (1 PTG = ${MPTG_PER_PTG} mPTG)`
              : 'Prestige (PTG) balance'}
          </h2>

          <div className="flex flex-wrap items-center mb-4">
            <div className="text-h4 font-gilroy-semibold py-2 flex items-center">
              <img
                src="/images/icons/prestige.svg"
                alt="PTG Icon"
                className="mr-2"
              />
              {humanBigNumber(prestigeBalance.toString(), isMiniPTG)}
            </div>
            <div className="p-4 focus:outline-none bg-gray-900 bg-opacity-80 rounded-12 ml-10 text-p2 font-gilroy-semibold text-primary uppercase">
              <button
                onClick={() => setShowGetMorePTG(true)}
                className="text-center w-full"
              >
                Get more PTG
              </button>
            </div>
          </div>
          <h2 className="text-p4 font-gilroy-semibold text-gray-200 mb-4">
            Game wallet address
          </h2>
          <div className="flex flex-wrap items-center">
            <div className="flex items-center">
              <p className="text-p2 pr-4 font-gilroy-medium lg:block hidden">
                {safeAddress}
              </p>
              <p className=" text-p2 pr-4 lg:hidden">
                {truncateText(safeAddress || '', 15)}
              </p>
              <button
                onClick={ClipURLcopyHandler}
                className="focus:outline-none"
              >
                <Icon
                  icon="content_copy"
                  className="text-primary"
                  fontSize={20}
                />
              </button>
            </div>
          </div>
        </div>
        {hasPTGBalance && (
          <div className="mt-10 lg:mt-0">
            <button
              onClick={() => setShowTransferPTGModal(true)}
              className="flex items-center focus:outline-none bg-gray-900 bg-opacity-80 rounded-12 p-4"
            >
              <img src="/images/icons/transfer.svg" alt="transfer" />
              <p className="text-p2 ml-4 font-gilroy-semibold text-primary uppercase">
                Send PTG
              </p>
            </button>
            <button
              onClick={() => setShowSwapPTGModal(true)}
              className="mt-10 text-center w-full"
            >
              <p className="text-p2 font-gilroy-semibold text-primary uppercase text-center">
                Swap PTG
              </p>
            </button>
          </div>
        )}
      </section>
      <section className="mt-12">
        {(hasGameWalletMatic || hasGameWalletWPTG) && (
          <h2 className="text-p-lg font-gilroy-semibold text-gray-200 mb-4">
            Game wallet balances
          </h2>
        )}
        {hasGameWalletWPTG && (
          <BalanceCard
            name="wPTG"
            symbol="/images/icons/prestige.svg"
            additionalUi={
              <p>wPTG is PTG (an erc1155) wrapped into a standard erc20.</p>
            }
            balance={wrappedPTGBalance}
            actions={[
              {
                label: 'UNWRAP wPTG to PTG',
                primary: true,
                action: unwrapPTG,
                loading: unwrapping
              }
            ]}
          />
        )}
        {hasGameWalletMatic && (
          <BalanceCard
            name="MATIC"
            symbol="/factions/MTC.png"
            balance={maticBalance}
            actions={[
              {
                label: 'Transfer',
                primary: false,
                action: () => {
                  if (maticBalance?.gt(0)) {
                    setShowTransferMaticModal(true)
                  }
                },
                loading: false
              },
              {
                label: 'Swap for PTG',
                primary: true,
                action: () => {
                  if (maticBalance?.gt(0)) {
                    setShowBuyPTGModal(true)
                  }
                },
                loading: false
              }
            ]}
          />
        )}
        {(walletPrestigeBalance?.gt(0)) && (
          <BalanceCard
            name="Wallet PTG"
            symbol="/images/icons/prestige.svg"
            balance={walletPrestigeBalance}
            actions={[
              {
                label: 'Deposit',
                primary: true,
                action: () => {
                  depositPrestige(walletPrestigeBalance)
                },
                loading: isSending
              },
            ]}
          />
        )}
      </section>

      <FreeBetCoinsSection />
      <BundledItems />

      <section className="mt-20">
        <h2 className="text-p-lg font-gilroy-semibold text-gray-200 mb-10">
          NFT items
        </h2>
        <div className="w-full flex justify-between flex-wrap bg-opacity-50 items-center top-36 sticky rounded-full bg-dark py-2 px-6 my-10">
          <div className="flex items-center justify-center lg:justify-start">
            {['items', 'gladiators', 'wrapped'].map((tab, idx) => (
              <TabWithIconButton
                active={activeNFTTab === idx}
                category={tab}
                key={tab}
                image={`/images/symbols/${tab}.svg`}
                onClick={() => setActiveNFTtab(idx)}
              />
            ))}
          </div>
        </div>

        {activeNFTTab === 0 && (
          <ItemsSection
            wrapItem={onWrapAsset}
            items={items}
            selectItem={selectItem}
          />
        )}

        {activeNFTTab === 1 && (
          <UserGladiators
            onTransferGladiator={selectGladiator}
            wrapGladiator={onWrapAsset}
            onWithdrawGladiator={(id: BigNumberish) => {
              return selectGladiator(id, true)
            }}
            gladiators={userGladiators}
          />
        )}

        {activeNFTTab === 2 && (
          <WrappedItemsSection
            ctx={ctx}
            items={wrappedAssetBalances?.items}
            gladiators={wrappedAssetBalances?.gladiators}
          />
        )}
      </section>

      <TransferPTGModal
        show={showTransferPTGMdoal}
        onModalClose={() => setShowTransferPTGModal(false)}
      />

      <TransferMaticModal
        show={showTransferMaticModal}
        onModalClose={() => setShowTransferMaticModal(false)}
      />

      <SwapMaticModal
        show={showBuyPTGModal}
        onModalClose={() => setShowBuyPTGModal(false)}
      />

      <SwapModal
        show={showGetMorePTG}
        onModalClose={() => setShowGetMorePTG(false)}
      />

      <SwapPTGModal
        show={showSwapPTGModal}
        onModalClose={() => setShowSwapPTGModal(false)}
      />

      {selectedGladiator && (
        <TransferGladiatorModal
          gladiator={selectedGladiator}
          ctx={ctx}
          show={showTransferGladiatorModal}
          onModalClose={() => {
            setShowTransferGladiatorModal(false)
            setSelectedGladiator(undefined)
          }}
        />
      )}

      {selectedGladiator && (
        <WithdrawFromGladiatorModal
          gladiator={selectedGladiator}
          show={showWithdrawGladiatorModal}
          onModalClose={() => {
            setShowWithdrawGladiatorModal(false)
            setSelectedGladiator(undefined)
          }}
        />
      )}

      {selectedItem && (
        <TransferItemModal
          item={selectedItem}
          ctx={ctx}
          show={showTransferItemModal}
          onModalClose={() => {
            setShowTransferItemModal(false)
            setSelectedItem(undefined)
          }}
        />
      )}

      {selectedItem && (
        <WrapItemModal
          item={selectedItem}
          safeAddress={ctx.safeAddress}
          show={showWrapItemModal}
          onModalClose={() => {
            setShowWrapItemModal(false)
            setSelectedItem(undefined)
          }}
        />
      )}

      {selectedGladiator && (
        <WrapGladiatorModal
          gladiator={selectedGladiator}
          safeAddress={ctx.safeAddress}
          show={showWrapGladiatorModal}
          onModalClose={() => {
            setShowWrapGladiatorModal(false)
            setSelectedGladiator(undefined)
          }}
        />
      )}

      {selectedItem && !selectedItem.attributes.disableMarketplace && (
        <>
          <BuyItemModal
            item={selectedItem}
            show={showBuyModal}
            onModalClose={() => {
              setShowBuyModal(false)
              setSelectedItem(undefined)
            }}
            getPrice={getPrice}
            buyItem={buyItem}
          />

          <SellItemModal
            item={selectedItem}
            show={showSellModal}
            onModalClose={() => {
              setShowSellModal(false)
              setSelectedItem(undefined)
            }}
            getPrice={getPrice}
            sellItem={sellItem}
          />
        </>
      )}
    </>
  )
}

const WalletPage = () => {
  const ctx = useWeb3Context()
  const { loading: web3Loading, connected } = ctx
  const { loading } = useMarketplace()

  if (web3Loading || loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    )
  }

  if (connected) {
    return (
      <Layout addPadding>
        <div className="w-full  max-w-screen rounded-12 py-24 min-h-full px-6 lg:px-24 bg-dark lg:max-w-1440">
          <WalletContentPage ctx={ctx} />
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="w-full px-8 text-p-lg flex flex-col items-center max-w-1440">
        <h1 className="mb-10 mt-40">Please sign in to continue</h1>
      </div>
    </Layout>
  )
}

export default WalletPage
