import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Spinner from '../../../atoms/Spinner/Spinner'
import GladiatorTitleBanner from '../GladiatorTitleBanner/GladiatorTitleBanner'

interface GladiatorBiographyProps {
  name: string
  faction?: string
  historyUrl: string
}

const GladiatorBiography: React.FC<GladiatorBiographyProps> = ({
  name,
  faction,
  historyUrl
}) => {
  const [gladiatorHistory, setGladiatorHistory] = useState('')
  const [blographyLoading, setBiographyLoading] = useState(true)

  useEffect(() => {
    const doAsync = async () => {
      try {
        const { data } = await axios.get(historyUrl.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'))
        setGladiatorHistory(data)
        setBiographyLoading(false)
      } catch (error) {
        console.error('error getting history', historyUrl)
        try {
          const { data } = await axios.get(historyUrl.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'))
          setGladiatorHistory(data)
          setBiographyLoading(false)
        } catch (err) {
          console.error(error)
          setBiographyLoading(false)
        }
      }
    }
    doAsync()
  }, [historyUrl])

  return (
    <div className="w-full">
      {faction && <GladiatorTitleBanner faction={faction} name={name} />}
      <div className="flex mt-12 flex-wrap lg:flex-no-wrap">
        <div className="w-full lg:w-full mb-16 lg:mb-0">
          {blographyLoading ? (
            <div className=" h-full w-full flex items-center justify-center">
              <Spinner color="white" />
            </div>
          ) : (
            <MarkdownContainer>
              <ReactMarkdown className="text-base leading-10 whitespace-pre-line pr-10">
                {gladiatorHistory}
              </ReactMarkdown>
            </MarkdownContainer>
          )}
        </div>
      </div>
    </div>
  )
}

const MarkdownContainer = styled.div`
  p {
    margin-top: 10px;
  }
`

export type { GladiatorBiographyProps }

export default GladiatorBiography
