import axios from 'axios'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { useGladiator } from '../../../../hooks/useGladiator'
import Modal from '../../../atoms/Modal/Modal'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../../atoms/Spinner/Spinner'
import CustomProgressGroup from '../../../composed/CustomProgressGroup/CustomProgressGroup'

interface GladiatorDetailsModalProps {
  gladiatorId: string
  faction: string
  onClose: () => void
}

const historyFetcher = async (_key: string, url: string) => {
  try {
    const { data } = await axios.get(url.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'))
    return data
  } catch (err) {
    console.error('error fetching history: ', url)
    const { data } = await axios.get(url.replace('siasky.net', 'skynet-backup.s3.fr-par.scw.cloud').replace('fileportal.org', 'skynet-backup.s3.fr-par.scw.cloud'))
    return data
  }
}

const GladiatorDetailsModal: React.FC<GladiatorDetailsModalProps> = ({
  gladiatorId,
  onClose,
  faction
}) => {
  const { gladiator, loading } = useGladiator(gladiatorId)

  const { data: gladiatorHistory } = useSWR(
    () => {
      if (!gladiator) {
        throw new Error('waiting on gladiator')
      }
      return ['/gladiator/history', gladiator.properties.history]
    },
    {
      fetcher: historyFetcher
    }
  )

  return (
    <Modal onModalClose={onClose} showModal={true} width="w-1/2">
      {loading ? (
        <div className="bg-black flex flex-col items-center justify-center py-48">
          <Spinner />
          <p className="text-base mt-8">
            Loading gladiator info, please wait ...
          </p>
        </div>
      ) : (
        <div className="bg-black p-6">
          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="flex flex-col items-center lg:mr-14">
              <SkynetImageFixer
                src={gladiator?.properties.heroimage}
                alt={gladiator?.name}
                className="h-64"
              />
              <Link
                to={`/gladiator/${gladiatorId}?faction=${faction}`}
                className="underline cursor-pointer text-p3 font-neversaydie text-primary"
              >
                View gladiator info {'>>'}
              </Link>
            </div>
            <div className="w-full lg:w-3/5 lg:mr-14">
              <CustomProgressGroup
                stats={[
                  {
                    title: 'Attack',
                    color: '#FFD37E',
                    stat: {
                      value: gladiator?.properties.attack || 0,
                      max: 2000
                    }
                  },
                  {
                    title: 'Defense',
                    color: '#E37171',
                    stat: {
                      value: gladiator?.properties.defense || 0,
                      max: 2000
                    }
                  },
                  {
                    title: 'Hit points',
                    color: '#7694FF',
                    stat: {
                      value: gladiator?.properties.hitpoints || 0,
                      max: 2000
                    }
                  },
                  {
                    title: 'POP',
                    color: '#CD11BA',
                    stat: {
                      value: gladiator?.properties.pop || 0,
                      max: 2000
                    }
                  }
                ]}
              />
            </div>
          </div>
          <div className="flex flex-wrap lg:flex-nowrap ">
            <div className="w-full mt-16 lg:mr-16">
              {gladiatorHistory ? (
                <ReactMarkdown className="text-base whitespace-pre-line pr-10">
                  {gladiatorHistory}
                </ReactMarkdown>
              ) : (
                <div className=" h-full w-full flex items-center justify-center">
                  <Spinner color="white" />
                </div>
              )}
            </div>
            <section className=" mt-16">
              <article className=" mb-10">
                <h4 className="text-p4 font-gilroy-semibold text-primary mb-3 uppercase">
                  special move
                </h4>
                <p className="font-vermin font-bold text-p1 tracking-wide">
                  {gladiator?.properties.specialMove}
                </p>
              </article>

              <article>
                <h4 className="text-p4 font-gilroy-semibold text-primary mb-3 uppercase">
                  OTHER ATTRIBUTES:
                </h4>
                <ul>
                  {gladiator?.properties.attributes.map((attribute) => (
                    <li
                      key={attribute}
                      className="text-p1 mb-4 font-gilroy-medium"
                    >
                      {attribute}
                    </li>
                  ))}
                </ul>
              </article>
            </section>
          </div>
        </div>
      )}
    </Modal>
  )
}

export type { GladiatorDetailsModalProps }
export default GladiatorDetailsModal
