import { BigNumber, BigNumberish, constants, utils } from 'ethers'
import { Relayer } from 'kasumah-relay-wrapper/dist/src/relayers'
import { Address } from 'kasumah-wallet'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import PlayerInterface from '../interfaces/Player.interface'
import gameChain, { Contracts } from '../models/GameChain'
import waitForTx from '../utils/waitForTx'
import { USER_PRESTIGE_KEY } from './usePrestigeBalance'
import { useSubscriber } from './useSubscription'
import { useTournament, useTournamentList } from './useTournament'
import { isV4, tournamentContractForTournament } from '../utils/isTournamentV4'
import { BetLogItem } from '../interfaces/BetLogItem.interface'

export const USER_BET_KEY = '/bets/user/tournament/gladiator'
export const BETS_BY_TOURNAMENT = '/bets/tournament'
const EXPECTED_WINNING = '/bets/tournament/expected/gladiator'

interface MakeBetProps {
  gladiatorId: BigNumberish
  tournamentId: string
  amount: string
  expectedTotalBet: BigNumber
}

export function localMutateBets(
  tournamentId: string,
  gladiatorId: BigNumberish,
  amount: BigNumber
) {
  const gladiatorHex = BigNumber.from(gladiatorId).toHexString()
  mutate(
    [USER_BET_KEY, tournamentId],
    (bets: ReturnType<typeof useUserBetAmounts>['userBets']) => {
      console.log('mutate existing bets: ', bets)
      const existingBets = bets || {
        tournament: constants.Zero
      }
      return {
        ...existingBets,
        [gladiatorHex]: (existingBets[gladiatorHex] || constants.Zero).add(
          amount
        ),
        tournament: existingBets.tournament.add(amount)
      }
    }
  )
  mutate([BETS_BY_TOURNAMENT, tournamentId], (bets?: TournamentBetAmounts) => {
    console.log('mutate BETS_BY_TOURNAMENT', bets)
    return {
      ...(bets || {}),
      tournament: (bets?.tournament || constants.Zero).add(amount),
      [gladiatorHex]: (
        (bets || ({} as Record<string, BigNumber>))[gladiatorHex] ||
        constants.Zero
      ).add(amount)
    }
  })
}

const _makeBetV4 = async (
  relayer: Relayer,
  contracts: Promise<Contracts>,
  safeAddress: Address,
  prestigePromise: Promise<BigNumber>,
  { gladiatorId, tournamentId, amount }: MakeBetProps
) => {
  try {
    const {
      assets,
      bettingPool,
      bettingLogger,
      betChecker,
      kasumahUintLogger,
      kasumahValueLogger
    } = await contracts
    const prestigeID = await prestigePromise
    const bet = utils.defaultAbiCoder.encode(
      ['uint256', 'uint256'],
      [tournamentId, gladiatorId]
    )
    const bettingAmount = utils.parseEther(amount)

    const betTx = await assets.populateTransaction.safeTransferFrom(
      safeAddress,
      bettingPool.address,
      prestigeID,
      bettingAmount,
      bet
    )
    const logTx = await bettingLogger.populateTransaction.add(tournamentId)
    const userLog1 = await kasumahUintLogger.populateTransaction.add(
      safeAddress,
      '/betting/tournaments',
      tournamentId
    )
    const userLog2 = await kasumahUintLogger.populateTransaction.add(
      safeAddress,
      `/betting/tournaments/${tournamentId}`,
      gladiatorId
    )
    const userLog3 = await kasumahValueLogger.populateTransaction.set(
      safeAddress,
      `/betting/tournaments/${tournamentId}/${gladiatorId}`,
      bettingAmount.toHexString()
    )

    const checkerTx = await betChecker.populateTransaction.checkBet(
      bettingPool.address,
      tournamentId,
      safeAddress,
      gladiatorId,
      bettingAmount
    )

    const tx = await waitForTx(
      relayer.multisend([betTx, checkerTx, logTx, userLog1, userLog2, userLog3])
    )

    console.log('makeBet receipt: ', tx)
    localMutateBets(tournamentId, gladiatorId, bettingAmount)
    return true
  } catch (error) {
    console.error('error betting ', error)
    throw error
  }
}

const _makeBetV5 = async (
  relayer: Relayer,
  contracts: Promise<Contracts>,
  safeAddress: Address,
  prestigePromise: Promise<BigNumber>,
  { gladiatorId, tournamentId, amount, expectedTotalBet }: MakeBetProps
) => {
  try {
    const {
      assets,
      bettingPoolV6,
      bettingLogger,
      kasumahUintLogger,
      kasumahValueLogger
    } = await contracts
    const prestigeID = await prestigePromise
    const bettingAmount = utils.parseEther(amount)

    const bet = utils.defaultAbiCoder.encode(
      ['address', 'uint256', 'uint256', 'uint256'],
      [safeAddress, tournamentId, gladiatorId, expectedTotalBet]
    )

    const betTx = await assets.populateTransaction.safeTransferFrom(
      safeAddress,
      bettingPoolV6.address,
      prestigeID,
      bettingAmount,
      bet
    )
    const logTx = await bettingLogger.populateTransaction.add(tournamentId)
    const userLog1 = await kasumahUintLogger.populateTransaction.add(
      safeAddress,
      '/betting/tournaments',
      tournamentId
    )
    const userLog2 = await kasumahUintLogger.populateTransaction.add(
      safeAddress,
      `/betting/tournaments/${tournamentId}`,
      gladiatorId
    )
    const userLog3 = await kasumahValueLogger.populateTransaction.set(
      safeAddress,
      `/betting/tournaments/${tournamentId}/${gladiatorId}`,
      bettingAmount.toHexString()
    )

    const tx = await waitForTx(
      relayer.multisend([betTx, logTx, userLog1, userLog2, userLog3])
    )

    console.log('makeBet receipt: ', tx)
    localMutateBets(tournamentId, gladiatorId, bettingAmount)
    return true
  } catch (error) {
    console.error('error betting ', error)
    throw error
  }
}

const _makeBet = async (
  relayer: Relayer,
  contracts: Promise<Contracts>,
  safeAddress: Address,
  prestigePromise: Promise<BigNumber>,
  props: MakeBetProps
) => {
  if (isV4(props.tournamentId)) {
    return _makeBetV4(relayer, contracts, safeAddress, prestigePromise, props)
  }
  return _makeBetV5(relayer, contracts, safeAddress, prestigePromise, props)
}

const expectedWinningsFetcher = async (
  _key: string,
  tournamentId: string,
  gladiatorId: BigNumberish
) => {
  if (!gameChain.safeAddress) {
    console.error('unexpected error, no safe address for useExpectedWinnings')
    throw new Error('UnexpectedError: no safeAddress for expectedWinnings')
  }

  if (!gladiatorId) {
    return constants.Zero
  }

  const bettingPool = await bettingPoolForTournament(
    tournamentId,
    gameChain.contracts
  )

  return bettingPool.expectedWinnings(
    tournamentId,
    gameChain.safeAddress,
    gladiatorId
  )
}

export const useExpectedWinnings = (
  tournamentId: string,
  champion?: PlayerInterface
) => {
  const { safeAddress } = useWeb3Context()

  const { data, isValidating } = useSWR(
    () => {
      if (!safeAddress) {
        throw new Error('Need a user to get expected winnings')
      }
      return [EXPECTED_WINNING, tournamentId, champion?.id]
    },
    {
      fetcher: expectedWinningsFetcher
    }
  )

  return { expectedWinning: data, loading: isValidating || !data }
}

export const useUserBetAmounts = (tournamentId: string) => {
  const { contracts, connected } = useWeb3Context()

  const { tournament, loading } = useTournament(tournamentId)

  const fetcher = async (
    _key: string,
    tournamentId: string
  ): Promise<TournamentBetAmounts> => {
    const { safeAddress } = gameChain
    if (!safeAddress) {
      return { tournament: BigNumber.from(0) }
    }
    const bettingPool = await bettingPoolForTournament(tournamentId, contracts)
    const { gladiators } = tournament
    const amounts = await Promise.all(
      gladiators.map(async (gladiator) => {
        return bettingPool.betsByUser(tournamentId, safeAddress, gladiator.id)
      })
    )

    const bets = gladiators.reduce(
      (memo: Record<string, BigNumber>, gladiator, i) => {
        return {
          ...memo,
          [BigNumber.from(gladiator.id).toHexString()]: amounts[i]
        }
      },
      {}
    )

    return {
      ...bets,
      tournament: amounts.reduce(
        (cnt: BigNumber, amnt) => cnt.add(amnt),
        constants.Zero
      )
    }
  }

  const swr = useSWR(
    () => {
      if (loading || !tournament) {
        throw new Error('waiting for tournament to load')
      }
      return [USER_BET_KEY, tournamentId]
    },
    {
      fetcher
    }
  )

  useEffect(() => {
    if (connected) {
      swr.revalidate()
    }
  }, [connected, swr])

  return {
    userBets: swr.data,
    loading: !swr.data,
    updating: swr.isValidating,
    revalidate: swr.revalidate
  }
}

export type TournamentBetAmounts = { tournament: BigNumber } & Record<
  string,
  BigNumber
>

const useBetsByTournamentFetcher = async (
  _key: string,
  tournamentId: BigNumberish
): Promise<TournamentBetAmounts> => {
  try {
    const bettingPool = await bettingPoolForTournament(
      tournamentId,
      gameChain.contracts
    )
    const tournament = await tournamentContractForTournament(
      tournamentId,
      gameChain.contracts
    )

    const regs = await tournament.registrations(tournamentId)

    const gladiatorIds = regs.map((reg) => reg.gladiator)

    const gladReqs = regs.map(async (reg) => {
      return bettingPool.betsByGladiator(tournamentId, reg.gladiator)
    })

    const [tournamentAmt, incentive, ...gladiatorAmts] = await Promise.all([
      bettingPool.betsByTournament(tournamentId),
      bettingPool.incentivesByTournament(tournamentId),
      ...gladReqs
    ])

    const gladiatorAmtsById = gladiatorAmts.reduce(
      (memo: Record<string, BigNumber>, amount, i) => {
        memo[BigNumber.from(gladiatorIds[i]).toHexString()] = amount // eslint-disable-line no-param-reassign
        return memo
      },
      {}
    )

    return {
      tournament: tournamentAmt,
      incentive,
      ...gladiatorAmtsById
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const useBetsByTournament = (tournamentId: BigNumberish) => {
  const { contracts } = useWeb3Context()
  const { subscribe } = useSubscriber()

  const { data, revalidate } = useSWR([BETS_BY_TOURNAMENT, tournamentId], {
    fetcher: useBetsByTournamentFetcher,
    revalidateOnMount: true,
    onSuccess: (_, key) => {
      subscribe(key, 'betsByTournament', async () => {
        const bettingPool = await bettingPoolForTournament(
          tournamentId,
          contracts
        )
        const filter = bettingPool.filters.BetPlaced(
          null,
          BigNumber.from(tournamentId),
          null
        )
        const cb = () => {
          mutate(key)
        }
        return [bettingPool, filter, cb]
      })
    }
  })

  const initialData: TournamentBetAmounts = useMemo(() => {
    return {
      tournament: BigNumber.from(0),
      incentive: BigNumber.from(0)
    }
  }, [])

  return {
    betAmounts: data || initialData,
    loading: !data,
    revalidate
  }
}

export async function bettingPoolForTournament(
  tournamentId: BigNumberish,
  contracts: Promise<Contracts>
) {
  const { bettingPool, bettingPoolV6 } = await contracts
  if (isV4(tournamentId)) {
    return bettingPool
  }
  return bettingPoolV6
}

export const useClaimBet = () => {
  const { contracts, safeAddress } = useWeb3Context()

  const claimBet = async (tournamentId: BigNumberish) => {
    if (!safeAddress) {
      throw new Error('Withdrawing when no safe address')
    }
    const bettingPool = await bettingPoolForTournament(tournamentId, contracts)

    const tx = await bettingPool.withdraw(tournamentId)
    await tx.wait()

    mutate([USER_BET_KEY, tournamentId])
    mutate(USER_PRESTIGE_KEY)
  }

  return {
    claimBet
  }
}

export const useMakeBet = () => {
  // this is a bit hacky, but doing useWeb3Context doesn't really work here as the contracts and safeAddress get bound incorrectly
  // and never update... so we use the gameChain directly
  const makeBet = useCallback(async (bet: MakeBetProps) => {
    console.log('recalculating make bet')
    const { contracts, prestigeID, safeAddress, relayer } = gameChain

    if (!safeAddress) {
      throw new Error('betting when no safe address')
    }

    if (!relayer) {
      throw new Error('betting when no relayer')
    }
    return _makeBet(relayer, contracts, safeAddress, prestigeID, bet)
  }, [])

  return {
    makeBet
  }
}

export const useBettingLog = () => {
  const { safeAddress, contracts } = useWeb3Context()
  const { tournaments } = useTournamentList()

  const { data, revalidate, isValidating } = useSWR(
    () => {
      try {
        if (!safeAddress) {
          throw new Error('no safe address')
        }
        if (!tournaments || tournaments.length === 0) {
          throw new Error('no tournaments')
        }
        return ['/beting-log', safeAddress]
      } catch (err) {
        console.error('error useBettingLog')
        throw err
      }
    },
    {
      fetcher: async (_, safeAddress) => {
        try {
          const {
            bettingLogger,
            kasumahValueLogger,
            kasumahUintLogger,
            gladiator
          } = await contracts
          const promises = tournaments.map(
            async (tournament): Promise<null | BetLogItem[]> => {
              const bettingPool = await bettingPoolForTournament(
                tournament.id,
                contracts
              )
              const tournamentContract = await tournamentContractForTournament(
                tournament.id,
                contracts
              )

              const bettors = (await bettingLogger.allFor(tournament.id)).map(
                (addr) => addr.toLowerCase()
              )
              if (!bettors.includes(safeAddress.toLowerCase())) {
                console.log('bettors not include ', safeAddress.toLowerCase())
                return null
              }

              const betGladiators = await kasumahUintLogger.all(
                safeAddress,
                `/betting/tournaments/${tournament.id}`
              )
              console.log('bet gladiators: ', betGladiators)
              const tournamentIsFinished = BigNumber.from(
                tournament.lastRoll
              ).gt(0)

              const champion = tournamentIsFinished
                ? await tournamentContract.getChampion(tournament.id)
                : undefined

              const expectedWinnings = tournamentIsFinished
                ? await bettingPool.expectedWinnings(
                    tournament.id,
                    safeAddress,
                    champion!.gladiator
                  )
                : constants.Zero

              if (betGladiators.length === 0) {
                return [
                  {
                    tournamentId: BigNumber.from(tournament.id),
                    date: DateTime.fromMillis(
                      tournament.notBefore.toNumber() * 1000
                    ),
                    gladiatorId: BigNumber.from(-1),
                    tournamentName: tournament.name,
                    gladiatorName: '',
                    amount: constants.Zero,
                    expectedWinnings,
                    champion
                  }
                ]
              }

              return Promise.all(
                betGladiators.map(async (gladiatorId) => {
                  const [name, amountHex] = await Promise.all([
                    gladiator.name(gladiatorId),
                    kasumahValueLogger.latest(
                      safeAddress,
                      `/betting/tournaments/${tournament.id}/${gladiatorId}`
                    )
                  ])
                  return {
                    tournamentId: BigNumber.from(tournament.id),
                    date: DateTime.fromMillis(
                      tournament.notBefore.toNumber() * 1000
                    ),
                    gladiatorId,
                    tournamentName: tournament.name,
                    gladiatorName: name,
                    amount: BigNumber.from(amountHex),
                    expectedWinnings,
                    champion
                  }
                })
              )
            }
          )
          const bets = (await Promise.all(promises))
            .filter(Boolean)
            .flat()
            .reverse() as any as BetLogItem[]
          console.log('bets: ', bets)
          return bets
        } catch (err) {
          console.error('error fetching: ', err)
          throw err
        }
      }
    }
  )

  return {
    bets: data,
    loading: !data,
    revalidate,
    updating: isValidating
  }
}
