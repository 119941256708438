/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IGameLogicV4, IGameLogicV4Interface } from "../IGameLogicV4";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
      {
        internalType: "int256",
        name: "lastRoll",
        type: "int256",
      },
    ],
    name: "bracket",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint256",
                name: "id",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "tournamentId",
                type: "uint256",
              },
              {
                internalType: "bool",
                name: "decided",
                type: "bool",
              },
              {
                internalType: "uint8",
                name: "winner",
                type: "uint8",
              },
              {
                internalType: "uint256",
                name: "firstRoll",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastRoll",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "string",
                    name: "name",
                    type: "string",
                  },
                  {
                    internalType: "uint256",
                    name: "id",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "registrationId",
                    type: "uint256",
                  },
                  {
                    internalType: "int256",
                    name: "hitPoints",
                    type: "int256",
                  },
                  {
                    internalType: "uint256",
                    name: "attack",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "defense",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "faction",
                    type: "uint256",
                  },
                  {
                    components: [
                      {
                        internalType: "uint256",
                        name: "id",
                        type: "uint256",
                      },
                      {
                        internalType: "string",
                        name: "name",
                        type: "string",
                      },
                      {
                        internalType: "int256",
                        name: "hitPoints",
                        type: "int256",
                      },
                      {
                        internalType: "int256",
                        name: "attack",
                        type: "int256",
                      },
                      {
                        internalType: "int256",
                        name: "defense",
                        type: "int256",
                      },
                      {
                        internalType: "uint256",
                        name: "percentChanceOfUse",
                        type: "uint256",
                      },
                      {
                        internalType: "uint256",
                        name: "numberOfUsesPerGame",
                        type: "uint256",
                      },
                      {
                        internalType: "bytes32",
                        name: "createdAt",
                        type: "bytes32",
                      },
                    ],
                    internalType: "struct IGameEquipment.EquipmentMetadata[]",
                    name: "equipment",
                    type: "tuple[]",
                  },
                  {
                    internalType: "uint256[]",
                    name: "equipmentUses",
                    type: "uint256[]",
                  },
                ],
                internalType: "struct ITournamentV4.GameGladiator[]",
                name: "players",
                type: "tuple[]",
              },
            ],
            internalType: "struct ITournamentV4.Game[]",
            name: "games",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "firstRoll",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastRoll",
            type: "uint256",
          },
        ],
        internalType: "struct ITournamentV4.Round[]",
        name: "rounds",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "tournamentId",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "decided",
            type: "bool",
          },
          {
            internalType: "uint8",
            name: "winner",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "firstRoll",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastRoll",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "string",
                name: "name",
                type: "string",
              },
              {
                internalType: "uint256",
                name: "id",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "registrationId",
                type: "uint256",
              },
              {
                internalType: "int256",
                name: "hitPoints",
                type: "int256",
              },
              {
                internalType: "uint256",
                name: "attack",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "defense",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "faction",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "uint256",
                    name: "id",
                    type: "uint256",
                  },
                  {
                    internalType: "string",
                    name: "name",
                    type: "string",
                  },
                  {
                    internalType: "int256",
                    name: "hitPoints",
                    type: "int256",
                  },
                  {
                    internalType: "int256",
                    name: "attack",
                    type: "int256",
                  },
                  {
                    internalType: "int256",
                    name: "defense",
                    type: "int256",
                  },
                  {
                    internalType: "uint256",
                    name: "percentChanceOfUse",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "numberOfUsesPerGame",
                    type: "uint256",
                  },
                  {
                    internalType: "bytes32",
                    name: "createdAt",
                    type: "bytes32",
                  },
                ],
                internalType: "struct IGameEquipment.EquipmentMetadata[]",
                name: "equipment",
                type: "tuple[]",
              },
              {
                internalType: "uint256[]",
                name: "equipmentUses",
                type: "uint256[]",
              },
            ],
            internalType: "struct ITournamentV4.GameGladiator[]",
            name: "players",
            type: "tuple[]",
          },
        ],
        internalType: "struct ITournamentV4.Game",
        name: "game",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "random",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
              },
              {
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            internalType: "struct IDiceRolls.PerformancePair[]",
            name: "performances",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "blockNumber",
            type: "uint256",
          },
        ],
        internalType: "struct IDiceRolls.RollParams[]",
        name: "rolls",
        type: "tuple[]",
      },
    ],
    name: "roll",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "tournamentId",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "decided",
            type: "bool",
          },
          {
            internalType: "uint8",
            name: "winner",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "firstRoll",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastRoll",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "string",
                name: "name",
                type: "string",
              },
              {
                internalType: "uint256",
                name: "id",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "registrationId",
                type: "uint256",
              },
              {
                internalType: "int256",
                name: "hitPoints",
                type: "int256",
              },
              {
                internalType: "uint256",
                name: "attack",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "defense",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "faction",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "uint256",
                    name: "id",
                    type: "uint256",
                  },
                  {
                    internalType: "string",
                    name: "name",
                    type: "string",
                  },
                  {
                    internalType: "int256",
                    name: "hitPoints",
                    type: "int256",
                  },
                  {
                    internalType: "int256",
                    name: "attack",
                    type: "int256",
                  },
                  {
                    internalType: "int256",
                    name: "defense",
                    type: "int256",
                  },
                  {
                    internalType: "uint256",
                    name: "percentChanceOfUse",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "numberOfUsesPerGame",
                    type: "uint256",
                  },
                  {
                    internalType: "bytes32",
                    name: "createdAt",
                    type: "bytes32",
                  },
                ],
                internalType: "struct IGameEquipment.EquipmentMetadata[]",
                name: "equipment",
                type: "tuple[]",
              },
              {
                internalType: "uint256[]",
                name: "equipmentUses",
                type: "uint256[]",
              },
            ],
            internalType: "struct ITournamentV4.GameGladiator[]",
            name: "players",
            type: "tuple[]",
          },
        ],
        internalType: "struct ITournamentV4.Game",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "registrationId",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "hitPoints",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "attack",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "defense",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "faction",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "id",
                type: "uint256",
              },
              {
                internalType: "string",
                name: "name",
                type: "string",
              },
              {
                internalType: "int256",
                name: "hitPoints",
                type: "int256",
              },
              {
                internalType: "int256",
                name: "attack",
                type: "int256",
              },
              {
                internalType: "int256",
                name: "defense",
                type: "int256",
              },
              {
                internalType: "uint256",
                name: "percentChanceOfUse",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "numberOfUsesPerGame",
                type: "uint256",
              },
              {
                internalType: "bytes32",
                name: "createdAt",
                type: "bytes32",
              },
            ],
            internalType: "struct IGameEquipment.EquipmentMetadata[]",
            name: "equipment",
            type: "tuple[]",
          },
          {
            internalType: "uint256[]",
            name: "equipmentUses",
            type: "uint256[]",
          },
        ],
        internalType: "struct ITournamentV4.GameGladiator[]",
        name: "gladiators",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "random",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
              },
              {
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            internalType: "struct IDiceRolls.PerformancePair[]",
            name: "performances",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "blockNumber",
            type: "uint256",
          },
        ],
        internalType: "struct IDiceRolls.RollParams[]",
        name: "rolls",
        type: "tuple[]",
      },
    ],
    name: "tournament",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "uint256",
                name: "id",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "tournamentId",
                type: "uint256",
              },
              {
                internalType: "bool",
                name: "decided",
                type: "bool",
              },
              {
                internalType: "uint8",
                name: "winner",
                type: "uint8",
              },
              {
                internalType: "uint256",
                name: "firstRoll",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "lastRoll",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "string",
                    name: "name",
                    type: "string",
                  },
                  {
                    internalType: "uint256",
                    name: "id",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "registrationId",
                    type: "uint256",
                  },
                  {
                    internalType: "int256",
                    name: "hitPoints",
                    type: "int256",
                  },
                  {
                    internalType: "uint256",
                    name: "attack",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "defense",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "faction",
                    type: "uint256",
                  },
                  {
                    components: [
                      {
                        internalType: "uint256",
                        name: "id",
                        type: "uint256",
                      },
                      {
                        internalType: "string",
                        name: "name",
                        type: "string",
                      },
                      {
                        internalType: "int256",
                        name: "hitPoints",
                        type: "int256",
                      },
                      {
                        internalType: "int256",
                        name: "attack",
                        type: "int256",
                      },
                      {
                        internalType: "int256",
                        name: "defense",
                        type: "int256",
                      },
                      {
                        internalType: "uint256",
                        name: "percentChanceOfUse",
                        type: "uint256",
                      },
                      {
                        internalType: "uint256",
                        name: "numberOfUsesPerGame",
                        type: "uint256",
                      },
                      {
                        internalType: "bytes32",
                        name: "createdAt",
                        type: "bytes32",
                      },
                    ],
                    internalType: "struct IGameEquipment.EquipmentMetadata[]",
                    name: "equipment",
                    type: "tuple[]",
                  },
                  {
                    internalType: "uint256[]",
                    name: "equipmentUses",
                    type: "uint256[]",
                  },
                ],
                internalType: "struct ITournamentV4.GameGladiator[]",
                name: "players",
                type: "tuple[]",
              },
            ],
            internalType: "struct ITournamentV4.Game[]",
            name: "games",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "firstRoll",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastRoll",
            type: "uint256",
          },
        ],
        internalType: "struct ITournamentV4.Round[]",
        name: "rounds",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tournamentId",
        type: "uint256",
      },
    ],
    name: "tournamentWinner",
    outputs: [
      {
        internalType: "uint256",
        name: "registrationId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastRoll",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IGameLogicV4__factory {
  static readonly abi = _abi;
  static createInterface(): IGameLogicV4Interface {
    return new utils.Interface(_abi) as IGameLogicV4Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IGameLogicV4 {
    return new Contract(address, _abi, signerOrProvider) as IGameLogicV4;
  }
}
