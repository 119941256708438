import styled from 'styled-components'
import React from 'react'

const DiscordBanner = () => {
  return (
    <Container className=" bg-dark-purple fixed bottom-0 w-full flex justify-center items-center px-4 lg:px-28 py-6">
      <img
        className="hidden lg:block"
        src="/images/icons/discord-dark.svg"
        alt="discord"
      />
      <img
        className="lg:hidden"
        src="/images/icons/discord-icon.svg"
        alt="discord"
      />
      <p className="hidden lg:block text-p3 font-semibold ml-8">
        Join us for cheering, mini games, loot boxes, etc on discord.
      </p>
      <p className="lg:hidden text-p3 mr-4 ml-8">Join action on Discord.</p>
      &nbsp;
      <a
        className="hidden lg:inline-block underline text-p3 text-primary font-bold  join-button"
        href="https://discord.gg/Z2S3EtQKCn"
        target="_blank"
        rel="noreferrer"
      >
        Click here to join
      </a>
      <a
        className="lg:hidden underline text-p3 text-primary font-bold  join-button"
        href="https://discord.gg/Z2S3EtQKCn"
        target="_blank"
        rel="noreferrer"
      >
        Join
      </a>
    </Container>
  )
}

const Container = styled.div`
  z-index: 99998;
`

export default DiscordBanner
