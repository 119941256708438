import React from 'react'
import classNames from 'classnames'
import getItemRarityColor, {
  TypeName
} from '../../../../utils/getItemRarityColor'
import Button from '../../../atoms/Button/Button'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import fixer from '../../../../utils/skynetUrlFixer'

interface WalletItemsCardProps {
  item: ItemConfigInterface
  viewItem: (buying: boolean, transfer?: boolean, wrap?: boolean) => void
  wrapItem: () => void
}

const WalletItemsCard: React.FC<WalletItemsCardProps> = ({
  item,
  viewItem,
  wrapItem
}) => {
  return (
    <div className="flex items-center flex-wrap lg:flex-nowrap justify-between bg-gray-800 bg-opacity-30 px-4 lg:px-12 py-10 mb-4 rounded-12">
      <img
        src={fixer(item.image)}
        alt="item"
        className="h-24 w-24 object-contain lg:mr-10"
      />
      <div className="flex-grow lg:mr-10">
        <div className="flex items-center">
          <h3 className="mr-4 text-p2 italic">{item.name}</h3>
          <span
            className={classNames(
              'inline-block rounded-full text-white text-p4 uppercase font-gilroy-semibold px-4 py-1',
              getItemRarityColor(item.attributes.rarity as TypeName)
            )}
          >
            {item.attributes.rarity}
          </span>
        </div>
        <p className="flex uppercase my-6 text-gray-400 font-gilroy-semibold text-p4 tracking-wide">
          {item.attributes.description}
        </p>
        <div className="flex mb-5 mt-8 lg:mt-0">
          <p className="flex uppercase text-gray-400 font-gilroy-semibold text-p4 tracking-wide">
            Quantity owned
          </p>
          <div className="px-3 bg-primary rounded-full ml-2 flex items-center justify-center">
            <span className=" text-p3 font-gilroy-semibold ">
              {item.balance.toNumber()}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center lg:w-1/2 flex-wrap lg:flex-nowrap  justify-between lg:justify-end">
        <Button
          background="bg-dark"
          className="border border-gray-400 hidden lg:flex mr-8"
          color="text-white"
          rounded
          onClick={wrapItem}
        >
          Wrap
        </Button>
        <Button
          background="bg-dark"
          className="border border-primary hidden lg:flex"
          color="text-primary"
          rounded
          onClick={() => viewItem(false, true)}
        >
          Transfer
        </Button>

        <p className=" text-h5 text-gray-600 font-thin hidden lg:block mx-8">
          .|.
        </p>

        {!item.attributes.disableMarketplace && (
          <>
            <Button
              rounded
              className="  bg-opacity-60"
              color="text-white"
              background="bg-theme-dark-gray"
              onClick={() => viewItem(true)}
            >
              Buy more
            </Button>
            <Button
              rounded
              className=" font-bold ml-8 bg-opacity-60 border border-primary"
              background="bg-theme-dark-gray"
              onClick={() => viewItem(false)}
            >
              Sell item
            </Button>
          </>
        )}

        <div className="flex justify-center pt-10 w-full lg:hidden">
          <Button
            background="bg-dark"
            className="border border-primary"
            color="text-primary"
            rounded
            onClick={() => viewItem(false, true)}
          >
            Transfer item
          </Button>
        </div>
      </div>
    </div>
  )
}

export type { WalletItemsCardProps }
export default WalletItemsCard
