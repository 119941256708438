import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

interface GladiatorHealthBarProps {
  home: boolean
  name: string
  initialHP: number
  currentHP: number
  image: string
}

interface GladiatorImageProps {
  home: boolean
}

const GladiatorHealthBar: React.FC<GladiatorHealthBarProps> = ({
  home,
  name,
  initialHP,
  currentHP,
  image
}) => {
  return (
    <Container
      className={classNames(
        'flex items-center',
        home ? 'justify-start' : 'justify-end'
      )}
    >
      {home && (
        <GladiatorImage home={home}>
          <div>
            <SkynetImageFixer src={image} alt={name} />
          </div>
        </GladiatorImage>
      )}

      <div>
        <div className="flex w-full">
          <GladiatorName className="text-primary">{name}</GladiatorName>
        </div>
        <div
          className="relative w-full"
          style={{
            transform: home ? 'rotateY(180deg)' : ''
          }}
        >
          <img src="/images/symbols/healthbar.svg" alt="halthbar" />
          <div className="flex items-center">
            <p
              className="font-gilroy-semibold text-p2"
              style={{
                transform: home ? 'rotateY(180deg)' : ''
              }}
            >
              {currentHP} / {initialHP}
            </p>

            <CustomProgressBar home={home}>
              <progress max={initialHP} value={currentHP} />
            </CustomProgressBar>
          </div>
        </div>
      </div>

      {!home && (
        <GladiatorImage home={home}>
          <div>
            <SkynetImageFixer src={image} alt={name} />
          </div>
        </GladiatorImage>
      )}
    </Container>
  )
}

const Container = styled.div`
  min-width: 30%;
`

const CustomProgressBar = styled.div<GladiatorImageProps>`
  position: absolute;
  top: 10px;
  left: 6px;
  width: 90%;
  transform: rotateY(180deg);
  progress {
    width: 100%;
    ::-webkit-progress-bar {
      background: linear-gradient(
        270.06deg,
        #2a1e54 75.19%,
        rgba(42, 30, 84, 0) 95.44%
      );
      height: 29.07px;
    }
    ::-webkit-progress-value {
      background: linear-gradient(
        269.96deg,
        #7f5dfa 75.18%,
        rgba(0, 0, 0, 0) 93.98%
      );
      height: 29.07px;
    }
  }

  @media screen and (max-width: 767px) {
    top: 5px;

    progress {
      width: 100%;
      ::-webkit-progress-bar {
        background: linear-gradient(
          270.06deg,
          #2a1e54 75.19%,
          rgba(42, 30, 84, 0) 95.44%
        );
        height: 24.07px;
      }
      ::-webkit-progress-value {
        background: linear-gradient(
          269.96deg,
          #7f5dfa 75.18%,
          rgba(0, 0, 0, 0) 93.98%
        );
        height: 24.07px;
      }
    }
  }
`

const GladiatorName = styled.p`
  font-family: NeverSayDie;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: right;

  z-index: 20;
`

const GladiatorImage = styled.div<GladiatorImageProps>`
  height: 74px;
  width: 74px;
  border-radius: 0px;
  transform: rotate(45deg);
  margin: ${(props) => {
    return props.home ? '-16px -10px 0px 0px' : '-16px 0px 0px -10px'
  }};

  > div {
    border: 4px solid rgba(127, 92, 255, 1);
  }

  img {
    min-height: 74px;
    min-width: 74px;
    transform: rotate(-45deg);
  }
`

export type { GladiatorHealthBarProps }
export default GladiatorHealthBar
