import React, { FormEventHandler, useState } from 'react'
import { Web3ContextData } from '../../../../contexts/Web3Context'
import { MinimalGladiator } from '../../../../hooks/useGladiator'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../../atoms/Spinner/Spinner'

interface TransferGladiatorModalProps {
  show: boolean
  onModalClose: () => void
  ctx: Web3ContextData
  gladiator: MinimalGladiator
}

const TransferGladiatorModal: React.FC<TransferGladiatorModalProps> = ({
  show,
  onModalClose,
  ctx: { contracts, safeAddress },
  gladiator
}) => {
  const [recieverAddress, setReceiverAddress] = useState('')
  const [transferLoading, setTransferLoading] = useState(false)

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    try {
      setTransferLoading(true)
      const { assets } = await contracts
      if (!safeAddress || !gladiator.id) return

      const transfer = await assets.safeTransferFrom(
        safeAddress,
        recieverAddress,
        gladiator.id,
        1,
        []
      )

      await transfer.wait()
      setTransferLoading(false)
      onModalClose()
      setReceiverAddress('')
    } catch (error) {
      setTransferLoading(false)
      throw error
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Transfer Gladiator
        </legend>

        {transferLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <SkynetImageFixer className="rounded-full" src={gladiator.image} alt="ptg" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">
                Transferring gladiator, please wait ...
              </p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-8">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  Transfer
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center mr-3">
                    <div className="bg-dark w-16 h-16 rounded-full flex items-center justify-center">
                      <SkynetImageFixer
                        className="w-12"
                        src={gladiator.image}
                        alt="prestige"
                      />
                    </div>
                    <p className=" px-5 text-p1 text-gray-200">
                      {gladiator.name}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
              <p className="text-p3 text-gray-300 font-gilroy-medium mb-4">
                Enter Recipient’s Wallet Address here
              </p>
              <input
                required
                className=" bg-transparent focus:outline-none text-p3 font-gilroy-medium "
                placeholder="Click here to enter address"
                value={recieverAddress}
                onChange={(e) => setReceiverAddress(e.target.value)}
              />
            </section>

            <div className="mt-8 flex justify-center">
              <Button
                rounded
                className="font-bold uppercase"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Transfer gladiator
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { TransferGladiatorModalProps }
export default TransferGladiatorModal
