import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface BlockedHitProps {
  name: string
  home: boolean
}

const HitInfo: React.FC<BlockedHitProps> = ({ name, home }) => {
  return (
    <div className={home ? 'ml-5' : 'mr-5'}>
      <p className="text-h6 font-neversaydie text-yellow-600">
        {name} blocked attack
      </p>
    </div>
  )
}

const BlockedHit: React.FC<BlockedHitProps> = ({ name, home }) => {
  const [position, setPosition] = useState(Math.random() * 410)

  useEffect(() => {
    const randomNumber = Math.random() * 410
    setPosition(randomNumber)
  }, [name, home])

  return (
    <Container
      home={home}
      position={position}
      className="flex items-center absolute bg-black bg-opacity-80 p-8 rounded-3xl"
    >
      {!home && <HitInfo name={name} home={home} />}
      <img src="/images/symbols/blocked-hit.svg" alt="attack blocked" />
      {home && <HitInfo name={name} home={home} />}
    </Container>
  )
}

const Container = styled.div<{ position: number; home: boolean }>`
  top: ${(props) => props.position}px;
  right: ${(props) => (props.home ? '-30%' : '100%')};
`

export type { BlockedHitProps }
export default BlockedHit
