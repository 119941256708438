import { BigNumber, utils } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import { MinimalGladiator } from '../../../../hooks/useGladiator'
import humanBigNumber from '../../../../utils/humanBigNumber'
import waitForTx from '../../../../utils/waitForTx'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'

interface WithdrawFromGladiatorProps {
  show: boolean
  onModalClose: () => void
  gladiator: MinimalGladiator
}

interface TransferPTGFormData {
  amountToWithdraw: string
}

const WithdrawFromGladiatorModal: React.FC<WithdrawFromGladiatorProps> = ({
  show,
  onModalClose,
  gladiator
}) => {
  const { contracts, prestigeID, safeAddress, relayer } = useWeb3Context()
  const { handleSubmit, register, clearErrors, setError, errors } =
    useForm<TransferPTGFormData>()
  const [transferLoading, setTransferLoading] = useState(false)

  const gladiatorDetails = useMemo(() => {
    return gladiator
  }, [gladiator])

  useEffect(() => {
    clearErrors()
  }, [clearErrors])

  const onSubmit = handleSubmit(async ({ amountToWithdraw }) => {
    try {
      clearErrors()
      setTransferLoading(true)
      const { gladiator } = await contracts
      const ptgID = await prestigeID

      if (!safeAddress) {
        throw new Error('no safe address')
      }

      if (!relayer) {
        throw new Error('no relayer')
      }

      const weiAmount = utils.parseEther(amountToWithdraw)

      const transfer = await waitForTx(
        gladiator.safeTransferFrom(
          BigNumber.from(gladiatorDetails.id),
          safeAddress,
          ptgID,
          weiAmount,
          []
        )
      )
      console.log('withdraw receipt: ', transfer)

      setTransferLoading(false)
      onModalClose()
    } catch (error) {
      setTransferLoading(false)
      console.error('error withdrawing: ', error)
      setError('amountToWithdraw', {
        message: `Error withdrawing: ${error.message}`,
        shouldFocus: false
      })
    }
  })

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Withdraw PTG from {gladiatorDetails.name}
        </legend>

        {errors.amountToWithdraw && (
          <legend className="text-base mb-16 font-gilroy-medium text-theme-red">
            {errors.amountToWithdraw.message}
          </legend>
        )}

        {transferLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <img
              className="rounded-full"
              src="/images/icons/prestige.svg"
              alt="ptg"
            />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">Withdrawing PTG, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  Amount to withdraw:
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src="/images/icons/prestige.svg" alt="prestige" />
                    <p className=" px-5 text-p-lg text-gray-200">PTG</p>
                  </div>
                  <input
                    className="bg-transparent focus:outline-none text-p3 font-gilroy-medium p-4"
                    placeholder="Enter amount here"
                    type="text"
                    pattern="[0-9,.]*"
                    ref={register({ required: true })}
                    name="amountToWithdraw"
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300">
                BAL: {humanBigNumber(gladiatorDetails.prestige)} $PTG
              </p>
            </section>

            <div className="flex -center">
              <img src="/images/icons/info.svg" alt="info" />
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300 ml-4">
                Withdrawing from Gladiator might reduce the gladiators rank and
                sending back the PTG does not affect rank
              </p>
            </div>

            <div className="flex justify-center mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Withdraw
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { WithdrawFromGladiatorProps }
export default WithdrawFromGladiatorModal
