import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import SlideIndicator from '../../atoms/SlideIndicator/SlideIndicator'

interface SectionWithTitleProps {
  className?: string
  href?: string
  title: string
  slideTitle: string
}

const SectionWithTitle: React.FC<SectionWithTitleProps> = ({
  className,
  href,
  children,
  title,
  slideTitle
}) => {
  return (
    <div
      className={classNames(className, 'bg-card p-12 rounded-24 max-w-full')}
    >
      <div className="py-4 flex items-center justify-between  lg:mb-12">
        <h2 className="font-zen-dots text-p1 lg:text-h5">{title}</h2>

        {href && (
          <Link to={href} className=" text-primary lg:text-p1 font-semibold">
            View all
          </Link>
        )}
      </div>
      <SlideIndicator className="mb-12" title={slideTitle} />
      <div className="max-w-full overflow-x-scroll">{children}</div>
    </div>
  )
}

export type { SectionWithTitleProps }
export default SectionWithTitle
