/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

interface ProgressBarProps {
  max: number
  value: number
  color: string
}

interface CustomProgressProps {
  color: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({ max, value, color }) => {
  return (
    <CustomProgress
      color={color}
      className={classNames('w-full')}
      max={max}
      value={value}
    />
  )
}

const CustomProgress = styled.progress<CustomProgressProps>`
  ::-webkit-progress-bar {
    background: #0f1118;
    height: 7px;
  }
  ::-webkit-progress-value {
    background: ${({ color }) =>
      `linear-gradient(180deg, #FFFFFF 0%, ${color} 15.05%, rgba(245, 0, 0, 0) 144.5%, ${color} 144.5%)`};
    clip-path: polygon(0 0, 100% 0%, calc(100% - 7px) 100%, 0% 100%);
    height: 7px;
    border-radius: 1px;
  }
`

export type { ProgressBarProps }

export default ProgressBar
