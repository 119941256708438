import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Layout } from '../layouts/Layout'
import { useGladiator, useGladiatorTrophies } from '../hooks/useGladiator'
import Spinner from '../components/atoms/Spinner/Spinner'

// IMAGES
import GladiatorImage from '../components/page-specific/gladiator-detail/GladiatorImage/GladiatorImage'
import GladiatorInfo from '../components/page-specific/gladiator-detail/GladiatorInfo/GladiatorInfo'
import HorizontalSlider from '../components/composed/shared/HorizontalSlider/HorizontalSlider'
import GladiatorHistoryCard from '../components/page-specific/gladiator-detail/GladiatorHistoryCard/GladiatorHistoryCard'

const GladiatorDetailPage = () => {
  const { gladiatorId } = useParams<{ gladiatorId: string }>()
  const { gladiator } = useGladiator(gladiatorId)
  const { trophies } = useGladiatorTrophies(gladiatorId)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const faction = searchParams.get('faction') || undefined

  if (!gladiator) {
    return (
      <div
        style={{
          minHeight: '50vh'
        }}
        className="w-full flex justify-center items-center"
      >
        <Spinner />
      </div>
    )
  }

  return (
    <Layout>
      <div className="w-full flex flex-col items-center">
        <section className="flex w-full flex-wrap lg:flex-no-wrap justify-center mb-24 max-w-1440">
          <GladiatorImage
            gladiator={gladiator.name}
            image={gladiator.properties.heroimage}
          />
          <GladiatorInfo gladiatorInfo={gladiator} faction={faction} />
        </section>
        {!trophies && <Spinner />}

        {trophies && (
          <>
            {trophies.length ? (
              <HorizontalSlider
                title={`${gladiator.name}'s Conquests`}
                className="w-full"
                slideTitle="Trophies"
              >
                {trophies.map((trophy) => (
                  <GladiatorHistoryCard
                    key={trophy.properties.tournamentID}
                    trophy={trophy}
                    gladiatorImage={gladiator.image}
                    className="mr-10"
                  />
                ))}
              </HorizontalSlider>
            ) : (
              <div className="px-6 lg:px-20 w-full">
                <h2 className="text-h5 capitalize font-gilroy-semibold mb-12">{`${gladiator.name}'s Conquests`}</h2>
                <div className="flex flex-col items-center w-full rounded-12 py-24 px-6 lg:px-12 bg-dark border border-gray-800">
                  <img
                    src="/images/symbols/no-conquest.svg"
                    alt="no conquest"
                  />
                  <p className=" font-gilroy-semibold text-p-lg py-4 text-center">
                    {gladiator.name} has not yet won a tournament.
                  </p>
                  <p className=" text-p3 text-gray-400 text-center">
                    Their conquests will show up here when they do.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default GladiatorDetailPage
