/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IDiceRollsV5, IDiceRollsV5Interface } from "../IDiceRollsV5";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "random",
        type: "uint256",
      },
    ],
    name: "DiceRoll",
    type: "event",
  },
  {
    inputs: [],
    name: "getLatestRoll",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "random",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
              },
              {
                internalType: "int256",
                name: "value",
                type: "int256",
              },
            ],
            internalType: "struct IDiceRollsV5.PerformancePair[]",
            name: "performances",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "blockNumber",
            type: "uint256",
          },
        ],
        internalType: "struct IDiceRollsV5.RollParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "start",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    name: "getRange",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "random",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
              },
              {
                internalType: "int256",
                name: "value",
                type: "int256",
              },
            ],
            internalType: "struct IDiceRollsV5.PerformancePair[]",
            name: "performances",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "blockNumber",
            type: "uint256",
          },
        ],
        internalType: "struct IDiceRollsV5.RollParams[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "getRoll",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "random",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "name",
                type: "bytes32",
              },
              {
                internalType: "int256",
                name: "value",
                type: "int256",
              },
            ],
            internalType: "struct IDiceRollsV5.PerformancePair[]",
            name: "performances",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "blockNumber",
            type: "uint256",
          },
        ],
        internalType: "struct IDiceRollsV5.RollParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latest",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "random",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "name",
            type: "bytes32",
          },
          {
            internalType: "int256",
            name: "value",
            type: "int256",
          },
        ],
        internalType: "struct IDiceRollsV5.PerformancePair[]",
        name: "performance",
        type: "tuple[]",
      },
    ],
    name: "roll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IDiceRollsV5__factory {
  static readonly abi = _abi;
  static createInterface(): IDiceRollsV5Interface {
    return new utils.Interface(_abi) as IDiceRollsV5Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IDiceRollsV5 {
    return new Contract(address, _abi, signerOrProvider) as IDiceRollsV5;
  }
}
