import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from '../../atoms/Icon/Icon'
import Avatar from '../../atoms/Avatar/Avatar'
import Button from '../../atoms/Button/Button'

interface NavLinkInterface {
  title: string
  href: string
}

const CANDIDATE_LINKS: NavLinkInterface[] = [
  {
    title: 'Wallet',
    href: '/wallet'
  },
  {
    title: 'My NFT Items',
    href: '/my-items'
  },
  {
    title: 'Betting log (beta)',
    href: '/betting-log'
  }
]

export interface NavProfileIconProps {
  address: string
}

const NavProfileIcon: React.FC<NavProfileIconProps> = ({ address }) => {
  const [showHiddenDropdown, setShowHiddenDropdown] = useState(false)
  let timeOutId: number | any = 0

  const onClickHandler = () => {
    setShowHiddenDropdown(!showHiddenDropdown)
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setShowHiddenDropdown(false)
    }, 10)
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId)
  }

  return (
    <div className="relative" onBlur={onBlurHandler} onFocus={onFocusHandler}>
      <button
        className="flex items-center focus:outline-none"
        onClick={onClickHandler}
      >
        <Avatar size={30} text={address} />

        <Icon
          fontSize={25}
          className="text-gray-400"
          icon={showHiddenDropdown ? 'expand_less' : 'expand_more'}
        />
      </button>

      <HiddenDropDown
        className={classNames(
          'bg-dark px-6',
          showHiddenDropdown ? 'block' : 'hidden'
        )}
        style={{
          transform: showHiddenDropdown ? 'scale(1)' : 'scale(0)'
        }}
      >
        <ul className="">
          {CANDIDATE_LINKS.map(({ title, href }) => (
            <li key={title} className="font-gilroy-medium">
              <Link to={href}>{title}</Link>
            </li>
          ))}

          <li>
            <Link to="/wallet" className="w-full">
              <Button rounded fluidWidth padding="px-10 py-3">
                Get PTG
              </Button>
            </Link>
          </li>
        </ul>
      </HiddenDropDown>
    </div>
  )
}

const HiddenDropDown = styled.nav`
  position: absolute;
  top: 5rem;
  right: 0;
  transition: 0.2s all;
  transform-origin: 100% 0;
  min-width: 256px;
  max-width: 256px;
  transition: 0.2s all;
  background: #151520;

  z-index: 1000;
  border: 1px solid var(--color-black);
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    min-width: 200px;
    max-width: 200px;
    transform: translateY(-100px);
    border: none;
  }

  ul {
    list-style: none;
    background: var(--color-white);
    padding: 16px 0px 0px;
  }

  li,
  > button {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 21px;
    display: flex;
    align-items: center;
  }
`

export default NavProfileIcon
