import { utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import { usePrestigeBalance } from '../../../../hooks/usePrestigeBalance'
import humanBigNumber from '../../../../utils/humanBigNumber'
import waitForTx from '../../../../utils/waitForTx'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'

interface TransferPTGModalProps {
  show: boolean
  onModalClose: () => void
}

interface TransferPTGFormData {
  receiverAddress: string
  amountToTransfer: string
  wrapPTG: boolean
}

const TransferPTGModal: React.FC<TransferPTGModalProps> = ({
  show,
  onModalClose
}) => {
  const { contracts, prestigeID, safeAddress, relayer } = useWeb3Context()
  const balance = usePrestigeBalance()
  const { handleSubmit, register, clearErrors, setError, errors } =
    useForm<TransferPTGFormData>()

  const [transferLoading, setTransferLoading] = useState(false)

  useEffect(() => {
    clearErrors()
  }, [clearErrors])

  const onSubmit = handleSubmit(
    async ({ receiverAddress, amountToTransfer, wrapPTG }) => {
      try {
        clearErrors()
        setTransferLoading(true)
        const { assets, wrappedPTG } = await contracts
        const ptgID = await prestigeID

        if (!safeAddress) {
          throw new Error('no safe address')
        }

        if (!relayer) {
          throw new Error('no relayer')
        }

        const weiAmount = utils.parseEther(amountToTransfer)
        if (wrapPTG) {
          const wrapTx = await assets.populateTransaction.safeTransferFrom(
            safeAddress,
            wrappedPTG.address,
            ptgID,
            weiAmount,
            []
          )
          const transferTx = await wrappedPTG.populateTransaction.transfer(
            receiverAddress,
            weiAmount
          )
          const transfer = await waitForTx(
            relayer.multisend([wrapTx, transferTx])
          )

          console.log('transfer (wrapped ptg) receipt: ', transfer)
        } else {
          const transfer = await waitForTx(
            assets.safeTransferFrom(
              safeAddress,
              receiverAddress,
              ptgID,
              weiAmount,
              []
            )
          )
          console.log('transfer receipt: ', transfer)
        }

        setTransferLoading(false)
        onModalClose()
      } catch (error) {
        setTransferLoading(false)
        console.error('error transferring: ', error)
        setError('receiverAddress', {
          message: `Error transferring: ${(error as any)?.message}`,
          shouldFocus: false
        })
      }
    }
  )

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-4 lg:p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Transfer PTG
        </legend>

        {errors.receiverAddress && (
          <legend className="text-base mb-16 font-gilroy-medium text-theme-red">
            {errors.receiverAddress.message}
          </legend>
        )}

        {transferLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <img
              className="rounded-full"
              src="/images/icons/prestige.svg"
              alt="ptg"
            />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">Transferring PTG, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-8 lg:mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  Amount to Send:
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src="/images/icons/prestige.svg" alt="prestige" />
                    <p className=" px-5 text-p3 lg:text-p-lg text-gray-200">
                      PTG
                    </p>
                  </div>
                  <input
                    className="bg-transparent focus:outline-none text-p3 font-gilroy-medium p-4"
                    placeholder="Enter amount here"
                    type="text"
                    pattern="[0-9,.]*"
                    ref={register({ required: true })}
                    name="amountToTransfer"
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300 ml-8">
                BAL: {humanBigNumber(balance)} $PTG
              </p>
            </section>
            <section className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
              <p className="text-p3 text-gray-300 font-gilroy-medium mb-4">
                Recipient Address:
              </p>
              <input
                required
                name="receiverAddress"
                ref={register({ required: true })}
                className=" bg-transparent focus:outline-none text-p3 font-gilroy-medium p-4 "
                placeholder="Click here to enter address"
              />
            </section>
            <section className="p-8 bg-opacity-80 rounded-xl flex flex-row justify-center">
              <label
                htmlFor="wrapPTG"
                className="text-gray-300 text-p3 font-gilroy-medium mr-4"
              >
                Wrap PTG?
              </label>
              <input
                className="w-8 h-8"
                type="checkbox"
                ref={register()}
                name="wrapPTG"
              />
            </section>

            <div className="flex justify-center mt-8 lg:mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p2"
                type="submit"
              >
                Transfer
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { TransferPTGModalProps }
export default TransferPTGModal
