import React, { useCallback, useState } from 'react'
import {
  BundledItem as BundledItemType,
  useBundledItems
} from '../../../../hooks/useBundledItems'
import fixer from '../../../../utils/skynetUrlFixer'
import waitForTx from '../../../../utils/waitForTx'
import Button from '../../../atoms/Button/Button'
import Spinner from '../../../atoms/Spinner/Spinner'

const BundledItemDisplay: React.FC<{ item: BundledItemType }> = ({ item }) => {
  const { unbundle } = useBundledItems()
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const onUnbundleClick = useCallback(async () => {
    try {
      setLoading(true)
      setErr('')
      await waitForTx(unbundle(item.id))
    } catch (error) {
      console.error('error unbundling', error)
      setErr(`Error: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }, [item.id, unbundle])

  return (
    <div className="flex items-center justify-between bg-gray-800 bg-opacity-30 px-12 py-10 mb-4 rounded-12">
      <img
        src={fixer(item.image_url)}
        alt="item"
        className="h-48 w-48 object-contain mr-10"
      />
      <div className="flex-grow mr-10">
        <div className="flex">
          <h3 className="mr-4 text-p2 italic">{item.name}</h3>
        </div>
        <p className="flex uppercase my-6 text-gray-400 font-gilroy-semibold text-p4 tracking-wide">
          {item.attributes.description}
        </p>
      </div>
      <div className="flex items-center w-2/5 justify-end">
        {loading && <Spinner />}
        {err && <p>err</p>}
        {!loading && (
          <Button
            rounded
            className=" font-bold uppercase"
            padding="px-10 py-4"
            fontSize="text-p3"
            background="bg-transparent"
            onClick={onUnbundleClick}
          >
            Unbundle
          </Button>
        )}
      </div>
    </div>
  )
}

const BundledItems: React.FC = () => {
  const { items } = useBundledItems()

  if (!items || items.length === 0) {
    return null
  }

  return (
    <section className="mt-20">
      <h2 className="text-p-lg uppercase text-gray-200 font-gilroy-semibold mb-10">
        Bundled Items
      </h2>
      {items.map((item) => {
        return (
          <BundledItemDisplay item={item} key={`bundled-item-${item.id}`} />
        )
      })}
    </section>
  )
}

export default BundledItems
