import React from 'react'
import classNames from 'classnames'

interface TabsProps {
  tabs: string[]
  className?: string
  activeTabIndex: number
  onTabSelect: (x: number) => void
  capitalize?: boolean
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabIndex,
  onTabSelect,
  className,
  capitalize = true
}) => {
  return (
    <div
      className={classNames(
        'w-full flex justify-center lg:justify-start items-center',
        className
      )}
    >
      {tabs.map((tab, index) => {
        const active = index === activeTabIndex
        return (
          <button
            key={tab}
            color={active ? 'text-primary' : 'text-white'}
            onClick={() => onTabSelect(index)}
            className={classNames(
              'focus:outline-none mr-12 border-b-2 pb-4 border-transparent ',
              active && 'border-primary text-primary',
              capitalize && 'capitalize'
            )}
          >
            <span className=" font-gilroy-medium">{tab}</span>
          </button>
        )
      })}
    </div>
  )
}

export type { TabsProps }
export default Tabs
