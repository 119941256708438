import React from 'react'
import {
  aiBlowByBlowKey,
  useAiDescriptions
} from '../../../../hooks/useBlowByBlow'

interface AiNarrativeProps {
  tournamentId: string
  game: number
  round: number
  idx: number
}

const AiNarrativeText: React.FC<{ description: string }> = ({
  description
}) => {
  return <p className="text-h5 px-8 py-2">{description}.</p>
}

export const LiveNarrative: React.FC<AiNarrativeProps> = ({
  tournamentId,
  game,
  round,
  idx
}) => {
  const { data: tournamentDescriptions } = useAiDescriptions(tournamentId)
  if (!tournamentDescriptions) {
    return null
  }
  const key = aiBlowByBlowKey(round, game, idx)

  const description = tournamentDescriptions[key]
  if (!description) {
    return null
  }
  return (
    <>
      {description.split('.').filter(Boolean).map((text, i) => {
        return (
          <AiNarrativeText
            description={text}
            key={`narrative-${tournamentId}-${game}-${round}-${idx}-${i}`} // eslint-disable-line react/no-array-index-key
          />
        )
      })}
    </>
  )
}

const AiNarrative: React.FC<AiNarrativeProps> = ({
  tournamentId,
  game,
  round,
  idx
}) => {
  const { data: tournamentDescriptions } = useAiDescriptions(tournamentId)
  if (!tournamentDescriptions) {
    return null
  }
  const key = aiBlowByBlowKey(round, game, idx)

  const description = tournamentDescriptions[key]
  if (!description) {
    return null
  }
  return (
    <p
      className="text-p2 p-8 bg-black bg-opacity-25"
      style={{ maxWidth: '400px' }}
    >
      {description}
    </p>
  )
}

export default AiNarrative
