/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { animated, config, useTransition } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { Link } from 'react-router-dom'
import { BigNumber } from 'ethers'
import { useWeb3Context } from '../contexts/Web3Context'
import { useTournamentList } from '../hooks/useTournament'
import { Layout } from '../layouts/Layout'
import Spinner from '../components/atoms/Spinner/Spinner'
import Button from '../components/atoms/Button/Button'
import Icon from '../components/atoms/Icon/Icon'
import HorizontalSlider from '../components/composed/shared/HorizontalSlider/HorizontalSlider'
import TournamentCard from '../components/page-specific/lobby/TournamentCard/TournamentCard'
import bettingImage from '../assets/images/lobby/betting.png'
import itemsImage from '../assets/images/lobby/items.png'
import youWonImage from '../assets/images/lobby/youwon.png'
import SectionWithTitle from '../components/composed/SectionWithTitle/SectionWithTitle'
import PastTournamentTable from '../components/page-specific/lobby/PastTournamentTable/PastTournamentTable'
// import AuctionTable from '../components/page-specific/lobby/AuctionTable/AuctionTable'

interface SlideContent {
  headline: React.ReactElement
  text: string
  image: string
  imageAlt: string
}

const slides: SlideContent[] = [
  {
    headline: (
      <>
        <span className="text-primary">Back</span> Your Favorite Gladiators
      </>
    ),
    text: `Back AI-fueled gladiators using Prestige
    ($PTG). Win untold fortunes, climb the ranks and go from pleb to patrician.`,
    image: bettingImage,
    imageAlt: 'list of gladiators with button to bet'
  },
  {
    headline: (
      <>
        <span className="text-primary">Influence</span> battles with{' '}
        <span className="text-primary">Items</span>
      </>
    ),
    text: 'Buy items. Place them on gladiators. Impact who wins and who loses. Risk your prestige, change the outcome.',
    image: itemsImage,
    imageAlt: 'the NFT item marketplace'
  },
  {
    headline: (
      <>
        <span className="text-primary">Earn</span> More $PTG by Buying{' '}
        <span className="text-primary">Gladiator Contracts</span>
      </>
    ),
    text: 'Stack Prestige ($PTG), the in-game currency, in your sleep. Gladiators earn a 5% cut of the betting pool for every tournament they win.',
    image: youWonImage,
    imageAlt: 'winning modal showing VitalStash winning'
  }
]

const CardText: React.FC<{ show: number }> = ({ show }) => {
  const [ref, bounds] = useMeasure()

  const transitions = useTransition(show, {
    key: show,
    from: {
      transform: 'translate3d(-50%,0,0)',
      opacity: 0,
      position: 'absolute',
      height: '400'
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    config: config.wobbly
  })

  return (
    <div className="flex" style={{ minHeight: bounds.height }}>
      {transitions((styles, key) => (
        <animated.div style={{ ...(styles as any), maxWidth: 600 }} ref={ref}>
          <h1 className="font-zen-dots text-h3 font-semibold leading-tight">
            {slides[key].headline}
          </h1>
          <p className="text-h6 mt-5 w-3/4">{slides[key].text}</p>
        </animated.div>
      ))}
    </div>
  )
}

const Images: React.FC<{ show: number }> = ({ show }) => {
  const transitions = useTransition(show, {
    key: show,
    from: {
      transform: 'translate3d(0,-100%,0)',
      opacity: 0,
      position: 'absolute'
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,0, 100%)' },
    config: config.stiff
  })

  return (
    <div style={{ width: 700, height: 500 }} className="hidden lg:block">
      {transitions((styles, key) => (
        <animated.div style={styles as any}>
          <img
            src={slides[key].image}
            alt={slides[key].imageAlt}
            style={{ maxWidth: 700 }}
          />
        </animated.div>
      ))}
    </div>
  )
}

const SlideIcon: React.FC<{ active: boolean }> = ({ active }) => {
  return active ? (
    <Icon icon="fiber_manual_record" color="text-primary" />
  ) : (
    <Icon icon="panorama_fish_eye" />
  )
}

const LandingPage = () => {
  const { loading } = useWeb3Context()
  const { tournaments, loading: listLoading } = useTournamentList()
  const [currentSlide, setCurrentSlide] = useState(0)

  const completed = useMemo(() => {
    return tournaments
      .filter((tourn) => {
        return BigNumber.from(tourn.lastRoll).gt(0)
      })
      .reverse()
  }, [tournaments])

  const upcomingAndRunning = useMemo(() => {
    return tournaments.filter((tourn) => {
      return BigNumber.from(tourn.lastRoll).eq(0)
    })
  }, [tournaments])

  useEffect(() => {
    setInterval(() => {
      setCurrentSlide((c) => (c + 1) % slides.length)
    }, 10000)
  }, [])

  if (loading) {
    return (
      <div className="text-base flex flex-col items-center mt-5">
        <div>
          <h1>hello.</h1>
        </div>
        <Spinner />
      </div>
    )
  }

  return (
    <Layout>
      <div className="w-full text-base">
        <div className="px-6 mt-16 lg:px-32">
          <h2 className="text-p1 font-gilroy-semibold text-primary mb-10 hidden lg:block">
            Play and earn prestige by backing NFT gladiators. Enjoy the whimsical violence.
          </h2>
          <div className="flex flex-row justify-between">
            <div className="pr-28">
              <CardText show={currentSlide} />

              <div className="my-5 lg:my-20">
                {slides.map((_, i) => {
                  return (
                    <SlideIcon
                      active={currentSlide === i}
                      key={`slide-icon-${i}`}
                    />
                  )
                })}
              </div>

              <div className="flex lg:flex-row flex-col lg:items-center mb-10">
                <div className="lg:mr-24">
                  <p className=" mb-4 text-p1 font-gilroy-medium">
                    Next tournament starts in
                  </p>
                  {listLoading && <Spinner />}
                  {!listLoading && !!upcomingAndRunning.length && (
                    <Countdown
                      date={upcomingAndRunning[0].notBefore
                        .mul(1000)
                        .toNumber()}
                      className="text-h2 font-bold"
                      daysInHours
                    />
                  )}
                </div>
                <div>
                  {!listLoading && !!upcomingAndRunning.length && (
                    <Link
                      to={`/tournament/${upcomingAndRunning[0].id.toString()}`}
                    >
                      <Button background="bg-primary" rounded>
                        Go to Tournament
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <Images show={currentSlide} />
            </div>
          </div>
        </div>
        <HorizontalSlider
          slideTitle="tournaments"
          title="Upcoming and Running Tournaments"
        >
          {listLoading && <Spinner />}
          {upcomingAndRunning.map((tournament) => {
            return (
              <TournamentCard
                tournament={tournament}
                className="mr-8"
                key={`tournament-${tournament.id}`}
              />
            )
          })}
        </HorizontalSlider>

        <section className="px-6 lg:px-32 mt-16 flex flex-col lg:flex-row items-center lg:items-start flex-wrap lg:flex-nowrap">
          <article className="lg:w-2/3 w-full  lg:mr-16">
            <SectionWithTitle
              slideTitle="Details"
              title="Recent Battles"
              href="/tournaments"
            >
              {listLoading ? (
                <div className="h-96 w-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <PastTournamentTable tournaments={completed.slice(0, 5)} />
              )}
            </SectionWithTitle>
          </article>
          {/* <article className="lg:w-1/2 w-full max-w-full lg:max-w-none mt-16 lg:mt-0">
            <SectionWithTitle
              slideTitle="Details"
              title="Open Auctions"
              href="/auctions"
            >
              <AuctionTable />
            </SectionWithTitle>
          </article> */}
        </section>
      </div>
    </Layout>
  )
}

export default LandingPage
