/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IAuction, IAuctionInterface } from "../IAuction";

const _abi = [
  {
    anonymous: false,
    inputs: [],
    name: "AuctionCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "startTime",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "auctionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minBidAmount",
        type: "uint256",
      },
    ],
    name: "NewAuction",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "bidder",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "auctionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bid",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "highestBidder",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "highestBid",
        type: "uint256",
      },
    ],
    name: "NewBid",
    type: "event",
  },
];

export class IAuction__factory {
  static readonly abi = _abi;
  static createInterface(): IAuctionInterface {
    return new utils.Interface(_abi) as IAuctionInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IAuction {
    return new Contract(address, _abi, signerOrProvider) as IAuction;
  }
}
