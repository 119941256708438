import { BigNumber, utils } from 'ethers'
import React, { FormEventHandler, useState, useEffect } from 'react'
import useSWR from 'swr'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import { getKillSwitch, usePtgMaticPair } from '../../../../hooks/useMaticPTGPair'
import { usePrestigeBalance } from '../../../../hooks/usePrestigeBalance'
import humanBigNumber from '../../../../utils/humanBigNumber'
import waitForTx from '../../../../utils/waitForTx'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'

interface SwapPTGModalProps {
  show: boolean
  onModalClose: () => void
}

const usePoolOutput = (amountToSwap: number) => {
  const { getOutput } = usePtgMaticPair()

  const { data } = useSWR(['/pool-output-ptg', amountToSwap], {
    fetcher: (_: string, amountToSwap: number) => {
      if (amountToSwap === 0) {
        return BigNumber.from('0')
      }
      return getOutput(utils.parseEther(amountToSwap.toString()))
    }
  })

  return {
    maticOut: data
  }
}

const MAX_WITHDRAW = utils.parseEther('100000')
const DEFAULT_AMOUNT = utils.parseEther('1000')

const SwapPTGModal: React.FC<SwapPTGModalProps> = ({
  show,
  onModalClose
}) => {
  const { safeAddress } = useWeb3Context()
  const ptgBalance = usePrestigeBalance()

  const userMax = ptgBalance.gt(MAX_WITHDRAW) ? MAX_WITHDRAW : ptgBalance
  const userDefault = userMax.gt(DEFAULT_AMOUNT) ? DEFAULT_AMOUNT : userMax.mul(10).div(100)

  const [amountToSwap, setAmountToSwap] = useState(
    parseFloat(utils.formatEther(userDefault))
  )
  const [errors, setErrors] = useState('')

  const [swapping, setSwapping] = useState(false)
  const { maticOut } = usePoolOutput(amountToSwap)
  const { swap } = usePtgMaticPair()

  useEffect(() => {
    setErrors('')
  }, [])

  const swapPTG: FormEventHandler = async (e) => {
    e.preventDefault()
    setErrors('')
    const ptgValue = utils.parseEther(amountToSwap.toString())
    if (ptgValue.eq(0)) {
      return
    }
    try {
      if (!maticOut) {
        throw new Error('Unknown matic out number (try again)')
      }
      if (!safeAddress) {
        throw new Error('must be logged in')
      }
      if (ptgValue.gt(userMax)) {
        throw new Error('trying to withdraw too much $PTG.')
      }
      setSwapping(true)
      const isKilled = await getKillSwitch()
      if (isKilled) {
        throw new Error('withdrawls temporarily disabled, join our discord for more')
      }

      const receipt = await waitForTx(swap(ptgValue, maticOut, safeAddress))
      console.log('receipt for swap: ', receipt.transactionHash)

      setSwapping(false)
      onModalClose()
      setAmountToSwap(0)
    } catch (error) {
      setSwapping(false)
      console.error('error swapping: ', error)
      setErrors(`Error swapping: ${(error as any)?.message}`)
    }
  }

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-4 lg:p-16 bg-dark rounded-24" onSubmit={swapPTG}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Swap PTG for MATIC
        </legend>
        {errors && (
          <legend className="text-base text-theme-red mb-16 font-gilroy-medium ">
            {errors}
          </legend>
        )}

        {swapping ? (
          <div className="flex flex-col mt-28 items-center">
            <img className="h-24 w-24" src="/factions/MTC.png" alt="$MATIC" />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">swapping, please wait ...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src="/images/icons/prestige.svg"
                      alt="$PTG"
                      className="w-20 h-20"
                    />
                    <p className=" px-5 text-p-lg text-gray-200">PTG</p>
                    <button
                      type="button"
                      onClick={() => {
                        setAmountToSwap(
                          parseFloat(utils.formatEther(userMax))
                        )
                      }}
                      className="px-6 py-2 focus:outline-none text-primary bg-primary bg-opacity-20 text-p4 tracking-wide font-gilroy-semibold rounded-full"
                    >
                      MAX
                    </button>
                  </div>
                  <input
                    className=" bg-transparent focus:outline-none text-h5 font-gilroy-medium text-right"
                    placeholder="Amount to swap"
                    value={amountToSwap}
                    min={0}
                    step="any"
                    max={parseFloat(utils.formatEther(userMax))}
                    type="number"
                    onChange={(e) => {
                      setAmountToSwap(parseFloat(e.target.value || '0'))
                    }}
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300">
                BAL: {humanBigNumber(ptgBalance || 0)} $PTG
              </p>
            </section>
            <section className="text-h4">
              <span>
                Receive $MATIC: {maticOut ? humanBigNumber(maticOut) : <Spinner />}
              </span>
              <p className="text-base">1% slippage allowed.</p>
              <p className="text-base">2% withdrawl fee</p>
              <p className="text-base">Max withdraw (per withdrawl): $PTG {humanBigNumber(MAX_WITHDRAW)}</p>
            </section>

            <div className="flex justify-center mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Swap
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export type { SwapPTGModalProps as SwapMaticModalProps }
export default SwapPTGModal
