import { BigNumber, BigNumberish } from 'ethers'
import { Contracts } from '../models/GameChain'

const V4StartID = 1000000000000

export function isV4(tournamentId:BigNumberish) {
  return BigNumber.from(tournamentId).gte(V4StartID)
}

export async function tournamentContractForTournament(tournamentId:BigNumberish, contracts: Promise<Contracts>) {
  const { tournament, tournamentV5 } = await contracts
  if (isV4(tournamentId)) {
    return tournament
  }
  return tournamentV5
}
