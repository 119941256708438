import React from 'react'
import classNames from 'classnames'

interface NoDataIndicatorProps {
  title?: string
  subtitle: string
  subtitle2?: string
  showBorder?: boolean
  image?: string
  addPadding?: boolean
}

const NoDataIndicator: React.FC<NoDataIndicatorProps> = ({
  title,
  subtitle,
  image = '/images/symbols/no-conquest.svg',
  subtitle2,
  showBorder = true,
  addPadding = true
}) => {
  return (
    <div className={classNames('  w-full', addPadding && 'lg:px-20 px-6')}>
      {title && (
        <h2 className="text-h5 capitalize font-gilroy-semibold mb-12">
          {title}
        </h2>
      )}
      <div
        className={classNames(
          'flex flex-col items-center w-full rounded-12 py-24 px-6 lg:px-12 bg-dark',
          showBorder && ' border border-gray-800'
        )}
      >
        <img src={image} alt="no conquest" />
        <p className=" font-gilroy-semibold mt-4 text-p-lg py-4 text-center">
          {subtitle}
        </p>
        {subtitle2 && (
          <p className=" text-p3 text-gray-400 text-center">{subtitle2}</p>
        )}
      </div>
    </div>
  )
}

export type { NoDataIndicatorProps }
export default NoDataIndicator
