import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { BigNumber } from 'ethers'
import styled from 'styled-components'
import Countdown from 'react-countdown'
import getItemRarityColor, {
  TypeName
} from '../../../../utils/getItemRarityColor'
import Button from '../../../atoms/Button/Button'
import AuctionInterface from '../../../../interfaces/Auction.interface'
import humanBigNumber from '../../../../utils/humanBigNumber'
import {
  useAuctionHighestBid,
  V2_IDENTIFIER
} from '../../../../hooks/useAuction'
import Spinner from '../../../atoms/Spinner/Spinner'
import AuctionTimeUnit from '../../../composed/AuctionTimeUnit/AuctionTimeUnit'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

interface AuctionCardProps {
  auction: AuctionInterface
}

const AuctionCard: React.FC<AuctionCardProps> = ({ auction }) => {
  const { isEquipment, itemDetails } = auction
  const { highestBid, loading: highestBigLoading } = useAuctionHighestBid(
    auction.id.toString(),
    auction.v2Auction
  )

  const name = isEquipment
    ? itemDetails?.equipment?.name
    : itemDetails?.gladiator?.name

  const image = isEquipment
    ? itemDetails?.equipment?.image
    : itemDetails?.gladiator?.image

  const auctionHasStarted = auction.startTime * 1000 < Date.now()

  const minimumAmount = useMemo(() => {
    if (!highestBid) return 0
    const highestBidUsed = highestBid.gt(0) ? highestBid : auction.minBidAmount

    return auction.minBidIncrement.add(highestBidUsed)
  }, [auction.minBidIncrement, highestBid, auction.minBidAmount])

  const auctionLink = auction.v2Auction
    ? `/auction/${V2_IDENTIFIER}/${auction.id.toString()}`
    : `/auction/${auction.id.toString()}`

  return (
    <StyledContainer className="w-full lg:w-1/3 h-full lg:px-5 mb-10">
      <div className="info w-full flex flex-col justify-around items-center rounded-24 py-10 bg-dark bg-opacity-50">
        <Link to={auctionLink}>
          <ImageBackground className=" w-full flex flex-col items-center justify-between">
            <SkynetImageFixer alt="item" src={image} />
            <div className=" bg-black mx-5 bg-opacity-50 mt-3 py-2 mb-8 px-2">
              <p className=" uppercase text-center font-neversaydie text-p4 tracking-wider ">
                {name}
              </p>
            </div>
          </ImageBackground>
        </Link>
        <section className="w-full lg:px-8 mt-16">
          <div className="flex items-center">
            {isEquipment ? (
              <>
                <p className=" text-p3 leading-7 truncate">
                  {itemDetails?.equipment?.attributes.description}
                </p>
                <Link to={auctionLink} className="text-primary text-p4">
                  Read
                </Link>
              </>
            ) : (
              <p className=" text-p3 leading-7">
                {`Rank - ${itemDetails?.gladiator?.rank}`}
              </p>
            )}
          </div>

          <div className="flex items-center mt-4">
            {isEquipment ? (
              <>
                <p className="text-p3 text-gray-400 mr-4">Rarity</p>
                <span
                  className={classNames(
                    'inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1 ',
                    getItemRarityColor(
                      itemDetails?.equipment?.attributes.rarity as TypeName
                    )
                  )}
                >
                  {itemDetails?.equipment?.attributes.rarity}
                </span>
              </>
            ) : (
              <span className="inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1 bg-primary bg-opacity-80">
                C.C Gladiator
              </span>
            )}
          </div>

          <div className="flex items-center justify-between flex-wrap mb-10">
            <div className="mt-4">
              <p className=" font-bold text-p4 text-gray-200">Highest bid</p>
              {highestBigLoading ? (
                <Spinner />
              ) : (
                <>
                  <p className="text-p1 font-gilroy-bold mt-2 mb-1">
                    {`${humanBigNumber(highestBid as BigNumber)}  $PTG`}
                  </p>

                  <p className="text-p3 text-gray-400 font-gilroy-medium">
                    {`Min: ${humanBigNumber(minimumAmount)} $PTG`}
                  </p>
                </>
              )}
            </div>
            {auctionHasStarted ? (
              <div className="mt-4">
                <p className=" font-gilroy-semibold text-p4 text-gray-200">
                  Auction ending in
                </p>
                <Countdown
                  date={new Date(auction.endTime * 1000)}
                  renderer={({ days, hours, minutes, seconds }) => {
                    return (
                      <div className="flex items-center ">
                        {days ? (
                          <AuctionTimeUnit value={days} title="days" />
                        ) : null}
                        <AuctionTimeUnit value={hours} title="hrs" />
                        <AuctionTimeUnit value={minutes} title="min" />
                        <AuctionTimeUnit value={seconds} title="sec" />
                      </div>
                    )
                  }}
                />
              </div>
            ) : (
              <div className="mt-4">
                <p className=" font-gilroy-semibold text-p4 text-gray-200">
                  Auction will start
                </p>
                <Countdown
                  date={new Date(auction.startTime * 1000)}
                  renderer={({ days, hours, minutes, seconds }) => {
                    return (
                      <div className="flex items-center ">
                        {days ? (
                          <AuctionTimeUnit value={days} title="days" />
                        ) : null}
                        <AuctionTimeUnit value={hours} title="hrs" />
                        <AuctionTimeUnit value={minutes} title="min" />
                        <AuctionTimeUnit value={seconds} title="sec" />
                      </div>
                    )
                  }}
                />
              </div>
            )}
          </div>
          <Link to={auctionLink}>
            <Button
              fluidWidth
              background="bg-transparent"
              color="text-white"
              fontSize="text-p3"
              padding="px-10 py-6"
              className="uppercase tracking-wide font-gilroy-bold border border-primary"
              rounded
            >
              View Auction
            </Button>
          </Link>
        </section>
      </div>
    </StyledContainer>
  )
}

const ImageBackground = styled.section`
  background: url('/images/symbols/auction-card-bg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 248px;
  width: 248px;

  img {
    height: 125px;
    margin-top: 40px;
  }
`

const StyledContainer = styled.article`
  .info {
    min-height: 519px;
  }
`

export type { AuctionCardProps }
export default AuctionCard
