/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { BigNumber } from 'ethers'
import { Link, useLocation } from 'react-router-dom'
import { useWeb3Context } from '../contexts/Web3Context'
import { useTournamentList } from '../hooks/useTournament'
import { Layout } from '../layouts/Layout'
import Spinner from '../components/atoms/Spinner/Spinner'
import TournamentCard from '../components/page-specific/lobby/TournamentCard/TournamentCard'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'

const MAX_TOURNAMENTS = 12

const TournamentsPage = () => {
  const { loading } = useWeb3Context()
  const location = useLocation()
  const { tournaments, loading: listLoading } = useTournamentList()

  const currentPage = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const pageNumber = params.get('page')
    return pageNumber ? Number(pageNumber) - 1 : 0
  }, [location])

  const completedTournaments = useMemo(() => {
    return tournaments
      .filter((tourn) => {
        return BigNumber.from(tourn.lastRoll).gt(0)
      })
      .reverse()
  }, [tournaments])
  const paginationLength = useMemo(() => {
    return Array.from({ length: completedTournaments.length / MAX_TOURNAMENTS })
  }, [completedTournaments])

  const currentPageTournaments = useMemo(() => {
    const start = currentPage * MAX_TOURNAMENTS
    const end = start + MAX_TOURNAMENTS

    console.log('Showing tournament', start + 1, 'to =>', end + 1)
    return completedTournaments.slice(start, end)
  }, [completedTournaments, currentPage])

  if (loading) {
    return (
      <div className="text-base flex flex-col items-center mt-5">
        <Spinner />
      </div>
    )
  }

  return (
    <Layout>
      <PageContainerWithTitle
        title="Previous Tournaments"
        loading={listLoading}
        loadingTitle="Tournaments loading"
      >
        <section className=" grid grid-cols-1 md:grid-cols-3 lg:md:grid-cols-3 gap-6">
          {!listLoading &&
            currentPageTournaments.map((tournament) => {
              return (
                <TournamentCard
                  tournament={tournament}
                  key={`tournament-${tournament.id}`}
                />
              )
            })}
        </section>

        <div className="flex justify-center">
          <div className="flex items-center w-1/2 text-base mt-20 flex-wrap justify-center">
            {paginationLength.map((_, idx) => (
              <Link
                to={`/tournaments?page=${idx + 1}`}
                className={classNames(
                  ' hover:bg-primary px-5 mx-4 inline-block transition-all duration-200 py-1 mb-2 rounded-lg text-gray-300 font-semibold  ',
                  idx === currentPage && 'bg-primary text-white'
                )}
              >
                {idx + 1}
              </Link>
            ))}
          </div>
        </div>
      </PageContainerWithTitle>
    </Layout>
  )
}

export default TournamentsPage
