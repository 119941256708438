import React from 'react'
import { useGladiatorRank } from '../../../../hooks/useGladiator'
import GladiatorInterface from '../../../../interfaces/Gladiator.interface'
import humanBigNumber from '../../../../utils/humanBigNumber'
import CustomProgressGroup from '../../../composed/CustomProgressGroup/CustomProgressGroup'
import GladiatorSkillsChart from '../GladiatorSkillsChart/GladiatorSkillsChart'
import GladiatorTitleBanner from '../GladiatorTitleBanner/GladiatorTitleBanner'

interface GladiatorStatsProps {
  gladiatorInfo: GladiatorInterface
  faction?: string
}

const GladiatorStats: React.FC<GladiatorStatsProps> = ({
  gladiatorInfo,
  faction
}) => {
  const { rank, numberOfGladiators, league } = useGladiatorRank(
    gladiatorInfo.id.toString()
  )

  return (
    <div className="w-full">
      <GladiatorTitleBanner faction={faction} name={gladiatorInfo.name} />
      <div className="mt-10 flex flex-wrap items-center">
        <div className="mr-16">
          <h4 className="uppercase font-gilroy-medium opacity-50 text-p3  mb-4">
            ranking
          </h4>
          {numberOfGladiators && (
            <div className="flex items-center">
              <p className=" font-gilroy-medium text-gray-200 text-p-lg  mb-4">
                <b>{rank}</b>
                {` of ${numberOfGladiators}`}
              </p>
            </div>
          )}
        </div>
        <div className=" mr-16">
          <h4 className="uppercase font-gilroy-medium opacity-50 text-p3  mb-4">
            League
          </h4>
          {league && (
            <div className="flex items-center mb-4">
              <img
                src={league?.image}
                className=" w-10 mr-4"
                alt={league.title}
              />

              <p className=" font-gilroy-medium text-gray-200 text-p-lg  ">
                {league?.title}
              </p>
            </div>
          )}
        </div>
        <div>
          <h4 className="uppercase font-gilroy-medium  mb-4 opacity-50 text-p3">
            Prestige
          </h4>
          <p className=" font-gilroy-semibold text-green-600 mb-4 text-p-lg">
            {humanBigNumber(gladiatorInfo.properties.prestige)}
          </p>
        </div>
      </div>
      <section className="mt-6 flex flex-wrap lg:flex-no-wrap">
        <div className="w-full lg:w-1/2">
          <CustomProgressGroup
            stats={[
              {
                title: 'Attack',
                color: '#FFD37E',
                stat: {
                  value: gladiatorInfo.properties.attack,
                  max: 2000
                }
              },
              {
                title: 'Defense',
                color: '#E37171',
                stat: {
                  value: gladiatorInfo.properties.defense,
                  max: 2000
                }
              },
              {
                title: 'Hit points',
                color: '#7694FF',
                stat: {
                  value: gladiatorInfo.properties.hitpoints,
                  max: 2000
                }
              },
              {
                title: 'POP',
                color: '#CD11BA',
                stat: {
                  value: gladiatorInfo.properties.pop,
                  max: 2000
                }
              }
            ]}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <GladiatorSkillsChart
            stats={{
              hitpoints: gladiatorInfo.properties.hitpoints,
              defense: gladiatorInfo.properties.defense,
              attack: gladiatorInfo.properties.attack,
              pop: gladiatorInfo.properties.pop
            }}
          />
        </div>
      </section>
      <section className="mt-20 flex flex-wrap lg:flex-no-wrap">
        <article className="mr-40 mb-16 lg:mb-0">
          <h4 className="text-p4 font-gilroy-semibold text-primary mb-3 uppercase">
            special move
          </h4>
          <p className="font-vermin font-bold text-p1 tracking-wide">
            {gladiatorInfo.properties.specialMove}
          </p>
        </article>
        <article>
          <h4 className="text-p4 font-gilroy-semibold text-primary mb-3 uppercase">
            OTHER ATTRIBUTES:
          </h4>
          <ul>
            {gladiatorInfo.properties.attributes.map((attribute) => (
              <li key={attribute} className="text-p1 mb-4 font-gilroy-medium">
                {attribute}
              </li>
            ))}
          </ul>
        </article>
      </section>
    </div>
  )
}

export type { GladiatorStatsProps }

export default GladiatorStats
