import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

interface SuccessfulHitProps {
  attack: boolean
  damage: string
  statAffected: string
  home: boolean
}

const HitInfo: React.FC<SuccessfulHitProps> = ({
  attack,
  damage,
  statAffected,
  home
}) => {
  return (
    <>
      {attack ? (
        <p
          className={classNames(
            'text-h6 font-neversaydie leading-10 text-green-600 italic',
            home ? 'ml-5' : 'mr-5'
          )}
        >
          Attacker
        </p>
      ) : (
        <div className={home ? 'ml-5' : 'mr-5'}>
          <p className="text-h4 font-neversaydie text-red-600">lost</p>
          <p className="text-h4 font-neversaydie leading-10 text-red-600">
            <span className="font-gilroy-bold mr-3">{damage}</span>
            <span className="italic">{statAffected}</span>
          </p>
        </div>
      )}
    </>
  )
}

const SuccessfulHit: React.FC<SuccessfulHitProps> = ({
  attack,
  damage,
  statAffected,
  home
}) => {
  const [position, setPosition] = useState(Math.random() * 410)

  useEffect(() => {
    const randomNumber = Math.random() * 410
    setPosition(randomNumber)
  }, [damage, statAffected, attack])

  return (
    <Container
      home={home}
      position={position}
      className={classNames('flex items-center absolute p-8 rounded-3xl', home ? 'bg-black bg-opacity-80' : '')}
    >
      {!home && (
        <HitInfo
          attack={attack}
          damage={damage}
          statAffected={statAffected}
          home={home}
        />
      )}
      <img
        src={`/images/symbols/${
          attack ? 'won-battle-points.svg' : 'lost-battle-points.svg'
        }`}
        alt={statAffected}
        className="h-42"
      />
      {home && (
        <HitInfo
          attack={attack}
          damage={damage}
          statAffected={statAffected}
          home={home}
        />
      )}
    </Container>
  )
}

const Container = styled.div<{ position: number; home: boolean }>`
  top: ${(props) => props.position}px;
  right: ${(props) => (props.home ? '-30%' : '100%')};
`

export type { SuccessfulHitProps }
export default SuccessfulHit
