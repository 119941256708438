import React, { useState, Suspense } from 'react'
import styled from 'styled-components'
import Button from '../../../atoms/Button/Button'
import { Web3ContextData } from '../../../../contexts/Web3Context'
import Spinner from '../../../atoms/Spinner/Spinner'
import { MinimalGladiator } from '../../../../hooks/useGladiator'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

const TransferWrappedItemModal = React.lazy(
  () => import('../TransferWrappedItemModal/TransferWrappedItemModal')
)
const UnwrapItemModal = React.lazy(
  () => import('../UnwrapItemModal/UnwrapItemModal')
)

interface ItemCardProps {
  gladiator: MinimalGladiator
  ctx: Web3ContextData
}

const WrappedGladiatorCard: React.FC<ItemCardProps> = ({ gladiator, ctx }) => {
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showUnwrapModal, setShowUnwrapModal] = useState(false)

  return (
    <>
      <StyledContainer className="w-full lg:w-1/4 bg-dark h-full lg:px-5 mb-10">
        <div className="'info w-full flex flex-col justify-around items-center py-6 px-10 bg-dark bg-opacity-50'">
          <div className="flex flex-col items-center">
            <p className="font-gilroy-semibold text-base pt-4 text-center">
              {gladiator.name}
            </p>
          </div>
          <SkynetImageFixer
            src={gladiator.image}
            className="pt-12 pb-8"
            alt={gladiator.name}
          />
        </div>
        <div className="mt-4 flex flex-col items-center w-full">
          <div className="flex mt-4 mb-10">
            <p className="flex text-gray-400 uppercase font-gilroy-medium  text-p4">
              Attributes
            </p>
          </div>

          <div className="flex">
            <Button
              rounded
              background="bg-theme-dark-gray"
              color="text-white"
              className=" border border-gray-900 mr-4 focus:outline-none"
              fontSize="text-p3"
              onClick={() => setShowUnwrapModal(true)}
            >
              Unwrap
            </Button>
            <Button
              rounded
              background="bg-red-900"
              color="text-white"
              className="focus:outline-none"
              fontSize="text-p3"
              onClick={() => setShowTransferModal(true)}
              style={{
                background: 'rgba(61, 18, 9, 1)'
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </StyledContainer>

      <Suspense fallback={<Spinner />}>
        <UnwrapItemModal
          item={gladiator}
          safeAddress={ctx.safeAddress}
          show={showUnwrapModal}
          onModalClose={() => setShowUnwrapModal(false)}
          isItem
        />
      </Suspense>

      <Suspense fallback={<Spinner />}>
        <TransferWrappedItemModal
          item={gladiator}
          ctx={ctx}
          show={showTransferModal}
          onModalClose={() => {
            setShowTransferModal(false)
          }}
        />
      </Suspense>
    </>
  )
}

const StyledContainer = styled.article`
  .info {
    min-height: 300px;
  }
`

export type { ItemCardProps }
export default WrappedGladiatorCard
