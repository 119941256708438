/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { EIP712Base, EIP712BaseInterface } from "../EIP712Base";

const _abi = [
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "version",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506040516102b93803806102b98339818101604052604081101561003357600080fd5b810190808051604051939291908464010000000082111561005357600080fd5b90830190602082018581111561006857600080fd5b825164010000000081118282018810171561008257600080fd5b82525081516020918201929091019080838360005b838110156100af578181015183820152602001610097565b50505050905090810190601f1680156100dc5780820380516001836020036101000a031916815260200191505b50604052602001805160405193929190846401000000008211156100ff57600080fd5b90830190602082018581111561011457600080fd5b825164010000000081118282018810171561012e57600080fd5b82525081516020918201929091019080838360005b8381101561015b578181015183820152602001610143565b50505050905090810190601f1680156101885780820380516001836020036101000a031916815260200191505b5060808101604052605280825290935091506102679050602083013980519060200120828051906020012082805190602001206101c961021560201b60201c565b6040805160208082019690965280820194909452606084019290925260808301523060a0808401919091528151808403909101815260c0909201905280519101206000555061021a9050565b600190565b603f806102286000396000f3fe6080604052600080fdfea2646970667358221220b9a551183ed2c170a9de88073aa0f4413687b86509ba09306480e0e1f3bea17464736f6c63430007040033454950373132446f6d61696e28737472696e67206e616d652c737472696e672076657273696f6e2c75696e7432353620636861696e49642c6164647265737320766572696679696e67436f6e747261637429";

export class EIP712Base__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    name: string,
    version: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<EIP712Base> {
    return super.deploy(name, version, overrides || {}) as Promise<EIP712Base>;
  }
  getDeployTransaction(
    name: string,
    version: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(name, version, overrides || {});
  }
  attach(address: string): EIP712Base {
    return super.attach(address) as EIP712Base;
  }
  connect(signer: Signer): EIP712Base__factory {
    return super.connect(signer) as EIP712Base__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): EIP712BaseInterface {
    return new utils.Interface(_abi) as EIP712BaseInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): EIP712Base {
    return new Contract(address, _abi, signerOrProvider) as EIP712Base;
  }
}
