import React, { MouseEventHandler } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../components/atoms/Button/Button'
import ConnectButton from '../components/atoms/ConnectButton/ConnectButton'
import Spinner from '../components/atoms/Spinner/Spinner'
import { useWeb3Context } from '../contexts/Web3Context'
import useDiscordAssociator from '../hooks/useDiscordAssociator'
import { Layout } from '../layouts/Layout'

const AssociatorPageConnected: React.FC = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const message = params.get('message')
  const { associate, response, signing } = useDiscordAssociator(
    message || undefined
  )

  const handleTextAreaClick:MouseEventHandler<HTMLTextAreaElement> = (event) => {
    (event.target as any).select()
  }

  if (!message) {
    return (
      <div>
        <p>
          looks like there was some problem, you did not come in with a message.
        </p>
      </div>
    )
  }

  if (signing) {
    return (
      <div>
        <p>Please sign the message in the popup</p>
      </div>
    )
  }

  if (response) {
    return (
      <div>
        <p>Copy and paste this into discord</p>
        <textarea
          onClick={handleTextAreaClick}
          className="bg-black w-1/3 h-64 border border-white p-4"
          value={response}
        />
      </div>
    )
  }

  return (
    <div>
      <p>
        Once you click sign there will be a popup to sign and then paste the
        results into discord
      </p>
      <Button className="mt-5" onClick={() => associate()}>
        Sign
      </Button>
    </div>
  )
}

const AssociatorPage: React.FC = () => {
  const ctx = useWeb3Context()
  const { loading, connected } = ctx

  if (loading) {
    return (
      <Layout addPadding>
        <Spinner />
      </Layout>
    )
  }

  if (connected) {
    return (
      <Layout addPadding>
        <div className="w-full  max-w-screen rounded-12 py-24 min-h-full px-6 text-p-lg text-center lg:px-24 bg-dark lg:max-w-1440">
          <AssociatorPageConnected />
        </div>
      </Layout>
    )
  }

  return (
    <Layout addPadding>
      <div className="w-full px-8 text-p-lg flex flex-col items-center max-w-1440">
        <h1 className="mb-10 mt-40">Please sign in to continue associating</h1>
        <ConnectButton />
      </div>
    </Layout>
  )
}

export default AssociatorPage
