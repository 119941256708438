import React, { useMemo } from 'react'
import { BigNumber, BigNumberish } from 'ethers'
import { DateTime } from 'luxon'
import classNames from 'classnames'
import Spinner from '../Spinner/Spinner'

interface TournamentTimeProps {
  notBefore?: BigNumberish
  className?: string
}

const dateFormat = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

const TournamentTime: React.FC<TournamentTimeProps> = ({
  notBefore: notBeforish,
  className
}) => {
  const date = useMemo(() => {
    if (notBeforish) {
      const notBefore = BigNumber.from(notBeforish)
      const date = DateTime.fromSeconds(notBefore.toNumber())
      return {
        formatted: date.toLocaleString(dateFormat),
        dom: date.toJSDate().toString()
      }
    }
    return undefined
  }, [notBeforish])

  if (date) {
    return (
      <time
        className={classNames(
          'font-gilroy-medium text-gray-300',
          className
        )}
        dateTime={date.dom}
      >
        {date.formatted}
      </time>
    )
  }

  return <Spinner />
}

export default TournamentTime
