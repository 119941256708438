/* eslint-disable react/no-array-index-key */
import { BigNumber, BigNumberish } from 'ethers'
import React, { useEffect, useMemo, useState } from 'react'
import { Tournament } from '../../../../hooks/useTournament'
import PlayerInterface from '../../../../interfaces/Player.interface'
import {
  RoundNames,
  TournamentRoundInterface
} from '../../../../interfaces/TournamentRound.interface'
import repeatArray from '../../../../utils/repeatArray'
import ChampionCard from '../Cards/ChampionCard/ChampionCard'
import ContestantCardGroup from '../Cards/ContestantCardGroup/ContestantCardGroup'

export interface BattleBracketProps {
  tournament: Tournament
  equipGladiator: (id: BigNumberish) => void
}

const roundNames: RoundNames[] = [
  'finals',
  'semi-finals',
  'quarter-finals',
  'round-of-eight',
  'round-of-sixteen',
  'round-of-thirty-two'
]

const defaultGladiator: PlayerInterface = {
  name: '',
  faction: '',
  image: '',
  id: ''
}

const BattleBracket: React.FC<BattleBracketProps> = ({
  equipGladiator,
  tournament
}) => {
  const {
    currentRound,
    currentGame,
    totalRounds,
    champion,
    tournamentId,
    bracket,
    started
  } = tournament

  const tournamentData = useMemo<TournamentRoundInterface[]>(() => {
    return bracket.map((round, i) => {
      return {
        title: roundNames[totalRounds - (i + 1)],
        gladiators: round
      }
    })
  }, [bracket, totalRounds])

  const [tournamentWithPlaceHolder, setTournamentWithPlaceHolder] = useState<
    TournamentRoundInterface[]
  >([])

  useEffect(() => {
    const getTournament = () => {
      let defaultRounds: TournamentRoundInterface[] = [
        {
          title: 'round-of-sixteen',
          gladiators: repeatArray(defaultGladiator, 8)
        },
        {
          title: 'quarter-finals',
          gladiators: repeatArray(defaultGladiator, 4)
        },
        {
          title: 'semi-finals',
          gladiators: repeatArray(defaultGladiator, 2)
        },
        {
          title: 'finals',
          gladiators: repeatArray(defaultGladiator, 1)
        }
      ]

      if (totalRounds === 3) {
        defaultRounds = [
          {
            title: 'round-of-thirty-two',
            gladiators: repeatArray(defaultGladiator, 32)
          },
          {
            title: 'round-of-sixteen',
            gladiators: repeatArray(defaultGladiator, 16)
          },
          {
            title: 'round-of-eight',
            gladiators: repeatArray(defaultGladiator, 8)
          },
          {
            title: 'quarter-finals',
            gladiators: repeatArray(defaultGladiator, 4)
          },
          {
            title: 'semi-finals',
            gladiators: repeatArray(defaultGladiator, 2)
          },
          {
            title: 'finals',
            gladiators: repeatArray(defaultGladiator, 1)
          }
        ]
      }

      let paddedTournament = tournamentData
      if (tournamentData.length < totalRounds) {
        paddedTournament = paddedTournament.concat(
          defaultRounds.splice(totalRounds + currentRound + 1)
        )
      }
      setTournamentWithPlaceHolder(paddedTournament)
    }
    getTournament()
  }, [
    totalRounds,
    currentRound,
    tournamentData.length,
    tournamentData,
    champion
  ])

  const tournamentHexId = BigNumber.from(tournamentId).toHexString()

  const checkIfMatchHasEnded = (gameIndex: number, roundIndex: number) => {
    if (roundIndex < currentRound) {
      return true
    }
    return gameIndex !== currentGame && gameIndex < currentGame
  }

  return (
    <div dir="ltr" className="flex items-center max-w-full overflow-x-scroll">
      {tournamentWithPlaceHolder.map((round, roundIdx) => (
        <div className="flex flex-col flex-0 items-center" key={round.title}>
          <h2 className="text-p2 uppercase font-gilroy-medium text-left w-full pl-6 lg:pl-24">
            {round.title}
          </h2>
          <div className="-ml-20 lg:ml-0 ">
            {round.gladiators.map((gladiators, idx) => {
              if (!gladiators[0]?.name) {
                return (
                  <ContestantCardGroup
                    tournamentId={tournamentHexId}
                    equipGladiator={equipGladiator}
                    key={idx}
                    undecided
                  />
                )
              }

              return (
                <ContestantCardGroup
                  tournamentId={tournamentHexId}
                  equipGladiator={equipGladiator}
                  inProgress={
                    champion || !started
                      ? false
                      : roundIdx === currentRound && idx === currentGame
                  }
                  matchEnded={
                    !started ? false : checkIfMatchHasEnded(idx, roundIdx)
                  }
                  gameNumber={idx}
                  roundIndex={roundIdx}
                  key={idx}
                  contestants={gladiators}
                />
              )
            })}
          </div>
        </div>
      ))}
      <div className="flex pr-10 mr-10 lg:pr-0 flex-col flex-0 items-center">
        <ChampionCard
          undecided={typeof champion === 'undefined'}
          champion={champion}
          className="relative"
        />
      </div>
    </div>
  )
}

export default BattleBracket
