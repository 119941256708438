import React from 'react'
import styled from 'styled-components'
import { factionToDisplayname } from '../../../../utils/factionToDisplayName'

interface GladiatorTitleBannerProps {
  name: string
  faction?: string
}

const GladiatorTitleBanner: React.FC<GladiatorTitleBannerProps> = ({
  name,
  faction
}) => {
  return (
    <div className="items-center flex justify-between">
      <div className="items-center flex">
        {faction && (
          <FactionImage
            src={`/factions/${faction.toUpperCase()}.png`}
            alt={factionToDisplayname(faction)}
          />
        )}
        <h3 className="text-h5 font-gilroy-semibold ml-5 capitalize">{name}</h3>
      </div>
    </div>
  )
}

const FactionImage = styled.img`
  width: 4.8rem;
  height: 4.8rem;
`

export type { GladiatorTitleBannerProps }

export default GladiatorTitleBanner
