import React, { useCallback, useContext, useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import Button from '../../atoms/Button/Button'
import Modal from '../../atoms/Modal/Modal'
import prestigeCoinsAnimation from '../../../assets/animations/prestige-signup.json'
import { Web3Context } from '../../../contexts/Web3Context'
import InviteCodeFeedback from '../InviteCodeFeedback/InviteCodeFeedback'

interface WelcomeModalProps {}

const WelcomeModal: React.FC<WelcomeModalProps> = () => {
  const { newUser, clearNewUser } = useContext(Web3Context)
  const [showModal, setShowModal] = useState(!!newUser)

  useEffect(() => {
    setShowModal(!!newUser)
  }, [newUser])

  const onClose = useCallback(() => {
    clearNewUser()
    setShowModal(false)
  }, [clearNewUser, setShowModal])

  return (
    <Modal showModal={showModal} onModalClose={onClose}>
      <section className="bg-black flex flex-col items-center px-24 pt-40 pb-24">
        <Lottie
          animationData={prestigeCoinsAnimation}
          style={{
            width: 200
          }}
        />
        <br />
        <h2 className="font-gilroy-semibold text-h5 text-center">
          Your first 6 bets are on us!
        </h2>
        <InviteCodeFeedback className="mt-4" />
        <p className="font-gilroy-medium text-p2 pt-5 pb-3 text-gray-300 text-center">
          Welcome to the Arena!<br />Bet on gladiator battles. Help your chances by
          placing items. Stack your PTG and you will own a gladiator contract one day. Make sure to join us in <a className="underline text-primary" href="https://discord.gg/Z2S3EtQKCn" target="_blank" rel="noreferrer">discord</a> for free stuff.
        </p>
        <Button onClick={onClose} className="mt-4">
          Let&apos;s Go!
        </Button>
      </section>
    </Modal>
  )
}

export type { WelcomeModalProps }
export default WelcomeModal
