/* eslint-disable class-methods-use-this */

import {
  providers,
  Signer,
  VoidSigner,
  constants,
  Contract,
  utils,
  BigNumber,
  EventFilter
} from 'ethers'
import Torus from '@toruslabs/torus-embed'
import { Address, safeFromAddr, WalletMaker } from 'kasumah-wallet'
import { MulticallWrapper } from 'kasumah-multicall'
import {
  GnosisBiconomy,
  GnosisLocalRelayer,
  Relayer
} from 'kasumah-relay-wrapper/dist/src/relayers'
import { AbstractProvider } from 'web3-core/types'
import Axios from 'axios'
import { wrapContract } from 'kasumah-relay-wrapper'
import { EventEmitter } from 'events'
import detectEthereumProvider from '@metamask/detect-provider'
import { GnosisSafe } from 'kasumah-wallet/dist/types/ethers-contracts'
import {
  KasumahValueLogger__factory,
  KasumahValueLogger,
  KasumahUintLogger,
  KasumahUintLogger__factory
} from 'kasumah-logger/dist/types/ethers-contracts/'
import { backOff } from 'exponential-backoff'
import {
  Assets,
  Assets__factory,
  Auction,
  Auction__factory,
  BettingPoolV5,
  BettingPoolV5__factory,
  Equipment,
  Equipment__factory,
  Gladiator,
  Gladiator__factory,
  TournamentV4,
  TournamentV4__factory,
  Trophy,
  Trophy__factory,
  GameLogicV4,
  GameLogicV4__factory,
  DiceRoller,
  DiceRoller__factory,
  VendingMachine,
  VendingMachine__factory,
  Equipper,
  Equipper__factory,
  Marketplace,
  Marketplace__factory,
  WrappedPTG__factory,
  WrappedPTG,
  FreeBet,
  FreeBet__factory,
  TournamentLogger,
  TournamentLogger__factory,
  IUniswapV2Router02,
  IUniswapV2Router02__factory,
  IUniswapV2Factory,
  IUniswapV2Factory__factory,
  Bundler,
  Bundler__factory,
  BettingLogger,
  BettingLogger__factory,
  BidChecker,
  BidChecker__factory,
  BetChecker__factory,
  BetChecker,
  TournamentV5,
  TournamentV5__factory,
  DiceRollerV5,
  DiceRollerV5__factory,
  EquipperV5,
  EquipperV5__factory,
  GameLogicV5,
  GameLogicV5__factory,
  BettingPoolV6,
  BettingPoolV6__factory,
  TrophyV5,
  TrophyV5__factory,
  AuctionV2,
  AuctionV2__factory,
  FreeBetCoin,
  FreeBetCoin__factory,
  InviteCode,
  InviteCode__factory,
  MarketplaceWrapper,
  MarketplaceWrapper__factory
} from '../types/contracts'
import { getReferrer, getInviteCode } from '../hooks/useInviteStore'
import { waitForRelayedTransaction } from '../utils/waitForTx'
import { subsidyDisabled } from '../hooks/useGasSubsidy'

/**
 * This is the main high level connection for the dapp to the chain.
 * Loads the contracts, wraps them in wrappers, handles exposing functions to
 * connect the wallet, etc.
 */

declare const window: any

export type SignerWithAddress = Signer & { address?: string }

export interface Subscription {
  unsubscribe: () => void
}

export const PRESTIGE_NAME = utils.formatBytes32String('prestige')
export const GLADIATOR_NAME = utils.formatBytes32String('gladiator')

console.log('Welcome dev, you are awesome')

const ethereumProviderPromise = detectEthereumProvider({ timeout: 300 })

const kasumahAddresses: Record<string, Record<string, string>> = {
  matic: {
    KasumahValueLogger: '0x7DEBDdfb8b6e11e0CEA3830e1196A69eb06083AF',
    KasumahUintLogger: '0x41eB847bD788F3219254371212C947793C392374'
  },
  mumbai: {
    KasumahValueLogger: '0xCBa3f725D72B4C4BB579e0db82BF74F017bEdF62',
    KasumahUintLogger: '0x2EC99f75c821825ad16b932407cA876aA9c84d78'
  }
}

const genesisBlocks = {
  localhost: 0,
  mumbai: 9390135,
  matic: 11777605,
  'forked-matic': 11777605
}

type SupportedNetworks = 'matic' | 'mumbai' | 'localhost' | 'forked-matic'

const networkName = (process.env.REACT_APP_NETWORK_NAME ||
  'localhost') as SupportedNetworks

const isForked = networkName === 'forked-matic'

export const addresses = require(`../deployments/${networkName}/addresses.json`) // eslint-disable-line import/no-dynamic-require
if (networkName !== 'localhost') {
  const kasumahNetworkName = isForked ? 'matic' : networkName
  addresses.KasumahValueLogger =
    kasumahAddresses[kasumahNetworkName].KasumahValueLogger
  addresses.KasumahUintLogger =
    kasumahAddresses[kasumahNetworkName].KasumahUintLogger
}

let localWmatic: any
// let localQuickswapFactory: any
let localQuickswapRouter: any
if (networkName === 'localhost') {
  localWmatic = require(`../deployments/${networkName}/WMatic.json`) // eslint-disable-line import/no-dynamic-require
  localQuickswapRouter = require(`../deployments/${networkName}/LocalQuickswapRouter.json`) // eslint-disable-line import/no-dynamic-require
}

let DEPLOYER_ADDRESS = Promise.resolve(
  '0xce7bf1Ac9E0baba188D1A297Ad64BdE16CF2967c'
)

let defaultProvider: providers.Provider
let providerNetwork: number

let wsProviderAddress: string

// mumbai
let biconomyKey = 'xYX3WvySh.2577ebd9-0d61-48fc-a89f-6d23a50d3055'
let biconomyId = '39d06c18-59d4-4828-a208-5617093849f3'

switch (networkName) {
  case 'localhost':
    console.log('using localhost network')
    defaultProvider = new providers.StaticJsonRpcProvider()
    providerNetwork = 31337

    MulticallWrapper.setMulticallAddress(providerNetwork, addresses.Multicall)

    DEPLOYER_ADDRESS = (defaultProvider as providers.StaticJsonRpcProvider)
      .getSigner(0)
      .getAddress()
      .then((addr) => {
        console.log('switching DEPLOYER_ADDRESS to ', addr)
        return addr
      })

    break
  case 'forked-matic':
    console.log('using forked matic network')
    defaultProvider = new providers.StaticJsonRpcProvider()
    providerNetwork = 137
    break
  case 'mumbai':
    console.log('using mumbai network')
    defaultProvider = new providers.StaticJsonRpcProvider(
      'https://rpc-mumbai.matic.today'
    )
    providerNetwork = 80001
    wsProviderAddress =
      'wss://polygon-mumbai.g.alchemy.com/v2/vuqmHbhe6y3z7qV342pPIEVSiSmO0wDP'

    break
  case 'matic':
    console.log('using matic network')
    biconomyKey = 'wZSh7_88S.867979ee-0eb1-4677-8403-84326796496c'
    biconomyId = '64ce32e3-4eb4-43c5-993d-afa9f26c65ac'

    // alternative RPC providers: [
    //   'https://polygon-mainnet.infura.io/v3/48ea023d08a749648547585ff77816d1',
    //   'https://polygon-rpc.com/'
    // ]
    defaultProvider = new providers.StaticJsonRpcProvider(
      'https://polygon-rpc.com'
    )

    providerNetwork = 137
    // wsProviderAddress =
    //   'wss://polygon-mainnet.g.alchemy.com/v2/mJ5FFcIBsIHX9cL5gxIlu4vjIdHMo1GK'
    break
  default:
    throw new Error('unsupported network')
}

const voidSigner = new VoidSigner(constants.AddressZero, defaultProvider)

console.log('provider Network: ', providerNetwork)
const walletMaker = new WalletMaker({
  signer: voidSigner,
  chainId: providerNetwork
})

const localNetwork = networkName === 'localhost' || isForked

const torus = new Torus({})
const torusInitPromise = torus.init({
  network: {
    host: 'https://polygon-rpc.com',
    networkName: 'matic',
    chainId: 137
  }
})

const setupRelayer = async (
  userSigner: Signer,
  maticProvider: providers.Provider
) => {
  if (localNetwork) {
    const jsonProvider = maticProvider as providers.StaticJsonRpcProvider
    const transmitSigner = jsonProvider.getSigner(0)
    const { chainId } = jsonProvider.network

    return new GnosisLocalRelayer({ transmitSigner, userSigner, chainId })
  }

  if (subsidyDisabled()) {
    console.log(
      '---------------------> disabling the gasless transaction relayer'
    )
    return new GnosisLocalRelayer({
      transmitSigner: userSigner,
      userSigner,
      chainId: providerNetwork
    })
  }

  const relayer = new GnosisBiconomy({
    userSigner,
    chainId: providerNetwork,
    apiKey: biconomyKey,
    apiId: biconomyId,
    targetChainProvider: maticProvider
  })

  console.dir(relayer)
  console.dir(GnosisBiconomy)

  return relayer
}

export interface Contracts {
  assets: Assets
  tournament: TournamentV4
  bettingPool: BettingPoolV5
  gladiator: Gladiator
  trophy: Trophy
  gameLogic: GameLogicV4
  vendingMachine: VendingMachine
  marketplace: Marketplace
  equipment: Equipment
  diceRoller: DiceRoller
  equipper: Equipper
  wrappedPTG: WrappedPTG
  freeBet: FreeBet
  tournamentLogger: TournamentLogger
  auction: Auction
  quickswapRouter: IUniswapV2Router02
  quickswapFactory: IUniswapV2Factory
  bundler: Bundler
  bettingLogger: BettingLogger
  kasumahValueLogger: KasumahValueLogger
  kasumahUintLogger: KasumahUintLogger
  bidChecker: BidChecker
  betChecker: BetChecker
  tournamentV5: TournamentV5
  gameLogicV5: GameLogicV5
  equipperV5: EquipperV5
  bettingPoolV6: BettingPoolV6
  diceRollerV5: DiceRollerV5
  trophyV5: TrophyV5
  auctionV2: AuctionV2
  freeBetCoin: FreeBetCoin
  inviteCode: InviteCode
  marketplaceWrapper: MarketplaceWrapper
}

const multicallWrapper = new MulticallWrapper(
  defaultProvider,
  providerNetwork,
  { batchScheduleFn: (cb) => setTimeout(cb, 200), maxBatchSize: 20 }
)

const wrappedMaticAddress: Record<string, string> = {
  mumbai: '0xBBC3b1B4CCD5FDB5832118b146276e70590a62a7',
  matic: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  'forked-matic': '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  localhost: localWmatic?.address || ''
}

const quickswapRouterAddress: Record<string, string> = {
  mumbai: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff', // TODO: don't think this is right, butleaving it here since we don't really use mumbai
  matic: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
  'forked-matic': '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
  localhost: localQuickswapRouter?.address || ''
}

interface NewUserData {
  inviteCodeTxHash?: string
  referrer?: string
  inviteCode?: string
}

export class GameChain extends EventEmitter {
  provider: providers.Provider

  contracts: Promise<Contracts>

  deprecatedAssets: Promise<Assets | undefined>

  prestigeID: Promise<BigNumber>

  gladiatorRange: Promise<[BigNumber, BigNumber]>

  wrappedMaticAddress: string = wrappedMaticAddress[networkName]

  trophyRangeV4: Promise<[BigNumber, BigNumber]>

  trophyRangeV5: Promise<[BigNumber, BigNumber]>

  signer?: SignerWithAddress

  safeAddress?: Address

  userSafe?: GnosisSafe

  relayer?: Relayer

  networkName = networkName

  chainId = providerNetwork

  localNetwork = localNetwork

  wsProvider?: providers.WebSocketProvider

  // when was the tournament contract first deployed?
  genesisBlock = genesisBlocks[networkName]

  connected = false

  connecting = false

  newUser?: NewUserData

  private ethereumPromise = ethereumProviderPromise

  deployerAddress = DEPLOYER_ADDRESS

  constructor(provider: providers.Provider) {
    super()
    this.provider = provider
    this.setupWsProvider()
    this.contracts = this.setupContracts()
    this.deprecatedAssets = this.setupDeprecatedAssets()
    this.prestigeID = this.fetchPrestigeId()
    this.gladiatorRange = this.fetchGladiatorRange()
    this.trophyRangeV4 = this.fetchTrophyRangeV4()
    this.trophyRangeV5 = this.fetchTrophyRangeV5()
    if (!this.wrappedMaticAddress) {
      throw new Error('undefined wrapped matic address')
    }
    this.loginReturningUser()
  }

  wrapInMulticall<T = Contract>(contract: Contract): Promise<T> {
    return multicallWrapper.wrap<T>(contract)
  }

  private setupWsProvider() {
    if (!wsProviderAddress) {
      return
    }
    const provider = new providers.WebSocketProvider(
      wsProviderAddress,
      providerNetwork
    )
    provider._websocket.onerror = (err: any) => {
      console.error('websocket error from provider', err)
      provider.destroy()
      this.setupWsProvider()
    }
    this.wsProvider = provider
  }

  private async loginReturningUser() {
    const isReturningTorus = sessionStorage.getItem('pageUsingTorus')
    const isReturningMetamask = sessionStorage.getItem('pageUsingMetamask')

    if (isReturningTorus) {
      this.connect()
      return
    }

    if (isReturningMetamask) {
      this.connectWithMetamask()
    }
  }

  private async relayerWrap<T>(contract: Contract): Promise<T> {
    if (this.signer) {
      if (!this.relayer) {
        throw new Error('signer was defined by not relayer')
      }
      return wrapContract<T>(contract, this.relayer)
    }

    return contract as any as T
  }

  async wrap<T>(contract: Contract): Promise<T> {
    return this.relayerWrap<T>(await this.wrapInMulticall(contract))
  }

  private async fetchTrophyRangeV4() {
    const { trophy } = await this.contracts
    return trophy.idRange()
  }

  private async fetchTrophyRangeV5() {
    const { trophyV5 } = await this.contracts
    return trophyV5.idRange()
  }

  async logout() {
    torus.logout()
    this.signer = undefined
    this.emit('logout')
  }

  async setupSigner(signer: SignerWithAddress) {
    const safeAddress = await walletMaker.walletAddressForUser(signer.address!)
    console.log('safeAddr: ', safeAddress)
    this.safeAddress = safeAddress
    this.userSafe = safeFromAddr(voidSigner, safeAddress)
    this.relayer = await setupRelayer(signer, this.provider)
    this.signer = signer
    this.setupContracts()
    this.deprecatedAssets = this.setupDeprecatedAssets()
  }

  private async setupWallet(wallet: any) {
    // really unclear what's broken about ethersjs but the 'network'
    // event doesn't emit in our setup when this happens - so instead
    // we are handling internally
    wallet.on('chainChanged', () => {
      console.log('chain changed')
      this.emit('chainChanged')
    })
    const walletProvider = new providers.Web3Provider(wallet as any, 'any')
    const network = await walletProvider.getNetwork()
    console.log('provider network: ', network, 'chain id: ', network.chainId)

    let signerWithAddress: SignerWithAddress | undefined = this.signer
    if (!signerWithAddress) {
      signerWithAddress = walletProvider.getSigner()

      const addr = await signerWithAddress.getAddress()
      signerWithAddress.address = addr
    }

    console.log('signer address: ', signerWithAddress.address!)

    const [safeAddress, hasWallet] = await Promise.all([
      walletMaker.walletAddressForUser(signerWithAddress.address!),
      walletMaker.isDeployed(signerWithAddress.address!)
    ])
    console.log('safe address, has wallet?', safeAddress, hasWallet)
    const referrer = getReferrer()
    const inviteCode = getInviteCode()

    const walletCreatorRespP = this.fetchWalletResponse({
      address: signerWithAddress.address!,
      inviteCode,
      referrer
    })

    console.log('safe: ', safeAddress)
    console.log('has wallet: ', signerWithAddress.address!, hasWallet)

    if (hasWallet) {
      console.log('user already has wallet')
    } else {
      const res = await walletCreatorRespP

      this.newUser = {
        referrer,
        inviteCode,
        inviteCodeTxHash: res.data.inviteCodeTx
      }

      if (!res.data.alreadyDeployed) {
        console.log('waiting for wallet deployment')
        await this.waitForWalletTx(res.data.walletTx)
        console.log('safe deployed: ', res.data.walletTx)
      }
    }

    return this.setupSigner(signerWithAddress)
  }

  async fetchWalletResponse({
    address: userAddress,
    referrer,
    inviteCode
  }: {
    address: string
    referrer?: string
    inviteCode?: string
  }) {
    return backOff(
      () => {
        return Axios.post('/api/wallet2', {
          userAddress,
          referrer,
          inviteCode
        })
      },
      {
        numOfAttempts: 10,
        maxDelay: 1000,
        delayFirstAttempt: true,
        startingDelay: 1000
      }
    )
  }

  async waitForWalletTx(txHash: string) {
    if (!this.provider) {
      throw new Error('no provider yet')
    }

    const tx = await backOff(
      () => {
        try {
          return this.provider.getTransaction(txHash)
        } catch (err) {
          console.error('error getting tx: ', err)
          throw err
        }
      },
      {
        maxDelay: 1000,
        numOfAttempts: 20
      }
    )
    return waitForRelayedTransaction(tx)
  }

  async hasMetamask() {
    const provider = await this.ethereumPromise
    return !!provider
  }

  async connectWithMetamask() {
    if (this.signer) {
      throw new Error('you must disconnect before reconnecting')
    }

    this.connecting = true
    this.emit('connecting')

    const ethereum = (await this.ethereumPromise) as
      | AbstractProvider
      | undefined
    if (!ethereum) {
      throw new Error('undefined ethereum provider')
    }
    const accounts = await ethereum.request!({ method: 'eth_requestAccounts' })
    console.log('account: ', accounts[0])
    await this.setupWallet(ethereum)
    this.connected = true
    this.connecting = false
    console.log('emitting connected. new user data: ', this.newUser)
    this.emit('connected', this.safeAddress)
    sessionStorage.setItem('pageUsingMetamask', 'true')
    torus.hideTorusButton()
  }

  async connect() {
    if (this.signer) {
      throw new Error('you must disconnect before reconnecting')
    }

    this.connecting = true
    this.emit('connecting')

    await torusInitPromise
    await torus.login({}) // await torus.ethereum.enable()
    sessionStorage.setItem('pageUsingTorus', 'true')
    await this.setupWallet(torus.provider)
    this.connected = true
    this.connecting = false
    this.emit('connected', this.safeAddress)
  }

  subscribe(
    contract: Contract,
    filter: EventFilter,
    cb: () => any
  ): Subscription {
    const { wsProvider } = this
    if (wsProvider) {
      wsProvider.on(filter, cb)
      return {
        unsubscribe: () => {
          wsProvider.off(filter, cb)
        }
      }
    }
    contract.on(filter, cb)
    return {
      unsubscribe: () => {
        contract.off(filter, cb)
      }
    }
  }

  private setupContracts() {
    const prov = this.provider
    const contractP = Promise.all([
      this.wrap<Assets>(Assets__factory.connect(addresses.Assets, prov)),
      this.wrap<TournamentV4>(
        TournamentV4__factory.connect(addresses.TournamentV4, prov)
      ),
      this.wrap<BettingPoolV5>(
        BettingPoolV5__factory.connect(addresses.BettingPoolV5, prov)
      ),
      this.wrap<Gladiator>(
        Gladiator__factory.connect(addresses.Gladiator, prov)
      ),
      this.wrap<GameLogicV4>(
        GameLogicV4__factory.connect(addresses.GameLogicV4, prov)
      ),
      this.wrap<VendingMachine>(
        VendingMachine__factory.connect(addresses.VendingMachine, prov)
      ),
      this.wrap<Marketplace>(
        Marketplace__factory.connect(addresses.Marketplace, prov)
      ),
      this.wrap<Equipment>(
        Equipment__factory.connect(addresses.Equipment, prov)
      ),
      this.wrap<DiceRoller>(
        DiceRoller__factory.connect(addresses.DiceRoller, prov)
      ),
      this.wrap<WrappedPTG>(
        WrappedPTG__factory.connect(addresses.WrappedPTG, prov)
      )
    ]).then(
      async ([
        assets,
        tournament,
        bettingPool,
        gladiator,
        gameLogic,
        vendingMachine,
        marketplace,
        equipment,
        diceRoller,
        wrappedPTG
      ]) => {
        const [trophyAddr, equipperAddr] = await Promise.all([
          tournament.trophies(),
          gameLogic.equipper()
        ])

        const [
          trophy,
          equipper,
          freeBet,
          tournamentLogger,
          quickswapRouter,
          auction,
          bundler,
          bettingLogger,
          kasumahValueLogger,
          bidChecker
        ] = await Promise.all([
          this.wrap<Trophy>(Trophy__factory.connect(trophyAddr, prov)),
          this.wrap<Equipper>(Equipper__factory.connect(equipperAddr, prov)),
          this.wrap<FreeBet>(FreeBet__factory.connect(addresses.FreeBet, prov)),
          this.wrap<TournamentLogger>(
            TournamentLogger__factory.connect(addresses.TournamentLogger, prov)
          ),
          this.wrap<IUniswapV2Router02>(
            IUniswapV2Router02__factory.connect(
              quickswapRouterAddress[this.networkName],
              prov
            )
          ),
          this.wrap<Auction>(Auction__factory.connect(addresses.Auction, prov)),
          this.wrap<Bundler>(Bundler__factory.connect(addresses.Bundler, prov)),
          this.wrap<BettingLogger>(
            BettingLogger__factory.connect(addresses.BettingLogger, prov)
          ),
          // purposely do NOT use the full wrapping here (don't use multicall) as these can error and we don't want to spoil the batch
          this.relayerWrap<KasumahValueLogger>(
            KasumahValueLogger__factory.connect(
              addresses.KasumahValueLogger,
              prov
            )
          ),
          this.wrap<BidChecker>(
            BidChecker__factory.connect(addresses.BidChecker, prov)
          )
        ])

        const [
          tournamentV5,
          diceRollerV5,
          equipperV5,
          gameLogicV5,
          bettingPoolV6,
          trophyV5,
          auctionV2,
          freeBetCoin,
          inviteCode,
          marketplaceWrapper
        ] = await Promise.all([
          this.wrap<TournamentV5>(
            TournamentV5__factory.connect(addresses.TournamentV5, prov)
          ),
          this.wrap<DiceRollerV5>(
            DiceRollerV5__factory.connect(addresses.DiceRollerV5, prov)
          ),
          this.wrap<EquipperV5>(
            EquipperV5__factory.connect(addresses.EquipperV5, prov)
          ),
          this.wrap<GameLogicV5>(
            GameLogicV5__factory.connect(addresses.GameLogicV5, prov)
          ),
          this.wrap<BettingPoolV6>(
            BettingPoolV6__factory.connect(addresses.BettingPoolV6, prov)
          ),
          this.wrap<TrophyV5>(
            TrophyV5__factory.connect(addresses.TrophyV5, prov)
          ),
          this.wrap<AuctionV2>(
            AuctionV2__factory.connect(addresses.AuctionV2, prov)
          ),
          this.wrap<FreeBetCoin>(
            FreeBetCoin__factory.connect(addresses.FreeBetCoin, prov)
          ),
          this.wrap<InviteCode>(
            InviteCode__factory.connect(addresses.InviteCode, prov)
          ),
          this.wrap<MarketplaceWrapper>(
            MarketplaceWrapper__factory.connect(
              addresses.MarketplaceWrapper,
              prov
            )
          )
        ])

        const betChecker = BetChecker__factory.connect(
          addresses.BetChecker,
          prov
        )

        const kasumahUintLogger = await this.wrap<KasumahUintLogger>(
          KasumahUintLogger__factory.connect(addresses.KasumahUintLogger, prov)
        )

        const factoryAddr = await quickswapRouter.factory()
        const quickswapFactory = await this.wrap<IUniswapV2Factory>(
          IUniswapV2Factory__factory.connect(factoryAddr, prov)
        )
        console.log(marketplaceWrapper.address, 'market place account')

        return {
          assets,
          tournament,
          bettingPool,
          gladiator,
          trophy,
          gameLogic,
          vendingMachine,
          marketplace,
          equipment,
          diceRoller,
          equipper,
          wrappedPTG,
          freeBet,
          tournamentLogger,
          quickswapRouter,
          quickswapFactory,
          auction,
          bundler,
          bettingLogger,
          kasumahValueLogger,
          kasumahUintLogger,
          bidChecker,
          betChecker,
          tournamentV5,
          diceRollerV5,
          gameLogicV5,
          equipperV5,
          bettingPoolV6,
          trophyV5,
          auctionV2,
          freeBetCoin,
          inviteCode,
          marketplaceWrapper
        }
      }
    )

    this.contracts = contractP
    this.emit('newContracts')
    return contractP
  }

  private async setupDeprecatedAssets() {
    const { assets } = await this.contracts
    const oldAssetsAddress = await assets.deprecatedAssets()
    if (
      [
        '0xa3D13a6B0dCF526a4A939440BfE72E9AfDf5Af74', // this was an error in the production deploy setting an incorrect deprecated assets
        constants.AddressZero
      ].includes(oldAssetsAddress)
    ) {
      return undefined
    }
    return this.wrap<Assets>(
      Assets__factory.connect(oldAssetsAddress, this.provider)
    )
  }

  private async fetchPrestigeId() {
    const { assets } = await this.contracts
    const range = await assets.idRange(PRESTIGE_NAME)
    return range[0]
  }

  private async fetchGladiatorRange() {
    const { assets } = await this.contracts
    const range = await assets.idRange(GLADIATOR_NAME)
    return range
  }
}

// useful for debugging
const chain = new GameChain(defaultProvider)
window.chain = chain

export default chain
