import React, { useMemo } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BigNumber } from 'ethers'
import { MinimalTournamnet } from '../../../../hooks/useTournament'
import { TOURNAMENT_IMAGE_KEY, useSkyDb } from '../../../../hooks/useSkyDB'
import { useBetsByTournament } from '../../../../hooks/useBetting'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Spinner from '../../../atoms/Spinner/Spinner'
import MinimalGladiatorImage from '../../../composed/MinimalGladiatorImage/MinimalGladiatorImage'
import TournamentTime from '../../../atoms/TournamentTime/TournamentTime'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

export interface TournamentCardProps {
  className?: string
  tournament: MinimalTournamnet
}

const TournamentCard: React.FC<TournamentCardProps> = ({
  className,
  tournament
}) => {
  const { name, id, lastRoll, notBefore } = tournament
  const { data: images } = useSkyDb(TOURNAMENT_IMAGE_KEY)
  const { betAmounts } = useBetsByTournament(tournament.id)
  const isComplete = BigNumber.from(lastRoll).gt(0)
  const tournamentImage =
    images && images[BigNumber.from(tournament.id).toHexString()]

  const getBackground = useMemo(() => {
    const isAlpha = tournament.name
      .split(' ')
      .find((text) => text.toLowerCase() === 'alpha')

    const isOmega = tournament.name
      .split(' ')
      .find((text) => text.toLowerCase() === 'omega')

    if (tournamentImage) {
      return tournamentImage
    }

    if (isAlpha) {
      return '/images/backgrounds/tournament/alpha.svg'
    }

    if (isOmega) {
      return '/images/backgrounds/tournament/omega.svg'
    }

    return '/images/backgrounds/tournament/regular.svg'
  }, [tournament.name, tournamentImage])

  const totalPurse = betAmounts.tournament.add(betAmounts.incentive)

  return (
    <Container className={classNames('', className)}>
      <div className="flex flex-col justify-between h-full">
        <figure className="flex  items-center p-5 justify-center">
          <SkynetImageFixer
            src={getBackground}
            alt="tournament"
            className="object-cover tournament-image w-full"
          />
        </figure>

        <div className="p-12 flex flex-grow flex-col justify-between ">
          <section>
            <h3 className="font-gilroy-medium uppercase text-p3 text-gray-300">
              Total purse
            </h3>
            <div className="text-h5 mt-1 mb- text-green-600 font-gilroy-semibold leading-10">
              {totalPurse.eq(0) ? (
                <Spinner />
              ) : (
                `${humanBigNumber(totalPurse)} PTG`
              )}
            </div>
          </section>

          <section className="flex-grow">
            <h5 className="text-p1 mt-7 font-gilroy-semibold">{name}</h5>

            <TournamentTime notBefore={notBefore} />
          </section>

          <section>
            <div className="flex my-7 ml-8">
              {tournament.gladiators.map((gladiator) => {
                return (
                  <MinimalGladiatorImage
                    gladiator={gladiator}
                    key={`glad-image-${gladiator.id.toString()}-${id.toString()}`}
                  />
                )
              })}
            </div>
            <Link
              to={`/tournament/${id.toString()}`}
              className=" px-10 py-4 text-primary text-p2 font-gilroy-semibold duration-200 inline-flex items-center justify-center transition-colors rounded-full border border-gray-700 "
            >
              {isComplete ? 'Relive the Glory' : 'View and Bet'}
            </Link>
          </section>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.article`
  background: #151520;
  border-radius: 24px;
  max-width: 325px;
  min-width: 325px;

  .tournament-image {
    height: 150px;
    border-radius: 24px;
  }
`

export default TournamentCard
