import Lottie from 'lottie-react'
import React, { useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Avatar from '../components/atoms/Avatar/Avatar'
import Button from '../components/atoms/Button/Button'
import Spinner from '../components/atoms/Spinner/Spinner'
import BackButton from '../components/composed/BackButton/BackButton'
import CustomProgressGroup from '../components/composed/CustomProgressGroup/CustomProgressGroup'
import SpinnerWithTitle from '../components/composed/SpinnerWithTitle/SpinnerWithTitle'
import Tabs from '../components/composed/Tabs/Tabs'
import BiddingCard from '../components/page-specific/auction/BiddingCard/BiddingCard'
import BidOnAuctionModal from '../components/page-specific/auction/BidOnAuctionModal/BidOnAuctionModal'
import GladiatorBiography from '../components/page-specific/gladiator-detail/GladiatorBiography/GladiatorBiography'
import { useWeb3Context } from '../contexts/Web3Context'
import {
  useAuction,
  useAuctionBidders,
  useAuctionHighestBid,
  useUserAuctionBid,
  useAuctionWithdraw,
  useIsV2Auction
} from '../hooks/useAuction'
import { useGladiator, useGladiatorRank } from '../hooks/useGladiator'
import { usePrestigeBalance } from '../hooks/usePrestigeBalance'
import { Layout } from '../layouts/Layout'
import humanBigNumber from '../utils/humanBigNumber'
import medalRank1 from '../assets/images/medals/1.svg'
import medalRank2 from '../assets/images/medals/2.svg'
import medalRank3 from '../assets/images/medals/3.svg'
import medalRank4 from '../assets/images/medals/4.svg'
import medalRank5 from '../assets/images/medals/5.svg'
import winnerAnimation from '../assets/animations/prestige-signup.json'
import ConnectButton from '../components/atoms/ConnectButton/ConnectButton'
import SkynetImageFixer from '../components/atoms/SkynetImageFixer/SkynetImageFixer'

interface TimeUnitProps {
  value: number
  title: string
}

interface PlaceBidButtonProps {
  placeBid: () => void
  withdraw: () => void
  auctionId: string
  highestBidder: string
  safeAddress: string
}

interface WithdrawButtonProps {
  highestBidder: string
  highestBidderWithdrawn: boolean
  auctionId: string
  safeAddress: string
  withdraw: () => void
}

interface CancelledButtonProps {
  auctionId: string
  withdraw: () => void
}

const TimeUnit: React.FC<TimeUnitProps> = ({ value, title }) => {
  return (
    <div className="mr-8">
      <p className="text-28 font-gilroy-bold mt-2 mb-1">{value}</p>
      <p className="text-p4 uppercase text-gray-400 font-gilroy-medium">
        {title}
      </p>
    </div>
  )
}

const medals = [medalRank1, medalRank2, medalRank3, medalRank4, medalRank5]

const GladiatorDetails: React.FC<{ id: string }> = ({ id }) => {
  const { gladiator } = useGladiator(id)
  const { rank } = useGladiatorRank(id)

  if (!gladiator || !gladiator.properties) {
    return <SpinnerWithTitle title="Fetching gladiator ..." />
  }

  return (
    <section className="mt-20 w-full ">
      <h1 className="text-h4 font-gilroy-semibold">{gladiator.name}</h1>
      <div className="flex items-center">
        <p className=" text-p2 text-gray-300 font-gilroy-normal">Ranked:</p>
        {rank && (
          <div className=" flex items-center ml-4">
            {rank < 6 ? (
              <>
                <img src={medals[rank - 1]} alt={`medal of rank ${rank}`} />
                <span className="font-gilroy-bold text-h6 inline-block -mb-2 ml-2">
                  {` 0${rank}`}
                </span>
              </>
            ) : (
              <span className="font-gilroy-bold text-h6 inline-block ml-2">
                {`0${rank}`}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="mt-8 flex items-center mb-8">
        <div className="flex items-center">
          <img
            className="rounded-full"
            src="/images/icons/prestige.svg"
            alt="ptg"
          />
          <p className="text-h6 inline-block ml-2 text-gray-300">$PTG</p>
        </div>

        <span className="font-gilroy-bold text-h6 inline-block ml-2">
          {`${humanBigNumber(gladiator.properties.prestige)}`}
        </span>
      </div>
      <div className="my-6 lg:block hidden">
        <GladiatorBiography
          name={gladiator.name}
          historyUrl={gladiator.properties.history}
        />
      </div>
      <div className="lg:w-4/5 w-full">
        <CustomProgressGroup
          stats={[
            {
              title: 'Attack',
              color: '#FFD37E',
              stat: {
                value: gladiator.properties.attack,
                max: 2000
              }
            },
            {
              title: 'Defense',
              color: '#E37171',
              stat: {
                value: gladiator.properties.defense,
                max: 2000
              }
            },
            {
              title: 'Hit points',
              color: '#7694FF',
              stat: {
                value: gladiator.properties.hitpoints,
                max: 2000
              }
            },
            {
              title: 'POP',
              color: '#CD11BA',
              stat: {
                value: gladiator.properties.pop,
                max: 2000
              }
            }
          ]}
        />
      </div>
    </section>
  )
}

const PlacePTGButton: React.FC<PlaceBidButtonProps> = ({
  placeBid,
  withdraw,
  auctionId,
  safeAddress,
  highestBidder
}) => {
  const isv2Auction = useIsV2Auction()
  const { amount: userBid, loading } = useUserAuctionBid(auctionId, isv2Auction)
  const isHighestBidder = useMemo(() => {
    return safeAddress === highestBidder
  }, [safeAddress, highestBidder])

  if (loading) {
    return <Spinner />
  }

  const hasPlacedBid = userBid && userBid.gt(0)

  return hasPlacedBid ? (
    <div className="w-full flex flex-wrap -pb-8 justify-between items-center">
      <p className=" text-p2 mr-4 mb-8">
        Your bid:
        <b>{` ${humanBigNumber(userBid!)} $PTG`}</b>
      </p>

      <div className="flex items-center mb-8">
        {!isHighestBidder && (
          <Button
            rounded
            background="bg-black"
            padding="px-10 py-3"
            fontSize="text-p2"
            className="border mr-6 border-primary font-gilroy-bold"
            onClick={withdraw}
            color="text-primary"
          >
            Reclaim {humanBigNumber(userBid!)}PTG
          </Button>
        )}

        <Button
          rounded
          background="bg-primary"
          padding="px-10 py-3"
          fontSize="text-p2"
          className="border border-primary font-gilroy-bold"
          onClick={placeBid}
          color="text-white"
        >
          Place Bid
        </Button>
      </div>
    </div>
  ) : (
    <div className="w-full flex flex-col items-center">
      <Button
        rounded
        padding="px-10 py-4"
        fontSize="text-p2"
        className="font-gilroy-bold uppercase w-1/2"
        onClick={placeBid}
      >
        Place bid
      </Button>
    </div>
  )
}

const WithdrawPTGbutton: React.FC<WithdrawButtonProps> = ({
  auctionId,
  safeAddress,
  highestBidder,
  highestBidderWithdrawn,
  withdraw
}) => {
  const isv2Auction = useIsV2Auction()
  const { amount: userBid, loading } = useUserAuctionBid(auctionId, isv2Auction)

  const isHighestBidder = useMemo(() => {
    return safeAddress === highestBidder
  }, [safeAddress, highestBidder])

  if (loading) {
    return <Spinner />
  }

  const hasPlacedBid = userBid && userBid.gt(0)

  return isHighestBidder ? (
    <div className="w-full flex flex-col mt-10 items-center">
      <p className="mb-6 text-p1 text-gray-200">You won the auction</p>
      <Lottie
        animationData={winnerAnimation}
        style={{
          width: 200
        }}
      />
      {!highestBidderWithdrawn && (
        <Button
          rounded
          background="bg-black"
          padding="px-10 py-3"
          fontSize="text-p2"
          className="border border-primary font-gilroy-bold"
          onClick={withdraw}
          color="text-primary"
        >
          Claim item
        </Button>
      )}
    </div>
  ) : (
    <div className="w-full flex flex-wrap justify-between items-center">
      <p className=" text-p2 mr-4 mb-4 lg:mb-0">
        You placed a bid of
        <b>{` ${humanBigNumber(userBid!)} $PTG`}</b>
      </p>
      {hasPlacedBid && (
        <Button
          rounded
          background="bg-black"
          padding="px-10 py-3"
          fontSize="text-p2"
          className="border border-primary font-gilroy-bold"
          onClick={withdraw}
          color="text-primary"
        >
          Reclaim {humanBigNumber(userBid!)}PTG
        </Button>
      )}
    </div>
  )
}

const AuctionCancelledButton: React.FC<CancelledButtonProps> = ({
  auctionId
}) => {
  const isv2Auction = useIsV2Auction()
  const { amount: userBid, loading } = useUserAuctionBid(auctionId, isv2Auction)
  const { withdraw } = useAuctionWithdraw()

  if (loading) {
    return <Spinner />
  }

  const hasPlacedBid = userBid && userBid.gt(0)

  return hasPlacedBid ? (
    <div className="w-full flex flex-wrap justify-between items-center">
      <p className=" text-p2 mr-4 mb-4 lg:mb-0">
        You placed a bit of
        <b>{` ${humanBigNumber(userBid!)} $PTG`}</b>
      </p>
      <Button
        rounded
        background="bg-black"
        padding="px-10 py-3"
        fontSize="text-p2"
        className="border border-primary font-gilroy-bold"
        onClick={() => withdraw(auctionId, isv2Auction)}
        color="text-primary"
      >
        Reclaim {humanBigNumber(userBid!)}PTG
      </Button>
    </div>
  ) : null
}

const AuctionItemPage = () => {
  const ctx = useWeb3Context()
  const isV2Auction = useIsV2Auction()
  const { auctionId } = useParams<{ auctionId: string }>()
  const { withdraw } = useAuctionWithdraw()
  const { loading: web3Loading, connected: web3Connected, safeAddress } = ctx
  const auction = useAuction(auctionId, isV2Auction)
  const { bidders } = useAuctionBidders(auctionId, isV2Auction)
  const { highestBid, loading: highestBigLoading } = useAuctionHighestBid(
    auctionId,
    isV2Auction
  )
  const [activeBidTab, setActiveBidTab] = useState(0)
  const [showBidModal, setShowBidModal] = useState(false)
  const prestigeBalance = usePrestigeBalance()
  const [withdrawalErrorMessage, setWithdrawalErrorMessage] = useState('')

  const [withdrawLoading, setWithdrawLoading] = useState(false)

  const highestBidder = useMemo(() => {
    if (!auction || !bidders) return ''
    const latestBidder = [...bidders].reverse()[0]
    if (!latestBidder) return ''
    return latestBidder.bidder.toLowerCase()
  }, [auction, bidders])

  const isHighestBidder = useMemo(() => {
    if (!auction || !bidders) return false
    const latestBidder = [...bidders].reverse()[0]
    if (!latestBidder) return false
    return latestBidder.bidder.toLowerCase() === safeAddress?.toLowerCase()
  }, [safeAddress, auction, bidders])

  if (!auction) {
    return <SpinnerWithTitle title="Auction loading ... prepare your PTG :)" />
  }

  const { isEquipment, itemDetails } = auction

  const name = isEquipment
    ? itemDetails?.equipment?.name
    : itemDetails?.gladiator?.name

  const image = isEquipment
    ? itemDetails?.equipment?.image
    : itemDetails?.gladiator?.image

  const description = isEquipment
    ? itemDetails?.equipment?.attributes.description
    : itemDetails?.gladiator?.name

  const auctionHasStarted = auction.startTime * 1000 < Date.now()
  const auctionHasNotEnded = auction.endTime * 1000 > Date.now()

  const onWithdrawHandler = async () => {
    setWithdrawalErrorMessage('')
    try {
      setWithdrawLoading(true)
      const tx = await withdraw(auctionId, isV2Auction)
      console.log('withdraw: ', tx.hash)
      await tx.wait()
    } catch (error) {
      setWithdrawalErrorMessage('An error occured when withdrawing')
      console.error('error withdrawing: ', error)
    } finally {
      setWithdrawLoading(false)
    }
  }

  const showPlaceButton =
    web3Connected &&
    !web3Loading &&
    safeAddress &&
    auctionHasNotEnded &&
    !auction.canceled &&
    !withdrawLoading &&
    auctionHasStarted

  const showWithDrawButton =
    web3Connected &&
    safeAddress &&
    !web3Loading &&
    !auctionHasNotEnded &&
    !auction.canceled &&
    !withdrawLoading

  console.log('Auction end time: ', auction.endTime)
  return (
    <Layout>
      <div className="lg:px-56 px-6 max-w-screen-2xl w-full">
        <div className="lg:mt-16 mt-0 w-full mb-10 -ml-5">
          <BackButton />
        </div>

        <div className="w-full flex flex-col lg:flex-row p-8 lg:p-32 bg-black">
          <div className=" w-full flex lg:block flex-col items-center lg:w-1/2 mr-32">
            <ImageBackground className=" w-full flex flex-col items-center justify-between">
              <SkynetImageFixer alt="item" src={image} />
              <div className=" bg-black mx-5 bg-opacity-50 mt-3 py-8 px-6 mb-32">
                <p className=" uppercase text-center font-neversaydie text-p4 lg:text-p1 tracking-wider ">
                  {name}
                </p>
              </div>
            </ImageBackground>

            {isEquipment ? (
              <section className="mt-20 w-full">
                <h1 className="text-h4 font-gilroy-semibold">{name}</h1>
                <p className="text-p1 my-6 font-gilroy-medium">{description}</p>
              </section>
            ) : (
              <GladiatorDetails
                id={itemDetails?.gladiator?.id.toString() || ''}
              />
            )}
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex items-center justify-between p-12 bg-opacity-40 rounded-12 bg-gray-800 flex-wrap mb-10">
              <div className="mt-4">
                <p className=" font-bold text-p4 uppercase text-gray-200">
                  Highest bid
                </p>
                {highestBigLoading ? (
                  <Spinner />
                ) : (
                  <p className="text-28 font-gilroy-bold mt-2 mb-1">
                    {humanBigNumber(highestBid!)} $PTG
                  </p>
                )}

                <p className="text-p3 text-gray-400 font-gilroy-medium">
                  {`Min: ${humanBigNumber(auction.minBidAmount)} $PTG`}
                </p>
              </div>

              {auctionHasStarted ? (
                <div className="mt-4">
                  {auctionHasNotEnded && (
                    <p className=" font-gilroy-semibold uppercase text-p4 text-gray-200">
                      Auction ending in
                    </p>
                  )}

                  <Countdown
                    date={new Date(auction.endTime * 1000)}
                    renderer={({ days, hours, minutes, seconds }) => {
                      if (
                        days === 0 &&
                        hours === 0 &&
                        minutes === 0 &&
                        seconds === 0
                      ) {
                        return (
                          <p className="text-28 font-gilroy-bold mt-8 mb-1">
                            Auction Ended
                          </p>
                        )
                      }
                      return (
                        <div className="flex items-center ">
                          {days ? (
                            <TimeUnit value={days} title="days" />
                          ) : (
                            <></>
                          )}
                          <TimeUnit value={hours} title="hours" />
                          <TimeUnit value={minutes} title="minutes" />
                          <TimeUnit value={seconds} title="seconds" />
                        </div>
                      )
                    }}
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <p className=" font-gilroy-semibold uppercase text-p4 text-gray-200">
                    Auction will start
                  </p>
                  <Countdown
                    date={new Date(auction.startTime * 1000)}
                    renderer={({ days, hours, minutes, seconds }) => {
                      return (
                        <div className="flex items-center ">
                          {days ? (
                            <TimeUnit value={days} title="days" />
                          ) : (
                            <></>
                          )}
                          <TimeUnit value={hours} title="hours" />
                          <TimeUnit value={minutes} title="minutes" />
                          <TimeUnit value={seconds} title="seconds" />
                        </div>
                      )
                    }}
                  />
                </div>
              )}

              <div className=" mt-16 flex flex-col items-center justify-center w-full">
                {web3Loading && <Spinner />}

                {withdrawLoading && (
                  <div className="flex flex-col items-center">
                    <img
                      className="rounded-full"
                      src="/images/icons/prestige.svg"
                      alt="ptg"
                    />
                    <div className="flex flex-col items-center mt-8">
                      <p className=" text-p1 mb-4">
                        Withdrawing bid, please wait ...
                      </p>
                      <Spinner />
                    </div>
                  </div>
                )}
                {!web3Connected && !web3Loading && !withdrawLoading && (
                  <ConnectButton customText="Sign in to bid" />
                )}

                {auction.canceled && !withdrawLoading && (
                  <div>
                    <h1 className="text-28 font-gilroy-bold mt-2 mb-1">
                      Auction was cancelled
                    </h1>
                    <AuctionCancelledButton
                      withdraw={onWithdrawHandler}
                      auctionId={auctionId}
                    />
                  </div>
                )}

                {highestBigLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {showPlaceButton && safeAddress && (
                      <PlacePTGButton
                        withdraw={onWithdrawHandler}
                        auctionId={auctionId}
                        safeAddress={safeAddress.toLowerCase()}
                        highestBidder={highestBidder}
                        placeBid={() => setShowBidModal(true)}
                      />
                    )}
                  </>
                )}

                {showWithDrawButton && safeAddress && (
                  <WithdrawPTGbutton
                    auctionId={auctionId}
                    withdraw={onWithdrawHandler}
                    safeAddress={safeAddress.toLowerCase()}
                    highestBidder={highestBidder}
                    highestBidderWithdrawn={auction.highestBidderHasWithdrawn}
                  />
                )}

                {withdrawalErrorMessage && (
                  <p className="text-p2 text-red-400 text-center my-8">
                    {withdrawalErrorMessage}
                  </p>
                )}

                {isHighestBidder && auctionHasNotEnded && (
                  <div className=" mt-20 flex justify-between items-center">
                    <img className="h-12" src={medalRank1} alt="ptg" />

                    <p className="text-p2 text-gray-300 ml-4">Highest bidder</p>
                  </div>
                )}
              </div>
            </div>

            <div className=" py-16">
              <h2 className=" text-base mb-5 font-gilroy-semibold">Owner</h2>
              <div className="inline-flex w-full  bg-gray-900 bg-opacity-80 rounded-full items-center py-4 pl-10 pr-16">
                <Avatar text={auction.owner.toString()} />
                <p className="ml-4 font-gilroy-semibold truncate w-1/2 lg:w-full text-base text-primary">
                  {auction.owner.toString()}
                </p>
              </div>
            </div>

            <div className="">
              <div className="text-p-lg mb-16 border-b border-primary border-opacity-40">
                <Tabs
                  tabs={['bid']}
                  activeTabIndex={activeBidTab}
                  onTabSelect={setActiveBidTab}
                />
              </div>
              <div className="w-full">
                {bidders ? (
                  [...bidders]
                    .reverse()
                    .map((bid) => (
                      <BiddingCard
                        key={bid.bidder.toString() + bid.amount.toString()}
                        bid={bid}
                        safeAddress={safeAddress}
                      />
                    ))
                ) : (
                  <Spinner />
                )}

                {!bidders?.length && bidders && (
                  <div className="px-6 lg:px-20 w-full">
                    <div className="flex flex-col items-center w-full rounded-12 py-24 px-6 lg:px-12">
                      <img
                        src="/images/symbols/no-conquest.svg"
                        alt="no conquest"
                      />
                      <p className=" font-gilroy-semibold mt-8 text-p-lg py-4 text-center">
                        No bids yet
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {highestBid && (
        <BidOnAuctionModal
          prestigeBalance={prestigeBalance}
          ctx={ctx}
          auctionId={auction.id}
          show={showBidModal}
          minBidIncrement={auction.minBidIncrement}
          minBidAmount={auction.minBidAmount}
          highestBid={highestBid}
          onModalClose={() => setShowBidModal(false)}
        />
      )}
    </Layout>
  )
}

const ImageBackground = styled.section`
  background: url('/images/symbols/auction-card-bg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 433px;
  width: 433px;
  img {
    height: 235px;
    margin-top: 80px;
  }

  @media screen and (max-width: 767px) {
    height: 248px;
    width: 248px;

    img {
      height: 125px;
      margin-top: 40px;
    }
  }
`

export default AuctionItemPage
