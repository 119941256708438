import React from 'react'
import Spinner from '../../atoms/Spinner/Spinner'

interface SpinnerWithTitleProps {
  title: string
}

const SpinnerWithTitle: React.FC<SpinnerWithTitleProps> = ({ title }) => {
  return (
    <div className="flex flex-col items-center py-64">
      <h3 className="font-gilroy-medium text-gray-400 text-p1 mb-4">{title}</h3>
      <Spinner />
    </div>
  )
}

export type { SpinnerWithTitleProps }
export default SpinnerWithTitle
