import React from 'react'

interface AuctionTimeUnitProps {
  value: number
  title: string
}

const AuctionTimeUnit: React.FC<AuctionTimeUnitProps> = ({ value, title }) => {
  return (
    <div className="mr-4">
      <p className="text-p1 font-gilroy-bold mt-2 mb-1">{value}</p>
      <p className="text-p4 uppercase text-gray-400 font-gilroy-medium">
        {title}
      </p>
    </div>
  )
}

export default AuctionTimeUnit
