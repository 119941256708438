import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTournament } from '../../../hooks/useTournament'
import PlayerInterface from '../../../interfaces/Player.interface'
import SkynetImageFixer from '../../atoms/SkynetImageFixer/SkynetImageFixer'
import Spinner from '../../atoms/Spinner/Spinner'

interface BattleProgressIndicatorProps {
  tournamentId: string
}

const BattleProgressGlad: React.FC<{ gladidator?: PlayerInterface }> = ({
  gladidator
}) => {
  return (
    <div className="flex items-center">
      <SkynetImageFixer
        className=" w-16 h-16"
        src={gladidator?.image}
        alt={gladidator?.name}
      />
      <p className=" hidden lg:block text-p-lg font-gilroy-medium text-gray-200 ml-4">
        {gladidator?.name}
      </p>
    </div>
  )
}

const BattleProgressIndicator: React.FC<BattleProgressIndicatorProps> = ({
  tournamentId
}) => {
  const { tournament, loading: tournLoading } = useTournament(tournamentId)

  const getLiveMatch = useMemo(() => {
    const round = tournament?.bracket[tournament?.currentRound]
    const game = round ? round[tournament?.currentGame] : undefined
    return {
      home: game ? game[0] : undefined,
      away: game ? game[1] : undefined
    }
  }, [tournament?.currentGame, tournament?.currentRound, tournament?.bracket])

  if (!tournament.started) {
    return <></>
  }

  return tournLoading ? (
    <div className=" mt-28">
      <Spinner />
    </div>
  ) : (
    <BattleProgressBar className="flex items-center justify-center  max-w-screen">
      <div className="flex items-center">
        <p className="whitespace-nowrap hidden lg:block text-primary font-gilroy-medium text-p2 uppercase">
          Battle in progress
        </p>
        <div className="glad-container flex items-center mx-4 lg:mx-20 py-3 lg:py-6 px-6 lg:px-12 rounded-full">
          <BattleProgressGlad gladidator={getLiveMatch.home} />
          <p className="text-p-lg font-bold  font-neversaydie text-primary mx-6">
            VS
          </p>
          <BattleProgressGlad gladidator={getLiveMatch.away} />
        </div>
        <Link
          to={`/battle/${tournament.tournamentId}/${tournament.currentRound}/${tournament.currentGame}`}
          className="font-gilroy-semibold duration-200 whitespace-nowrap hidden lg:inline-flex items-center justify-center transition-colors  bg-primary text-white px-10 py-3 rounded-full text-p2 w-auto"
          type="button"
        >
          Check it out
        </Link>

        <Link
          to={`/battle/${tournament.tournamentId}/${tournament.currentRound}/${tournament.currentGame}`}
          className=" underline text-p2 text-primary inline-flex lg:hidden"
          type="button"
        >
          Check it out
        </Link>
      </div>
    </BattleProgressBar>
  )
}

const BattleProgressBar = styled.div`
  background: #151520;
  padding: 10px 0px;
  width: 100%;
  position: relative;
  z-index: 49;
  margin-top: 65px;
  margin-bottom: -50px;
  .glad-container {
    background: #232335;
    padding: 16px 32px;
  }

  @media screen and (max-width: 767px) {
    .glad-container {
      padding: 6px 10px;
    }
  }
`

export type { BattleProgressIndicatorProps }
export default BattleProgressIndicator
