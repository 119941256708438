import { utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useWeb3Context } from '../../../../contexts/Web3Context'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Button from '../../../atoms/Button/Button'
import Modal from '../../../atoms/Modal/Modal'
import Spinner from '../../../atoms/Spinner/Spinner'
import useMaticBalance from '../../../../hooks/useMaticBalance'
import waitForTx from '../../../../utils/waitForTx'

export interface TransferMaticModalProps {
  show: boolean
  onModalClose: () => void
}

interface TransferFormData {
  receiverAddress?: string
  amountToTransfer?: string
}

const TransferMaticModal: React.FC<TransferMaticModalProps> = ({
  show,
  onModalClose
}) => {
  const { signer, relayer, safeAddress } = useWeb3Context()
  const { balance } = useMaticBalance()
  const { register, handleSubmit, reset, setError, clearErrors, errors } =
    useForm<TransferFormData>()

  const [transferLoading, setTransferLoading] = useState(false)

  useEffect(() => {
    clearErrors()
  }, [clearErrors])

  const onSubmit = handleSubmit(
    async ({ receiverAddress, amountToTransfer }) => {
      try {
        clearErrors()
        setTransferLoading(true)

        if (!signer || !relayer) {
          throw new Error('no signer or relayer')
        }

        if (!amountToTransfer || !receiverAddress) {
          throw new Error('missing form information')
        }

        console.log('amount to transfer:', amountToTransfer)

        const tx = await waitForTx(relayer.multisend([{
          to: receiverAddress,
          from: safeAddress!,
          value: utils.parseEther(amountToTransfer)
        }])) // TODO: upgrade kasumah multisend to handle TransactionRequest as well
        console.log('matic transfer: ', tx)
        setTransferLoading(false)
        reset()
        onModalClose()
      } catch (error) {
        setTransferLoading(false)
        setError('receiverAddress', {
          message: `There was an error with your transaction: ${error.message}`
        })
      }
    }
  )

  return (
    <Modal showModal={show} onModalClose={onModalClose} gradientBorder={false}>
      <form className="p-16 bg-dark rounded-24" onSubmit={onSubmit}>
        <legend className="text-h5 mb-16 font-gilroy-medium ">
          Transfer Matic
        </legend>
        {errors.receiverAddress && (
          <legend className="text-base mb-16 font-gilroy-medium text-theme-red">
            {errors.receiverAddress.message}
          </legend>
        )}

        {transferLoading ? (
          <div className="flex flex-col mt-28 items-center">
            <img
              className="rounded-full h-24 w-24"
              src="/factions/MTC.png"
              alt="MATIC"
            />
            <div className="flex flex-col items-center mb-16 mt-8">
              <p className=" text-p1 mb-4">
                Transferring MATIC, please wait ...
              </p>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <section className="mb-16">
              <div className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
                <p className="text-p3 text-gray-300 font-gilroy-medium mb-2">
                  Amount to Send:
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src="/factions/MTC.png" alt="$MATIC" className="h-24 w-24" />
                    <p className=" px-5 text-p-lg text-gray-200">MATIC</p>
                    <button
                      type="submit"
                      className="px-6 py-2 focus:outline-none text-primary bg-primary bg-opacity-20 text-p4 tracking-wide font-gilroy-semibold rounded-full"
                    >
                      MAX
                    </button>
                  </div>
                  <input
                    required
                    className=" bg-transparent focus:outline-none text-p3 font-gilroy-medium "
                    placeholder="Enter amount here"
                    type="number"
                    name="amountToTransfer"
                    ref={register({
                      required: true
                    })}
                  />
                </div>
              </div>
              <p className="text-p3 font-gilroy-semibold mt-3 text-gray-300">
                BAL: {humanBigNumber(balance || 0)} $MATIC
              </p>
            </section>
            <section className="p-8 bg-gray-900 bg-opacity-80 rounded-xl h-40 flex flex-col justify-center">
              <p className="text-p3 text-gray-300 font-gilroy-medium mb-4">
                Recipient’s Wallet Address:
              </p>
              <input
                required
                className=" bg-transparent focus:outline-none text-p3 font-gilroy-medium "
                placeholder="Click here to enter address"
                name="receiverAddress"
                ref={register({
                  required: true
                })}
              />
            </section>

            <div className="flex justify-center mt-16">
              <Button
                rounded
                className=" w-1/3 font-bold"
                padding="px-10 py-4"
                fontSize="text-p3"
                type="submit"
              >
                Transfer
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export default TransferMaticModal
