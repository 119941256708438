import { ethers } from 'ethers'
import { useState, useCallback } from 'react'
import { useWeb3Context } from '../contexts/Web3Context'

(window as any).ethers = ethers

const useDiscordAssociator = (message?: string) => {
  const { signer } = useWeb3Context()
  const [signing, setSigning] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [response, setResponse] = useState<string | undefined>()

  const associate = useCallback(() => {
    if (!signer || !message) {
      throw new Error('no signer or no message')
    }
    setSigning(true)
    const decodedMessage = Buffer.from(message, 'base64').toString('utf8')
    console.log('decoded: ', decodedMessage);

    (signer.provider as any)
      .send('personal_sign', [decodedMessage, signer.address])
      .then((signature: string) => {
        console.log('signature: ', signature)
        setResponse(`/associate signature:\t${signature}`)
      })
      .catch((err: any) => {
        console.error('error signing: ', err)
        setError(err.message)
      })
      .finally(() => {
        setSigning(false)
      })
  }, [message, signer])

  return {
    associate,
    signing,
    error,
    response
  }
}

export default useDiscordAssociator
