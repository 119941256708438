import React, { useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import GladiatorBiography from '../GladiatorBiography/GladiatorBiography'
import GladiatorStats from '../GladiatorStats/GladiatorStats'
import GladiatorInterface from '../../../../interfaces/Gladiator.interface'
import Avatar from '../../../atoms/Avatar/Avatar'
import YouPill from '../../../atoms/YouPill/YouPill'
import { useGladiatorOwner } from '../../../../hooks/useGladiatorOwner'

type TabName = 'stats' | 'biography'

interface GladiatorInfoProps {
  gladiatorInfo: GladiatorInterface
  faction?: string
}

const Tabs: TabName[] = ['stats', 'biography']

const GladiatorInfo: React.FC<GladiatorInfoProps> = ({
  gladiatorInfo,
  faction
}) => {
  const [activeTab, setActiveTab] = useState<TabName>('stats')
  const { owner, isCurrentUserOwner } = useGladiatorOwner(gladiatorInfo.id)

  const getTabClassName = (tabName: TabName) => {
    let tabClassName =
      'text-gray-400 font-gilroy-medium  border-b-4 border-transparent'
    if (tabName === activeTab) {
      tabClassName = 'font-gilroy-bold border-b-4 border-primary text-primary'
    }
    return tabClassName
  }

  return (
    <Container className="px-6 py-20 lg:px-20 w-full lg:w-3/5 ml-0 lg:ml-16 bg-black bg-opacity-50">
      {owner && (
        <div className="w-full mb-20 flex items-center max-w-full">
          <p className="text-base font-bold">Contract Owner:</p>
          <div className="flex ml-8 items-center max-w-full">
            <Avatar size={40} text={owner.address} />
            <p className=" text-p3 mx-8 truncate w-1/3">{owner.address}</p>
            {isCurrentUserOwner && <YouPill />}
          </div>
        </div>
      )}

      <nav className="mb-6">
        <ul className="flex items-center">
          {Tabs.map((tab) => (
            <li key={tab}>
              <button
                className={classNames(
                  'text-p-lg pb-6 text-gray-400 focus:outline-none capitalize mr-10 font-gilroy-regular',
                  getTabClassName(tab)
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <section className={activeTab === 'stats' ? 'block' : 'hidden'}>
        <GladiatorStats gladiatorInfo={gladiatorInfo} faction={faction} />
      </section>

      <section className={activeTab === 'biography' ? 'block' : 'hidden'}>
        <GladiatorBiography
          faction={faction}
          name={gladiatorInfo.name}
          historyUrl={gladiatorInfo.properties.history}
        />
      </section>
    </Container>
  )
}

const Container = styled.figure`
  border: 3px solid;
  border-image-source: linear-gradient(
    180deg,
    #000000 0%,
    #646464 48.96%,
    #242424 97.92%,
    #000000 100%
  );
  border-image-slice: 1%;

  img {
    max-height: 90%;
  }
`

export type { GladiatorInfoProps }

export default GladiatorInfo
