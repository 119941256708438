import { useCallback, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { BigNumber, constants } from 'ethers'
import { useWeb3Context } from '../contexts/Web3Context'
import {
  fetchApproveSpender,
  fetchApproveTransaction,
  fetchQuote,
  fetchSwap
} from '../utils/1inch'
import { tokenFromAddress, isNativeToken } from '../utils/tokens'
import waitForTx from '../utils/waitForTx'

const safeDecimals = 5

/**
 * @param amtString the human readable input (1 PTG, 0.0001 BTC)
 * @param decimals the number of decimals the destination token
 * @returns the expected token units for example 1 PTG becomes 1*1e18 and 1 USDC becomes 1e6
 */
function humanInputToToken(amtString: string, decimals: number) {
  const amtStringAsFloat = parseFloat(amtString) || 0
  // first multiply the human by a safe number of digits so that we can make sure we keep at least safeDecimals accuracy
  const expandedInput = Math.floor(amtStringAsFloat * 10 ** safeDecimals)
  // now we convert that to a BigNumber and increase its size by the correct number of decimals for the token.
  const amount = BigNumber.from(
    expandedInput
  ).mul((10 ** (decimals - safeDecimals)).toString())
  // do not support negative numbers
  if (amount.lte(0)) {
    return BigNumber.from(0)
  }
  return amount
}

export const useBalance = (address: string) => {
  const { signer } = useWeb3Context()
  const { token } = useToken(address)
  const { data, revalidate } = useSWR(
    () => {
      if (!signer) {
        throw new Error('waiting on dependencies')
      }
      return ['/token-balance', address]
    },
    {
      fetcher: async () => {
        if (!signer) {
          throw new Error('expected signer')
        }
        if (isNativeToken(address)) {
          console.log('signer addr: ', await signer.getAddress())
          const bal = await signer.getBalance()
          console.log('bal: ', bal)
          return bal
        }
        if (!token) {
          throw new Error('expected token')
        }

        return token.balanceOf(signer.address!)
      }
    }
  )

  return {
    balance: data,
    loading: !data,
    revalidate
  }
}

export const useApprovalSpender = () => {
  const { data } = useSWR('/token-approvals/spender', {
    fetcher: async () => {
      const resp = await fetchApproveSpender()
      return resp.data.address
    },
    revalidateOnFocus: false,
    refreshInterval: 12 * 60 * 60 * 1000 // only refresh once every 12 hours
  })
  return data
}

export const useIsApproved = (
  tokenAddress: string,
  amount: string,
  userAddress: string|undefined
) => {
  const approvalAddress = useApprovalSpender()
  const { decimals, token, loading } = useToken(tokenAddress)

  const { data, revalidate } = useSWR(
    () => {
      if (!approvalAddress || !userAddress || loading) {
        throw new Error('waiting on dependencies')
      }
      return ['/token-approvals', tokenAddress, amount]
    },
    {
      fetcher: async (_, _tokenAddress, amountString) => {
        try {
          if (isNativeToken(_tokenAddress)) {
            return constants.MaxUint256
          }

          if (!approvalAddress || !userAddress || !token || !decimals) {
            throw new Error('waiting on dependencies')
          }

          const amount = humanInputToToken(amountString, decimals)
          const approvedFor = await token.allowance(
            userAddress,
            approvalAddress
          )
          console.log(
            'approved for: ',
            approvedFor.toString(),
            'amount: ',
            amount.toString()
          )
          return approvedFor.gte(amount)
        } catch (err) {
          console.error(err)
          throw err
        }
      }
    }
  )

  return {
    isApproved: data,
    revalidate,
    loading: !data
  }
}

export const useApproval = () => {
  const { signer } = useWeb3Context()
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const approve = useCallback(
    async (address: string) => {
      setLoading(true)
      setErr('')
      try {
        const tx = await fetchApproveTransaction(address)
        return signer?.sendTransaction(tx)
      } catch (err: any) {
        setErr(err.message)
      } finally {
        setLoading(false)
      }
      return undefined
    },
    [signer]
  )

  return {
    approve,
    err,
    loading
  }
}

const useToken = (address: string) => {
  const { data, revalidate } = useSWR(['/token', address], {
    fetcher: async (_, address) => {
      if (isNativeToken(address)) {
        return {
          token: undefined,
          decimals: 18,
        }
      }
      const token = await tokenFromAddress(address)
      const decimals = await token.decimals()
      return {
        token,
        decimals
      }
    }
  })
  return {
    decimals: (data || {}).decimals,
    token: (data || {}).token,
    revalidate,
    loading: !data
  }
}

export const useTokenQuote = (
  input: string,
  output: string,
  amount: string // human-readable amount (this function takes care of decimals)
) => {
  const { decimals, loading } = useToken(input)

  const { data, isValidating, revalidate } = useSWR(
    () => {
      if (loading) {
        throw new Error('still getting the input token')
      }
      return ['/1inch-quote', input, output, amount]
    },
    {
      fetcher: async (_, input, output, amtString) => {
        if (!decimals) {
          throw new Error('still no decimals... should not happen')
        }
        try {
          const amount = humanInputToToken(amtString, decimals)
          return fetchQuote(input, output, amount)
        } catch (err) {
          console.error('error fetching 1inch quote: ', err)
          throw err
        }
      }
    }
  )

  return {
    amountOut: data,
    isValidating,
    revalidate,
    loading: !data
  }
}

export const useSwapFromPTG = (output: string) => {
  const { signer, safeAddress, contracts, relayer, prestigeID } =
    useWeb3Context()

  const swap = useCallback(
    async (amountString: string) => {
      if (!relayer || !safeAddress || !signer) {
        console.error(relayer, safeAddress, signer)
        throw new Error('missing dependencies')
      }
      const { wrappedPTG, assets } = await contracts
      const amount = humanInputToToken(amountString, 18)
      const signerAddr = signer.address
      if (!signerAddr) {
        throw new Error('missing signer address')
      }

      const [approvalTx, swapTx, wrapTx] = await Promise.all([
        fetchApproveTransaction(wrappedPTG.address),
        fetchSwap(
          wrappedPTG.address,
          output,
          amount,
          safeAddress,
          signerAddr
        ),
        assets.populateTransaction.safeTransferFrom(
          safeAddress,
          wrappedPTG.address,
          await prestigeID,
          amount,
          []
        )
      ])

      if (!swapTx) {
        throw new Error('problem getting swap tx')
      }

      return relayer.multisend([approvalTx, wrapTx, swapTx])
    },
    [signer, safeAddress, contracts, relayer, prestigeID, output]
  )

  return {
    swap
  }
}

export const useSwapToPTG = (input: string, humanAmount: string) => {
  const { signer, safeAddress, contracts } = useWeb3Context()
  const { decimals } = useToken(input)
  const { isApproved } = useIsApproved(input, humanAmount, signer?.address)
  const amount = humanInputToToken(humanAmount, decimals || 18)

  const swap = useCallback(async () => {
    console.log('swap called')
    if (!safeAddress || !signer) {
      throw new Error('missing dependencies')
    }
    const { wrappedPTG } = await contracts
    if (!isApproved) {
      throw new Error('must approve first')
    }
    // first we swap
    const swapTxData = await fetchSwap(
      input,
      wrappedPTG.address,
      amount,
      signer.address!,
      safeAddress
    )
    if (!swapTxData) {
      throw new Error('something went wrong in the swap')
    }

    // let the user sign this
    await waitForTx(signer.sendTransaction(swapTxData))
    console.log('swap complete, unwrapping')
    // now the safeAddress has wrappedPTG so let's unwrap it
    const balance = await wrappedPTG.balanceOf(safeAddress)
    const receiptP = waitForTx(wrappedPTG.unwrap(safeAddress, safeAddress, balance))
    receiptP.then(() => {
      mutate(['/wrapped-ptg-balance', safeAddress], BigNumber.from(0))
    })
    return receiptP
  }, [safeAddress, signer, isApproved, amount, contracts, input])

  return {
    swap
  }
}
