import { BigNumber, BigNumberish, utils } from 'ethers'
import { MPTG_PER_PTG } from '../constants'

const formatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 3,
  minimumFractionDigits: 2
})

const humanBigNumber = (num: BigNumber | BigNumberish, miniPTG?: boolean) => {
  const formatted = utils.formatEther(num)
  let number = parseFloat(formatted)

  if (miniPTG) {
    number = parseFloat(formatted) * MPTG_PER_PTG
  }
  return formatter.format(number)
}

export default humanBigNumber
