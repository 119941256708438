import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BigNumber, providers } from 'ethers'
import { Relayer } from 'kasumah-relay-wrapper/dist/src/relayers'
import { Address } from 'kasumah-wallet'

import gameChain, { Contracts, GameChain, SignerWithAddress } from '../models/GameChain'
import { Assets } from '../types/contracts'
import useInviteStore from '../hooks/useInviteStore'

export interface Web3ContextData {
  contracts: Promise<Contracts>
  deprecatedAssets: Promise<Assets|undefined>
  prestigeID: Promise<BigNumber>
  gladiatorRange: Promise<[BigNumber, BigNumber]>
  trophyRangeV4: Promise<[BigNumber, BigNumber]>
  trophyRangeV5: Promise<[BigNumber, BigNumber]>
  localNetwork: boolean
  networkName: string
  chainId: number
  connected: boolean
  connecting: boolean

  newUser: GameChain['newUser']
  clearNewUser: ()=>void

  hasMetamask: () => Promise<boolean>
  connect: () => Promise<any>
  connectMetamask: () => Promise<any>

  logout: () => Promise<void>
  subscribe: GameChain['subscribe']
  genesisBlock: number
  relayer?: Relayer

  provider?: providers.Provider
  signer?: SignerWithAddress
  safeAddress?: Address
  loading?: boolean
  error?: string
}

function ctxFromGameChain() {
  return {
    contracts: gameChain.contracts,
    deprecatedAssets: gameChain.deprecatedAssets,
    prestigeID: gameChain.prestigeID,
    gladiatorRange: gameChain.gladiatorRange,
    trophyRangeV4: gameChain.trophyRangeV4,
    trophyRangeV5: gameChain.trophyRangeV5,
    provider: gameChain.provider,
    signer: gameChain.signer,
    localNetwork: gameChain.localNetwork,
    networkName: gameChain.networkName,
    chainId: gameChain.chainId,
    connected: gameChain.connected,
    genesisBlock: gameChain.genesisBlock,
    newUser: gameChain.newUser,
    relayer: gameChain.relayer,
    hasMetamask: gameChain.hasMetamask.bind(gameChain),
    connectMetamask: gameChain.connectWithMetamask.bind(gameChain),
    connect: gameChain.connect.bind(gameChain),
    logout: gameChain.logout.bind(gameChain),
    subscribe: gameChain.subscribe.bind(gameChain),
  }
}

const defaultContextData = {
  ...ctxFromGameChain(),
  connecting: false,
  clearNewUser: () => undefined
}

export const useWeb3Context = () => {
  return useContext(Web3Context)
}

export const Web3Context = React.createContext<Web3ContextData>(
  defaultContextData
)

export const Web3Provider: React.FC = ({ children }) => {
  // re-render on connected vs not connected
  const [connected, setConnected] = useState(gameChain.connected)
  const [connecting, setConnecting] = useState(gameChain.connecting)
  const [safeAddress, setSafeAddress] = useState(gameChain.safeAddress)
  const [newUser, setNewUser] = useState<GameChain['newUser']>()
  const { clearInviteCode } = useInviteStore()

  const clearNewUser = useCallback(() => {
    setNewUser(undefined)
    clearInviteCode()
  }, [setNewUser, clearInviteCode])

  useEffect(() => {
    const onConnect = async () => {
      console.log('web3 context emitting new user: ', gameChain.newUser)
      setNewUser(gameChain.newUser)
      setConnected(true)
      setConnecting(false)
      setSafeAddress(gameChain.safeAddress)
    }
    const onConnecting = async () => {
      setConnecting(true)
    }
    gameChain.on('connected', onConnect)
    gameChain.on('connecting', onConnecting)
    return () => {
      gameChain.off('connected', onConnect)
      gameChain.off('connecting', onConnecting)
    }
  }, [])

  return (
    <Web3Context.Provider
      value={{
        ...ctxFromGameChain(),
        safeAddress,
        connected,
        connecting,
        newUser,
        clearNewUser,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}
