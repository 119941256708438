import { SkyDb } from 'qc-skydb'

const networkName = process.env.REACT_APP_NETWORK_NAME || 'localhost'

const publicKey = (networkName === 'localhost') ? 'dca31bc576dea72608cd8b5c804b2de97c98e3ee944d4ddde12527af655c2cc9' : 'c47cabffd0c3f769b70b607e9a283b776e390a2840eb4a83f9fe585e2872b500'

const client = new SkyDb()

export async function getJSON<T=Record<string, unknown>>(key:string) {
    const resp = await client.db.getJSON(publicKey, key)
    return {
        ...resp,
        data: resp.data as T
    }
}
