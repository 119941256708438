import { ContractTransaction, constants, VoidSigner } from 'ethers'
import { GnosisSafe__factory } from 'kasumah-wallet/dist/types/ethers-contracts'

const voidSafe = GnosisSafe__factory.connect(
  constants.AddressZero,
  new VoidSigner(constants.AddressZero)
)
const successTopic = voidSafe.interface.getEventTopic(
  'ExecutionSuccess(bytes32,uint256)'
)

export default async function waitForTx(txP: Promise<ContractTransaction>) {
  const tx = await txP
  console.log('waiting for tx: ', tx.hash)
  return tx.wait()
}

export async function waitForRelayedTransaction(tx: ContractTransaction) {
  const receipt = await tx.wait()
  const lastTwoLogs = receipt.logs.slice(-2) // on the mainnet there's a LogFeeTransfer event that's last, but not locally
  const success = lastTwoLogs.find((l) => l.topics[0] === successTopic)
  if (!success) {
    console.error('error with transaction: ', receipt)
    throw new Error(`Error with transaction ${receipt.transactionHash}`)
  }
  return receipt
}
