import { BigNumber } from 'ethers'
import { DateTime } from 'luxon'
import React from 'react'
import { Bid } from '../../../../interfaces/Auction.interface'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Avatar from '../../../atoms/Avatar/Avatar'

interface BiddingCardProps {
  bid: Bid
  safeAddress?: string
}

const BiddingCard: React.FC<BiddingCardProps> = ({ bid, safeAddress }) => {
  const time = bid.time
    ? DateTime.fromSeconds(BigNumber.from(bid.time).toNumber())
    : 0

  return (
    <article className="flex max-w-screen overflow-x-scroll justify-between px-6 py-3 rounded-full lg:hover:bg-gray-900 bg-opacity-80 items-center mb-12">
      <div className="flex items-center w-1/2 lg:w-1/3">
        <Avatar text={bid.bidder.toString()} />

        <div className="ml-3 w-2/3">
          <p className="font-gilroy-semibold truncate text-base text-primary">
            {bid.bidder.toString()}
          </p>
          {safeAddress === bid.bidder.toString().toLowerCase() ? (
            <span className="inline-block rounded-full text-white text-p4 mt-2 font-gilroy-bold px-4 py-1 bg-primary">
              Your bid
            </span>
          ) : (
            <p className="font-gilroy-medium text-p3 text-gray-300">
              Placed bid
            </p>
          )}
        </div>
      </div>
      <div className="w-1/2 mx-4 lg:w-1/4">
        {time && (
          <>
            <p className="font-gilroy-semibold text-base">
              {time.toLocaleString(DateTime.DATE_FULL)}
            </p>
            <p className="font-gilroy-medium text-p4 text-gray-200">
              {time.toLocaleString(DateTime.TIME_SIMPLE)}
            </p>
          </>
        )}
      </div>
      <div className="w-1/4 lg:w-1/4">
        <p className="font-gilroy-bold text-base">
          {humanBigNumber(bid.amount)}
        </p>
      </div>
    </article>
  )
}

export type { BiddingCardProps }
export default BiddingCard
