import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { backOff } from 'exponential-backoff'
import classNames from 'classnames'
import { useWeb3Context } from '../../../contexts/Web3Context'
import Spinner from '../../atoms/Spinner/Spinner'
import useInviteCodeMetadata from '../../../hooks/useInviteCodeMetadata'
import humanBigNumber from '../../../utils/humanBigNumber'
import { waitForRelayedTransaction } from '../../../utils/waitForTx'

interface InviteCodeFeedbackparams {
  className?: string
}

const InviteCodeFeedback: React.FC<InviteCodeFeedbackparams> = ({ className }) => {
  const { newUser, provider } = useWeb3Context()

  const wrapperDivClasses = useMemo(() => {
    return classNames('flex flex-col align-middle text-base text-center', className)
  }, [className])

  const { metadata, loading: inviteCodeLoading } = useInviteCodeMetadata(newUser ? newUser.inviteCode : undefined)
  const [feedbackLoading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const loading = feedbackLoading || inviteCodeLoading

  const waitForTx = useCallback(
    async (txHash: string) => {
      console.log('calling waitForTx: ', txHash)
      if (!provider) {
        throw new Error('no provider yet')
      }
      try {
        setLoading(true)
        const tx = await backOff(
          () => {
            try {
              return provider.getTransaction(txHash)
            } catch (err) {
              console.error('error getting invite code gransaction: err')
              throw err
            }
          },
          {
            maxDelay: 1000,
            numOfAttempts: 20
          }
        )
        console.log('waiting on invite code tx: ', tx.hash)
        await waitForRelayedTransaction(tx)
      } catch (err) {
        console.error('error with invite code: ', err)
        setErr("Your invite code is no longer valid, but don't let that stop your fun!")
      } finally {
        setLoading(false)
      }
    },
    [provider]
  )

  useEffect(() => {
    if (newUser?.inviteCodeTxHash) {
      waitForTx(newUser.inviteCodeTxHash)
    }
    console.log('no tx hash')
  }, [waitForTx, newUser])

  if (!newUser?.inviteCodeTxHash) {
    return null
  }

  if (loading) {
    return (
      <div className={wrapperDivClasses}>
        <Spinner />
        <p className="text-base">Checking on your invite code.</p>
      </div>
    )
  }

  if (err) {
    return (
      <div className={wrapperDivClasses}>
        <p>{err}</p>
      </div>
    )
  }

  if (!metadata) {
    console.log('metadata: ', metadata, inviteCodeLoading, loading)
    throw new Error(
      'at this point the loading is off and so metadata should be loaded'
    )
  }

  return (
    <div className={wrapperDivClasses}>
      <p>
        Congratulations! Your invite code: &quot;{newUser.inviteCode}&quot; has gifted you{' '}
        {metadata?.instancesPerUse.toString()} free bets worth $PTG {humanBigNumber(metadata?.valuePerInstance)} each!
      </p>
    </div>
  )
}

export default InviteCodeFeedback
