const REFERRER = 'referrer'
const INVITE_CODE = 'invite-code'

// exported here for use in GameChain as well
export function getReferrer() {
  return localStorage.getItem(REFERRER) || undefined
}

function setReferrer(referrer: string) {
  console.log('storing referrer: ', referrer)
  return localStorage.setItem(REFERRER, referrer)
}

export function getInviteCode() {
  return localStorage.getItem(INVITE_CODE) || undefined
}

function setInviteCode(inviteCode: string) {
  console.log('storing inviteCode: ', inviteCode)
  return localStorage.setItem(INVITE_CODE, inviteCode)
}

function clearInviteCode() {
  localStorage.removeItem(REFERRER)
  return localStorage.removeItem(INVITE_CODE)
}

export default function useInviteStore() {
  return {
    setReferrer,
    getReferrer,
    setInviteCode,
    getInviteCode,
    clearInviteCode
  }
}
