import React, { useState } from 'react'
import styled from 'styled-components'
import { ItemConfigInterface } from '../../../../interfaces/ItemConfig.interface'
import SkynetImageFixer from '../../../atoms/SkynetImageFixer/SkynetImageFixer'

export interface BoughtItemIndicatorProps {
  item: ItemConfigInterface
}

type ItemTypeName = 'common' | 'uncommon' | 'rare'

const getBackgroundColor = (type: ItemTypeName): string => {
  switch (type) {
    case 'common':
      return 'bg-green-600'
    case 'uncommon':
      return 'bg-indigo-600'
    case 'rare':
      return 'bg-purple-600'
    default:
      return ''
  }
}

const BoughtItemIndicator: React.FC<BoughtItemIndicatorProps> = ({ item }) => {
  const [showHiddenDropdown, setShowHiddenDropdown] = useState(false)
  let timeOutId: number | any = 0

  const onClickHandler = () => {
    setShowHiddenDropdown(!showHiddenDropdown)
  }

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      setShowHiddenDropdown(false)
    }, 10)
  }

  const onFocusHandler = () => {
    clearTimeout(timeOutId)
  }

  return (
    <StyledContainer onBlur={onBlurHandler} onFocus={onFocusHandler}>
      <button onClick={onClickHandler}>
        <SkynetImageFixer
          src={item.image}
          alt="items equipped"
          className="item-image mr-3"
        />
      </button>

      <div className="item-detail">
        <div className="w-full h-full flex flex-col justify-around items-center py-6 px-10 bg-dark bg-opacity-50">
          <div className="flex flex-col items-center">
            <span
              className={[
                'inline-block rounded-full text-white text-p5 uppercase font-gilroy-semibold px-4 py-1 ',
                getBackgroundColor(item.attributes.rarity as ItemTypeName)
              ].join('')}
            >
              {item.attributes.rarity}
            </span>
            <p className="font-gilroy-semibold text-p3 pt-4 text-center">
              {item.name}
            </p>
          </div>
          <SkynetImageFixer
            src={item.image}
            className="pt-12 pb-8 w-12"
            alt={item.attributes.rarity}
          />

          <div className="flex flex-col items-center">
            <p className="text-p5 font-gilroy-semibold text-center mb-4">
              {item.attributes.description}
            </p>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;

  &:hover {
    > .item-detail {
      transform: scale(1) !important;
    }
  }

  &:focus {
    > .item-detail {
      transform: scale(1) !important;
    }
  }

  .container {
    display: inline-flex;
    align-items: center;
    border: 1.5px solid var(--color-black);
    border-radius: 1000px;
    height: 39px;
    padding: 2px 10px 2px 2px;
  }

  .item-image {
    min-width: 3rem;
    max-width: 3rem;
  }

  .item-detail {
    position: absolute;
    top: 4rem;
    left: 0;
    transition: 0.2s all;
    transform-origin: top left;
    min-width: 225px;
    max-width: 215px;
    z-index: 10000;
    transform: scale(0);
    border: 1px solid black;
    background: black;
    border-radius: 8px;
    overflow: hidden;

    .itemDetail__header {
      background-color: var(--color-yellow);
      text-align: center;
      padding: 10px 0px;
    }
  }
`

export default BoughtItemIndicator
