import React from 'react'
import classnames from 'classnames'
import styled from 'styled-components'

type FontWeight = 400 | 500 | 600 | 700

interface IconProps {
  /** Check https://material.io/resources/icons/ for icon name to use */
  icon: string
  fontSize?: number
  fontWeight?: FontWeight
  color?:
    | 'text-primary'
    | 'text-white'
    | 'text-green-600'
    | 'text-red-600'
    | 'text-gray-300'
  className?: string
}

interface StyledIconProp {
  fontSize: number
  fontWeight: FontWeight
}

const StyledIcon = styled.span<StyledIconProp>`
  align-items: center;
  font-size: ${({ fontSize }) => fontSize}px !important;
  font-weight: ${({ fontWeight }) => fontWeight} !important;
  max-width: ${({ fontSize }) => fontSize}px !important;
  overflow: hidden;

  &::before {
    display: none;
  }
`

const Icon: React.FC<IconProps> = ({
  fontSize,
  fontWeight,
  icon,
  className,
  color
}) => {
  return (
    <StyledIcon
      fontSize={fontSize || 24}
      fontWeight={fontWeight || 600}
      className={classnames('material-icons', color, className)}
    >
      {icon}
    </StyledIcon>
  )
}

Icon.defaultProps = {
  color: 'text-white',
  fontSize: 14,
  fontWeight: 500
}

export type { IconProps }
export default Icon
