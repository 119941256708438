import React, { useMemo } from 'react'
import { utils } from 'ethers'
import { Link } from 'react-router-dom'
import NavLink from '../../atoms/NavLink/NavLink'
import Logo from '../../atoms/Logo/Logo'
import { useWeb3Context } from '../../../contexts/Web3Context'
import { usePrestigeBalance } from '../../../hooks/usePrestigeBalance'
import NavbarProfileIcon from '../NavProfileIcon/NavProfileIcon'
import humanBigNumber from '../../../utils/humanBigNumber'
import MobileMenu from '../MobileMenu/MobileMenu'
import { useFreeBetCoins } from '../../../hooks/useFreeBets'
import ConnectButton from '../../atoms/ConnectButton/ConnectButton'
import Button from '../../atoms/Button/Button'
import useGasSubsidy from '../../../hooks/useGasSubsidy'

export interface NavbarProps {}

interface NavLinkProps {
  title: string
  href: string
}

const navLinks: NavLinkProps[] = [
  {
    title: 'Tournaments',
    href: '/'
  },
  {
    title: 'Leaderboard',
    href: '/leaderboard'
  },
  {
    title: 'Gladiator Ranks',
    href: '/gladiators'
  },
  {
    title: 'NFT Marketplace',
    href: '/marketplace'
  },
]

const Navbar: React.FC<NavbarProps> = () => {
  const { connected, safeAddress } = useWeb3Context()
  const prestige = usePrestigeBalance()
  const { balance } = useFreeBetCoins()
  const { disabled, toggle } = useGasSubsidy()

  const isMiniPTG = useMemo(() => {
    return Number(utils.formatEther(prestige)) < 1
  }, [prestige])

  return (
    <>
      <div className="fixed top-0 left-0  z-50 w-full">
        <header className="w-full flex  px-6 lg:px-32 py-6 bg-black shadow-sm items-center justify-between">
          <div className="mr-24 flex items-center">
            <MobileMenu />
            <Logo className="h-20" />
          </div>
          <nav className="flex-grow hidden lg:block">
            <ul className="flex items-center">
              {navLinks.map(({ href, title }) => (
                <li key={title} className="mr-16">
                  <NavLink href={href} active={location.pathname === href}>
                    {title}
                  </NavLink>
                </li>
              ))}
              <li className="mr-16">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-white opacity-50 text-p3 uppercase font-gilroy-semibold"
                  href="https://docs.cryptocolosseum.com"
                >
                  Learn More
                </a>
              </li>
              <li className="text-white opacity-50 text-p3 uppercase font-gilroy-semibold">
                Gasless: <button onClick={toggle} className="underline">{disabled ? 'off' : 'on'}</button>
              </li>
            </ul>
          </nav>

          {connected && (
            <div className="flex items-center">
              <div className="mr-10 hidden md:block">
                <Link to="/wallet">
                  <Button rounded fluidWidth padding="px-10 py-3">
                    Get PTG
                  </Button>
                </Link>
              </div>
              <div className="font-gilroy-semibold whitespace-nowrap text-p3 lg:text-p2 text-primary mr-4">
                <p>
                  {humanBigNumber(prestige, isMiniPTG)}{' '}
                  {isMiniPTG ? 'mPTG' : 'PTG'}
                </p>
                {!!balance && balance > 0 && <p>Free bets: {balance}</p>}
              </div>
              {safeAddress && <NavbarProfileIcon address={safeAddress} />}
            </div>
          )}

          {!connected && (
            <ConnectButton spinnerText="If this is your first login, it could take up to 60s" />
          )}
        </header>
      </div>
    </>
  )
}
export default Navbar
