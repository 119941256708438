import useSWR from 'swr'
import { useWeb3Context } from '../contexts/Web3Context'
import gameChain from '../models/GameChain'

const useMaticBalanceFetcher = async (_:string, safeAddress:string) => {
    const { provider } = gameChain
    return provider.getBalance(safeAddress)
  }

const useMaticBalance = () => {
    const { safeAddress } = useWeb3Context()

    const { data, revalidate, isValidating } = useSWR(() => {
        if (!safeAddress) {
            throw new Error('no safe address yet')
        }
        return ['/matic-balance', safeAddress]
    }, {
        fetcher: useMaticBalanceFetcher
    })

    return {
        balance: data,
        loading: !data,
        revalidate,
        updating: isValidating
    }
}

export default useMaticBalance
