import { utils } from 'ethers'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'
import { useWeb3Context } from '../contexts/Web3Context'
import { useBettingLog } from '../hooks/useBetting'
import { BetLogItem } from '../interfaces/BetLogItem.interface'
import { Layout } from '../layouts/Layout'
import humanBigNumber from '../utils/humanBigNumber'

const BettingLogItem: React.FC<{ log: BetLogItem }> = ({ log }) => {
  const didWin = log.champion && log.champion.gladiator.eq(log.gladiatorId)
  const isUnknownBet = log.gladiatorId.eq(-1)

  const isMiniPTG = useMemo(() => {
    return Number(utils.formatEther(log.amount)) < 1
  }, [log.amount])

  return (
    <li className="mt-20">
      <div className="flex justify-between">
        <div>
          <p>{log.date.toLocaleString()}</p>
          <p>
            <Link
              to={`/tournament/${log.tournamentId}`}
              className="text-green-500 underline"
            >
              {log.tournamentName}
            </Link>
          </p>
          <p>{log.gladiatorName}</p>
          <p>
            {log.amount.gt(0) ? (
              <>
                {humanBigNumber(log.amount, isMiniPTG)}{' '}
                {isMiniPTG ? 'mPTG' : 'PTG'}{' '}
              </>
            ) : (
              '?'
            )}
          </p>
        </div>
        <div>
          <p>
            {log.champion && isUnknownBet && 'Unknown'}
            {log.champion && !isUnknownBet && (didWin ? 'Winner!' : 'Lost')}
            {!log.champion && 'Pending'}
          </p>
          {log.expectedWinnings.gt(0) && didWin && <p>Unclaimed</p>}
        </div>
      </div>
    </li>
  )
}

const BettingLogPage = () => {
  const ctx = useWeb3Context()
  const { loading: web3Loading, connected } = ctx

  const { bets, loading } = useBettingLog()

  if (connected) {
    return (
      <Layout>
        <PageContainerWithTitle
          title="Your bets"
          subTitle="Beta: free bets are not shown, and not all outcomes are known"
          loadingTitle="Bet log loading"
          loading={web3Loading || (connected && loading)}
        >
          <ol className="mt-10 text-p2">
            {bets?.map((log) => {
              return (
                <BettingLogItem
                  log={log}
                  key={`betting-log-item-${log.tournamentId.toHexString()}-${log.gladiatorId.toHexString()}-${log.amount.toString()}`}
                />
              ) // eslint-disable-line react/no-array-index-key
            })}
          </ol>
        </PageContainerWithTitle>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="w-full px-8 text-p-lg flex flex-col items-center max-w-1440">
        <h1 className="mb-10 mt-40">Please sign in to continue</h1>
      </div>
    </Layout>
  )
}

export default BettingLogPage
