/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-param-reassign */
import { Link } from 'react-router-dom'
import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import ItemCategoryButton from '../components/composed/TabWithIconButton/TabWithIconButton'
import { useWeb3Context } from '../contexts/Web3Context'
import {
  ItemStore,
  useGroupedEquipmentList,
  useMarketplace,
  BuyItemFn,
  SellItemFn,
  GetPricefn,
  useUserEquipment
} from '../hooks/useEquipment'
import { Layout } from '../layouts/Layout'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import UserItemCard from '../components/page-specific/user-items/UserItemCard/UserItemCard'
import BuyItemModal from '../components/page-specific/marketplace/BuyItemModal/BuyItemModal'
import SellItemModal from '../components/page-specific/marketplace/SellItemModal/SellItemModal'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'

interface MarketPlaceItems {
  items: ItemStore
  buyItem: BuyItemFn
  getPrice: GetPricefn
  sellItem: SellItemFn
}

const MarketPlaceItems: React.FC<MarketPlaceItems> = ({
  items,
  buyItem,
  getPrice,
  sellItem
}) => {
  const groupedItems = useGroupedEquipmentList(items)
  const [activeCategory, setActiveCategory] = useState<string>(
    Object.keys(groupedItems)[0]
  )
  const [showBuyModal, setShowBuyModal] = useState(false)
  const [showSellModal, setShowSellModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ItemConfigInterface | null>(
    null
  )
  const { connected, connect, loading } = useWeb3Context()

  const selectItem = (itemId: BigNumber, buying: boolean) => {
    if (!loading && !connected) {
      connect()
      return null
    }
    const item = groupedItems[activeCategory].find(
      (item: ItemConfigInterface) => item.id === itemId
    )
    setSelectedItem(item)

    if (buying) {
      return setShowBuyModal(true)
    }
    return setShowSellModal(true)
  }

  if (groupedItems.length) {
    return <p>No item</p>
  }

  return (
    <>
      <div className="w-full flex justify-center bg-opacity-50 lg:justify-start items-center top-36 sticky rounded-full bg-dark py-2 px-6 my-10">
        {Object.keys(groupedItems).map((category) => (
          <ItemCategoryButton
            active={activeCategory === category}
            category={category}
            key={category}
            image={
              groupedItems[category][0]?.image ||
              `/images/symbols/items-category/${category}.svg`
            }
            onClick={() => setActiveCategory(category)}
          />
        ))}
      </div>
      <section className="w-full mt-10 flex flex-wrap lg:-mx-5">
        {groupedItems[activeCategory]?.map((item: ItemConfigInterface) => (
          <UserItemCard
            viewItem={(buying: boolean) => {
              selectItem(item.id as BigNumber, buying)
            }}
            item={item}
            key={`demo-marketplace-row-${item.id}`}
          />
        ))}
      </section>

      {selectedItem && (
        <>
          <BuyItemModal
            item={selectedItem}
            show={showBuyModal}
            onModalClose={() => {
              setShowBuyModal(false)
              setSelectedItem(null)
            }}
            getPrice={getPrice}
            buyItem={buyItem}
          />

          <SellItemModal
            item={selectedItem}
            show={showSellModal}
            onModalClose={() => {
              setShowSellModal(false)
              setSelectedItem(null)
            }}
            getPrice={getPrice}
            sellItem={sellItem}
          />
        </>
      )}
    </>
  )
}

const UserItems = () => {
  const { loading: web3Loading, connected } = useWeb3Context()
  const { buyItem, getPrice, sellItem, loading } = useMarketplace()

  const { userInventory } = useUserEquipment()

  if (!connected) {
    return <Layout>Sign in to view items</Layout>
  }

  return (
    <Layout>
      <PageContainerWithTitle
        title="My NFT items"
        loadingTitle="Your items are loading, please wait"
        loading={web3Loading || !userInventory || loading}
        additionalItem={
          <Link
            to="/marketplace"
            className="font-gilroy-semibold duration-200 whitespace-nowrap inline-flex items-center justify-center transition-colors rounded-full bg-primary text-white px-10 py-4 text-p2 w-auto"
          >
            Visit marketplace
          </Link>
        }
      >
        {Object.keys(userInventory).length ? (
          <MarketPlaceItems
            sellItem={sellItem}
            getPrice={getPrice}
            items={userInventory}
            buyItem={buyItem}
          />
        ) : (
          <p className="font-gilroy-medium text-gray-400 text-p1 mb-4">
            Please visit Marketplace to add items
          </p>
        )}
      </PageContainerWithTitle>
    </Layout>
  )
}

export default UserItems
