/* eslint-disable no-param-reassign */
import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import ItemCard from '../components/composed/ItemCard/ItemCard'
import ItemCategoryButton from '../components/composed/TabWithIconButton/TabWithIconButton'
import BuyItemModal from '../components/page-specific/marketplace/BuyItemModal/BuyItemModal'
import { useWeb3Context } from '../contexts/Web3Context'
import {
  ItemStore,
  useGroupedEquipmentList,
  useMarketplace
} from '../hooks/useEquipment'
import { Layout } from '../layouts/Layout'
import { ItemConfigInterface } from '../interfaces/ItemConfig.interface'
import PageContainerWithTitle from '../components/composed/PageContainerWithTitle/PageContainerWithTitle'

type BuyItemFn = ReturnType<typeof useMarketplace>['buyItem']
type GetPricefn = ReturnType<typeof useMarketplace>['getPrice']

interface MarketPlaceItems {
  items: ItemStore
  buyItem: BuyItemFn
  getPrice: GetPricefn
}

const MarketPlaceItems: React.FC<MarketPlaceItems> = ({
  items,
  buyItem,
  getPrice
}) => {
  const groupedItems = useGroupedEquipmentList(items)
  const [activeCategory, setActiveCategory] = useState<string>(
    Object.keys(groupedItems)[0]
  )
  const [selectedItem, setSelectedItem] = useState<ItemConfigInterface | null>(
    null
  )
  const [showItemBuyModal, setShowItemBuyModal] = useState(false)
  const { connected, connect, loading } = useWeb3Context()

  const selectItem = (itemId: BigNumber) => {
    if (!loading && !connected) {
      connect()
      return
    }
    const item = groupedItems[activeCategory].find(
      (item: ItemConfigInterface) => item.id === itemId
    )
    setSelectedItem(item)
    setShowItemBuyModal(true)
  }

  return (
    <>
      <div className="w-full flex justify-center bg-opacity-50 lg:justify-start items-center top-36 sticky rounded-full bg-dark py-2 flex-wrap px-6 my-10">
        {Object.keys(groupedItems).map((category) => (
          <ItemCategoryButton
            active={activeCategory === category}
            category={category}
            key={category}
            image={
              groupedItems[category][0]?.image ||
              `/images/symbols/items-category/${category}.svg`
            }
            onClick={() => setActiveCategory(category)}
          />
        ))}
      </div>
      <section className="w-full mt-10 flex flex-wrap lg:-mx-5">
        {groupedItems[activeCategory].map((item: ItemConfigInterface) => (
          <ItemCard
            onBuyHandler={selectItem}
            item={item}
            key={`demo-marketplace-row-${item.id}`}
          />
        ))}
      </section>
      {selectedItem && (
        <BuyItemModal
          show={showItemBuyModal}
          onModalClose={() => {
            setShowItemBuyModal(false)
            setSelectedItem(null)
          }}
          getPrice={getPrice}
          buyItem={buyItem}
          item={selectedItem}
        />
      )}
    </>
  )
}

const Marketplace = () => {
  const { loading: web3Loading } = useWeb3Context()
  const { items, buyItem, getPrice } = useMarketplace()

  return (
    <Layout>
      <PageContainerWithTitle
        title="NFT Marketplace"
        subTitle=" Buy items to help your favorites win. (Note only 3 can be applied
            for any battle)"
        loading={web3Loading || !items}
        loadingTitle="Items loading"
      >
        {items && (
          <MarketPlaceItems
            getPrice={getPrice}
            items={items}
            buyItem={buyItem}
          />
        )}
      </PageContainerWithTitle>
    </Layout>
  )
}

export default Marketplace
