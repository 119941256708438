import React from 'react'
import Icon from '../../atoms/Icon/Icon'

interface PercentageMoveIndicatorProps {
  percent: number
  down?: boolean
}

const PercentageMoveIndicator: React.FC<PercentageMoveIndicatorProps> = ({
  percent,
  down
}) => {
  return (
    <div className="flex items-center">
      <Icon
        icon={down ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
        color={down ? 'text-red-600' : 'text-green-600'}
        fontSize={20}
      />
      <p
        className={`font-gilroy-regular text-p3 ${
          down ? 'text-red-600' : 'text-green-600'
        }`}
      >
        {percent.toPrecision(4)}%
      </p>
    </div>
  )
}
export type { PercentageMoveIndicatorProps }
export default PercentageMoveIndicator
