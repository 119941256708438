import classNames from 'classnames'
import React from 'react'

export interface SwipeRightIndicatorProps {
  className?: string
  title?: string
}

const SlideIndicator: React.FC<SwipeRightIndicatorProps> = ({
  className,
  title = 'Detail'
}) => {
  return (
    <div className={classNames(className, 'lg:hidden')}>
      <p className="text-primary font-semibold text-p4">
        Swipe for more {title} <span className="inline-block">← →</span>
      </p>
    </div>
  )
}

export default SlideIndicator
