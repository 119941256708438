import React from 'react'
import { BigNumber } from 'ethers'
import styled from 'styled-components'
import Button from '../../../atoms/Button/Button'
import humanBigNumber from '../../../../utils/humanBigNumber'
import Spinner from '../../../atoms/Spinner/Spinner'

interface BalanceAction {
  label: string
  action: () => any
  primary?: boolean
  loading?: boolean
}

interface BalanceCardProps {
  name: string
  symbol: string
  actions: BalanceAction[]
  additionalUi?: React.ReactElement
  balance?: BigNumber
  actionsDisabled?: React.ReactElement // if you pass this in it disables actions and shows this text instead
}

const ActionButton: React.FC<BalanceAction> = ({
  label,
  action,
  primary,
  loading
}) => {
  const classes = primary
    ? 'border border-primary ml-8 bg-opacity-60'
    : 'bg-opacity-60'

  if (loading) {
    return <Spinner />
  }

  return (
    <Button
      rounded
      className={classes}
      background="bg-theme-dark-gray"
      onClick={action}
    >
      {label}
    </Button>
  )
}

const BalanceCard: React.FC<BalanceCardProps> = ({
  name,
  symbol,
  balance,
  actions,
  actionsDisabled,
  additionalUi
}) => {
  return (
    <div className="flex flex-wrap lg:flex-nowrap items-center justify-between bg-gray-900 bg-opacity-30 px-4 lg:px-12 py-10 mb-4 rounded-12 text-base">
      <div className="flex-grow mr-10 flex lg:flex-row justify-start lg:items-center">
        <StyledImage
          src={symbol}
          alt={`${name} symbol`}
          className="object-contain"
        />
        <h3 className="text-p-lg ml-6 text-gray-200 font-gilroy-semibold flex">
          {name}
        </h3>
        <div className="ml-20">
          <p className=" text-p3 text-gray-300 font-gilroy-semibold">Balance</p>
          {balance ? (
            <p className="text-h5 font-gilroy-semibold">
              {humanBigNumber(balance)}
            </p>
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap items-center lg:ml-20 justify-end">
        {additionalUi && (
          <div className=" lg:w-1/2 my-12  lg:my-0">{additionalUi}</div>
        )}

        {!actionsDisabled && (
          <p className=" text-h5 text-gray-600 font-thin hidden lg:block">
            .|.
          </p>
        )}

        <div className="flex items-center lg:w-1/2 justify-end text-base">
          {actionsDisabled}
          {!actionsDisabled &&
            actions.map((action) => {
              return (
                <ActionButton
                  key={`balance-card-action-${name}-${action.label}`}
                  {...action}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

const StyledImage = styled.img`
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
`

export type { BalanceCardProps }
export default BalanceCard
